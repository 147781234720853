import React, { useEffect, useState } from "react";
import * as Mui from "@mui/material";
import { themes } from "services/constants";
import { GlobalDialogBox } from "components/GlobalDialogBox";
import { CustomButton } from "components/CustomComponents/CustomButton";
import { ViewPayRun } from "./ViewPayRun";
// import { dateFormat } from "services/constants";
// import moment from "moment";
// import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
// import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { useFetch } from "services/hooks/useFetch";
import { payRunTableHeader } from "../PayRollHeaders";
// import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { MoreVert } from "@mui/icons-material";
import { useSelector } from "react-redux";
import { showToast } from "services/utils/Status";
import sessionHandling from "services/utils/notificationUtils";

export const PayRunTable = ({
  payrunDetails,
  companyId,
  companyName,
  setViewPayRunScreen,
  setListViewPage,
  listViewPage,
  viewPayrunScreen,
  reCallApi,
}) => {
  const { domain, token } = useSelector((state) => state.tokenReducer);
  const [editDetails, setEditDetails] = useState(null);
  const [viewPayrunDetails, setViewPayRunDetails] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [openDialog, setOpenDialog] = useState(false);
  const [openInitiateDialog, setOpenInitiateDialog] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [updateData, setUpdateData] = useState(false);
  const [payrollGroupDetails, setPayrollGroupDetails] = useState(null);
  const {
    result: payRunGroupDetails,
    setLoad,
    loading,
  } = useFetch(
    editDetails?.id
      ? `${domain}payrun/group/retrieve/?pay_schedule_id=${editDetails?.id}`
      : null
  );

  useEffect(() => {
    if (!loading && updateData) {
      let groupDetails = payRunGroupDetails?.group_details?.map((element) => {
        return {
          ...element,
          date: null,
          formatedDate: null,
        };
      });
      setPayrollGroupDetails(groupDetails);
    }
  }, [loading, payRunGroupDetails?.group_details, updateData]);

  const handleChangePage = (event, newPage) => {
    setListViewPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setListViewPage(0);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  //ToDo: I want this code to initiate the details

  const actionButtonBasedOnStatusWithInitiate = async () => {
    try {
      const response = await fetch(`${domain}payrun/initiate/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `token ${token}`,
        },
        body: JSON.stringify({
          pay_schedule_id: editDetails?.id,
        }),
      });
      const res = await response.json();
      if (response.ok) {
        showToast("success", res.message);
        setOpenInitiateDialog(false);
        reCallApi(true);
      } else if (response.status === 409) {
        sessionHandling();
      } else {
        throw new Error(res.error);
      }
    } catch (error) {
      showToast("error", error.message);
    }
  };

  // const updateNewDateDetails = (shifGroupDetails, newDate) => {
  //   if ((shifGroupDetails, newDate)) {
  //     let updatedData = payrollGroupDetails?.map((element) => {
  //       if (element?.group_id === shifGroupDetails?.group_id) {
  //         return {
  //           ...element,
  //           date: newDate,
  //           formatedDate: moment(newDate).format(dateFormat),
  //         };
  //       } else {
  //         return element;
  //       }
  //     });
  //     setPayrollGroupDetails(updatedData);
  //   }
  //   const countNullDates = payrollGroupDetails.filter(
  //     (item) => item.date === null
  //   ).length;
  //   if (payrollGroupDetails.length === countNullDates) {
  //     let updatedData = payrollGroupDetails?.map((element) => {
  //       return {
  //         ...element,
  //         date: newDate,
  //         formatedDate: moment(newDate).format(dateFormat),
  //       };
  //     });
  //     setPayrollGroupDetails(updatedData);
  //   }
  // };

  return (
    <React.Fragment>
      {viewPayrunScreen ? (
        <ViewPayRun
          setViewPayRunScreen={setViewPayRunScreen}
          companyName={companyName}
          companyId={companyId}
          viewPayrunDetails={viewPayrunDetails}
          setViewPayRunDetails={setViewPayRunDetails}
          reCallApi={reCallApi}
        />
      ) : (
        <Mui.TableContainer
          component={Mui.Paper}
          sx={{ mt: 8, width: "100%", maxHeight: 850 }}
        >
          <Mui.Table>
            <Mui.TableHead
              sx={{
                backgroundColor: themes.primary,
                position: "sticky",
                top: -1,
                zIndex: 10,
              }}
            >
              <Mui.TableRow
                sx={{
                  "& .MuiTableCell-root": {
                    height: "15px",
                    padding: "3px 5px",
                  },
                }}
              >
                {payRunTableHeader.map((header) => (
                  <Mui.TableCell
                    sx={{ color: themes.blackColor, fontWeight: "bold" }}
                  >
                    {header.name}
                  </Mui.TableCell>
                ))}
                <Mui.TableCell>Action</Mui.TableCell>
              </Mui.TableRow>
            </Mui.TableHead>
            <Mui.TableBody>
              {payrunDetails?.length === 0 ? (
                <Mui.TableRow>
                  <Mui.TableCell colSpan={12}>
                    <Mui.Alert severity="info">No data available</Mui.Alert>
                  </Mui.TableCell>
                </Mui.TableRow>
              ) : (
                payrunDetails
                  ?.slice(
                    listViewPage * rowsPerPage,
                    listViewPage * rowsPerPage + rowsPerPage
                  )
                  .map((payschedule) => (
                    <Mui.TableRow
                      sx={{
                        "& .MuiTableCell-root": {
                          height: "15px",
                          padding: "3px 5px",
                          cursor:
                            payschedule.status === "Draft" ? "default" : "pointer",
                        },
                      }}
                    >
                      {payRunTableHeader.map((header) => (
                        <Mui.TableCell
                          onClick={() => {
                            if (payschedule.status !== "Draft") {
                              setViewPayRunDetails(payschedule);
                              setViewPayRunScreen(true);
                            }
                          }}
                          sx={{ color: themes.blackColor }}
                        >
                          {payschedule[header.key] ||
                          payschedule[header.key] === 0
                            ? payschedule[header.key]
                            : "N/A"}
                        </Mui.TableCell>
                      ))}
                      <Mui.TableCell>
                        <Mui.IconButton
                          onClick={(event) => {
                            setAnchorEl(event.currentTarget);
                            setEditDetails(payschedule);
                            setViewPayRunDetails(payschedule);
                          }}
                        >
                          <MoreVert />
                        </Mui.IconButton>
                        <Mui.Menu
                          anchorEl={anchorEl}
                          open={Boolean(anchorEl)}
                          onClose={handleMenuClose}
                          elevation={1}
                          transformOrigin={{
                            horizontal: "right",
                            vertical: "top",
                          }}
                          anchorOrigin={{
                            horizontal: "right",
                            vertical: "bottom",
                          }}
                        >
                          <Mui.MenuItem
                            sx={{ width: "100%" }}
                            onClick={() => {
                              setViewPayRunScreen(true);
                            }}
                          >
                            View
                          </Mui.MenuItem>
                          {editDetails?.status === "Draft" ? (
                            <Mui.MenuItem
                              sx={{ width: "100%" }}
                              onClick={() => {
                                handleMenuClose();
                                setOpenInitiateDialog(true);
                                setLoad(true);
                                setUpdateData(true);
                              }}
                            >
                              Initiate
                            </Mui.MenuItem>
                          ) : null}
                        </Mui.Menu>
                      </Mui.TableCell>
                    </Mui.TableRow>
                  ))
              )}
            </Mui.TableBody>
          </Mui.Table>
        </Mui.TableContainer>
      )}
      {!viewPayrunScreen && payrunDetails.length > 25 && (
        <Mui.TablePagination
          className="custom-pagination"
          component="div"
          rowsPerPageOptions={[25, 50, 75, 100]}
          count={payrunDetails.length}
          rowsPerPage={rowsPerPage}
          page={listViewPage}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      )}
      <GlobalDialogBox
        handleCloseDialog={() => {
          setOpenDialog(false);
        }}
        open={openDialog}
        title="Delete payrun period"
      >
        <Mui.Grid container>
          <Mui.Typography sx={{ paddingBottom: 2 }}>
            Are you sure,you want to delete the ?
          </Mui.Typography>
          <Mui.Grid
            xs={12}
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              alignItems: "flex-end",
            }}
          >
            <CustomButton
              actionFuntion={() => {
                setOpenDialog(false);
              }}
              actionName="No"
              typeName="button"
            />
            &nbsp;&nbsp;
            <CustomButton actionName="Yes" typeName="button" />
          </Mui.Grid>
        </Mui.Grid>
      </GlobalDialogBox>
      {openInitiateDialog ? (
        <GlobalDialogBox
          handleCloseDialog={() => {
            setOpenInitiateDialog(false);
          }}
          open={openInitiateDialog}
          title="Payrun Initiate"
          minWidth={700}
        >
          <Mui.Grid container>
            <Mui.Grid
              container
              sx={{ border: `2px solid ${themes.sidebarDivider}` }}
            >
              <Mui.Grid container sx={{ padding: 1, paddingBottom: 1 }}>
                <Mui.Grid xs={6}>
                  <Mui.Grid container>
                    <Mui.Grid xs={6}>
                      <Mui.Typography>Payrun Name</Mui.Typography>
                    </Mui.Grid>
                    <Mui.Grid xs={6}>
                      <Mui.Typography>{editDetails?.name}</Mui.Typography>
                    </Mui.Grid>
                  </Mui.Grid>
                </Mui.Grid>
                <Mui.Grid xs={6}>
                  <Mui.Grid container>
                    <Mui.Grid xs={6}>
                      <Mui.Typography>No of Employee</Mui.Typography>
                    </Mui.Grid>
                    <Mui.Grid xs={6}>
                      <Mui.Typography>
                        {editDetails?.num_of_employees}
                      </Mui.Typography>
                    </Mui.Grid>
                  </Mui.Grid>
                </Mui.Grid>
              </Mui.Grid>
              <Mui.Grid container sx={{ padding: 1 }}>
                <Mui.Grid xs={6}>
                  <Mui.Grid container>
                    <Mui.Grid xs={6}>
                      <Mui.Typography>Start date</Mui.Typography>
                    </Mui.Grid>
                    <Mui.Grid xs={6}>
                      <Mui.Typography>{editDetails?.start_date}</Mui.Typography>
                    </Mui.Grid>
                  </Mui.Grid>
                </Mui.Grid>
                <Mui.Grid xs={6}>
                  <Mui.Grid container>
                    <Mui.Grid xs={6}>
                      <Mui.Typography>End date</Mui.Typography>
                    </Mui.Grid>
                    <Mui.Grid xs={6}>
                      <Mui.Typography>{editDetails?.end_date}</Mui.Typography>
                    </Mui.Grid>
                  </Mui.Grid>
                </Mui.Grid>
              </Mui.Grid>
            </Mui.Grid>
            {/* payroll group details */}
            <Mui.Grid container sx={{ paddingTop: 1, paddingBottom: 1 }}>
              <Mui.Grid
                container
                sx={{ border: `2px solid ${themes.sidebarDivider}` }}
              >
                <Mui.Grid container sx={{ padding: 1 }}>
                  <Mui.Grid xs={4}>
                    <Mui.Typography sx={{ fontSize: 18, fontWeight: "bold" }}>
                      Payroll Group
                    </Mui.Typography>
                  </Mui.Grid>
                  <Mui.Grid xs={4}>
                    <Mui.Typography sx={{ fontSize: 18, fontWeight: "bold" }}>
                      Payment Bank
                    </Mui.Typography>
                  </Mui.Grid>
                  <Mui.Grid xs={4}>
                    <Mui.Typography sx={{ fontSize: 18, fontWeight: "bold" }}>
                      Pay Date
                    </Mui.Typography>
                  </Mui.Grid>
                </Mui.Grid>
                {payrollGroupDetails?.map((shifGroupDetails, index) => (
                  <Mui.Grid container sx={{ padding: 1 }}>
                    <Mui.Grid xs={4}>
                      <Mui.Typography>
                        {shifGroupDetails?.group_name
                          ? shifGroupDetails?.group_name.length >= 15
                            ? shifGroupDetails?.group_name.substr(0, 15) + "..."
                            : shifGroupDetails?.group_name
                          : "N/A"}
                      </Mui.Typography>
                    </Mui.Grid>
                    <Mui.Grid xs={4}>
                      <Mui.Typography>
                        {shifGroupDetails?.payment_bank
                          ? shifGroupDetails?.payment_bank.length >= 15
                            ? shifGroupDetails?.payment_bank.substr(0, 15) +
                              "..."
                            : shifGroupDetails?.payment_bank
                          : "N/A"}
                      </Mui.Typography>
                    </Mui.Grid>
                    <Mui.Grid xs={4} sx={{ paddingLeft: 1 }}>
                      {editDetails?.run_date}
                      {/* <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DatePicker
                        slotProps={{ textField: { size: "small" } }}
                        format="dd/MM/yyyy"
                        value={shifGroupDetails?.date}
                        onChange={(newDate) =>
                          updateNewDateDetails(shifGroupDetails, newDate)
                        }
                        minDate={new Date("2023-01-01")}
                        maxDate={new Date("2023-12-31")}
                        renderInput={(params) => <Mui.TextField {...params} />}
                      />
                    </LocalizationProvider> */}
                    </Mui.Grid>
                  </Mui.Grid>
                ))}
              </Mui.Grid>
            </Mui.Grid>
            <Mui.Grid container sx={{ paddingTop: 1 }}>
              <Mui.Grid
                xs={12}
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-end",
                  alignItems: "flex-end",
                }}
              >
                <CustomButton
                  actionFuntion={() => {
                    setOpenInitiateDialog(false);
                  }}
                  actionName="Cancel"
                  typeName="button"
                />
                &nbsp;&nbsp;
                <CustomButton
                  actionFuntion={() => {
                    actionButtonBasedOnStatusWithInitiate();
                  }}
                  actionName="Initiate"
                  typeName="button"
                />
              </Mui.Grid>
            </Mui.Grid>
          </Mui.Grid>
        </GlobalDialogBox>
      ) : null}
    </React.Fragment>
  );
};
