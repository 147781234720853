import React, { useState } from "react";
import {
  shiftCategoryWise,
  shiftWiseExpandDetails,
} from "pages/TeamZone/TeamZoneHeaders";
import { tableRowSx } from "services/constants";
import * as Mui from "@mui/material";
import { Loader } from "components/Loader";
import { themes } from "services/constants";
import { KeyboardArrowRight, KeyboardArrowDown } from "@mui/icons-material";
import { tableHeaderSx } from "services/constants";
export const ShiftWise = ({
  shiftReports,
  shiftLoading,
  setLeavePage,
  leavePage,
}) => {
  const [expandedRow, setExpandedRow] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const handleChangePage = (event, newPage) => {
    setLeavePage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setLeavePage(0);
  };
  return shiftLoading ? (
    <Loader info="Loading..." />
  ) : (
    <React.Fragment>
      <Mui.Grid container>
        <Mui.Grid xs={4}></Mui.Grid>
        <Mui.Grid xs={4}>
          <Mui.Typography sx={{ fontSize: 22, fontWeight: "bold" }}>
            Shift Attendance Report - Shift wise
          </Mui.Typography>
        </Mui.Grid>
        <Mui.Grid xs={4}></Mui.Grid>
      </Mui.Grid>
      <Mui.TableContainer
        component={Mui.Paper}
        sx={{ mt: 2, width: "100%", maxHeight: 850 }}
      >
        <Mui.Table>
          <Mui.TableHead
            sx={{
              backgroundColor: themes.primary,
              position: "sticky",
              top: -1,
              zIndex: 10,
            }}
          >
            <Mui.TableRow sx={tableRowSx}>
              <Mui.TableCell></Mui.TableCell>
              {shiftCategoryWise.map((header) => (
                <Mui.TableCell
                  sx={{ color: themes.blackColor, fontWeight: "bold" }}
                >
                  {header.name}
                </Mui.TableCell>
              ))}
            </Mui.TableRow>
          </Mui.TableHead>
          <Mui.TableBody>
            {shiftReports?.length === 0 ? (
              <Mui.TableRow sx={tableRowSx}>
                <Mui.TableCell colSpan={12} align="center">
                  <Mui.Alert severity="info">No data available</Mui.Alert>
                </Mui.TableCell>
              </Mui.TableRow>
            ) : (
              shiftReports
                ?.slice(
                  leavePage * rowsPerPage,
                  leavePage * rowsPerPage + rowsPerPage
                )
                .map((group_details) => (
                  <>
                    <Mui.TableRow sx={tableRowSx}>
                      <Mui.TableCell>
                        <Mui.IconButton
                          onClick={() => {
                            if (expandedRow === group_details.shift_name) {
                              setExpandedRow("");
                            } else {
                              setExpandedRow(group_details?.shift_name);
                            }
                          }}
                        >
                          {group_details?.shift_name === expandedRow ? (
                            <KeyboardArrowDown />
                          ) : (
                            <KeyboardArrowRight />
                          )}
                        </Mui.IconButton>
                      </Mui.TableCell>
                      {shiftCategoryWise.map((header) => (
                        <Mui.TableCell sx={{ color: themes.blackColor }}>
                          <Mui.Tooltip title={group_details[header.key]}>
                            {group_details[header.key] ||
                            group_details[header.key] === 0
                              ? group_details[header.key]
                              : "N/A"}
                          </Mui.Tooltip>
                        </Mui.TableCell>
                      ))}
                    </Mui.TableRow>
                    {group_details?.shift_name === expandedRow && (
                      <Mui.TableRow>
                        <Mui.TableCell colSpan={12}>
                          <Mui.TableContainer
                            sx={{
                              marginTop: 0,
                              marginLeft: "auto",
                              marginRight: "auto",
                              width: "100%px",
                            }}
                            className="w-96 border rounded"
                          >
                            <Mui.Table className="border">
                              <Mui.TableHead sx={tableHeaderSx}>
                                <Mui.TableRow sx={tableRowSx}>
                                  {shiftWiseExpandDetails.map((header) => (
                                    <Mui.TableCell
                                      sx={{
                                        color: themes.blackColor,
                                        fontWeight: "bold",
                                      }}
                                    >
                                      {header.name}
                                    </Mui.TableCell>
                                  ))}
                                </Mui.TableRow>
                              </Mui.TableHead>
                              <Mui.TableBody>
                                {group_details?.department?.length !== 0 ? (
                                  group_details?.department?.map(
                                    (group_employee_details) => (
                                      <Mui.TableRow sx={tableRowSx}>
                                        {shiftWiseExpandDetails.map(
                                          (header) => (
                                            <Mui.TableCell
                                              sx={{ color: themes.blackColor }}
                                            >
                                              <Mui.Tooltip
                                                title={
                                                  group_employee_details[
                                                    header.key
                                                  ]
                                                }
                                              >
                                                {group_employee_details[
                                                  header.key
                                                ] ||
                                                group_employee_details[
                                                  header.key
                                                ] === 0
                                                  ? group_employee_details[
                                                      header.key
                                                    ]
                                                  : "N/A"}
                                              </Mui.Tooltip>
                                            </Mui.TableCell>
                                          )
                                        )}
                                      </Mui.TableRow>
                                    )
                                  )
                                ) : (
                                  <Mui.TableRow sx={tableRowSx}>
                                    <Mui.TableCell
                                      colSpan={12}
                                      sx={{ padding: 0.5 }}
                                    >
                                      <Mui.Alert severity="info">
                                        No data available
                                      </Mui.Alert>
                                    </Mui.TableCell>
                                  </Mui.TableRow>
                                )}
                              </Mui.TableBody>
                            </Mui.Table>
                          </Mui.TableContainer>
                        </Mui.TableCell>
                      </Mui.TableRow>
                    )}
                  </>
                ))
            )}
          </Mui.TableBody>
        </Mui.Table>
        {shiftReports?.length > 25 ? (
          <Mui.TablePagination
            className="custom-pagination"
            component="div"
            rowsPerPageOptions={[25, 50, 75, 100]}
            count={shiftReports?.length}
            rowsPerPage={shiftReports}
            page={leavePage}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        ) : null}
      </Mui.TableContainer>
    </React.Fragment>
  );
};
