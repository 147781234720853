import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { themes } from "services/constants";
import { showToast } from "services/utils/Status";
import { Delete } from "@mui/icons-material";
import { CustomButton } from "components/CustomComponents/CustomButton";
import { Loader } from "components/Loader";
import { useForm } from "react-hook-form";
import * as Mui from "@mui/material";
import { GlobalDialogBox } from "components/GlobalDialogBox";
import { tableRowSx } from "services/constants";
import sessionHandling from "services/utils/notificationUtils";
export const Employee = ({ editSalaryeTemplateDetails }) => {
  const { globalSearchValue, domain, token } = useSelector(
    (state) => state.tokenReducer
  );
  const [employeeData, setEmployeeData] = useState(true);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [openDialog, setOpenDialog] = useState(false);
  const [loading, setLoading] = useState(false);
  const [employee, setEmployee] = useState([]);
  const [popupTitle, setPopupTitle] = useState("");
  const [deletePopup, setDeletePopup] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [employeeList, setEmployeeList] = useState([]);

  const { reset, setValue, getValues } = useForm({
    mode: "onBlur",
  });

  const handleClose = () => {
    setOpenDialog(false);
    setDeletePopup(false);
    setButtonDisabled(false);
  };

  useEffect(() => {
    const getDesignationDetails = async () => {
      setLoading(true);
      try {
        const response = await fetch(
          `${domain}salary_template/employee/?salary_template_id=${editSalaryeTemplateDetails.id}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `token ${token}`,
            },
          }
        );
        const res = await response.json();
        if (response.ok) {
          setEmployee(res);
          setEmployeeList(res);
        } else if (response.status === 409) {
          sessionHandling();
        } else {
          throw new Error(res.error);
        }
      } catch (error) {
        showToast("error", error.message);
      } finally {
        setLoading(false);
      }
    };
    if (employeeData) {
      getDesignationDetails();
      setEmployeeData(false);
    }
  }, [domain, editSalaryeTemplateDetails, employeeData, token]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    const searchFields = [
      "user_name",
      "employee_id",
      "department",
      "department_lead_name",
    ];
    const filteredResults = employeeList.filter((item) =>
      searchFields.some((key) =>
        item[key]
          ?.toString()
          .toLowerCase()
          .includes(globalSearchValue.toString().toLowerCase())
      )
    );
    setEmployee(filteredResults);
    setPage(globalSearchValue ? 0 : page);
  }, [globalSearchValue, employeeList, page]);

  const handleApproveClick = async () => {
    const id = getValues("user_id");
    setButtonDisabled(true);
    try {
      const response = await fetch(`${domain}salary_template/remove/`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `token ${token}`,
        },
        body: JSON.stringify({
          isdeleted: true,
          user_id: id,
        }),
      });
      const res = await response.json();
      if (response.ok) {
        setEmployeeData(true);
        showToast("error", res.message);
        handleClose();
      } else if (response.status === 409) {
        sessionHandling();
      } else {
        throw new Error(res.error);
      }
    } catch (error) {
      showToast("error", error.message);
    } finally {
      setButtonDisabled(false);
    }
  };

  return loading ? (
    <Loader info="Loading..." />
  ) : (
    <React.Fragment>
      {loading === false ? (
        <>
          <Mui.TableContainer
            sx={{
              paddingLeft: 5,
              paddingRight: 5,
              padding: 4,
              width: "100%",
              minHeight: "95%",
              borderRadius: 4,
              justifyContent: "center",
            }}
          >
            <Mui.Table sx={tableRowSx}>
              <Mui.TableHead
                sx={{
                  backgroundColor: themes.primary,
                }}
              >
                <Mui.TableRow maxWidth="xl" align="left">
                  <Mui.TableCell
                    sx={{ color: themes.blackColor, fontWeight: "bold" }}
                  >
                    Employee name
                  </Mui.TableCell>
                  <Mui.TableCell
                    sx={{ color: themes.blackColor, fontWeight: "bold" }}
                  >
                    Employee id
                  </Mui.TableCell>
                  <Mui.TableCell
                    sx={{ color: themes.blackColor, fontWeight: "bold" }}
                  >
                    Department
                  </Mui.TableCell>
                  <Mui.TableCell
                    sx={{ color: themes.blackColor, fontWeight: "bold" }}
                  >
                    Manager
                  </Mui.TableCell>
                  <Mui.TableCell
                    sx={{ color: themes.blackColor, fontWeight: "bold" }}
                  >
                    Actions
                  </Mui.TableCell>
                </Mui.TableRow>
              </Mui.TableHead>
              <Mui.TableBody>
                {employee
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((item, index) => (
                    <Mui.TableRow maxWidth="xl" align="left">
                      <Mui.TableCell
                        sx={{ color: themes.blackColor, fontWeight: "bold" }}
                      >
                        <Mui.Typography>
                          {item?.user_name ? item?.user_name : "N/A"}
                        </Mui.Typography>
                      </Mui.TableCell>
                      <Mui.TableCell
                        sx={{ color: themes.blackColor, fontWeight: "bold" }}
                      >
                        <Mui.Typography>
                          {item?.employee_id ? item?.employee_id : "N/A"}
                        </Mui.Typography>
                      </Mui.TableCell>
                      <Mui.TableCell
                        sx={{ color: themes.blackColor, fontWeight: "bold" }}
                      >
                        <Mui.Typography>
                          {item?.department ? item?.department : "N/A"}
                        </Mui.Typography>
                      </Mui.TableCell>
                      <Mui.TableCell
                        sx={{ color: themes.blackColor, fontWeight: "bold" }}
                      >
                        <Mui.Typography>
                          {item?.department_lead_name
                            ? item?.department_lead_name
                            : "N/A"}
                        </Mui.Typography>
                      </Mui.TableCell>
                      <Mui.TableCell
                        sx={{ color: themes.blackColor, fontWeight: "bold" }}
                      >
                        <Mui.IconButton
                          onClick={(event) => {
                            reset();
                            setValue("user_id", item.user_id);
                            setDeletePopup(true);
                            setOpenDialog(true);
                            setPopupTitle("Confirm Removal");
                          }}
                          sx={{ marginLeft: 1 }}
                        >
                          <Delete />
                        </Mui.IconButton>
                      </Mui.TableCell>
                    </Mui.TableRow>
                  ))}
              </Mui.TableBody>
            </Mui.Table>
            {employee.length === 0 && loading === false ? (
              <Mui.Alert sx={{ marginTop: 3 }} severity="info">
                No data available
              </Mui.Alert>
            ) : null}
          </Mui.TableContainer>
          <Mui.Grid
            container
            sx={{
              paddingTop: 1,
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              alignItems: "flex-end",
              paddingRight: 1,
            }}
          >
            {employee.length > 25 ? (
              <Mui.TablePagination
                className="custom-pagination"
                component="div"
                rowsPerPageOptions={[25, 50, 75, 100]}
                count={employee.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            ) : null}
          </Mui.Grid>
        </>
      ) : null}

      <GlobalDialogBox
        handleCloseDialog={handleClose}
        open={openDialog}
        title={popupTitle}
      >
        {deletePopup ? (
          <Mui.Grid container>
            <Mui.Grid container spacing={3}>
              <Mui.Grid item xs={10}>
                <Mui.Typography sx={{ fontSize: 19, paddingBottom: 2 }}>
                  Are you sure you want to remove from user this salary
                  template?
                </Mui.Typography>
              </Mui.Grid>
            </Mui.Grid>
            <Mui.Grid xs={12} sx={{ paddingTop: 2 }}>
              <Mui.Stack
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-end",
                  alignItems: "flex-end",
                }}
              >
                <CustomButton
                  key={"Confirm Removal"}
                  actionFuntion={() => {
                    setOpenDialog(false);
                    handleClose();
                  }}
                  actionName="No"
                  typeName="submit"
                />
                &nbsp;&nbsp;
                <CustomButton
                  actionFuntion={() => {
                    setOpenDialog(false);
                    handleApproveClick();
                  }}
                  disableAction={buttonDisabled}
                  actionName="Yes"
                  typeName="submit"
                />
              </Mui.Stack>
            </Mui.Grid>
          </Mui.Grid>
        ) : null}
      </GlobalDialogBox>
    </React.Fragment>
  );
};
