import React, { useState, useEffect, useContext, useCallback } from "react";
import * as Mui from "@mui/material";
import { themes } from "services/constants";
import { makeStyles } from "@mui/styles";
import { useSelector } from "react-redux";
import { showToast } from "services/utils/Status";
import moment from "moment";
import { Loader } from "components/Loader";
import { TaskContext } from "../Tasks/TaskContext";
import { TaskDetails } from "../Tasks/TaskDetails";
import { projectMyTaskList } from "services/constants";
import {
  PlayArrow,
  StopCircle,
  AvTimer,
  PauseCircle,
  LockClock,
} from "@mui/icons-material";
import sessionHandling from "services/utils/notificationUtils";
import { getContrastingTextColor } from "services/utils/colorChecker";

const useStyles = makeStyles({
  tableCell: {
    paddingTop: "0px",
    paddingBottom: "0px",
  },
});

const MyTaskList = () => {
  const classes = useStyles();
  const {
    allUsers,
    projectDetails,
    openTaskDetails,
    setOpenTaskDetails,
    setEditTask,
    setLabelsApi,
    setLabelBasedProjectId,
    userTasklist,
    setUserTasklist,
    currentPlayTaskId,
    setCurrentPlayTaskId,
    isActive,
    setIsActive,
    taskStartTime,
    setTaskStartTime,
  } = useContext(TaskContext);
  const { domain, token, userDetails, globalSearchValue } = useSelector(
    (state) => state.tokenReducer
  );
  const [isLoading, setIsLoading] = useState(false);
  const [selectedAssignee, setSelectedAssignee] = useState(
    userDetails.username
  );
  const [projectList, setProjectList] = useState([]);
  const [selectedProject, setSelectedProject] = useState("All Projects");
  const [sprintList, setSprintList] = useState([]);
  const [selectedSprint, setSelectedSprint] = useState("All Sprints");
  const [tasktypeList, setTasktypeList] = useState([]);
  const [selectedTasktype, setSelectedTasktype] = useState("All Task Type");
  const [selectedTaskid, setSelectedTaskid] = useState(null);
  const [selectedTask, setSelectedTask] = useState(null);
  const [durationTime, setDuration] = useState(null);
  const [masterDataForSearch, setMasterDataForSearch] = useState([]);

  const checkDateWithMoment = (inputDate) => {
    const givenDate = moment(inputDate, "DD-MM-YYYY");
    const currentDate = moment();
    if (givenDate.isSameOrAfter(currentDate, "day")) {
      return true;
    } else {
      return false;
    }
  };

  const taskListTypes = [
    {
      id: 1,
      type: "Parent Task",
    },
    {
      id: 2,
      type: "Child Task",
    },
  ];

  const priorityColor = (priority) => {
    switch (priority) {
      case "Low":
        return themes.outTap;
      case "Medium":
        return themes.activeTap;
      case "High":
        return themes.onleaveTap;
      default:
        break;
    }
  };

  // const checkDateWithMoment = (inputDate) => {
  //   const givenDate = moment(inputDate, "DD-MM-YYYY");
  //   const currentDate = moment();
  //   if (givenDate.isSameOrAfter(currentDate, "day")) {
  //     return true;
  //   } else {
  //     return false;
  //   }
  // };

  const ticks = useCallback(() => {
    let momenttime = 0;
    const startTime = moment(taskStartTime, "HH:mm:ss a");
    let notesTime = moment(new Date()).utcOffset("+05:30").format("hh:mm:ss A");
    const endTime = moment(notesTime, "HH:mm:ss a");
    const duration = moment.duration(endTime.diff(startTime));
    const hours = parseInt(duration.asHours());
    const minutes = parseInt(duration.asMinutes()) % 60;
    const sec = parseInt(duration.asSeconds()) % 60;
    const time = `${hours}:${minutes}:${sec}`;
    momenttime = moment(time, "HH:mm:ss").format("HH:mm:ss");
    setDuration(momenttime);
  }, [taskStartTime]);

  useEffect(() => {
    let interval = null;
    if (isActive) {
      interval = setInterval(() => {
        ticks();
      }, 1000);
    } else if (!isActive && taskStartTime !== null) {
      clearInterval(interval);
    }
    return () => clearInterval(interval);
  }, [isActive, taskStartTime, ticks]);

  const fetchTaskData = useCallback(async () => {
    try {
      setIsLoading(true);
      const response = await fetch(
        `${domain}task/?current_assignee=${userDetails.id}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `token ${token}`,
          },
        }
      );
      const res = await response.json();
      if (response.ok) {
        const filtered_data = res.filter(
          (task) => task.work_flow_status.name !== "Completed"
        );
        setUserTasklist(filtered_data);
        setMasterDataForSearch(filtered_data);
      } else if (response.status === 409) {
        sessionHandling();
      } else {
        throw new Error(res.error);
      }
    } catch (error) {
      showToast("error", error.message);
    } finally {
      setIsLoading(false);
    }
  }, [domain, setUserTasklist, token, userDetails.id]);

  const getProjectDetails = useCallback(async () => {
    const filteredProjects = projectDetails.filter(
      (project) =>
        project.members?.some((member) => member.id === userDetails.id) ||
        project.projectType === "Public"
    );
    setProjectList(filteredProjects);
  }, [userDetails.id, projectDetails]);

  useEffect(() => {
    fetchTaskData();
  }, [fetchTaskData]);

  useEffect(() => {
    getProjectDetails();
  }, [getProjectDetails]);

  const onSelectedAssignee = async (event) => {
    setIsLoading(true);
    const selectUser = allUsers.filter(
      (usr) => usr.username === event.target.value
    );
    setSelectedAssignee(event.target.value);
    try {
      const response = await fetch(
        `${domain}task/?current_assignee=${selectUser[0].id}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `token ${token}`,
          },
        }
      );
      const res = await response.json();
      if (response.ok) {
        const filtered_data = res.filter(
          (task) => task.work_flow_status.name !== "Completed"
        );
        setUserTasklist(filtered_data);
        setMasterDataForSearch(filtered_data);
        const filteredProjects = projectDetails.filter(
          (project) =>
            project.members?.some((member) => member.id === selectUser[0].id) ||
            project.projectType === "Public"
        );
        setProjectList(filteredProjects);
      } else if (response.status === 409) {
        sessionHandling();
      } else {
        throw new Error(res.error);
      }
    } catch (error) {
      showToast("error", error.message);
    } finally {
      setSprintList([]);
      setTasktypeList([]);
      setSelectedProject("All Projects");
      setSelectedSprint("All Sprints");
      setSelectedTasktype("All Task Type");
      setIsLoading(false);
    }
  };

  const getProjectUnCompletedSprint = async (projectId) => {
    try {
      const response = await fetch(`${domain}sprints/?project=${projectId}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `token ${token}`,
        },
      });
      const res = await response.json();
      if (response.ok) {
        setSprintList(res.filter((data) => data.is_completed === false));
      } else if (response.status === 409) {
        sessionHandling();
      } else {
        throw new Error(res.error);
      }
    } catch (error) {
      showToast("error", error.message);
    }
  };

  const onSelectedProject = async (event) => {
    setIsLoading(true);
    const selectUser = allUsers.filter(
      (usr) => usr.username === selectedAssignee
    );
    const selectProject = projectList.filter((pro) =>
      event.target.value !== "All Projects"
        ? pro.projectname === event.target.value
        : pro.projectname === ""
    );
    setSelectedProject(event.target.value);
    let projectAPI = "";
    if (event.target.value !== "All Projects") {
      projectAPI = `${domain}task/?current_assignee=${selectUser[0].id}&project=${selectProject[0].id}`;
    } else {
      projectAPI = `${domain}task/?current_assignee=${selectUser[0].id}`;
    }
    try {
      const url = projectAPI;
      const response = await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `token ${token}`,
        },
      });
      const res = await response.json();
      if (response.ok) {
        const filtered_data = res.filter(
          (task) => task.work_flow_status.name !== "Completed"
        );
        setUserTasklist(filtered_data);
        setMasterDataForSearch(filtered_data);
        if (event.target.value !== "All Projects") {
          getProjectUnCompletedSprint(selectProject[0].id);
        } else {
          setSprintList([]);
        }
      } else if (response.status === 409) {
        sessionHandling();
      } else {
        throw new Error(res.error);
      }
    } catch (error) {
      showToast("error", error.message);
    } finally {
      setTasktypeList([]);
      setSelectedTasktype("All Task Type");
      setSelectedSprint("All Sprints");
      setIsLoading(false);
    }
  };

  const onSelectedSprint = async (event) => {
    setIsLoading(true);
    const selectUser = allUsers.filter(
      (usr) => usr.username === selectedAssignee
    );
    const selectProject = projectList.filter(
      (pro) => pro.projectname === selectedProject
    );
    const selectSprint = sprintList.filter((spr) =>
      event.target.value !== "All Sprints"
        ? spr.name === event.target.value
        : spr.name === ""
    );
    setSelectedSprint(event.target.value);

    let sprintAPI = "";
    if (event.target.value !== "All Sprints") {
      sprintAPI = `${domain}task/?current_assignee=${selectUser[0].id}&project=${selectProject[0].id}&sprint=${selectSprint[0].id}`;
    } else {
      sprintAPI = `${domain}task/?current_assignee=${selectUser[0].id}&project=${selectProject[0].id}`;
    }
    try {
      const url = sprintAPI;
      const response = await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `token ${token}`,
        },
      });
      const res = await response.json();
      if (response.ok) {
        const filtered_data = res.filter(
          (task) => task.work_flow_status.name !== "Completed"
        );
        setUserTasklist(filtered_data);
        setMasterDataForSearch(filtered_data);
        if (event.target.value !== "All Sprints") {
          setTasktypeList(taskListTypes);
        } else {
          setTasktypeList([]);
        }
      } else if (response.status === 409) {
        sessionHandling();
      } else {
        throw new Error(res.error);
      }
    } catch (error) {
      showToast("error", error.message);
    } finally {
      setSelectedTasktype("All Task Type");
      setIsLoading(false);
    }
  };

  const onSelectedTasktype = async (event) => {
    setIsLoading(true);
    const selectUser = allUsers.filter(
      (usr) => usr.username === selectedAssignee
    );
    const selectProject = projectList.filter(
      (pro) => pro.projectname === selectedProject
    );
    const selectSprint = sprintList.filter(
      (spr) => spr.name === selectedSprint
    );
    setSelectedTasktype(event.target.value);
    let taskTypeAPI = "";
    if (event.target.value !== "All Task Type") {
      taskTypeAPI = `${domain}task/?current_assignee=${selectUser[0].id}&project=${selectProject[0].id}&sprint=${selectSprint[0].id}&task_type=${event.target.value}`;
    } else {
      taskTypeAPI = `${domain}task/?current_assignee=${selectUser[0].id}&project=${selectProject[0].id}&sprint=${selectSprint[0].id}`;
    }
    try {
      const url = taskTypeAPI;
      const response = await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `token ${token}`,
        },
      });
      const res = await response.json();
      if (response.ok) {
        const filtered_data = res.filter(
          (task) => task.work_flow_status.name !== "Completed"
        );
        setUserTasklist(filtered_data);
        setMasterDataForSearch(filtered_data);
      } else if (response.status === 409) {
        sessionHandling();
      } else {
        throw new Error(res.error);
      }
    } catch (error) {
      showToast("error", error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const onRefreshDataFunc = async () => {
    const selectUser = allUsers.filter(
      (usr) => usr.username === selectedAssignee
    );
    try {
      const response = await fetch(
        `${domain}task/?current_assignee=${selectUser[0].id}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `token ${token}`,
          },
        }
      );
      const res = await response.json();
      if (response.ok) {
        const filtered_data = res.filter(
          (task) => task.work_flow_status.name !== "Completed"
        );
        setUserTasklist(filtered_data);
        setMasterDataForSearch(filtered_data);
      } else if (response.status === 409) {
        sessionHandling();
      } else {
        throw new Error(res.error);
      }
    } catch (error) {
      showToast("error", error.message);
    } finally {
      setSelectedProject("All Projects");
      setSelectedSprint("All Sprints");
      setSelectedTasktype("All Task Type");
    }
  };

  const handleClick = (task) => {
    handleMenuClose();
    setOpenTaskDetails(true);
    setSelectedTask(task);
    setSelectedTaskid(task.id);
    setEditTask(task);
    setLabelBasedProjectId(task.project.id);
    setLabelsApi(true);
  };

  const handleMenuClose = () => {
    setEditTask(null);
  };

  const handleWorkingTime = async (taskid, action, task) => {
    try {
      const response = await fetch(`${domain}toggle_timer/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `token ${token}`,
        },
        body: JSON.stringify({
          task_id: taskid,
          action: action,
          user_id: userDetails.id,
          task_status:
            task?.work_flow_status?.name === "ToDo"
              ? "InProgress"
              : task?.work_flow_status?.name,
        }),
      });
      const res = await response.json();
      if (response.ok) {
        if (action === "start") {
          setIsActive(true);
          setTaskStartTime(
            moment(new Date()).utcOffset("+05:30").format("hh:mm:ss A")
          );
          if (task?.work_flow_status?.name === "ToDo") {
            await moveToInProgressWithPay(taskid, task);
          }
          setCurrentPlayTaskId(taskid);
        } else {
          setIsActive(false);
          setCurrentPlayTaskId(0);
          onRefreshDataFunc();
        }
      } else if (response.status === 409) {
        sessionHandling();
      } else {
        throw new Error(res.error);
      }
    } catch (error) {
      showToast("error", error.message);
    }
  };

  const moveToInProgressWithPay = async (taskid, task) => {
    try {
      const response = await fetch(`${domain}edit_task/`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `token ${token}`,
        },
        body: JSON.stringify({
          task_id: taskid,
          status: "InProgress",
          workflow_status_id: task.work_flow_status.workflow,
        }),
      });
      const res = await response.json();
      if (response.ok) {
        onRefreshDataFunc();
        showToast("success", res.message);
      } else if (response.status === 409) {
        sessionHandling();
      } else {
        throw new Error(res.error);
      }
    } catch (error) {
      showToast("error", error.message);
    }
  };

  const showLabels = (labels, taskid) => {
    return labels.length > 0 ? (
      <Mui.AvatarGroup
        max={3}
        sx={{
          "& .MuiAvatar-root": {
            width: 30,
            height: 20,
            fontSize: 10,
            padding: "1px 5px",
            margin: "0 2px",
            marginLeft: "0px",
            borderRadius: 2,
            borderWidth: 0,
          },
        }}
      >
        {labels.map((lab) => (
          <Mui.Tooltip title={lab.label_name}>
            <Mui.Avatar
              key={taskid}
              sx={{
                height: 20,
                textTransform: "capitalize",
                backgroundColor: lab.color_code
                  ? lab.color_code
                  : themes.primaryIndicator,
                "&:hover": {
                  backgroundColor: lab.color_code
                    ? lab.color_code
                    : themes.primaryIndicator,
                },
                color: getContrastingTextColor(
                  lab.color_code ? lab.color_code : themes.primaryIndicator
                ),
              }}
            >
              {String(lab.label_name).substring(0, 6)}
            </Mui.Avatar>
          </Mui.Tooltip>
        ))}
      </Mui.AvatarGroup>
    ) : null;
  };

  const getNestedValue = (obj, path) => {
    return path.split(".").reduce((acc, part) => acc && acc[part], obj);
  };

  useEffect(() => {
    const filteredResults = masterDataForSearch.filter((item) =>
      projectMyTaskList.some((key) =>
        getNestedValue(item, key)
          ?.toString()
          .toLowerCase()
          .includes(globalSearchValue.toString().toLowerCase())
      )
    );
    setUserTasklist(filteredResults);
  }, [globalSearchValue, masterDataForSearch, setUserTasklist]);

  return (
    <React.Fragment>
      <div
        style={{
          backgroundColor: themes.whiteColor,
          position: "sticky",
          top: "63px",
          zIndex: 10,
        }}
      >
        <Mui.Grid container spacing={1} sx={{ mt: 3, mb: 2 }}>
          <Mui.Grid item xs={12} sm={6} md={4} lg={2}>
            <Mui.FormControl fullWidth>
              <Mui.InputLabel id="assignee-select">Assignee</Mui.InputLabel>
              <Mui.Select
                size="small"
                labelId="assignee-select"
                id="assignee-select-label"
                value={selectedAssignee}
                label="Assignee"
                fullWidth
                sx={{ minWidth: "90%" }}
                onChange={onSelectedAssignee}
                renderValue={(selected) => selected}
                MenuProps={{
                  PaperProps: {
                    sx: {
                      minHeight: "200px",
                      maxHeight: "400px",
                      maxWidth: "50px",
                    },
                  },
                }}
              >
                {allUsers.map((user) => (
                  <Mui.MenuItem value={user.username} key={user.id}>
                    <Mui.Avatar
                      sx={{ width: 20, height: 20 }}
                      alt={user.username.charAt(0).toUpperCase()}
                      src={user.image_url}
                    />
                    <Mui.ListItemText primary={user.username} />
                  </Mui.MenuItem>
                ))}
              </Mui.Select>
            </Mui.FormControl>
          </Mui.Grid>
          <Mui.Grid item xs={12} sm={6} md={4} lg={2}>
            <Mui.FormControl fullWidth>
              <Mui.InputLabel id="project-select">Project</Mui.InputLabel>
              <Mui.Select
                size="small"
                labelId="project-select"
                id="project-select-label"
                value={selectedProject}
                label="Project"
                fullWidth
                sx={{ minWidth: "100%" }}
                onChange={onSelectedProject}
                renderValue={(selected) => selected}
                MenuProps={{
                  PaperProps: {
                    sx: {
                      minHeight: "200px",
                      maxHeight: "400px",
                      maxWidth: "50px",
                    },
                  },
                }}
                placeholder="Select Project name"
              >
                <Mui.MenuItem key={0} value="All Projects">
                  <Mui.ListItemText primary="All Projects" />
                </Mui.MenuItem>
                {projectList.map(
                  (project) =>
                    project.status?.status === "Active" && (
                      <Mui.MenuItem
                        value={project.projectname}
                        key={project.id}
                      >
                        <Mui.ListItemText primary={project.projectname} />
                      </Mui.MenuItem>
                    )
                )}
              </Mui.Select>
            </Mui.FormControl>
          </Mui.Grid>
          <Mui.Grid item xs={12} sm={6} md={4} lg={2}>
            <Mui.FormControl fullWidth>
              <Mui.InputLabel id="sprint-select">Sprint</Mui.InputLabel>
              <Mui.Select
                size="small"
                labelId="sprint-select"
                id="sprint-select-label"
                value={selectedSprint}
                label="Sprint"
                fullWidth
                sx={{ minWidth: "100%" }}
                onChange={onSelectedSprint}
                renderValue={(selected) => selected}
              >
                <Mui.MenuItem key={0} value="All Sprints">
                  <Mui.ListItemText primary="All Sprints" />
                </Mui.MenuItem>
                {sprintList.map((sprint) => (
                  <Mui.MenuItem value={sprint.name} key={sprint.id}>
                    <Mui.ListItemText primary={sprint.name} />
                  </Mui.MenuItem>
                ))}
              </Mui.Select>
            </Mui.FormControl>
          </Mui.Grid>
          <Mui.Grid item xs={12} sm={6} md={4} lg={2}>
            <Mui.FormControl fullWidth>
              <Mui.InputLabel id="tasktype-select">Task Type</Mui.InputLabel>
              <Mui.Select
                size="small"
                labelId="tasktype-select"
                id="tasktype-select-label"
                value={selectedTasktype}
                label="Task Type"
                fullWidth
                sx={{ minWidth: "100%" }}
                onChange={onSelectedTasktype}
                renderValue={(selected) => selected}
              >
                <Mui.MenuItem key={0} value="All Task Type">
                  <Mui.ListItemText primary="All Task Type" />
                </Mui.MenuItem>
                {tasktypeList.map((tasktype) => (
                  <Mui.MenuItem value={tasktype.type} key={tasktype.id}>
                    <Mui.ListItemText primary={tasktype.type} />
                  </Mui.MenuItem>
                ))}
              </Mui.Select>
            </Mui.FormControl>
          </Mui.Grid>
        </Mui.Grid>
        <Mui.Grid
          container
          sx={{
            paddingRight: 1,
          }}
        >
          <Mui.TableContainer
            component={Mui.Paper}
            style={{ paddingTop: 4, paddingBottom: 2 }}
            sx={{
              mt: 2,
              backgroundColor: themes.primary,
              borderBottom: "1.5px solid transparent",
            }}
          >
            <Mui.Table
              style={{
                tableLayout: "auto",
                borderBottom: "1.5px solid transparent",
                marginLeft: "auto",
                marginRight: "auto",
              }}
              sx={{ backgroundColor: themes.primary }}
            >
              <Mui.TableHead sx={{ backgroundColor: themes.primary }}>
                <Mui.TableRow
                  maxWidth="xl"
                  align="left"
                  sx={{
                    "& .MuiTableCell-root": {
                      height: "15px",
                      padding: "2px 15px",
                      backgroundColor: themes.primary,
                      borderBottom: "1.5px solid transparent",
                    },
                  }}
                >
                  <Mui.TableCell
                    className={classes.tableCell}
                    sx={{
                      fontSize: 14,
                      fontWeight: "bold",
                      width: "25%",
                      borderRight: 1,
                      borderColor: "transparent",
                    }}
                  >
                    Task Name
                  </Mui.TableCell>
                  <Mui.TableCell
                    className={classes.tableCell}
                    sx={{
                      fontSize: 14,
                      fontWeight: "bold",
                      width: "10%",
                      borderRight: 1,
                      borderColor: "transparent",
                    }}
                  >
                    Status
                  </Mui.TableCell>
                  <Mui.TableCell
                    className={classes.tableCell}
                    sx={{
                      fontSize: 14,
                      fontWeight: "bold",
                      width: "10%",
                      borderRight: 1,
                      borderColor: "transparent",
                    }}
                  >
                    Labels
                  </Mui.TableCell>
                  <Mui.TableCell
                    className={classes.tableCell}
                    sx={{
                      fontSize: 14,
                      fontWeight: "bold",
                      width: "10%",
                      borderRight: 1,
                      borderColor: "transparent",
                    }}
                  >
                    Hours
                  </Mui.TableCell>
                  <Mui.TableCell
                    className={classes.tableCell}
                    sx={{
                      fontSize: 14,
                      fontWeight: "bold",
                      width: "5%",
                      borderRight: 1,
                      borderColor: "transparent",
                    }}
                  >
                    ETA
                  </Mui.TableCell>
                  <Mui.TableCell
                    className={classes.tableCell}
                    sx={{
                      fontSize: 14,
                      fontWeight: "bold",
                      width: "10%",
                      borderRight: 1,
                      borderColor: "transparent",
                    }}
                  >
                    Project
                  </Mui.TableCell>
                  <Mui.TableCell
                    className={classes.tableCell}
                    sx={{
                      fontSize: 14,
                      fontWeight: "bold",
                      width: "10%",
                      borderRight: 1,
                      borderColor: "transparent",
                    }}
                  >
                    Sprint
                  </Mui.TableCell>
                  <Mui.TableCell
                    className={classes.tableCell}
                    sx={{
                      fontSize: 14,
                      fontWeight: "bold",
                      width: "10%",
                      borderRight: 1,
                      borderColor: "transparent",
                    }}
                  >
                    Due Date
                  </Mui.TableCell>
                  <Mui.TableCell
                    className={classes.tableCell}
                    sx={{
                      fontSize: 14,
                      fontWeight: "bold",
                      width: "10%",
                      borderRight: 1,
                      borderColor: "transparent",
                    }}
                  >
                    Priority
                  </Mui.TableCell>
                </Mui.TableRow>
              </Mui.TableHead>
            </Mui.Table>
          </Mui.TableContainer>
        </Mui.Grid>
      </div>
      {isLoading ? (
        <Loader info="Loading..." />
      ) : (
        <>
          {userTasklist.length !== 0 ? (
            <Mui.Grid
              container
              sx={{
                paddingRight: 1,
              }}
            >
              <Mui.TableContainer
                component={Mui.Paper}
                style={{ paddingBottom: 2 }}
                sx={{ height: "100%" }}
              >
                <Mui.Table
                  fixedHeader={false}
                  style={{
                    tableLayout: "auto",
                    borderBottom: "1.5px solid transparent",
                    marginLeft: "auto",
                    marginRight: "auto",
                  }}
                >
                  <Mui.TableBody>
                    {userTasklist.map((task, index) => (
                      <>
                        <Mui.TableRow
                          maxWidth="xl"
                          align="left"
                          key={index}
                          sx={{
                            "& .MuiTableCell-root": {
                              height: "15px",
                              padding: "2px 15px",
                            },
                            backgroundColor:
                              selectedTaskid === task.id ? "yellow" : null,
                          }}
                          hover={true}
                        >
                          <Mui.TableCell
                            align="left"
                            className={classes.tableCell}
                            sx={{
                              width: "25%",
                              borderRight: 1,
                              borderColor: themes.taskBorder,
                              overflow: "hidden",
                              whiteSpace: "nowrap",
                              maxWidth: 100,
                              fontSize: 14,
                            }}
                            onClick={() => handleClick(task)}
                          >
                            <Mui.Stack
                              direction="row"
                              style={{
                                paddingTop: "0px",
                                paddingBottom: "0px",
                              }}
                            >
                              <Mui.Grid
                                container
                                style={{
                                  paddingTop: "0px",
                                  paddingBottom: "0px",
                                }}
                              >
                                <Mui.Grid
                                  xs={11.5}
                                  style={{
                                    paddingTop: "0px",
                                    paddingBottom: "0px",
                                  }}
                                >
                                  <Mui.Stack
                                    direction="row"
                                    style={{
                                      paddingTop: "0px",
                                      paddingBottom: "0px",
                                    }}
                                  >
                                    <Mui.Tooltip title={task.taskname}>
                                      <Mui.Typography
                                        sx={{
                                          fontSize: 14,
                                          paddingTop: "0px",
                                          paddingBottom: "0px",
                                          overflow: "hidden",
                                        }}
                                      >
                                        <Mui.Stack
                                          direction="row"
                                          style={{
                                            paddingTop: "0px",
                                            paddingBottom: "0px",
                                          }}
                                        >
                                          <Mui.Typography
                                            sx={{
                                              fontSize: 14,
                                              paddingTop: "0px",
                                              paddingBottom: "0px",
                                              color: themes.blackColor,
                                              fontWeight: "700",
                                              marginRight: 0.5,
                                            }}
                                          >
                                            {task.task_code
                                              ? task.task_code
                                              : "N/A"}
                                          </Mui.Typography>
                                          <Mui.Typography
                                            sx={{
                                              fontSize: 14,
                                              paddingTop: "0px",
                                              paddingBottom: "0px",
                                              textOverflow: "ellipsis",
                                              overflow: "hidden",
                                              whiteSpace: "nowrap",
                                            }}
                                          >
                                            {" " + task.taskname}
                                          </Mui.Typography>
                                        </Mui.Stack>
                                      </Mui.Typography>
                                    </Mui.Tooltip>
                                    &nbsp;
                                    {!checkDateWithMoment(task?.due_date) &&
                                    task?.work_flow_status?.name !==
                                      "Completed" ? (
                                      <Mui.Tooltip title={"Deadline Expired"}>
                                        <LockClock
                                          style={{ fontSize: 14 }}
                                          sx={{
                                            width: 20,
                                            height: 20,
                                            mt: "3px",
                                            textTransform: "capitalize",
                                            color: themes.redColor,
                                          }}
                                        />
                                      </Mui.Tooltip>
                                    ) : null}
                                  </Mui.Stack>
                                </Mui.Grid>
                              </Mui.Grid>
                            </Mui.Stack>
                          </Mui.TableCell>
                          <Mui.TableCell
                            align="left"
                            className={classes.tableCell}
                            sx={{
                              width: "10%",
                              borderRight: 1,
                              borderColor: themes.taskBorder,
                              fontSize: 14,
                              padding: 1,
                              overflow: "hidden",
                              whiteSpace: "nowrap",
                              maxWidth: 100,
                            }}
                          >
                            <Mui.Typography
                              style={{
                                paddingTop: "0px",
                                paddingBottom: "0px",
                                fontSize: 14,
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap",
                              }}
                            >
                              {task?.work_flow_status?.name}
                            </Mui.Typography>
                          </Mui.TableCell>
                          <Mui.TableCell
                            align="left"
                            className={classes.tableCell}
                            sx={{
                              width: "10%",
                              borderRight: 1,
                              borderColor: themes.taskBorder,
                              fontSize: 14,
                              maxWidth: 100,
                              overflow: "hidden",
                              whiteSpace: "nowrap",
                            }}
                          >
                            <Mui.Paper
                              elevation={0}
                              sx={{
                                p: "0px 0px",
                                display: "flex",
                                alignItems: "center",
                                backgroundColor: "transparent",
                              }}
                            >
                              {task.labels.length > 0
                                ? showLabels(task.labels, task.id)
                                : null}
                            </Mui.Paper>
                          </Mui.TableCell>
                          <Mui.TableCell
                            align="left"
                            className={classes.tableCell}
                            sx={{
                              width: "10%",
                              borderRight: 1,
                              borderColor: themes.taskBorder,
                              fontSize: 14,
                              overflow: "hidden",
                              whiteSpace: "nowrap",
                              maxWidth: 100,
                            }}
                          >
                            <Mui.Paper
                              elevation={0}
                              component="form"
                              sx={{
                                p: "0px 0px",
                                display: "flex",
                                alignItems: "center",
                                backgroundColor: "transparent",
                              }}
                            >
                              <Mui.IconButton style={{ padding: "0px" }}>
                                <AvTimer />
                              </Mui.IconButton>
                              <Mui.InputBase
                                sx={{ ml: 1, flex: 1, fontSize: 14 }}
                                value={
                                  currentPlayTaskId === task.id
                                    ? durationTime
                                    : task.total_time
                                }
                                disabled={
                                  currentPlayTaskId === task.id ? false : true
                                }
                              />
                              <Mui.IconButton
                                color="primary"
                                size="small"
                                sx={{
                                  padding: "2px",
                                  borderRadius: 3,
                                  backgroundColor:
                                    currentPlayTaskId === task.id
                                      ? themes.onleaveTap
                                      : themes.primaryIndicator,
                                  color: themes.backgroundWhite,
                                  ":hover": {
                                    backgroundColor:
                                      currentPlayTaskId === task.id
                                        ? themes.onleaveTap
                                        : themes.primaryIndicator,
                                  },
                                }}
                                onClick={() => {
                                  if (isActive) {
                                    handleWorkingTime(task.id, "pause", task);
                                  } else {
                                    setDuration(null);
                                    handleWorkingTime(task.id, "start", task);
                                  }
                                }}
                                disabled={
                                  userDetails.id ===
                                    task.current_assignee?.id &&
                                  !task.sprint?.is_completed &&
                                  task.sprint?.name !== "Backlog"
                                    ? false
                                    : true
                                }
                              >
                                {currentPlayTaskId === task.id ? (
                                  <PauseCircle
                                    size="small"
                                    sx={{ fontSize: 14 }}
                                  />
                                ) : !task.is_running ? (
                                  <PlayArrow
                                    size="small"
                                    sx={{ fontSize: 14 }}
                                  />
                                ) : (
                                  <Mui.Tooltip title="In Running">
                                    <StopCircle
                                      size="small"
                                      sx={{
                                        fontSize: 18,
                                        color: themes.greenColor,
                                      }}
                                    />
                                  </Mui.Tooltip>
                                )}
                              </Mui.IconButton>
                            </Mui.Paper>
                          </Mui.TableCell>
                          <Mui.TableCell
                            align="left"
                            className={classes.tableCell}
                            sx={{
                              width: "5%",
                              borderRight: 1,
                              borderColor: themes.taskBorder,
                              fontSize: 14,
                              overflow: "hidden",
                              whiteSpace: "nowrap",
                              maxWidth: 100,
                            }}
                          >
                            {task.estimate ? task.estimate : "N/A"}
                          </Mui.TableCell>
                          <Mui.TableCell
                            align="left"
                            className={classes.tableCell}
                            sx={{
                              width: "10%",
                              borderRight: 1,
                              borderColor: themes.taskBorder,
                              fontSize: 14,
                              padding: 1,
                              overflow: "hidden",
                              whiteSpace: "nowrap",
                              maxWidth: 100,
                            }}
                          >
                            <Mui.Typography
                              style={{
                                paddingTop: "0px",
                                paddingBottom: "0px",
                                fontSize: 14,
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap",
                              }}
                            >
                              {task.project?.projectname}
                            </Mui.Typography>
                          </Mui.TableCell>
                          <Mui.TableCell
                            align="left"
                            className={classes.tableCell}
                            sx={{
                              width: "10%",
                              borderRight: 1,
                              borderColor: themes.taskBorder,
                              fontSize: 14,
                              padding: 1,
                              overflow: "hidden",
                              whiteSpace: "nowrap",
                              maxWidth: 100,
                            }}
                          >
                            <Mui.Typography
                              style={{
                                paddingTop: "0px",
                                paddingBottom: "0px",
                                fontSize: 14,
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap",
                              }}
                            >
                              {task.sprint?.name}
                            </Mui.Typography>
                          </Mui.TableCell>
                          <Mui.TableCell
                            align="left"
                            className={classes.tableCell}
                            sx={{
                              width: "10%",
                              borderRight: 1,
                              borderColor: themes.taskBorder,
                              fontSize: 14,
                              padding: 1,
                              overflow: "hidden",
                              whiteSpace: "nowrap",
                              maxWidth: 100,
                            }}
                          >
                            <Mui.Typography
                              style={{
                                paddingTop: "0px",
                                paddingBottom: "0px",
                                fontSize: 14,
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap",
                              }}
                            >
                              {task?.due_date ? task?.due_date : "N/A"}
                            </Mui.Typography>
                          </Mui.TableCell>
                          <Mui.TableCell
                            align="left"
                            className={classes.tableCell}
                            sx={{
                              width: "10%",
                              borderRight: 1,
                              borderColor: themes.taskBorder,
                              fontSize: 14,
                              padding: 1,
                              color: priorityColor(task.priority?.name),
                              overflow: "hidden",
                              whiteSpace: "nowrap",
                              maxWidth: 100,
                            }}
                          >
                            <Mui.Typography
                              style={{
                                paddingTop: "0px",
                                paddingBottom: "0px",
                                fontSize: 14,
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap",
                              }}
                            >
                              {task.priority?.name}
                            </Mui.Typography>
                          </Mui.TableCell>
                        </Mui.TableRow>
                      </>
                    ))}
                  </Mui.TableBody>
                </Mui.Table>
              </Mui.TableContainer>
            </Mui.Grid>
          ) : (
            <Mui.Stack sx={{ padding: 0.5 }} spacing={2} fullWidth>
              <Mui.Alert severity="info">
                There is a no available task
              </Mui.Alert>
            </Mui.Stack>
          )}
        </>
      )}
      {openTaskDetails && selectedTaskid !== null ? (
        <TaskDetails
          selectedTask={selectedTask}
          setSelectedTask={setSelectedTask}
          selectedTaskid={selectedTaskid}
          setSelectedTaskid={setSelectedTaskid}
        />
      ) : null}
    </React.Fragment>
  );
};

export default MyTaskList;
