import React, { useEffect, useState, useRef } from "react";
import { useSelector } from "react-redux";
import { showToast } from "services/utils/Status";
import { MoreVert } from "@mui/icons-material";
import { CustomButton } from "components/CustomComponents/CustomButton";
import { Loader } from "components/Loader";
import { useForm } from "react-hook-form";
import * as Mui from "@mui/material";
import {
  themes,
  tableContainerSx,
  tableHeaderSx,
  tableRowSx,
  scrollTop,
} from "services/constants";
import { GlobalDialogBox } from "components/GlobalDialogBox";
import sessionHandling from "services/utils/notificationUtils";
import { atleastOneCharacterWithSpace } from "services/constants/patterns";
import { useFetch } from "services/hooks/useFetch";
export const Unit = () => {
  const { globalSearchValue, domain, token } = useSelector(
    (state) => state.tokenReducer
  );
  const {
    result: masterUnit,
    loading,
    setLoad: reCallApi,
  } = useFetch(`${domain}work_units/`);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [anchorEl, setAnchorEl] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  // const [loading, setLoading] = useState(false);
  const [unit, setUnit] = useState(masterUnit);
  const [popupTitle, setPopupTitle] = useState("");
  const [editUnit, setEditUnit] = useState("");
  const [deletePopup, setDeletePopup] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(false);
  // const [masterUnit, setMasterUnit] = useState([]);
  const tableContainerRef = useRef(null);
  useEffect(() => {
    scrollTop(tableContainerRef);
  }, [page]);

  const {
    register,
    reset,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors },
  } = useForm({ mode: "onBlur" });

  const handleClose = () => {
    setOpenDialog(false);
    setEditUnit("");
    setTimeout(function () {
      setDeletePopup(false);
      setButtonDisabled(false);
    }, 200);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    const searchFields = ["name", "description"];
    const filteredResults = masterUnit.filter((item) =>
      searchFields.some((key) =>
        item[key]
          ?.toString()
          .toLowerCase()
          .includes(globalSearchValue.toString().toLowerCase())
      )
    );
    setUnit(filteredResults);
    setPage(globalSearchValue ? 0 : page);
  }, [globalSearchValue, masterUnit, page]);

  const handleMenuClose = () => {
    setAnchorEl(false);
  };

  const checkSpecialCharWithDigits = (e) => {
    if (!/[a-zA-Z\d ]/.test(e.key)) {
      e.preventDefault();
    }
  };

  const createNewDesignation = async (data) => {
    if (popupTitle === "Add Unit") {
      if (!buttonDisabled) {
        setButtonDisabled(true);
        try {
          const response = await fetch(`${domain}work_units/`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `token ${token}`,
            },
            body: JSON.stringify({
              name: data.unit.trim(),
              description: data.description,
            }),
          });
          const res = await response.json();
          if (response.ok) {
            reCallApi(true);
            showToast("success", "Unit added successfully.");
            setOpenDialog(false);
            setTimeout(function () {
              setButtonDisabled(false);
            }, 1000);
          } else if (response.status === 409) {
            sessionHandling();
          } else {
            // setValue("unit", design);
            // setValue("description", desc);
            throw new Error(res.error);
          }
        } catch (error) {
          showToast("error", error.message);
        }
      }
    } else {
      // let design = data.unit;
      // let desc = data.description;
      if (!buttonDisabled) {
        setButtonDisabled(true);
        try {
          const response = await fetch(`${domain}work_units/${data.id}/`, {
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
              Authorization: `token ${token}`,
            },
            body: JSON.stringify({
              name: data.unit.trim(),
              description: data.description,
            }),
          });
          const res = await response.json();
          if (response.ok) {
            reCallApi(true);
            setEditUnit("");
            showToast("success", "Unit updated successfully.");
            setOpenDialog(false);
            setTimeout(function () {
              setButtonDisabled(false);
            }, 1000);
          } else if (response.status === 409) {
            sessionHandling();
          } else {
            // setValue("unit", design);
            // setValue("description", desc);
            throw new Error(res.error);
          }
        } catch (error) {
          showToast("error", error.message);
        }
      }
    }
  };

  const deleteDesignation = async () => {
    const id = getValues("id");
    try {
      const response = await fetch(`${domain}work_units/${id}/`, {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          Authorization: `token ${token}`,
        },
        body: JSON.stringify({
          isdeleted: true,
        }),
      });
      const res = await response.json();
      if (response.ok) {
        reCallApi(true);
        showToast("error", "Unit deleted successfully");
        handleClose();
      } else if (response.status === 409) {
        sessionHandling();
      } else {
        throw new Error(res.error);
      }
    } catch (error) {
      showToast("error", error.message);
    }
  };

  const moveToAddDesignation = () => {
    setPopupTitle("Add Unit");
    setOpenDialog(true);
    setDeletePopup(false);
    reset();
  };

  return loading ? (
    <Loader info="Loading..." />
  ) : (
    <React.Fragment>
      <Mui.Grid container sx={{ padding: 1 }}>
        <Mui.Grid
          container
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
            alignItems: "flex-end",
            padding: 1,
          }}
        >
          <CustomButton
            actionFuntion={moveToAddDesignation}
            actionName="Add Unit"
            typeName="button"
          />
        </Mui.Grid>
        <Mui.TableContainer sx={tableContainerSx} ref={tableContainerRef}>
          <Mui.Table>
            <Mui.TableHead sx={tableHeaderSx}>
              <Mui.TableRow maxWidth="xl" sx={tableRowSx}>
                <Mui.TableCell
                  sx={{ color: themes.blackColor, fontWeight: "bold" }}
                >
                  Unit Name
                </Mui.TableCell>
                <Mui.TableCell
                  sx={{ color: themes.blackColor, fontWeight: "bold" }}
                >
                  Description
                </Mui.TableCell>
                <Mui.TableCell
                  sx={{ color: themes.blackColor, fontWeight: "bold" }}
                >
                  Actions
                </Mui.TableCell>
              </Mui.TableRow>
            </Mui.TableHead>
            <Mui.TableBody>
              {unit
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((item, index) => (
                  <Mui.TableRow
                    maxWidth="xl"
                    sx={{
                      "& .MuiTableCell-root": {
                        height: "10px",
                        padding: "3px 15px",
                      },
                    }}
                    style={{ padding: 1 }}
                  >
                    <Mui.TableCell
                      sx={{
                        color: themes.blackColor,
                        fontWeight: "bold",
                      }}
                    >
                      <Mui.Typography sx={{ paddingLeft: 2 }}>
                        {item?.name ? item?.name : "N/A"}
                      </Mui.Typography>
                    </Mui.TableCell>
                    <Mui.TableCell
                      sx={{
                        color: themes.blackColor,
                        fontWeight: "bold",
                      }}
                    >
                      <Mui.Tooltip title={item?.description}>
                        <Mui.Typography sx={{ paddingLeft: 2 }}>
                          {item?.description?.length > 50
                            ? item?.description.substr(0, 50) + "..."
                            : item?.description}
                        </Mui.Typography>
                      </Mui.Tooltip>
                    </Mui.TableCell>
                    <Mui.TableCell
                      sx={{
                        color: themes.blackColor,
                        fontWeight: "bold",
                      }}
                    >
                      <Mui.IconButton
                        onClick={(event) => {
                          reset();
                          setAnchorEl(event.currentTarget);
                          setValue("unit", item.name);
                          setValue("description", item.description);
                          setValue("id", item.id);
                        }}
                        sx={{ marginLeft: 3 }}
                      >
                        <MoreVert />
                      </Mui.IconButton>
                      <Mui.Menu
                        anchorEl={anchorEl}
                        open={Boolean(anchorEl)}
                        onClose={handleMenuClose}
                        elevation={1}
                        transformOrigin={{
                          horizontal: "right",
                          vertical: "top",
                        }}
                        anchorOrigin={{
                          horizontal: "right",
                          vertical: "bottom",
                        }}
                      >
                        <Mui.MenuItem
                          sx={{ width: "100%" }}
                          onClick={() => {
                            setPopupTitle("Edit Unit");
                            setEditUnit(getValues("unit"));
                            setOpenDialog(true);
                            setDeletePopup(false);
                            setAnchorEl(false);
                          }}
                        >
                          Edit
                        </Mui.MenuItem>
                        <Mui.MenuItem
                          sx={{ width: "100%" }}
                          onClick={() => {
                            setAnchorEl(false);
                            setDeletePopup(true);
                            setOpenDialog(true);
                            setPopupTitle("Delete Unit");
                          }}
                        >
                          Delete
                        </Mui.MenuItem>
                      </Mui.Menu>
                    </Mui.TableCell>
                  </Mui.TableRow>
                ))}
            </Mui.TableBody>
          </Mui.Table>
          {unit.length === 0 && loading === false ? (
            <Mui.Alert sx={{ marginTop: 3 }} severity="info">
              No data available
            </Mui.Alert>
          ) : null}
        </Mui.TableContainer>
      </Mui.Grid>
      <Mui.Grid
        container
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-end",
          alignItems: "flex-end",
        }}
      >
        {unit.length > 25 ? (
          <Mui.TablePagination
            className="custom-pagination"
            component="div"
            rowsPerPageOptions={[25, 50, 75, 100]}
            count={unit.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        ) : null}
      </Mui.Grid>

      <GlobalDialogBox
        handleCloseDialog={handleClose}
        open={openDialog}
        title={popupTitle}
      >
        {deletePopup ? (
          <Mui.Grid container>
            <Mui.Grid xs={12}>
              <Mui.Typography sx={{ fontSize: 22, paddingBottom: 2 }}>
                Do you want to remove the unit?
              </Mui.Typography>
            </Mui.Grid>
            <Mui.Grid xs={12}>
              <Mui.Stack
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-end",
                  alignItems: "flex-end",
                }}
              >
                <CustomButton
                  actionFuntion={handleClose}
                  actionName="No"
                  typeName="submit"
                />
                &nbsp;&nbsp;
                <CustomButton
                  actionFuntion={() => {
                    setOpenDialog(false);
                    deleteDesignation();
                  }}
                  actionName="Yes"
                  typeName="submit"
                />
              </Mui.Stack>
            </Mui.Grid>
          </Mui.Grid>
        ) : popupTitle === "Add Unit" || popupTitle === "Edit Unit" ? (
          <form onSubmit={handleSubmit(createNewDesignation)} noValidate>
            <Mui.Grid container sx={{ paddingBottom: 1 }}>
              <Mui.Grid xs={3} sx={{ justifyContent: "flex-end" }}>
                <Mui.Stack
                  direction="Row"
                  sx={{
                    paddingTop: 1,
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "start",
                    alignItems: "flex-start",
                    paddingRight: 1,
                  }}
                >
                  <Mui.Typography>Unit Name</Mui.Typography> &nbsp;&nbsp;
                </Mui.Stack>
              </Mui.Grid>
              <Mui.Grid xs={9}>
                <Mui.TextField
                  placeholder="Enter Unit Name"
                  {...register("unit", {
                    required: "Unit name is required",
                    minLength: {
                      value: 3,
                      message: "Minimum 3 characters are required",
                    },
                    validate: {
                      atleastOneCharacterWithSpaceReg: (value) => {
                        if (
                          atleastOneCharacterWithSpace.test(value) ||
                          value === ""
                        ) {
                          return true;
                        } else {
                          return "Atleast one alphabet required";
                        }
                      },
                      designationAvailable: async (fieldValue) => {
                        if (popupTitle !== "Edit Unit") {
                          if (fieldValue.trim().length < 3) {
                            return "Minimum 3 characters are required";
                          } else {
                            try {
                              const response = await fetch(
                                `${domain}unique/work-unit/?name=${fieldValue.trim()}`,
                                {
                                  method: "GET",
                                  headers: {
                                    "content-type": "application/json",
                                    Authorization: `token ${token}`,
                                  },
                                }
                              );
                              const res = await response.json();
                              if (response.ok) {
                                if (res.exists) {
                                  return "Unit name is already exists";
                                } else {
                                  return true;
                                }
                              } else if (response.status === 409) {
                                sessionHandling();
                              } else {
                                throw new Error(res.error);
                              }
                            } catch (error) {
                              showToast("error", error.message);
                            }
                          }
                        }
                      },
                      editUnit: async (fieldValue) => {
                        if (
                          popupTitle === "Edit Unit" &&
                          editUnit !== getValues("unit")
                        ) {
                          if (fieldValue.trim().length < 3) {
                            return "Minimum 3 characters are required";
                          } else {
                            try {
                              const response = await fetch(
                                `${domain}unique/work-unit/?name=${fieldValue.trim()}`,
                                {
                                  method: "GET",
                                  headers: {
                                    "content-type": "application/json",
                                    Authorization: `token ${token}`,
                                  },
                                }
                              );
                              const res = await response.json();
                              if (response.ok) {
                                if (res.exists) {
                                  return "Unit name is already exists";
                                } else {
                                  return true;
                                }
                              } else if (response.status === 409) {
                                sessionHandling();
                              } else {
                                throw new Error(res.error);
                              }
                            } catch (error) {
                              showToast("error", error.message);
                            }
                          }
                        }
                      },
                    },
                  })}
                  size="small"
                  InputProps={{
                    inputProps: { maxLength: 32 },
                  }}
                  error={Boolean(errors.unit)}
                  helperText={errors.unit?.message || ""}
                  fullWidth
                />
              </Mui.Grid>
            </Mui.Grid>
            <Mui.Grid container sx={{ paddingBottom: 1 }}>
              <Mui.Grid xs={3} sx={{ justifyContent: "flex-end" }}>
                <Mui.Stack
                  direction="Row"
                  sx={{
                    paddingTop: 1,
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "start",
                    alignItems: "flex-start",
                    paddingRight: 1,
                  }}
                >
                  <Mui.Typography>Description</Mui.Typography> &nbsp;&nbsp;
                </Mui.Stack>
              </Mui.Grid>
              <Mui.Grid xs={9}>
                <Mui.TextField
                  multiline
                  rows={3}
                  maxRows={5}
                  placeholder="Enter the description"
                  {...register("description", {
                    required: "Description is required",
                    minLength: {
                      value: 3,
                      message: "Minimum 3 characters are required",
                    },
                    validate: {
                      designationAvailable: async (fieldValue) => {
                        if (fieldValue.trim().length < 3) {
                          return "Minimum 3 characters are required";
                        } else {
                          return true;
                        }
                      },
                    },
                  })}
                  size="small"
                  InputProps={{
                    inputProps: { maxLength: 128 },
                  }}
                  error={Boolean(errors.description)}
                  helperText={errors.description?.message || ""}
                  onKeyPress={checkSpecialCharWithDigits}
                  fullWidth
                />
              </Mui.Grid>
              <Mui.Grid xs={1}></Mui.Grid>
            </Mui.Grid>
            <Mui.Stack
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
                alignItems: "flex-end",
              }}
            >
              <CustomButton
                actionFuntion={handleClose}
                actionName="Cancel"
                typeName="button"
              />
              &nbsp;&nbsp;
              <Mui.Button
                variant="contained"
                type="submit"
                // onclick={() => {
                //   handleSubmit(createNewDesignation);
                // }}
                sx={{
                  background: themes.primaryButton,
                  fontWeight: 700,
                  color: themes.headLine,
                  textTransform: "capitalize",
                  "&:hover": {
                    backgroundColor: themes.primaryButton,
                  },
                }}
                disabled={buttonDisabled}
              >
                {popupTitle === "Add Unit" ? "Add" : "Update"}
              </Mui.Button>
            </Mui.Stack>
          </form>
        ) : null}
      </GlobalDialogBox>
    </React.Fragment>
  );
};
