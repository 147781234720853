import React from "react";
import PayslipList from "./PayslipList";

export const Payslip = () => {
  return (
    <React.Fragment>
      <PayslipList />
    </React.Fragment>
  );
};
