import React, { useState } from "react";
import * as Mui from "@mui/material";
import { useFetch } from "services/hooks/useFetch";
import { useSelector } from "react-redux";
import { themes } from "services/constants";
import { MoreVert } from "@mui/icons-material";
import { CustomButton } from "components/CustomComponents/CustomButton";
import { GlobalDialogBox } from "components/GlobalDialogBox";
import { useForm } from "react-hook-form";
import sessionHandling from "services/utils/notificationUtils";
import { showToast } from "services/utils/Status";

export const EmployeeList = ({ actionRowData, companyId }) => {
  const { domain, token } = useSelector((state) => state.tokenReducer);
  const [anchorElMenu, setAnchorElMenu] = useState(null);
  const {
    register,
    reset,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();
  const [moveRowData, setMoveRowData] = useState(null);
  const [swapSalaryGroup, setSwapSalaryGroup] = useState(false);
  const [userDetails, setUserDetails] = useState(actionRowData?.users_detail);
  const { result: salaryGroupData } = useFetch(
    companyId !== 0 ? `${domain}payroll-group/?company=${companyId}` : null
  );
  const [rowsPerPage, setLeaveRowsPerPage] = useState(25);
  const [page, setPage] = useState(0);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setLeaveRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const swapSalaryGroupName = async (data) => {
    try {
      const response = await fetch(`${domain}move/payroll-group-users`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `token ${token}`,
        },
        body: JSON.stringify({
          salary_group_id: data.salary_group_name_id,
          user_id: moveRowData.id,
        }),
      });
      const res = await response.json();
      if (response.ok) {
        let filterData = userDetails.filter((data) => {
          return data.id !== moveRowData.id;
        });
        showToast(
          "success",
          `${moveRowData.username} was successfully swapped to ${data.salary_group_name}`
        );
        setUserDetails(filterData);
        setSwapSalaryGroup(false);
        reset();
      } else if (response.status === 409) {
        sessionHandling();
      } else {
        throw new Error(res.error);
      }
    } catch (error) {
      showToast("error", error.message);
    }
  };

  return (
    <React.Fragment>
      <Mui.Grid container>
        <Mui.TableContainer
          sx={{
            mt: 2,
            width: "100%",
            paddingLeft: 1,
            paddingRight: 1,
            maxHeight: 700,
          }}
        >
          <Mui.Table>
            <Mui.TableHead
              sx={{
                backgroundColor: themes.primary,
                position: "sticky",
                top: -1,
                zIndex: 10,
              }}
            >
              <Mui.TableRow
                sx={{
                  "& .MuiTableCell-root": {
                    height: "10px",
                    padding: "6px 5px",
                  },
                }}
              >
                <Mui.TableCell
                  sx={{ color: themes.blackColor, fontWeight: "bold" }}
                >
                  Employee Name
                </Mui.TableCell>
                <Mui.TableCell
                  sx={{
                    color: themes.blackColor,
                    fontWeight: "bold",
                  }}
                >
                  Employee Id
                </Mui.TableCell>
                {/* <Mui.TableCell
                  sx={{
                    color: themes.blackColor,
                    fontWeight: "bold",
                  }}
                >
                  Department
                </Mui.TableCell>
                <Mui.TableCell
                  sx={{
                    color: themes.blackColor,
                    fontWeight: "bold",
                  }}
                >
                  Supervisor
                </Mui.TableCell> */}
                <Mui.TableCell
                  sx={{
                    color: themes.blackColor,
                    fontWeight: "bold",
                  }}
                >
                  Action
                </Mui.TableCell>
              </Mui.TableRow>
            </Mui.TableHead>
            <Mui.TableBody>
              {userDetails?.length === 0 ? (
                <Mui.TableRow>
                  <Mui.TableCell colSpan={12} align="center">
                    <Mui.Alert severity="info">No data available</Mui.Alert>
                  </Mui.TableCell>
                </Mui.TableRow>
              ) : (
                userDetails
                  ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  ?.map((payrollGroupData, index) => (
                    <React.Fragment>
                      <Mui.TableRow
                        sx={{
                          "& .MuiTableCell-root": {
                            height: "7px",
                            padding: "6px 5px",
                          },
                        }}
                      >
                        <Mui.TableCell>
                          {payrollGroupData?.username
                            ? payrollGroupData?.username
                            : "N/A"}
                        </Mui.TableCell>
                        <Mui.TableCell>
                          {payrollGroupData?.employee_id
                            ? payrollGroupData?.employee_id
                            : "N/A"}
                        </Mui.TableCell>
                        {/* <Mui.TableCell>Not in Api</Mui.TableCell>
                        <Mui.TableCell>Not in Api</Mui.TableCell> */}
                        <Mui.TableCell>
                          <Mui.IconButton
                            onClick={(event) => {
                              setMoveRowData(payrollGroupData);
                              setAnchorElMenu(event.currentTarget);
                            }}
                          >
                            <MoreVert />
                          </Mui.IconButton>
                          <Mui.Menu
                            anchorEl={anchorElMenu}
                            open={Boolean(anchorElMenu)}
                            onClose={() => {
                              setAnchorElMenu(null);
                            }}
                            elevation={1}
                            transformOrigin={{
                              horizontal: "right",
                              vertical: "top",
                            }}
                            anchorOrigin={{
                              horizontal: "right",
                              vertical: "bottom",
                            }}
                          >
                            <Mui.MenuItem
                              onClick={() => {
                                setSwapSalaryGroup(true);
                                setAnchorElMenu(null);
                              }}
                            >
                              Move
                            </Mui.MenuItem>
                          </Mui.Menu>
                        </Mui.TableCell>
                      </Mui.TableRow>
                    </React.Fragment>
                  ))
              )}
            </Mui.TableBody>
          </Mui.Table>
          <Mui.Grid
            container
            sx={{
              paddingTop: 1,
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              alignItems: "flex-end",
              paddingRight: 1,
            }}
          >
            {userDetails?.length >= 25 ? (
              <Mui.TablePagination
                className="custom-pagination"
                component="div"
                rowsPerPageOptions={[25, 50, 75, 100]}
                count={userDetails?.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            ) : null}
          </Mui.Grid>
        </Mui.TableContainer>
      </Mui.Grid>
      <GlobalDialogBox
        handleCloseDialog={() => {
          setSwapSalaryGroup(false);
        }}
        open={swapSalaryGroup}
        title="Swap payroll group"
      >
        <form onSubmit={handleSubmit(swapSalaryGroupName)}>
          <Mui.Grid container>
            <Mui.Grid xs={12}>
              <Mui.Stack>
                <Mui.Grid container>
                  <Mui.Typography sx={{ paddingBottom: 1 }}>
                    Current Payroll Group
                  </Mui.Typography>
                  <Mui.TextField
                    defaultValue={actionRowData?.name}
                    size="small"
                    sx={{ width: "100%", paddingBottom: 2 }}
                  />
                </Mui.Grid>
                <Mui.Grid container sx={{ paddingBottom: 1 }}>
                  <Mui.Typography>Select Payroll Group </Mui.Typography>
                  <Mui.Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    size="small"
                    options={salaryGroupData?.map((obj) => ({
                      ...obj,
                      label: obj.name,
                    }))}
                    sx={{ width: "100%", paddingBottom: 2 }}
                    ListboxProps={{ style: { maxHeight: "100px" } }}
                    filterOptions={(options, state) => {
                      return options.filter((option) =>
                        option.name
                          .toLowerCase()
                          .includes(state.inputValue.toLowerCase())
                      );
                    }}
                    renderOption={(props, option) => {
                      return actionRowData?.name !== option.name ? (
                        <li {...props}>{option.name}</li>
                      ) : null;
                    }}
                    onChange={(event, value) => {
                      if (value) {
                        setValue("salary_group_name_id", value.id);
                        setValue("salary_group_name", value.name);
                      } else {
                        setValue("salary_group_name_id", null);
                        setValue("salary_group_name", null);
                      }
                    }}
                    renderInput={(params) => (
                      <Mui.TextField
                        {...params}
                        {...register("salary_group_name", {
                          required: "Salary group name is required",
                        })}
                        sx={{
                          background: themes.whiteColor,
                          maxHeight: 120,
                        }}
                        placeholder="Select salary group name"
                        size="small"
                        error={Boolean(errors.salary_group_name)}
                        helperText={errors.salary_group_name?.message || ""}
                      />
                    )}
                  />
                </Mui.Grid>
                <Mui.Grid
                  container
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-end",
                    alignItems: "flex-end",
                    paddingRight: 1,
                    paddingTop: 4,
                  }}
                >
                  <CustomButton
                    actionFuntion={() => setSwapSalaryGroup(false)}
                    actionName="Cancel"
                    typeName="button"
                  />
                  &nbsp;&nbsp;
                  <CustomButton actionName="Swap" typeName="submit" />
                </Mui.Grid>
              </Mui.Stack>
            </Mui.Grid>
          </Mui.Grid>
        </form>
      </GlobalDialogBox>
    </React.Fragment>
  );
};
