import React, { useState, useEffect } from "react";
import { MoreVert } from "@mui/icons-material";
import * as Mui from "@mui/material";
import { themes } from "services/constants";
import { useFetch } from "services/hooks/useFetch";
import { useSelector } from "react-redux";
import { Loader } from "components/Loader";
import { teamZoneLoanHistory } from "../Headers";
import { tableRowSx } from "services/constants";
const keys = [
  "applied_date",
  "department_name",
  "user_name",
  "principal_amount",
  "tenure_months",
  "interest_rate",
  "monthly_installment",
  "loan_type",
  "interest_type",
  "status",
];

export const Approve = ({
  departmentId,
  departmentChange,
  setDepartmentChange,
  loanScreen,
  setViewLoanDetails,
  userChange,
  setUserChange,
  setShowApprove,
  userId,
}) => {
  const { domain, userDetails, globalSearchValue } = useSelector(
    (state) => state.tokenReducer
  );
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [loanData, setLoanData] = useState([]);
  const [updateData, setUpdateData] = useState(true);
  const [anchorEl, setAnchorEl] = useState(null);

  const {
    result: loanDetails,
    loading,
    setLoad: reCallApi,
  } = useFetch(
    `${domain}loans/?user_id=${userDetails.id}&status=Pending&department_id=${departmentId}&selected_user_id=${userId}`
  );

  useEffect(() => {
    if ((departmentChange || userChange) && loanScreen === "Approve") {
      reCallApi(true);
      setUserChange(false);
      setDepartmentChange(false);
    }
  }, [
    departmentChange,
    loanScreen,
    reCallApi,
    setDepartmentChange,
    setUserChange,
    userChange,
  ]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    if (updateData && loanDetails?.length !== 0 && loanDetails) {
      setLoanData(loanDetails);
      setUpdateData(false);
    }
  }, [loanDetails, updateData]);

  useEffect(() => {
    if (loanDetails?.length !== 0 || loanDetails?.length === 0) {
      const filteredResults = loanDetails?.filter((item) =>
        keys.some((key) =>
          item[key]
            ?.toString()
            .toLowerCase()
            .includes(globalSearchValue.toString().toLowerCase())
        )
      );
      setLoanData(filteredResults);
      setPage(globalSearchValue ? 0 : page);
    }
  }, [globalSearchValue, loanDetails, page]);

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  return loading ? (
    <Loader info="Loading..." />
  ) : (
    <React.Fragment>
      <Mui.TableContainer
        sx={{
          padding: 0.5,
          marginTop: 2,
          marginLeft: "auto",
          marginRight: "auto",
          overflow: "auto",
        }}
      >
        <Mui.Table>
          <Mui.TableHead
            sx={{
              backgroundColor: themes.primary,
              position: "sticky",
              top: -1,
              zIndex: 10,
            }}
          >
            <Mui.TableRow
            sx={tableRowSx}
            >
              {teamZoneLoanHistory.map((head) => (
                <Mui.TableCell>
                  <Mui.Typography fontWeight="bold">{head.name}</Mui.Typography>
                </Mui.TableCell>
              ))}
            </Mui.TableRow>
          </Mui.TableHead>

          <Mui.TableBody>
            {loanData.length === 0 ? (
              <Mui.TableRow sx={tableRowSx}>
                <Mui.TableCell colSpan={12}>
                  <Mui.Alert sx={{ paddingTop: 1 }} severity="info">
                    No data available
                  </Mui.Alert>
                </Mui.TableCell>
              </Mui.TableRow>
            ) : (
              loanData
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((item, index) => (
                  <Mui.TableRow
                   sx={tableRowSx}
                  >
                    {teamZoneLoanHistory.map((head) =>
                      head.name !== "Action" ? (
                        <Mui.TableCell>
                          {item[head.key] ? item[head.key] : "N/A"}
                        </Mui.TableCell>
                      ) : (
                        <Mui.TableCell>
                          <Mui.IconButton
                            onClick={(e) => {
                              handleMenuOpen(e, index);
                              setViewLoanDetails(item);
                            }}
                          >
                            <MoreVert
                              sx={{ color: themes.blackColor, strokeWidth: 2 }}
                            />
                          </Mui.IconButton>
                          <Mui.Menu
                            anchorEl={anchorEl}
                            open={Boolean(anchorEl)}
                            onClose={handleMenuClose}
                            elevation={1}
                            transformOrigin={{
                              horizontal: "right",
                              vertical: "top",
                            }}
                            anchorOrigin={{
                              horizontal: "right",
                              vertical: "bottom",
                            }}
                          >
                            <Mui.MenuItem
                              onClick={() => {
                                setShowApprove(true);
                              }}
                            >
                              View
                            </Mui.MenuItem>
                          </Mui.Menu>
                        </Mui.TableCell>
                      )
                    )}
                  </Mui.TableRow>
                ))
            )}
          </Mui.TableBody>
        </Mui.Table>
        {loanData.length > 25 && (
          <Mui.TablePagination
            className="custom-pagination"
            rowsPerPageOptions={[25,50,75,100]}
            component="div"
            count={loanData.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        )}
      </Mui.TableContainer>
    </React.Fragment>
  );
};
