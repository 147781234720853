import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { themes, clientFilterKey } from "services/constants";
import * as Mui from "@mui/material";
import { AddClient } from "./AddClient";
import { Loader } from "components/Loader";
import EditIcon from "@mui/icons-material/Edit";
import { CustomButton } from "components/CustomComponents/CustomButton";
import { BackArrow } from "components/CustomComponents/BackArrow";
import { useFetch } from "services/hooks/useFetch";
import { tableRowSx } from "services/constants";

export const clientHeader = [
  { header: "Client Name", key: "clientname" },
  { header: "Client Code", key: "clientid" },
  { header: "Address", key: "address" },
  { header: "Gst No", key: "gstnumber" },
  { header: "Email", key: "email" },
  { header: "Phone No", key: "phonenumber" },
  { header: "Actions", key: "action" },
];

export const Clients = () => {
  const { domain, userDetails, globalSearchValue } = useSelector(
    (state) => state.tokenReducer
  );
  const role = userDetails.role === "User" ? true : false;
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [addClientScreen, setAddClientScreen] = useState(false);
  const [allClients, setAllClients] = useState([]);
  const [fetchClients, setFetchClients] = useState(true);
  const [screenName, setScreenName] = useState("Add Client");
  const [editClientDetails, setEditClientDetails] = useState(null);
  const [updateData, setUpdateData] = useState(false);
  const {
    result: expenseDetails,
    loading,
    setLoad: reCallApi,
  } = useFetch(`${domain}client/`);

  useEffect(() => {
    if (fetchClients) {
      reCallApi(true);
      setFetchClients(false);
    }
  }, [fetchClients, reCallApi]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const addNewClient = () => {
    setEditClientDetails({
      clientname: "",
      clientcode: "",
      address: "",
      gstno: "",
      email: "",
      phonenumber: "",
    });
    setScreenName("Add Client");
    setUpdateData(true);
    setAddClientScreen(true);
  };

  const EditClient = (item) => {
    setEditClientDetails({
      id: item.id,
      clientname: item.clientname,
      clientcode: item.clientid,
      address: item.address,
      gstno: item.gstnumber,
      email: item.email,
      phonenumber: item.phonenumber,
    });
    setScreenName("Edit Client");
    setUpdateData(true);
    setAddClientScreen(true);
  };

  useEffect(() => {
    const filteredResults = expenseDetails.filter((item) =>
      clientFilterKey.some((key) =>
        item[key]
          ?.toString()
          .toLowerCase()
          .includes(globalSearchValue.toString().toLowerCase())
      )
    );
    setAllClients(filteredResults);
    setPage(0);
  }, [globalSearchValue, expenseDetails]);

  return loading ? (
    <Loader info="Loading..." />
  ) : (
    <React.Fragment>
      <Mui.Grid container sx={{ paddingRight: 1 }}>
        <Mui.Grid container>
          {addClientScreen ? (
            <>
              <Mui.Grid xs={1} sm={1} md={1} lg={0.5} sx={{ paddingTop: 1.5 }}>
                <BackArrow actionFunction={() => setAddClientScreen(false)} />
              </Mui.Grid>
              <Mui.Grid xs={2} sx={{ paddingTop: 1.5 }}>
                <Mui.Typography
                  sx={{ fontWeight: "bold", fontSize: 25, paddingTop: 1 }}
                >
                  {screenName === "Add Client"
                    ? "Clients / Add Client"
                    : "Clients / Edit Client"}
                </Mui.Typography>
              </Mui.Grid>
            </>
          ) : null}
        </Mui.Grid>
        <Mui.Grid
          item
          xs={11}
          sm={11}
          md={11}
          lg={12}
          sx={{
            mt: 2,
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
            alignItems: "flex-end",
          }}
        >
          {addClientScreen === false && !role ? (
            <CustomButton
              actionFuntion={() => addNewClient()}
              actionName="Add Client"
              typeName="button"
            />
          ) : null}
        </Mui.Grid>
      </Mui.Grid>

      <Mui.Grid container sx={{ paddingLeft: 2, paddingRight: 2 }}>
        {addClientScreen === false ? (
          <Mui.TableContainer
            sx={{
              marginTop: 2,
              marginLeft: "auto",
              marginRight: "auto",
              overflow: "auto",
            }}
          >
            <Mui.Table>
              <Mui.TableHead
                sx={{
                  backgroundColor: themes.primary,
                }}
              >
                <Mui.TableRow sx={tableRowSx}>
                  {clientHeader?.map((value) => (
                    <Mui.TableCell
                      sx={{ color: themes.blackColor, fontWeight: "bold" }}
                    >
                      {value?.header}
                    </Mui.TableCell>
                  ))}
                </Mui.TableRow>
              </Mui.TableHead>
              <Mui.TableBody>
                {allClients.length === 0 ? (
                  <Mui.TableRow sx={tableRowSx}>
                    <Mui.TableCell colSpan={12}>
                      <Mui.Alert
                        severity="info"
                        sx={{ margin: 2, paddingTop: 2 }}
                      >
                        No data available
                      </Mui.Alert>
                    </Mui.TableCell>
                  </Mui.TableRow>
                ) : (
                  allClients
                    ?.slice(
                      page * rowsPerPage,
                      page * rowsPerPage + rowsPerPage
                    )
                    ?.map((value) => (
                      <Mui.TableRow sx={tableRowSx}>
                        {clientHeader.map((head) =>
                          head.header !== "Actions" ? (
                            <Mui.TableCell>
                              {value[head.key] ? value[head.key] : "N/A"}
                            </Mui.TableCell>
                          ) : (
                            <Mui.TableCell>
                              <Mui.IconButton
                                onClick={(e) => {
                                  EditClient(value);
                                }}
                                disabled={role}
                              >
                                <EditIcon
                                  sx={{
                                    color: themes.blackColor,
                                    strokeWidth: 2,
                                  }}
                                />
                              </Mui.IconButton>
                            </Mui.TableCell>
                          )
                        )}
                      </Mui.TableRow>
                    ))
                )}
              </Mui.TableBody>
            </Mui.Table>
          </Mui.TableContainer>
        ) : null}
      </Mui.Grid>

      {addClientScreen ? (
        <AddClient
          screenName={screenName}
          editClientDetails={editClientDetails}
          setAddClientScreen={setAddClientScreen}
          updateData={updateData}
          setUpdateData={setUpdateData}
          setFetchClients={setFetchClients}
        />
      ) : null}
      {allClients.length > 25 && addClientScreen === false ? (
        <Mui.TablePagination
          className="custom-pagination"
          component="div"
          rowsPerPageOptions={[25, 50, 75, 100]}
          count={allClients.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      ) : null}
    </React.Fragment>
  );
};
