import React, { useCallback, useEffect, useState } from "react";
import { showToast } from "services/utils/Status";
import { useSelector } from "react-redux";
import { IndividualLeaveGroupDetails } from "./IndividualLeaveGroup";
import { LeaveGroupDetails } from "./LeaveGroup";
import { Loader } from "components/Loader";
import sessionHandling from "services/utils/notificationUtils";

const LeaveType = ["leave_type", "leave_per_period"];

const Users = ["user_name", "email", "user_code"];

export const LeaveGroup = () => {
  const [view, setView] = useState("LeaveGroup");
  const { domain, token, globalSearchValue } = useSelector(
    (state) => state.tokenReducer
  );
  const [leaveGroupData, setLeaveGroupData] = useState([]);
  const [individualLeaveGroupData, setIndividualLeaveGroupData] = useState([]);
  const [fetchLeaveGroupData, setFetchLeaveGroupData] = useState(false);
  const [fetchIndividualLeaveGroupData, setFetchIndividualLeaveGroupData] =
    useState(false);
  const [leaveTypeData, setLeaveTypeData] = useState([]);
  const [fetchLeaveTypeData, setFetchLeaveTypeData] = useState(false);
  const [usersData, setUsersData] = useState([]);
  const [fetchUsersData, setFetchUsersData] = useState(false);
  const [loader, setLoader] = useState(false);
  const [edit, setEdit] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [masterLeaveGroup, setmasterLeaveGroup] = useState([]);
  const [update, setUpdate] = useState(false);
  const [individualLeaveType, setIndividualLeaveType] = useState([]);
  const [individualUser, setIndividualUser] = useState([]);
  const [page, setPage] = useState(0);
  const [page1, setPage1] = useState(0);


  const fetchLeaveGroupDatas = useCallback(async () => {
    setLoader(true);
    try {
      const response = await fetch(`${domain}leave-groups/`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `token ${token}`,
        },
      });
      const res = await response.json();
      if (response.ok) {
        setLeaveGroupData(res);
        setmasterLeaveGroup(res);
      } else if (response.status === 409) {
        sessionHandling();
      } else {
        throw new Error(res.error);
      }
    } catch (error) {
      showToast("error", error.message);
    } finally {
      setFetchUsersData(true);
    }
    setLoader(false);
  }, [domain, token]);

  useEffect(() => {
    const updateLeaveGroupMembers = async () => {
      try {
        const response = await fetch(`${domain}leave-groups/${edit?.id}`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `token ${token}`,
          },
        });
        const res = await response.json();
        if (response.ok) {
          setIndividualLeaveGroupData(res);
        } else if (response.status === 409) {
          sessionHandling();
        } else {
          throw new Error(res.error);
        }
      } catch (error) {
        showToast("error", error.message);
      }
    };
    if (update) {
      updateLeaveGroupMembers();
      setUpdate(false);
    }
  }, [domain, edit?.id, token, update]);

  const fetchIndividualLeaveGroupDatas = useCallback(async () => {
    setLoader(true);
    try {
      const response = await fetch(`${domain}leave-groups/${edit?.id}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `token ${token}`,
        },
      });
      const res = await response.json();
      if (response.ok) {
        setIndividualLeaveGroupData(res);
        setIndividualLeaveType(res.leave_types);
        setIndividualUser(res.users);
        setLoader(false);
      } else if (response.status === 409) {
        sessionHandling();
      } else {
        throw new Error(res.error);
      }
    } catch (error) {
      showToast("error", error.message);
    }
  }, [domain, edit?.id, token]);

  useEffect(() => {
    setFetchLeaveGroupData(true);
  }, [setLeaveGroupData]);

  useEffect(() => {
    if (fetchIndividualLeaveGroupData) {
      fetchIndividualLeaveGroupDatas();
      setFetchIndividualLeaveGroupData(false);
    }
    if (fetchLeaveGroupData) {
      fetchLeaveGroupDatas();
      setFetchLeaveGroupData(false);
    }
  }, [
    fetchIndividualLeaveGroupData,
    fetchIndividualLeaveGroupDatas,
    fetchLeaveGroupData,
    fetchLeaveGroupDatas,
  ]);

  const fetchUsersDatas = useCallback(async () => {
    try {
      const response = await fetch(`${domain}leave_group/unassigned_users`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `token ${token}`,
        },
      });
      const res = await response.json();
      if (response.ok) {
        setUsersData(res);
      } else if (response.status === 409) {
        sessionHandling();
      } else {
        throw new Error(res.error);
      }
    } catch (error) {
      showToast("error", error.message);
    }
  }, [domain, token]);

  useEffect(() => {
    setFetchUsersData(true);
  }, [setFetchUsersData]);

  useEffect(() => {
    if (fetchUsersData) {
      fetchUsersDatas();
      setFetchUsersData(false);
    }
  }, [fetchUsersDatas, fetchUsersData, setFetchUsersData]);

  const fetchLeaveTypeDatas = useCallback(async () => {
    try {
      const response = await fetch(`${domain}leave-types/`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `token ${token}`,
        },
      });
      const res = await response.json();
      if (response.ok) {
        setLeaveTypeData(res);
      } else if (response.status === 409) {
        sessionHandling();
      } else {
        throw new Error(res.error);
      }
    } catch (error) {
      showToast("error", error.message);
    }
  }, [domain, token]);

  useEffect(() => {
    setFetchLeaveTypeData(true);
  }, [setFetchLeaveTypeData]);

  useEffect(() => {
    if (fetchLeaveTypeData) {
      fetchLeaveTypeDatas();
      setFetchLeaveTypeData(false);
    }
  }, [fetchLeaveTypeDatas, fetchLeaveTypeData, setFetchLeaveTypeData]);

  useEffect(() => {
    const filteredResults = individualLeaveGroupData?.leave_types?.filter(
      (item) =>
        LeaveType.some((key) =>
          item[key]
            ?.toString()
            .toLowerCase()
            .includes(globalSearchValue.toString().toLowerCase())
        )
    );
    setIndividualLeaveType(filteredResults);
    setPage(0);
  }, [globalSearchValue, individualLeaveGroupData?.leave_types]);

  useEffect(() => {
    const filteredResults = individualLeaveGroupData?.users?.filter((item) =>
      Users.some((key) =>
        item[key]
          ?.toString()
          .toLowerCase()
          .includes(globalSearchValue.toString().toLowerCase())
      )
    );
    setIndividualUser(filteredResults);
    setPage1(0);
  }, [globalSearchValue, individualLeaveGroupData?.users]);

  return loader ? (
    <Loader info="Loading..." />
  ) : (
    <React.Fragment>
      {view === "LeaveGroup" ? (
        <LeaveGroupDetails
          setView={setView}
          leaveGroupData={leaveGroupData}
          leaveTypeData={leaveTypeData}
          globalSearchValue={globalSearchValue}
          masterLeaveGroup={masterLeaveGroup}
          setLeaveGroupData={setLeaveGroupData}
          usersData={usersData}
          setFetchLeaveGroupData={setFetchLeaveGroupData}
          edit={edit}
          setEdit={setEdit}
          openDialog={openDialog}
          setOpenDialog={setOpenDialog}
          setFetchIndividualLeaveGroupData={setFetchIndividualLeaveGroupData}
          setFetchLeaveTypeData={setFetchLeaveTypeData}
          setFetchUsersData={setFetchUsersData}
        />
      ) : (
        <IndividualLeaveGroupDetails
          page={page}
          setPage={setPage}
          setView={setView}
          setPage1={setPage1}
          page1={page1}
          individualLeaveType={individualLeaveType}
          individualUser={individualUser}
          individualLeaveGroupData={individualLeaveGroupData}
          leaveTypeData={leaveTypeData}
          setLeaveGroupData={setLeaveGroupData}
          masterLeaveGroup={masterLeaveGroup}
          usersData={usersData}
          edit={edit}
          setFetchIndividualLeaveGroupData={setFetchIndividualLeaveGroupData}
          setFetchLeaveGroupData={setFetchLeaveGroupData}
          leaveGroupData={leaveGroupData}
          setUpdate={setUpdate}
        />
      )}
    </React.Fragment>
  );
};
