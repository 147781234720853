import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { themes, leaveTypeHrms, tableRowSx } from "services/constants";
import { showToast } from "services/utils/Status";
import { MoreVert } from "@mui/icons-material";
import { Loader } from "components/Loader";
import * as Mui from "@mui/material";
import { GlobalDialogBox } from "components/GlobalDialogBox";
import { CustomButton } from "components/CustomComponents/CustomButton";
import sessionHandling from "services/utils/notificationUtils";
import { AddLeaveType } from "./AddLeaveType";
import { EditLeaveType } from "./EditLeaveType";
import { useFetch } from "services/hooks/useFetch";
import { tableContainerSx, tableHeaderSx } from "services/constants";
let multiApiCallRestriction = true;
export const LeaveType = () => {
  const { domain, token, globalSearchValue } = useSelector(
    (state) => state.tokenReducer
  );
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [anchorEl, setAnchorEl] = useState(null);
  const {
    result: masterLeaveType,
    loading: loader,
    setLoad: setApiCall,
  } = useFetch(`${domain}new-leave-type/`);
  const [openDialog, setOpenDialog] = useState(false);
  const [leaveTypes, setLeaveTypes] = useState([]);
  const [editLeaveType, setEditLeaveType] = useState("");
  const [editAction, setEditAction] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [showAddLeaveType, setShowAddLeaveType] = useState(false);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleMenuClose = () => {
    setAnchorEl(false);
    handleClose();
  };

  const handleClose = () => {
    setOpenDialog(false);
    setEditLeaveType("");
  };

  const deleteLeaveType = async () => {
    if (multiApiCallRestriction) {
      setButtonDisabled(true);
      multiApiCallRestriction = false;
      try {
        const response = await fetch(
          `${domain}new-leave-type/${editLeaveType.id}/`,
          {
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
              Authorization: `token ${token}`,
            },
            body: JSON.stringify({
              isdeleted: true,
              leave_per_period: editLeaveType.leave_per_period,
            }),
          }
        );
        const res = await response.json();
        if (response.ok) {
          handleClose();
          setApiCall(true);
          showToast("error", "Leave type deleted successfully");
          multiApiCallRestriction = true;
          setButtonDisabled(false);
        } else if (response.status === 409) {
          sessionHandling();
        } else {
          throw new Error(res.error);
        }
      } catch (error) {
        showToast("error", error.message);
        multiApiCallRestriction = true;
        setButtonDisabled(false);
      }
    }
  };

  useEffect(() => {
    const filteredResults = masterLeaveType.filter((item) =>
      leaveTypeHrms.some((key) =>
        item[key]
          ?.toString()
          .toLowerCase()
          .includes(globalSearchValue.toString().toLowerCase())
      )
    );
    setLeaveTypes(filteredResults);
    setPage(globalSearchValue ? 0 : page);
  }, [globalSearchValue, masterLeaveType, page]);

  const addButtonAction = () => {
    setShowAddLeaveType(true);
  };

  return loader ? (
    <Loader info="Loading..." />
  ) : (
    <React.Fragment>
      {!showAddLeaveType && !editAction ? (
        <>
          <Mui.Grid
            container
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              alignItems: "flex-end",
              padding: 2,
            }}
          >
            <CustomButton
              actionFuntion={addButtonAction}
              actionName="Add Leave Type"
              typeName="button"
            />
          </Mui.Grid>
          <Mui.TableContainer sx={tableContainerSx}>
            <Mui.Table>
              <Mui.TableHead sx={tableHeaderSx}>
                <Mui.TableRow maxWidth="xl" align="left" sx={tableRowSx}>
                  <Mui.TableCell
                    sx={{ color: themes.blackColor, fontWeight: "bold" }}
                  >
                    Leave Name
                  </Mui.TableCell>
                  <Mui.TableCell
                    sx={{ color: themes.blackColor, fontWeight: "bold" }}
                  >
                    Crediting Schedule
                  </Mui.TableCell>
                  <Mui.TableCell
                    sx={{ color: themes.blackColor, fontWeight: "bold" }}
                  >
                    Leave Per Year
                  </Mui.TableCell>
                  <Mui.TableCell
                    sx={{ color: themes.blackColor, fontWeight: "bold" }}
                  >
                    Payout policy
                  </Mui.TableCell>
                  <Mui.TableCell
                    sx={{ color: themes.blackColor, fontWeight: "bold" }}
                  >
                    Actions
                  </Mui.TableCell>
                </Mui.TableRow>
              </Mui.TableHead>
              <Mui.TableBody>
                {leaveTypes.length === 0 && !loader ? (
                  <Mui.TableRow maxWidth="xl" align="left" sx={tableRowSx}>
                    <Mui.TableCell colSpan={12}>
                      <Mui.Alert sx={{ marginTop: 3 }} severity="info">
                        No data available
                      </Mui.Alert>
                    </Mui.TableCell>
                  </Mui.TableRow>
                ) : (
                  leaveTypes
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((item, index) => (
                      <Mui.TableRow maxWidth="xl" align="left" sx={tableRowSx}>
                        <Mui.TableCell
                          sx={{ color: themes.blackColor, fontWeight: "bold" }}
                        >
                          <Mui.Typography>
                            {item?.leave_type ? item?.leave_type : "N/A"}
                          </Mui.Typography>
                        </Mui.TableCell>
                        <Mui.TableCell
                          sx={{ color: themes.blackColor, fontWeight: "bold" }}
                        >
                          <Mui.Typography>
                            {item?.leave_crediting_schedule
                              ? item?.leave_crediting_schedule
                              : "N/A"}
                          </Mui.Typography>
                        </Mui.TableCell>
                        <Mui.TableCell
                          sx={{ color: themes.blackColor, fontWeight: "bold" }}
                        >
                          <Mui.Typography>
                            {item?.leave_per_year
                              ? item?.leave_per_year
                              : "N/A"}
                          </Mui.Typography>
                        </Mui.TableCell>
                        <Mui.TableCell
                          sx={{ color: themes.blackColor, fontWeight: "bold" }}
                        >
                          <Mui.Typography>
                            {item?.leave_pay_out_policy
                              ? item?.leave_pay_out_policy
                              : "N/A"}
                          </Mui.Typography>
                        </Mui.TableCell>
                        <Mui.TableCell
                          sx={{ color: themes.blackColor, fontWeight: "bold" }}
                        >
                          <Mui.IconButton
                            onClick={(event) => {
                              setAnchorEl(event.currentTarget);
                              setEditLeaveType(item);
                            }}
                          >
                            <MoreVert />
                          </Mui.IconButton>
                          <Mui.Menu
                            anchorEl={anchorEl}
                            open={Boolean(anchorEl)}
                            onClose={handleMenuClose}
                            elevation={1}
                            transformOrigin={{
                              horizontal: "right",
                              vertical: "top",
                            }}
                            anchorOrigin={{
                              horizontal: "right",
                              vertical: "bottom",
                            }}
                          >
                            <Mui.MenuItem
                              sx={{ width: "100%" }}
                              onClick={() => {
                                setAnchorEl(false);
                                setEditAction(true);
                              }}
                            >
                              Edit
                            </Mui.MenuItem>
                            <Mui.MenuItem
                              sx={{ width: "100%" }}
                              onClick={() => {
                                setAnchorEl(false);
                                setOpenDialog(true);
                              }}
                            >
                              Delete
                            </Mui.MenuItem>
                          </Mui.Menu>
                        </Mui.TableCell>
                      </Mui.TableRow>
                    ))
                )}
              </Mui.TableBody>
            </Mui.Table>
          </Mui.TableContainer>
          <Mui.Grid
            container
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              alignItems: "flex-end",
            }}
          >
            {leaveTypes.length > 25 ? (
              <Mui.TablePagination
                className="custom-pagination"
                component="div"
                rowsPerPageOptions={[25, 50, 75, 100]}
                count={leaveTypes.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            ) : null}
          </Mui.Grid>
        </>
      ) : editAction ? (
        <EditLeaveType
          setEditAction={setEditAction}
          setApiCall={setApiCall}
          editLeaveType={editLeaveType}
        />
      ) : (
        <AddLeaveType
          setShowAddLeaveType={setShowAddLeaveType}
          setApiCall={setApiCall}
        />
      )}
      <GlobalDialogBox
        handleCloseDialog={handleClose}
        open={openDialog}
        title={"Delete leave type"}
        minWidth={700}
      >
        <Mui.Grid container>
          <Mui.Grid xs={12}>
            <Mui.Typography sx={{ paddingBottom: 2, fontSize: 22 }}>
              Are you sure you want to delete{" "}
              <b fontSize={22} style={{ color: themes.redColor }}>
                {editLeaveType?.leave_type}
              </b>
              ?
            </Mui.Typography>
          </Mui.Grid>
          <Mui.Grid xs={12}>
            <Mui.Stack
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <CustomButton
                actionFuntion={() => {
                  setOpenDialog(false);
                  handleClose();
                }}
                actionName="No"
                typeName="submit"
              />
              &nbsp;
              <CustomButton
                actionFuntion={() => {
                  setOpenDialog(false);
                  deleteLeaveType();
                }}
                disableAction={
                  openDialog && buttonDisabled ? buttonDisabled : false
                }
                actionName="Yes"
                typeName="submit"
              />
            </Mui.Stack>
          </Mui.Grid>
        </Mui.Grid>
      </GlobalDialogBox>
    </React.Fragment>
  );
};
