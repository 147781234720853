import React, { useState, useEffect } from "react";
import * as Mui from "@mui/material";
import { themes } from "services/constants";
import {
  KeyboardArrowDown,
  KeyboardArrowRight,
  Edit,
} from "@mui/icons-material";
import { Loader } from "components/Loader";
import {
  ShiftCategoryHeader,
  shiftDetails,
} from "pages/Administration/AdministrationHeaders";
import { AddShiftCategory } from "./AddShiftCategory";
import { EditShiftCategory } from "./EditShiftCategory";
import { setGlobalSearchValue } from "services/Redux/userToken";
import { useDispatch, useSelector } from "react-redux";

const keys = [
  "shift_category_name",
  "shift_grade_name",
  "shift_type_name",
  "week_off_day",
  "over_time",
  "number_of_shifts",
  "first_start_time",
];

export const ShiftCategory = ({
  shiftCategory,
  companyId,
  workUnitGroup,
  reCallApi,
  loading,
  setShowTopBar,
  setshiftCategory,
}) => {
  const { globalSearchValue } = useSelector((state) => state.tokenReducer);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [incentiveDetailsStateData, setIncentiveStateData] = useState([]);
  const [expendRowId, setExpendRowId] = useState(0);
  const [updateData, setUpdateData] = useState(true);
  const [shiftCategoryDetails, setshiftCategoryDetails] = useState({});
  const [editshiftCategory, setEditshiftCategory] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if (updateData && workUnitGroup.length !== 0) {
      setIncentiveStateData(workUnitGroup);
      setUpdateData(false);
    }
  }, [updateData, workUnitGroup]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    if (updateData && workUnitGroup?.length !== 0 && workUnitGroup) {
      setIncentiveStateData(workUnitGroup);
      setUpdateData(false);
    }
  }, [workUnitGroup, updateData]);

  useEffect(() => {
    if (workUnitGroup?.length !== 0 || workUnitGroup?.length === 0) {
      const filteredResults = workUnitGroup?.filter((item) =>
        keys.some((key) =>
          item[key]
            ?.toString()
            .toLowerCase()
            .includes(globalSearchValue.toString().toLowerCase())
        )
      );
      setIncentiveStateData(filteredResults);
      setPage(0);
    }
  }, [globalSearchValue, workUnitGroup]);

  return (
    <React.Fragment>
      {shiftCategory ? (
        <AddShiftCategory
          companyId={companyId}
          setshiftCategory={setshiftCategory}
          reCallApi={reCallApi}
          setShowTopBar={setShowTopBar}
        />
      ) : loading ? (
        <Loader />
      ) : editshiftCategory ? (
        <EditShiftCategory
          setshiftCategory={setshiftCategory}
          reCallApi={reCallApi}
          shiftCategoryDetails={shiftCategoryDetails}
          setShowTopBar={setShowTopBar}
          setEditshiftCategory={setEditshiftCategory}
        />
      ) : (
        <React.Fragment>
          <Mui.TableContainer
            sx={{ mt: 2, width: "100%", paddingLeft: 1, maxHeight: 850 }}
          >
            <Mui.Table>
              <Mui.TableHead
                sx={{
                  backgroundColor: themes.primary,
                  position: "sticky",
                  top: -1,
                  zIndex: 10,
                }}
              >
                <Mui.TableRow
                  sx={{
                    "& .MuiTableCell-root": {
                      height: "6px",
                      padding: "6px 10px",
                    },
                  }}
                >
                  <Mui.TableCell></Mui.TableCell>
                  {ShiftCategoryHeader.map((head) => (
                    <Mui.TableCell
                      sx={{ color: themes.blackColor, fontWeight: "bold" }}
                    >
                      {head.name}
                    </Mui.TableCell>
                  ))}
                </Mui.TableRow>
              </Mui.TableHead>
              <Mui.TableBody>
                {incentiveDetailsStateData?.length === 0 ? (
                  <Mui.TableRow>
                    <Mui.TableCell colSpan={12}>
                      <Mui.Alert
                        severity="info"
                        sx={{ width: "100%", margin: 2 }}
                      >
                        No data found
                      </Mui.Alert>
                    </Mui.TableCell>
                  </Mui.TableRow>
                ) : (
                  incentiveDetailsStateData
                    ?.slice(
                      page * rowsPerPage,
                      page * rowsPerPage + rowsPerPage
                    )
                    ?.map((account, index) => (
                      <React.Fragment>
                        <Mui.TableRow
                          sx={{
                            "& .MuiTableCell-root": {
                              height: "10px",
                              padding: "6px 35px",
                            },
                          }}
                        >
                          <Mui.TableCell>
                            {expendRowId === account.id ? (
                              <Mui.IconButton onClick={() => setExpendRowId(0)}>
                                <KeyboardArrowDown />
                              </Mui.IconButton>
                            ) : (
                              <Mui.IconButton
                                onClick={() => setExpendRowId(account.id)}
                              >
                                <KeyboardArrowRight />
                              </Mui.IconButton>
                            )}
                          </Mui.TableCell>
                          {ShiftCategoryHeader.map((head) =>
                            head.name === "Actions" ? (
                              <Mui.TableCell>
                                <Mui.Stack direction="row">
                                  <Mui.IconButton
                                    onClick={() => {
                                      setShowTopBar(false);
                                      setshiftCategoryDetails(account);
                                      setEditshiftCategory(true);
                                      dispatch(setGlobalSearchValue(""));
                                    }}
                                  >
                                    <Edit sx={{ fontSize: "10" }} />
                                  </Mui.IconButton>
                                  {/* <Mui.IconButton
                                    onClick={() => {
                                      // setEditWorkUnitPay(account);
                                      // setOpenDialog(true);
                                    }}
                                  >
                                    <Delete />
                                  </Mui.IconButton> */}
                                </Mui.Stack>
                              </Mui.TableCell>
                            ) : (
                              <Mui.TableCell>
                                {account[head.key] ? account[head.key] : "N/A"}
                              </Mui.TableCell>
                            )
                          )}
                        </Mui.TableRow>
                        {expendRowId === account.id && (
                          <Mui.TableRow>
                            <Mui.TableCell colSpan={12}>
                              <Mui.TableContainer
                                sx={{
                                  marginTop: 0,
                                  marginLeft: "auto",
                                  marginRight: "auto",
                                  width: "100%px",
                                }}
                                className="w-96 border rounded"
                              >
                                <Mui.Table>
                                  <Mui.TableHead
                                    sx={{
                                      backgroundColor: themes.primary,
                                    }}
                                  >
                                    <Mui.TableRow
                                      sx={{
                                        "& .MuiTableCell-root": {
                                          height: "6px",
                                          padding: "6px 10px",
                                        },
                                      }}
                                    >
                                      {shiftDetails.map((head) => (
                                        <Mui.TableCell
                                          sx={{
                                            color: themes.blackColor,
                                            fontWeight: "bold",
                                          }}
                                        >
                                          {head.name}
                                        </Mui.TableCell>
                                      ))}
                                    </Mui.TableRow>
                                  </Mui.TableHead>
                                  <Mui.TableBody>
                                    {account?.shifts?.map((detail) => (
                                      <Mui.TableRow
                                        sx={{
                                          "& .MuiTableCell-root": {
                                            height: "10px",
                                            padding: "10px 10px",
                                          },
                                        }}
                                      >
                                        {shiftDetails.map((head) => (
                                          <Mui.TableCell>
                                            {detail[head.key]
                                              ? detail[head.key]
                                              : "N/A"}
                                          </Mui.TableCell>
                                        ))}
                                      </Mui.TableRow>
                                    ))}
                                  </Mui.TableBody>
                                </Mui.Table>
                              </Mui.TableContainer>
                            </Mui.TableCell>
                          </Mui.TableRow>
                        )}
                      </React.Fragment>
                    ))
                )}
              </Mui.TableBody>
            </Mui.Table>
          </Mui.TableContainer>
          <Mui.Grid
            container
            sx={{
              paddingTop: 1,
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              alignItems: "flex-end",
              paddingRight: 1,
            }}
          >
            {incentiveDetailsStateData?.length > 10 && (
              <Mui.TablePagination
                className="custom-pagination"
                component="div"
                rowsPerPageOptions={[25, 50, 75, 100]}
                count={incentiveDetailsStateData?.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            )}
          </Mui.Grid>
        </React.Fragment>
      )}
    </React.Fragment>
  );
};
