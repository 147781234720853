import React, { useState, useEffect, useContext } from "react";
import * as Mui from "@mui/material";
import { themes } from "services/constants";
import { useSelector } from "react-redux";
import { showToast } from "services/utils/Status";
import { Loader } from "components/Loader";
import { ProjectReportContext } from "../ProRepContext/projectContext";
import sessionHandling from "services/utils/notificationUtils";
import { BackArrow } from "components/CustomComponents/BackArrow";
import { tableRowSx } from "services/constants";
import { DownloadForOffline } from "@mui/icons-material";
import dayjs from "dayjs";

export const keys = [
  "time_entry.user_name",
  "project.projectname",
  "task_code",
  "taskname",
  "task_owner.username",
  "tasks.time_entry.task_status",
  "tasks.time_entry.date",
  "time_entry.start_time",
  "time_entry.hours",
];

export const InprogressTaskReport = ({ updateShowReport }) => {
  const { allUsers } = useContext(ProjectReportContext);
  const { domain, token, userDetails, globalSearchValue } = useSelector(
    (state) => state.tokenReducer
  );
  const [isLoading, setIsLoading] = useState(false);
  const [selectedAssignee, setSelectedAssignee] = useState(
    userDetails?.role === "Admin" ? "All" : userDetails?.username
  );
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [selectedAssigneeId, setSelectedAssigneeId] = useState(
    userDetails?.role === "Admin" ? "" : userDetails?.id
  );
  const [selectedProject, setSelectedProject] = useState("All");
  const [selectedProjectId, setSelectedProjectId] = useState("");
  const today = dayjs().format("YYYY-MM-DD");
  const todayDate = dayjs().format("DD-MM-YYYY");
  const [allProjectDetails, setAllProjectDetails] = useState([]);
  const [allUserRepDetails, setAllUserRepDetails] = useState([]);
  const [allUserDetails, setAllUserDetails] = useState(allUserRepDetails);

  useEffect(() => {
    const fetchProjectDetails = async () => {
      try {
        const selUsrId =
          selectedAssignee === "All" ? 99999 : selectedAssigneeId;

        const response = await fetch(
          `${domain}get-project-based-user/?user_id=${selUsrId}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `token ${token}`,
            },
          }
        );
        const res = await response.json();
        if (response.ok) {
          const AllProjectsData = res.map((obj) => ({
            ...obj,
            label: obj.project_name,
          }));
          setAllProjectDetails(AllProjectsData);
        } else if (response.status === 409) {
          sessionHandling();
        } else {
          throw new Error(res.error);
        }
      } catch (error) {
        showToast("error", error.message);
      }
    };
    fetchProjectDetails();
  }, [domain, token, selectedAssignee, selectedAssigneeId]);

  useEffect(() => {
    const fetchUsertaskDetails = async () => {
      try {
        setIsLoading(true);
        const selProId = selectedProject === "All" ? 99999 : selectedProjectId;
        const selUsrId =
          selectedAssignee === "All" ? 99999 : selectedAssigneeId;

        const response = await fetch(
          `${domain}inprogress-task-report/?project_id=${selProId}&user_id=${selUsrId}&task_id=99999&start_date=${today}&end_date=${today}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `token ${token}`,
            },
          }
        );
        const res = await response.json();
        if (response.ok) {
          setAllUserRepDetails(res?.tasks);
        } else if (response.status === 409) {
          sessionHandling();
        } else {
          throw new Error(res.error);
        }
      } catch (error) {
        showToast("error", error.message);
      } finally {
        setIsLoading(false);
      }
    };
    fetchUsertaskDetails();
  }, [
    selectedAssignee,
    selectedProject,
    domain,
    token,
    selectedAssigneeId,
    selectedProjectId,
    today,
  ]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const getDetailedUserReport = () => {
    let dataPreparation = [];
    allUserDetails?.forEach((element) => {
      let overAllData = {
        assignee: element?.time_entry[0]?.user_name,
        projectname: element?.project?.projectname,
        taskid: element?.task_code,
        taskname: element?.taskname,
        taskowner: element?.task_owner.username,
        status: element?.time_entry[0]?.task_status,
        date: element?.time_entry[0]?.date,
        starttime: element?.time_entry[0]?.start_time,
      };
      dataPreparation = [...dataPreparation, overAllData];
    });
    const DetailedReport = ["Inprogress Task Report"];
    const Space = [""];
    const Date = ["Date"];
    const User = ["User Name"];
    const Project = ["Project Name"];
    DetailedReport.push("");
    Date.push(`${todayDate}`);
    User.push(selectedAssignee);
    Project.push(selectedProject);
    Space.push("");
    const items = dataPreparation;
    const TableHead = ["Assignee"];
    TableHead.push("Project Name");
    TableHead.push("Task Id");
    TableHead.push("Task Name");
    TableHead.push("Task Owner");
    TableHead.push("Status");
    TableHead.push("Date");
    TableHead.push("Start Time");

    const replacer = (key, value) => (value === null ? "" : value);
    const header = Object.keys(items[0]);
    let csv = items.map((row) =>
      header
        .map((fieldName) => JSON.stringify(row[fieldName], replacer))
        .join(",")
    );
    csv.unshift(TableHead.join(","));
    csv.unshift(Space.join(","));
    csv.unshift(Project.join(","));
    csv.unshift(User.join(","));
    csv.unshift(Date.join(","));
    csv.unshift(DetailedReport.join(","));
    csv = csv.join("\r\n");
    const ConvertData = csv;
    const element = document.createElement("a");
    const file = new Blob([ConvertData], {
      type: "text/plain",
    });
    element.href = URL.createObjectURL(file);
    element.download = "DetailedUserReport.csv";
    document.body.appendChild(element);
    element.click();
  };

  const getNestedValue = (obj, path) => {
    return path.split(".").reduce((acc, part) => acc && acc[part], obj);
  };

  useEffect(() => {
    const filteredResults = allUserRepDetails.filter((item) =>
      keys.some((key) =>
        getNestedValue(item, key)
          ?.toString()
          .toLowerCase()
          .includes(globalSearchValue.toString().toLowerCase())
      )
    );
    setAllUserDetails(filteredResults);
    setPage(0);
  }, [allUserRepDetails, globalSearchValue]);

  return (
    <React.Fragment>
      <Mui.Grid container spacing={1} sx={{ mt: 0 }}>
        <Mui.Grid item xs={12} sm={12} md={12} lg={12}>
          <Mui.Stack direction="row">
            <BackArrow actionFunction={() => updateShowReport(false)} />
            <Mui.Typography
              sx={{
                fontSize: 20,
                fontWeight: "bold",
                mt: 1.5,
              }}
            >
              Inprogress Task Report
            </Mui.Typography>
          </Mui.Stack>
        </Mui.Grid>
      </Mui.Grid>
      <Mui.Grid container spacing={1} sx={{ mt: 1, mb: 1 }}>
        <Mui.Grid item xs={12} sm={6} md={3} lg={2}>
          <Mui.Autocomplete
            disablePortal
            id="combo-box-demo"
            size="small"
            sx={{ minWidth: "100%" }}
            value={
              allUsers?.find((option) => option?.label === selectedAssignee) ||
              selectedAssignee ||
              ""
            }
            options={allUsers}
            ListboxProps={{
              style: {
                maxHeight: "150px",
              },
            }}
            filterOptions={(options, state) => {
              return options.filter((option) =>
                option.label
                  ?.toLowerCase()
                  .includes(state.inputValue.toLowerCase())
              );
            }}
            renderOption={(props, option) => {
              return <li {...props}>{option.label}</li>;
            }}
            onChange={(event, value) => {
              if (value && value?.label) {
                setSelectedAssignee(value.label);
                setSelectedProject("All");
                setSelectedAssigneeId(value.id);
              }
            }}
            renderInput={(params) => (
              <Mui.TextField
                {...params}
                size="small"
                label="User Name"
                sx={{ background: "white" }}
                name="username"
                placeholder="Select User Name"
              />
            )}
          />
        </Mui.Grid>
        <Mui.Grid item xs={12} sm={6} md={3} lg={2}>
          <Mui.Autocomplete
            disablePortal
            id="combo-box-demo"
            size="small"
            sx={{ minWidth: "100%" }}
            value={
              allProjectDetails?.find(
                (option) => option?.label === selectedProject
              ) ||
              selectedProject ||
              ""
            }
            options={allProjectDetails}
            ListboxProps={{
              style: {
                maxHeight: "150px",
              },
            }}
            filterOptions={(options, state) => {
              return options?.filter((option) =>
                option?.label
                  ?.toLowerCase()
                  .includes(state.inputValue.toLowerCase())
              );
            }}
            renderOption={(props, option) => {
              return <li {...props}>{option?.label}</li>;
            }}
            onChange={(event, value) => {
              if (value && value?.label) {
                setSelectedProject(value.label);
                setSelectedProjectId(value.project_id);
              }
            }}
            renderInput={(params) => (
              <Mui.TextField
                {...params}
                size="small"
                label="Project"
                sx={{ background: "white" }}
                name="projects"
                placeholder="Select Project"
              />
            )}
          />
        </Mui.Grid>
        <Mui.Grid item xs={12} sm={6} md={3.5} lg={2}></Mui.Grid>
        <Mui.Grid item lg={5.4}></Mui.Grid>
        <Mui.Grid item xs={2} sm={2} md={2} lg={0.5} sx={{ marginTop: -1.3 }}>
          <Mui.IconButton
            onClick={() => {
              getDetailedUserReport();
            }}
            disabled={allUserDetails?.length === 0 ? true : false}
            sx={{
              backgroundColor: themes.primary,
              "&:hover": {
                backgroundColor: themes.primary,
              },
            }}
          >
            <DownloadForOffline
              fontSize="large"
              sx={{
                color: themes.blueColor,
              }}
            />
          </Mui.IconButton>
        </Mui.Grid>
      </Mui.Grid>

      {isLoading ? (
        <Loader info="Loading..." />
      ) : (
        <>
          <Mui.TableContainer sx={{ paddingRight: 2, marginTop: 2 }}>
            <Mui.Table sx={{ border: `2px solid ${themes.sidebarDivider}` }}>
              <Mui.TableHead sx={{ backgroundColor: themes.primary }}>
                <Mui.TableRow sx={tableRowSx}>
                  <Mui.TableCell
                    sx={{ color: themes.blackColor, fontWeight: "bold" }}
                  >
                    Assignee
                  </Mui.TableCell>
                  {selectedProject === "All" ? (
                    <Mui.TableCell
                      sx={{ color: themes.blackColor, fontWeight: "bold" }}
                    >
                      Project Name
                    </Mui.TableCell>
                  ) : (
                    ""
                  )}
                  <Mui.TableCell
                    sx={{ color: themes.blackColor, fontWeight: "bold" }}
                  >
                    Task Id
                  </Mui.TableCell>
                  <Mui.TableCell
                    sx={{ color: themes.blackColor, fontWeight: "bold" }}
                  >
                    Task Name
                  </Mui.TableCell>
                  <Mui.TableCell
                    sx={{ color: themes.blackColor, fontWeight: "bold" }}
                  >
                    Task Owner
                  </Mui.TableCell>
                  <Mui.TableCell
                    sx={{ color: themes.blackColor, fontWeight: "bold" }}
                  >
                    Status
                  </Mui.TableCell>
                  <Mui.TableCell
                    sx={{ color: themes.blackColor, fontWeight: "bold" }}
                  >
                    Date
                  </Mui.TableCell>
                  <Mui.TableCell
                    sx={{ color: themes.blackColor, fontWeight: "bold" }}
                  >
                    Start Time
                  </Mui.TableCell>
                </Mui.TableRow>
              </Mui.TableHead>
              <Mui.TableBody>
                {allUserDetails.length === 0 ? (
                  <Mui.TableRow>
                    <Mui.TableCell colSpan={12}>
                      <Mui.Alert severity="info">
                        There is a no available task
                      </Mui.Alert>
                    </Mui.TableCell>
                  </Mui.TableRow>
                ) : (
                  allUserDetails
                    ?.slice(
                      page * rowsPerPage,
                      page * rowsPerPage + rowsPerPage
                    )
                    .map((item, index) => (
                      <>
                        <Mui.TableRow sx={tableRowSx}>
                          <Mui.TableCell>
                            {item?.time_entry[0]?.user_name
                              ? item?.time_entry[0]?.user_name
                              : "N/A"}
                          </Mui.TableCell>
                          {selectedProject === "All" ? (
                            <Mui.TableCell>
                              {item?.project?.projectname}
                            </Mui.TableCell>
                          ) : (
                            ""
                          )}
                          <Mui.TableCell>
                            {item?.task_code ? item?.task_code : "N/A"}
                          </Mui.TableCell>
                          <Mui.TableCell>
                            {item?.taskname ? item?.taskname : "N/A"}
                          </Mui.TableCell>
                          <Mui.TableCell>
                            {item?.task_owner.username
                              ? item?.task_owner.username
                              : "N/A"}
                          </Mui.TableCell>

                          <Mui.TableCell>
                            {item?.time_entry[0]?.task_status}
                          </Mui.TableCell>
                          <Mui.TableCell>
                            {item?.time_entry[0]?.date
                              ? item?.time_entry[0]?.date
                              : "N/A"}
                          </Mui.TableCell>
                          <Mui.TableCell>
                            {item?.time_entry[0]?.start_time
                              ? item?.time_entry[0]?.start_time
                              : "00:00"}
                          </Mui.TableCell>
                        </Mui.TableRow>
                      </>
                    ))
                )}
              </Mui.TableBody>
            </Mui.Table>
          </Mui.TableContainer>
          {allUserDetails.length > 25 ? (
            <Mui.TablePagination
              className="custom-pagination"
              component="div"
              rowsPerPageOptions={[25, 50, 75, 100]}
              count={allUserDetails.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          ) : null}
        </>
      )}
    </React.Fragment>
  );
};
