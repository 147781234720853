import React, { useState, useEffect } from "react";
import * as Mui from "@mui/material";
import { CustomButton } from "components/CustomComponents/CustomButton";
import { Loader } from "components/Loader";
import AddTaskIcon from "@mui/icons-material/AddTask";
import AutoDeleteIcon from "@mui/icons-material/AutoDelete";
import Checkbox from "@mui/material/Checkbox";
import { DialogBox } from "../DialogBox";
import { useSelector } from "react-redux";
import { themes } from "services/constants";
import { showToast } from "services/utils/Status";
import { tableRowSx } from "services/constants";
import { customAPI } from "services/utils/CustomAPI";
let multiApiCallRestriction = true;
export const Approve = ({
  overTimeDetails,
  listViewPage,
  setListViewPage,
  refreshReCall,
  overTimeKeyPair,
  selectAll,
  setSelectAll,
  setReloadData,
  loader,
}) => {
  const { domain } = useSelector((state) => state.tokenReducer);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [selectedItem, setSelectedItem] = useState(null);
  const [openRejectDialog, setOpenRejectDialog] = useState(false);
  const [rejectedReasonValidation, setRejectedReasonValidation] = useState("");
  const [rejectReason, setRejectReason] = useState("");
  const [selectedRows, setSelectedRows] = useState(new Set());
  const isDisabled = overTimeKeyPair.name === "Action" || selectedRows.size > 1;
  const isDisabledIcon = selectedRows.size === 0;
  const [buttonLoader, setButtonLoader] = useState(false);

  useEffect(() => {
    if (overTimeDetails.length > 0) {
      setSelectedItem(overTimeDetails[0]);
      setSelectedRows(new Set());
    }
  }, [overTimeDetails]);

  const handleDeleteCancel = () => {
    setRejectReason("");
    setOpenRejectDialog(false);
    setRejectedReasonValidation("");
  };

  const handleChangePage = (event, newPage) => {
    setListViewPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setListViewPage(0);
  };

  const onSubmit = async () => {
    if (rejectReason.trim() === "") {
      setRejectedReasonValidation("Reason");
      return;
    } else if (rejectReason.length < 5) {
      setRejectedReasonValidation("Less than 5 character");
      return;
    }
    try {
      if (selectedRows.size > 1) {
        await handleBulkReject();
      } else {
        await handleReject();
      }
      setOpenRejectDialog(false);
      setRejectedReasonValidation("");
    } catch (error) {
      showToast("error", error.message);
    }
  };

  const handleApprove = async (department) => {
    if (multiApiCallRestriction) {
      setButtonLoader(true);
      multiApiCallRestriction = false;
      const response = await customAPI(
        `${domain}week-off-approvals/${department.id}/`,
        "PUT",
        {
          status: "Approved",
        }
      );
      if (response.ok) {
        showToast("success", "Week off approved successfully");
        multiApiCallRestriction = true;
        refreshReCall();
        setReloadData(true);
        setSelectedItem(null);
      } else {
        multiApiCallRestriction = true;
      }
      setButtonLoader(false);
    }
  };

  const handleBulkApprove = async () => {
    if (multiApiCallRestriction) {
      if (selectedRows.size === 0) return;
      setButtonLoader(true);
      multiApiCallRestriction = false;
      let approvalData = Array.from(selectedRows);
      const response = await customAPI(
        `${domain}week-off-approvals/batch/`,
        "PUT",
        { ids: approvalData, status: "Approved" }
      );
      if (response.ok) {
        showToast("success", "Week-off approved successfully");
        multiApiCallRestriction = true;
        refreshReCall();
        setReloadData(true);
        setSelectedItem(null);
      } else {
        multiApiCallRestriction = true;
      }
      setButtonLoader(false);
    }
  };

  const handleReject = async () => {
    if (multiApiCallRestriction) {
      if (!selectedItem) return;
      setButtonLoader(true);
      multiApiCallRestriction = false;
      const response = await customAPI(
        `${domain}week-off-approvals/${selectedItem.id}/`,
        "PUT",
        {
          status: "Rejected",
          reason: rejectReason,
        }
      );
      if (response.ok) {
        showToast("error", "Week off rejected successfully");
        multiApiCallRestriction = true;
        refreshReCall();
        setReloadData(true);
        setSelectedItem(null);
      } else {
        multiApiCallRestriction = true;
      }
      setButtonLoader(false);
    }
  };

  const handleBulkReject = async () => {
    if (multiApiCallRestriction) {
      if (selectedRows.size === 0) return;
      setButtonLoader(true);
      multiApiCallRestriction = false;
      let rejectedData = Array.from(selectedRows);
      const response = await customAPI(
        `${domain}week-off-approvals/batch/`,
        "PUT",
        {
          ids: rejectedData,
          status: "Rejected",
          reason: rejectReason,
        }
      );
      if (response.ok) {
        showToast("error", "Week-off rejected successfully");
        multiApiCallRestriction = true;
        refreshReCall();
        setReloadData(true);
        setSelectedItem(null);
      } else {
        multiApiCallRestriction = true;
      }
      setButtonLoader(false);
    }
  };

  const handleSelectAll = (event) => {
    const checked = event.target.checked;
    setSelectAll(checked);
    setSelectedRows(
      checked ? new Set(overTimeDetails.map((d) => d.id)) : new Set()
    );
  };

  const handleRowSelect = (ids) => {
    setSelectedRows((prevSelectedRows) => {
      const newSelectedRows = new Set(prevSelectedRows);
      if (newSelectedRows.has(ids)) {
        newSelectedRows.delete(ids);
      } else {
        newSelectedRows.add(ids);
      }
      return newSelectedRows;
    });
  };

  return loader ? (
    <Loader info="Loading..." />
  ) : (
    <React.Fragment>
      <Mui.TableContainer
        component={Mui.Paper}
        sx={{ mt: 2, width: "100%", paddingRight: 1 }}
      >
        <Mui.Grid container xs={12} sx={{ paddingTop: 1 }}>
          <Mui.Grid xs={10.5}></Mui.Grid>
          {selectAll || selectedRows.size > 1 ? (
            <Mui.Grid xs={1.5}>
              <CustomButton
                actionFuntion={() => {
                  handleBulkApprove();
                }}
                actionName="Approve"
                typeName="button"
                disableAction={buttonLoader}
                disabled={isDisabledIcon}
              />
              &nbsp;
              <CustomButton
                actionFuntion={() => {
                  setOpenRejectDialog(true);
                }}
                actionName="Reject"
                typeName="button"
                disabled={isDisabledIcon}
              />
            </Mui.Grid>
          ) : null}
        </Mui.Grid>
        <br></br>
        <Mui.Table>
          <Mui.TableHead
            sx={{
              backgroundColor: themes.primary,
            }}
          >
            <Mui.TableRow sx={tableRowSx}>
              {overTimeKeyPair.map((header) => (
                <Mui.TableCell
                  sx={{
                    color: themes.blackColor,
                    fontWeight: "bold",
                    textAlign: header.name === "Action" ? "center" : "left",
                  }}
                >
                  {header.key === "checkbox" ? (
                    <Checkbox
                      checked={selectAll}
                      onChange={handleSelectAll}
                      size="medium"
                      disabled={
                        overTimeDetails?.length === 0 ||
                        overTimeDetails?.length === 1
                      }
                    />
                  ) : (
                    header.name
                  )}
                </Mui.TableCell>
              ))}
            </Mui.TableRow>
          </Mui.TableHead>
          <Mui.TableBody>
            {overTimeDetails.length === 0 ? (
              <Mui.TableRow sx={tableRowSx}>
                <Mui.TableCell colSpan={8}>
                  <Mui.Alert severity="info" sx={{ margin: 1, width: "100%" }}>
                    No data available
                  </Mui.Alert>
                </Mui.TableCell>
              </Mui.TableRow>
            ) : (
              overTimeDetails
                ?.slice(
                  listViewPage * rowsPerPage,
                  listViewPage * rowsPerPage + rowsPerPage
                )
                .map((department) => (
                  <Mui.TableRow key={department.id} sx={tableRowSx}>
                    {overTimeKeyPair.map((header) =>
                      header.name === "" ? (
                        <Mui.TableCell>
                          <Checkbox
                            checked={selectedRows.has(department.id)}
                            onChange={() => handleRowSelect(department.id)}
                          />
                        </Mui.TableCell>
                      ) : header.name !== "Action" ? (
                        <Mui.TableCell sx={{ color: themes.blackColor }}>
                          {department[header.key]
                            ? department[header.key]
                            : "N/A"}
                        </Mui.TableCell>
                      ) : header.name === "Action" || selectedRows.size > 1 ? (
                        <Mui.TableCell
                          sx={{
                            textAlign:
                              header.name === "Action" ? "center" : "left",
                          }}
                        >
                          <Mui.Tooltip title="Approve" arrow>
                            <Mui.IconButton
                              onClick={() => {
                                handleApprove(department);
                              }}
                              disabled={
                                isDisabled || department.check_out_time === null
                              }
                              sx={{
                                color:
                                  department.check_out_time === null
                                    ? "gray"
                                    : themes.primaryIndicator,
                              }}
                            >
                              <AddTaskIcon
                                sx={{
                                  color:
                                    department.check_out_time === null
                                      ? "gray"
                                      : themes.primaryIndicator,
                                  strokeWidth: 2,
                                }}
                              />
                            </Mui.IconButton>
                          </Mui.Tooltip>
                          <Mui.Tooltip title="Reject" arrow>
                            <Mui.IconButton
                              onClick={() => {
                                setSelectedItem(department);
                                setOpenRejectDialog(true);
                              }}
                              disabled={isDisabled}
                            >
                              <AutoDeleteIcon
                                sx={{
                                  color: themes.redColor,
                                  strokeWidth: 2,
                                }}
                              />
                            </Mui.IconButton>
                          </Mui.Tooltip>
                        </Mui.TableCell>
                      ) : null
                    )}
                  </Mui.TableRow>
                ))
            )}
          </Mui.TableBody>
        </Mui.Table>
        {overTimeDetails?.length > 25 && (
          <Mui.TablePagination
            className="custom-pagination"
            component="div"
            rowsPerPageOptions={[25, 50, 75, 100]}
            count={overTimeDetails?.length}
            rowsPerPage={rowsPerPage}
            page={listViewPage}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        )}
      </Mui.TableContainer>

      <DialogBox
        open={openRejectDialog}
        onClose={handleDeleteCancel}
        title="Reason for Rejection"
      >
        <Mui.TextField
          multiline
          minRows={3}
          sx={{
            marginTop: 1,
            width: "95%",
            marginLeft: 2,
            marginRight: "auto",
          }}
          placeholder="please enter reason of rejection"
          onChange={(e) => setRejectReason(e.target.value)}
        />
        {rejectedReasonValidation === "Reason" ? (
          <Mui.Typography
            variant="body2"
            color="error"
            sx={{ fontSize: 13, paddingLeft: 3, paddingTop: 0.5 }}
          >
            Please enter the rejected reason
          </Mui.Typography>
        ) : null}
        {rejectedReasonValidation === "Less than 5 character" ? (
          <Mui.Typography color="red" sx={{ paddingLeft: 3 }}>
            Minium 5 character required
          </Mui.Typography>
        ) : null}

        <Mui.Grid container sx={{ paddingTop: 1 }}>
          <Mui.Grid xs={8}></Mui.Grid>
          <Mui.Grid
            xs={4}
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              alignItems: "flex-end",
            }}
          >
            <CustomButton
              actionFuntion={handleDeleteCancel}
              actionName="No"
              typeName="button"
            />
            &nbsp;&nbsp;
            <CustomButton
              actionFuntion={onSubmit}
              actionName="Yes"
              disableAction={
                openRejectDialog && buttonLoader ? buttonLoader : false
              }
              typeName="submit"
            />
          </Mui.Grid>
        </Mui.Grid>
      </DialogBox>
    </React.Fragment>
  );
};
