import React, { useState, useEffect } from "react";
import * as Mui from "@mui/material";
import { Info } from "@mui/icons-material";
import dayjs from "dayjs";
import { BackArrow } from "components/CustomComponents/BackArrow";
import { themes } from "services/constants";
import { useForm } from "react-hook-form";
import { customAPI } from "services/utils/CustomAPI";
import { useSelector } from "react-redux";
import { showToast } from "services/utils/Status";
import { CustomButton } from "components/CustomComponents/CustomButton";
import { CustomTextBox } from "components/CustomComponents/CustomTextBox";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import {
  LocalizationProvider,
  renderTimeViewClock,
  TimePicker,
} from "@mui/x-date-pickers";

export const EditShiftGrade = ({
  setEditGrade,
  reCallApi,
  setShowTopBar,
  editWorkUnitPay,
}) => {
  const {
    register,
    reset,
    handleSubmit,
    setValue,

    formState: { errors },
  } = useForm();
  const { domain } = useSelector((state) => state.tokenReducer);
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [weekoffDays, setWeekoffDays] = useState(0);
  const [weekoffAttendance, setWeekoffAttendance] = useState(0);
  const [selectType, setSelectType] = useState(editWorkUnitPay?.work_unit);
  const [shiftDuration, setShiftDuration] = useState(null);
  const [shiftName, setShiftName] = useState("");
  const [error, setError] = useState("");
  const [updatename, setUpdateName] = useState(false);

  const parseShiftDuration = (duration) => {
    if (!duration) return null;
    const [hours, minutes] = duration.split(":").map(Number);
    return { hours, minutes };
  };

  useEffect(() => {
    if (editWorkUnitPay?.shift_duration) {
      setShiftDuration(parseShiftDuration(editWorkUnitPay.shift_duration));
    }
    setValue("weekoff_days", editWorkUnitPay?.weekoff_days);
    setValue("factor_weekoff_days", editWorkUnitPay?.factor_weekoff_days);
    setValue("shift_grade_name", editWorkUnitPay?.shift_grade_name);
    setShiftName(editWorkUnitPay?.shift_grade_name);
    setWeekoffDays(editWorkUnitPay?.weekoff_days);
    setWeekoffAttendance(editWorkUnitPay?.factor_weekoff_days);

    // setShiftDuration(editWorkUnitPay?.shift_duration);
  }, [
    editWorkUnitPay?.factor_weekoff_days,
    editWorkUnitPay.shift_duration,
    editWorkUnitPay?.shift_grade_name,
    editWorkUnitPay?.weekoff_days,
    setValue,
  ]);
  const onSubmit = async (data) => {
    if (
      !shiftDuration ||
      shiftDuration.hours === undefined ||
      shiftDuration.minutes === undefined
    ) {
      setError("Shift duration hour and minute are required.");
      return;
    }

    setError("");
    if (data) {
      setButtonDisabled(true);
      let formattedShiftDuration = shiftDuration
        ? `${String(shiftDuration.hours).padStart(2, "0")}:${String(
            shiftDuration.minutes
          ).padStart(2, "0")}`
        : null;
      let payload = {
        ...data,
        work_unit: selectType === "Yes",
        name: shiftName,
        shift_duration: formattedShiftDuration,
        shift_grade_id: editWorkUnitPay?.shift_grade_id,
      };
      const response = await customAPI(
        `${domain}shift-grade/`,
        "PUT",
        payload
      );
      if (response.ok) {
        showToast("success", "Shift grade updated successfully");
        setEditGrade(false);
        setShowTopBar(true);
        reCallApi(true);
        reset();
      } else {
        setButtonDisabled(false);
      }
    }
  };

  const weekoffUpdate = (event) => {
    const value = event.target.value;
    const isValidNumber = /^[0-6]$/.test(value);
    if (value === "" || isValidNumber) {
      setWeekoffDays(value);
      setValue("weekoff_days", value);
    }
  };

  const weekoffDaysAttendance = (event) => {
    const { value } = event.target;
    const rgxWithDot = /^([0-9]{1,2})(\.[0-9]{0,2})?$/;
    if (value === "" || rgxWithDot.test(value)) {
      setWeekoffAttendance(value);
      setValue("factor_weekoff_days", value);
    } else {
      event.preventDefault();
    }
  };

  const selectTypeChanges = (event) => {
    setSelectType(event.target.value);
  };

  const handleTime = (newValue) => {
    if (!newValue || !dayjs(newValue).isValid()) {
      setError("Shift duration hour and minute are required.");
      setShiftDuration(null);
      return;
    }

    const hours = newValue.hour();
    const minutes = newValue.minute();

    if (hours === 0 && minutes === 0) {
      setError("Shift duration must have either hours or minutes.");
      setShiftDuration(null);
    } else {
      setShiftDuration({ hours, minutes });
      setError("");
    }
  };

  useEffect(() => {
    if (updatename) {
      const formatWithLeadingZero = (value) =>
        value < 10 ? `0${value}` : value;
      const formattedHours = formatWithLeadingZero(shiftDuration?.hours || 0);
      const formattedMinutes = formatWithLeadingZero(
        shiftDuration?.minutes || 0
      );
      const newShiftName = `${formattedHours}${formattedMinutes}hr-${
        weekoffDays || 0
      }wo${selectType === "Yes" ? "-wu" : ""}`;
      setShiftName(newShiftName);
      setValue("shift_grade_name", newShiftName);
    }
  }, [
    selectType,
    setValue,
    shiftDuration?.hours,
    shiftDuration?.minutes,
    updatename,
    weekoffDays,
  ]);

  const nameUpdate = (event) => {
    if (event.target.value.length < 32) {
      setValue("shift_grade_name", event.target.value);
      setShiftName(event.target.value);
    }
  };

  return (
    <>
      <Mui.Grid container>
        <BackArrow
          actionFunction={() => {
            setEditGrade(false);
            setShowTopBar(true);
          }}
        />
        <Mui.Typography
          sx={{ fontWeight: "bold", fontSize: 27, paddingTop: 1 }}
        >
          Shift Grade / Edit Shift Grade
        </Mui.Typography>
      </Mui.Grid>
      <form onSubmit={handleSubmit(onSubmit)} style={{ padding: 20 }}>
        <Mui.Grid
          container
          sx={{ paddingLeft: 40, paddingRight: 40, paddingTop: 10 }}
        >
          <Mui.Grid container component={Mui.Paper} elevation={5}>
            <Mui.Grid
              xs={12}
              sx={{
                backgroundColor: themes.primary,

                padding: 2,
                textAlign: "center",
              }}
            >
              <Mui.Typography
                sx={{ fontSize: 24, fontWeight: 800, font: "bold" }}
              >
                Edit Shift Grade
              </Mui.Typography>
            </Mui.Grid>
            <Mui.Grid container sx={{ padding: 4 }}>
              <Mui.Grid
                container
                fullWidth
                sx={{ padding: 1, borderRadius: 3 }}
              >
                <Mui.Grid item xs={4} sx={{ paddingTop: 1.5 }}>
                  <Mui.Typography
                    sx={{
                      fontSize: 18,
                      color: themes.headLine,
                      fontWeight: "bold",
                    }}
                  >
                    Shift Duration Hours &nbsp;
                    <Mui.Tooltip title="This is the Shift Duration field. Update the total number of hours an employee is expected to work during a shift, such as changing it from 8 to 10 hours, based on revised schedules.">
                      <Info />
                    </Mui.Tooltip>
                  </Mui.Typography>
                </Mui.Grid>
                <Mui.Grid item xs={1} sx={{ paddingTop: 1.5 }}>
                  :
                </Mui.Grid>
                <Mui.Grid item xs={6}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DemoContainer components={["TimePicker"]}>
                      <TimePicker
                        ampm={false}
                        viewRenderers={{
                          hours: renderTimeViewClock,
                          minutes: renderTimeViewClock,
                          seconds: renderTimeViewClock,
                        }}
                        value={
                          shiftDuration
                            ? dayjs()
                                .hour(shiftDuration.hours)
                                .minute(shiftDuration.minutes)
                            : null
                        }
                        slotProps={{
                          textField: {
                            size: "small",
                            sx: {
                              width: 700,
                            },
                            error: error,
                            helperText: error,
                          },
                        }}
                        onChange={(newValue) => {
                          handleTime(newValue);
                          setUpdateName(true);
                        }}
                        onBlur={() => {
                          if (
                            !shiftDuration ||
                            shiftDuration.hours === undefined ||
                            shiftDuration.minutes === undefined
                          ) {
                            setError(
                              "Shift duration hour and minute are required."
                            );
                          }
                        }}
                      />
                    </DemoContainer>
                  </LocalizationProvider>
                </Mui.Grid>
              </Mui.Grid>

              <Mui.Grid container fullWidth sx={{ padding: 1 }}>
                <Mui.Grid item xs={4}>
                  <Mui.Typography
                    sx={{
                      fontSize: 18,
                      color: themes.headLine,
                      paddingBottom: 0.5,
                      fontWeight: "bold",
                    }}
                  >
                    Number of WeekOff Days &nbsp;
                    <Mui.Tooltip title="This is the Number of WeekOff field. Modify the weekly off days assigned to employees, such as increasing or decreasing from 1 to 2 days per week, to reflect updated policies.">
                      <Info />
                    </Mui.Tooltip>
                  </Mui.Typography>
                </Mui.Grid>
                <Mui.Grid item xs={1} sx={{ paddingTop: 1 }}>
                  :
                </Mui.Grid>
                <Mui.Grid xs={6}>
                  <CustomTextBox
                    selectedValue={weekoffDays}
                    rules={{
                      ...register("weekoff_days", {
                        required: "Weekoff days is required",
                      }),
                    }}
                    updateDataFunction={weekoffUpdate}
                    placeHolder="Enter no of weekoff days"
                    errorMessage={
                      errors?.weekoff_days?.message
                        ? errors?.weekoff_days?.message
                        : ""
                    }
                    textBoxError={errors.weekoff_days ? true : false}
                  />
                </Mui.Grid>
              </Mui.Grid>
              <Mui.Grid container fullWidth sx={{ padding: 1 }}>
                <Mui.Grid item xs={3.5}>
                  <Mui.Typography
                    sx={{
                      fontSize: 18,
                      color: themes.headLine,
                      paddingBottom: 0.5,
                      fontWeight: "bold",
                    }}
                  >
                    Factor for Weekoff day attendance &nbsp;
                    <Mui.Tooltip title="This is the Factor for the WeekOff Day field. Enter a multiplier or factor used to calculate allowances or work credits for weekly off days, ensuring alignment with payroll or performance metrics. The value must be a number with up to two digits before the decimal point and up to two digits after the decimal point (e.g., 12.34,2.5)">
                      <Info />
                    </Mui.Tooltip>
                  </Mui.Typography>{" "}
                </Mui.Grid>
                <Mui.Grid xs={0.5}></Mui.Grid>
                <Mui.Grid item xs={1} sx={{ paddingTop: 1 }}>
                  :
                </Mui.Grid>
                <Mui.Grid item xs={6}>
                  <CustomTextBox
                    selectedValue={weekoffAttendance}
                    rules={{
                      ...register("factor_weekoff_days", {
                        required: "Factor for weekoff day is required",
                      }),
                    }}
                    updateDataFunction={weekoffDaysAttendance}
                    placeHolder="Enter multipler factor"
                    errorMessage={
                      errors?.factor_weekoff_days?.message
                        ? errors?.factor_weekoff_days?.message
                        : ""
                    }
                    textBoxError={errors.factor_weekoff_days ? true : false}
                  />
                </Mui.Grid>
              </Mui.Grid>
              <Mui.Grid container sx={{ padding: 1 }}>
                <Mui.Grid xs={4}>
                  <Mui.Typography
                    sx={{
                      fontSize: 18,
                      color: themes.headLine,
                      paddingBottom: 0.5,
                      fontWeight: "bold",
                    }}
                  >
                    Include WorkUnit &nbsp;
                    <Mui.Tooltip title="This is the Include WorkUnit field. Update the selection to Yes if the WorkUnit should be included, or No if it should remain excluded.">
                      <Info />
                    </Mui.Tooltip>
                  </Mui.Typography>
                </Mui.Grid>
                <Mui.Grid xs={1} sx={{ paddingTop: 1 }}>
                  {" "}
                  :
                </Mui.Grid>
                <Mui.Grid xs={6}>
                  <Mui.RadioGroup
                    row
                    onChange={selectTypeChanges}
                    aria-labelledby="demo-radio-buttons-group-label"
                    defaultValue={selectType}
                    name="radio-buttons-group"
                  >
                    <Mui.FormControlLabel
                      value="Yes"
                      control={<Mui.Radio />}
                      label="Yes"
                    />
                    <Mui.FormControlLabel
                      value="No"
                      control={<Mui.Radio />}
                      label="No"
                    />
                  </Mui.RadioGroup>
                </Mui.Grid>
              </Mui.Grid>
              <Mui.Grid container sx={{ padding: 1 }}>
                <Mui.Grid xs={4}>
                  <Mui.Typography
                    sx={{
                      fontSize: 18,
                      color: themes.headLine,
                      paddingBottom: 0.5,
                      fontWeight: "bold",
                    }}
                  >
                    Name &nbsp;
                    <Mui.Tooltip title="This is the Name field. Modify the shift grade name, ensuring it follows the format 0600hr-1wo-wu for consistency and clarity.">
                      <Info />
                    </Mui.Tooltip>
                  </Mui.Typography>{" "}
                </Mui.Grid>
                <Mui.Grid xs={1} sx={{ paddingTop: 1 }}>
                  :
                </Mui.Grid>
                <Mui.Grid xs={6}>
                  <CustomTextBox
                    selectedValue={shiftName}
                    updateDataFunction={nameUpdate}
                    rules={{
                      ...register("shift_grade_name", {
                        required: "Shift grade name is required",
                      }),
                    }}
                    errorMessage={
                      errors?.shift_grade_name?.message
                        ? errors?.shift_grade_name?.message
                        : ""
                    }
                    textBoxError={errors.shift_grade_name ? true : false}
                  />
                </Mui.Grid>
              </Mui.Grid>

              <Mui.Grid
                container
                sx={{
                  paddingTop: 2,
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-end",
                  alignItems: "flex-end",
                  paddingRight: 10,
                }}
              >
                <CustomButton
                  actionFuntion={() => {
                    setEditGrade(false);
                    setShowTopBar(true);
                  }}
                  actionName="Cancel"
                  typeName="button"
                />
                &nbsp;&nbsp;
                <CustomButton
                  actionName="Update"
                  typeName="submit"
                  disableAction={buttonDisabled}
                />
              </Mui.Grid>
            </Mui.Grid>
          </Mui.Grid>
        </Mui.Grid>
      </form>
    </>
  );
};
