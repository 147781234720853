import * as React from "react";
import PropTypes from "prop-types";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  styled,
} from "@mui/material";
import { themes } from "services/constants";
import CloseIcon from "@mui/icons-material/Close";

const BootstrapDialog = styled(Dialog)(({ theme, minWidth }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
  "& .MuiDialog-paper": {
    borderRadius: "20px",
    minWidth: minWidth ? minWidth : 550,
  },
}));

function FuncDialogTitle(props) {
  const { children, onClose, title, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon sx={{ color: themes.blackColor }} />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

FuncDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export const GlobalDialogBox = ({
  key,
  handleCloseDialog,
  open,
  title,
  minWidth,
  minHeight,
  children,
}) => {
  return (
    <div>
      <BootstrapDialog
        key={key}
        aria-labelledby="customized-dialog-title"
        open={open}
        minWidth={minWidth}
      >
        <FuncDialogTitle
          id="customized-dialog-title"
          align="center"
          sx={{
            minWidth: minWidth ? minWidth : 550,
            color: themes.blackColor,
            fontWeight: 800,
            fontSize: "24px",
            font: "bold",
            background: themes.primary,
          }}
          onClose={handleCloseDialog}
          title={title}
        >
          {title}
        </FuncDialogTitle>
        <DialogContent
          dividers
          sx={{
            minHeight: minHeight,
          }}
        >
          {children}
        </DialogContent>
      </BootstrapDialog>
    </div>
  );
};
