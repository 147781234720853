import React, { useState, useEffect, useRef } from "react";
import { administrationCompanies } from "services/constants";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { showToast } from "services/utils/Status";
import { GlobalDialogBox } from "components/GlobalDialogBox";
import * as Mui from "@mui/material";
import { setGlobalSearchValue } from "services/Redux/userToken";
import { customGetAPI } from "services/utils/CustomAPI";
import { AddCompany } from "./AddCompany";
import { MoreVert } from "@mui/icons-material";
import { Loader } from "components/Loader";
import { CustomButton } from "components/CustomComponents/CustomButton";
import { setCompanyId, setCompanyName } from "services/Redux/userManagament";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useDispatch } from "react-redux";
import sessionHandling from "services/utils/notificationUtils";
import { companyHeader } from "../AdministrationHeaders";
import {
  themes,
  tableContainerSx,
  tableHeaderSx,
  tableRowSx,
  tableCellFontSize,
  scrollTop,
} from "services/constants";
import { PunchDetails } from "./PunchDevice";
export const Companies = () => {
  const { domain, token, globalSearchValue } = useSelector(
    (state) => state.tokenReducer
  );
  const [masterDataForSearch, setMasterDataForSearch] = useState([]);
  const history = useHistory();
  const dispatch = useDispatch();
  const [page, setPage] = useState(0);
  const [loader, setLoader] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [getCompanyDetails, setGetCompanyDetails] = useState(true);
  const [companyDetails, setCompanyDetails] = useState([]);
  const [addCompanyScreen, setAddCompanyScreen] = useState(false);
  const [screenName, setScreenName] = useState("Create Company");
  const [editCompanyDetails, setEditCompanyDetails] = useState(null);
  const [editDetails, setEditDetails] = useState(null);
  const [updateData, setUpdateData] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [isNull, setNull] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [dialogActionType, setDialogActionType] = useState("");
  const [dialogMessage, setDialogMessage] = useState("");
  const [dialogCompanyDetails, setDialogCompanyDetails] = useState(null);
  const [buttonLoader, setButtonLoader] = useState(false);
  const tableContainerRef = useRef(null);
  const [showPunchDetails, setShowPunchDetails] = useState(false);

  useEffect(() => {
    scrollTop(tableContainerRef);
  }, [page]);

  useEffect(() => {
    const getCompany = async () => {
      setLoader(true);
      const res = await customGetAPI(`${domain}company/`);
      if (res.ok) {
        res.responseDetails.length === 0
          ? addNewCompany()
          : setCompanyDetails(res.responseDetails);
        setMasterDataForSearch(res.responseDetails);
        setLoader(false);
      } else {
        setLoader(false);
      }
    };
    if (getCompanyDetails) {
      getCompany();
      setGetCompanyDetails(false);
    }
  }, [domain, getCompanyDetails, token]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const activatedAndDeactivateCompany = (params, companyDetails) => {
    let message = "";
    if (params === "Activate") {
      message = (
        <span>
          Are you sure you want to activate{" "}
          <b style={{ color: themes.redColor }}>{companyDetails.companyname}</b>
          ?
        </span>
      );
    } else if (params === "Inactivate") {
      message = (
        <sapn>
          Are you sure you want to inactivate{" "}
          <b style={{ color: themes.redColor }}>{companyDetails.companyname}</b>
          ?
        </sapn>
      );
    }

    setDialogMessage(message);
    setDialogCompanyDetails(companyDetails);
    setDialogActionType(params);
    setOpenDialog(true);
  };

  const handleDialogAction = async (confirm) => {
    if (confirm) {
      // Perform the action based on dialogActionType
      if (
        dialogActionType === "Activate" ||
        dialogActionType === "Inactivate"
      ) {
        await activatedAndDeactivateCompanyConfirmed(
          dialogActionType,
          dialogCompanyDetails
        );
      }
    }
  };

  const addNewCompany = () => {
    setEditCompanyDetails({
      companyname: "",
      companycode: "",
      address: "",
      gstno: "",
      email: "",
      phonenumber: "",
      isactive: true,
      primary: false,
    });
    setScreenName("Create Company");
    setUpdateData(true);
    setAddCompanyScreen(true);
  };

  const EditCompany = (item) => {
    setEditCompanyDetails({
      id: item.id,
      companyname: item.companyname,
      companycode: item.company_code,
      address: item.address,
      gstno: item.gstnumber,
      email: item.email,
      phonenumber: item.phonenumber,
      isactive: item.isactive,
      primary: item.isprimary,
    });
    setScreenName("Edit Company Details");
    setUpdateData(true);
    setAddCompanyScreen(true);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };
  useEffect(() => {
    if (anchorEl === null) {
      setNull(false);
    }
  }, [anchorEl]);

  const activatedAndDeactivateCompanyConfirmed = async (
    actionType,
    companyDetails
  ) => {
    setButtonLoader(true);
    try {
      const myHeaders = new Headers();
      const formData = new FormData();
      myHeaders.append("Authorization", `token ${token}`);
      formData.append("isactive", actionType === "Activate" ? true : false);
      const response = await fetch(`${domain}company/${companyDetails.id}/`, {
        method: "PUT",
        headers: myHeaders,
        body: formData,
        redirect: "follow",
      });
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error);
      } else {
        setGetCompanyDetails(true);
        if (actionType === "Activate") {
          toast.success("Company activated successfully");
        } else if (actionType === "Inactivate") {
          showToast("error", "Company inactiavted successfully");
        } else {
          toast.success("Company updated successfully");
        }
      }
    } catch (error) {
      showToast("error", error.message);
    } finally {
      setOpenDialog(false);
      setButtonLoader(false);
    }
  };
  useEffect(() => {
    const filteredResults = masterDataForSearch.filter((item) =>
      administrationCompanies.some((key) =>
        item[key]
          ?.toString()
          .toLowerCase()
          .includes(globalSearchValue.toString().toLowerCase())
      )
    );
    setCompanyDetails(filteredResults);
    setPage(globalSearchValue ? 0 : page);
  }, [globalSearchValue, masterDataForSearch, page]);

  const changeCompanyType = async () => {
    try {
      const myHeaders = new Headers();
      const formData = new FormData();
      myHeaders.append("Authorization", `token ${token}`);
      formData.append("isprimary", editDetails.isprimary ? false : true);
      const response = await fetch(`${domain}company/${editDetails?.id}/`, {
        method: "PUT",
        headers: myHeaders,
        body: formData,
        redirect: "follow",
      });
      const res = await response.json();
      if (response.ok) {
        const companyType = editDetails.isprimary ? "Secondary" : "Primary";
        showToast(
          "success",
          `${editDetails.companyname} is now set as ${companyType} company.You can switch back anytime.`
        );
        setGetCompanyDetails(true);
      } else if (response.status === 409) {
        sessionHandling();
      } else {
        throw new Error(res.error);
      }
    } catch (error) {
      showToast("error", error.message);
    }
  };

  return showPunchDetails ? (
    <PunchDetails
      setShowPunchDetails={setShowPunchDetails}
      editDetails={editDetails}
    />
  ) : (
    <React.Fragment>
      <Mui.Grid
        container
        sx={{
          paddingTop: 2,
          paddingRight: 1,
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-end",
          alignItems: "flex-end",
        }}
      >
        {addCompanyScreen === false ? (
          <CustomButton
            actionFuntion={addNewCompany}
            actionName="Add Company"
            typeName="button"
          />
        ) : null}
        <Mui.TableContainer sx={tableContainerSx} ref={tableContainerRef}>
          {addCompanyScreen === false ? (
            <Mui.Table>
              <Mui.TableHead sx={tableHeaderSx}>
                <Mui.TableRow maxWidth="xl" align="left" sx={tableRowSx}>
                  {companyHeader?.map?.((value) => (
                    <Mui.TableCell
                      sx={{
                        color: themes.blackColor,
                        fontWeight: "bold",
                        fontSize: tableCellFontSize,
                      }}
                    >
                      {value?.name}
                    </Mui.TableCell>
                  ))}
                </Mui.TableRow>
              </Mui.TableHead>
              <Mui.TableBody>
                {companyDetails.length === 0 ? (
                  <Mui.TableRow>
                    <Mui.TableCell colSpan={12} align="center">
                      <Mui.Alert severity="info">No data available</Mui.Alert>
                    </Mui.TableCell>
                  </Mui.TableRow>
                ) : (
                  companyDetails
                    ?.slice(
                      page * rowsPerPage,
                      page * rowsPerPage + rowsPerPage
                    )
                    ?.map((item, index) => (
                      <Mui.TableRow
                        maxWidth="xl"
                        align="left"
                        sx={{
                          cursor: "pointer",
                          "& .MuiTableCell-root": {
                            height: "10px",
                            padding: "3px 15px",
                          },
                        }}
                        onDoubleClick={
                          isNull === true
                            ? null
                            : () => {
                                dispatch(setCompanyId(""));
                                dispatch(setCompanyName(""));
                                dispatch(setCompanyId(item?.id));
                                dispatch(setCompanyName(item?.companyname));
                                localStorage.setItem(
                                  "companyName",
                                  item?.companyname
                                );
                                localStorage.setItem("companyId", item?.id);
                                dispatch(setGlobalSearchValue(""));
                                history.push("/admin/view/company/groups");
                              }
                        }
                      >
                        <Mui.TableCell></Mui.TableCell>
                        <Mui.TableCell
                          align="left"
                          sx={{
                            fontSize: tableCellFontSize,
                          }}
                        >
                          {item?.companyname}
                        </Mui.TableCell>
                        <Mui.TableCell
                          align="left"
                          sx={{
                            fontSize: tableCellFontSize,
                          }}
                        >
                          {item?.company_code ? item?.company_code : "N/A"}
                        </Mui.TableCell>
                        <Mui.TableCell
                          align="left"
                          sx={{
                            fontSize: tableCellFontSize,
                          }}
                        >
                          <Mui.Tooltip title={item?.address}>
                            <Mui.Typography
                              sx={{
                                textOverflow: "ellipsis",
                                overflow: "hidden",
                                whiteSpace: "nowrap",
                                maxWidth: 200,
                                fontSize: tableCellFontSize,
                              }}
                            >
                              {item?.address ? item?.address : "N/A"}
                            </Mui.Typography>
                          </Mui.Tooltip>
                        </Mui.TableCell>
                        <Mui.TableCell
                          align="left"
                          sx={{
                            fontSize: tableCellFontSize,
                          }}
                        >
                          {item?.gstnumber ? item?.gstnumber : "N/A"}
                        </Mui.TableCell>
                        <Mui.TableCell
                          align="left"
                          sx={{
                            fontSize: tableCellFontSize,
                          }}
                        >
                          {item?.email ? item?.email : "N/A"}
                        </Mui.TableCell>
                        <Mui.TableCell
                          align="left"
                          sx={{
                            fontSize: tableCellFontSize,
                          }}
                        >
                          {item?.phonenumber ? item?.phonenumber : "N/A"}
                        </Mui.TableCell>
                        <Mui.TableCell
                          align="left"
                          sx={{
                            fontSize: tableCellFontSize,
                          }}
                        >
                          {item?.isactive ? "Active" : "Inactive"}
                        </Mui.TableCell>
                        <Mui.TableCell
                          align="left"
                          sx={{
                            fontSize: tableCellFontSize,
                          }}
                        >
                          {item?.isprimary ? "Primary" : "Secondary"}
                        </Mui.TableCell>
                        <Mui.TableCell
                          align="left"
                          sx={{
                            fontSize: tableCellFontSize,
                          }}
                        >
                          <Mui.IconButton
                            onClick={(event) => {
                              setNull(true);
                              setAnchorEl(event.currentTarget);
                              setEditDetails(item);
                            }}
                          >
                            <MoreVert />
                          </Mui.IconButton>
                          <Mui.Menu
                            anchorEl={anchorEl}
                            open={Boolean(anchorEl)}
                            onClose={handleMenuClose}
                            elevation={1}
                            transformOrigin={{
                              horizontal: "right",
                              vertical: "top",
                            }}
                            anchorOrigin={{
                              horizontal: "right",
                              vertical: "bottom",
                            }}
                          >
                            <Mui.MenuItem
                              sx={{ width: "100%" }}
                              onClick={() => {
                                setEditCompanyDetails(null);
                                setAnchorEl(null);
                                EditCompany(editDetails);
                              }}
                            >
                              Edit Company Details
                            </Mui.MenuItem>
                            {editDetails?.isactive &&
                            editDetails.isprimary === false ? (
                              <Mui.MenuItem
                                sx={{ width: "100%" }}
                                onClick={() => {
                                  setAnchorEl(null);
                                  changeCompanyType();
                                }}
                              >
                                Primary
                              </Mui.MenuItem>
                            ) : null}
                            {editDetails?.isactive &&
                            !editDetails?.isprimary ? (
                              <Mui.MenuItem
                                sx={{ width: "100%" }}
                                onClick={() => {
                                  setAnchorEl(null);
                                  activatedAndDeactivateCompany(
                                    "Inactivate",
                                    editDetails
                                  );
                                }}
                              >
                                Inactivate
                              </Mui.MenuItem>
                            ) : editDetails?.isprimary ? null : (
                              <Mui.MenuItem
                                sx={{ width: "100%" }}
                                onClick={() => {
                                  setAnchorEl(null);
                                  activatedAndDeactivateCompany(
                                    "Activate",
                                    editDetails
                                  );
                                }}
                              >
                                Activate
                              </Mui.MenuItem>
                            )}
                            <Mui.MenuItem
                              sx={{ width: "100%" }}
                              onClick={() => {
                                setAnchorEl(null);
                                setShowPunchDetails(true);
                              }}
                            >
                              Attendance System
                            </Mui.MenuItem>
                          </Mui.Menu>
                        </Mui.TableCell>
                      </Mui.TableRow>
                    ))
                )}
              </Mui.TableBody>
            </Mui.Table>
          ) : null}
          {loader ? <Loader info="Loading..." /> : null}
          {addCompanyScreen ? (
            <AddCompany
              screenName={screenName}
              editEompanyDetails={editCompanyDetails}
              setAddCompanyScreen={setAddCompanyScreen}
              updateData={updateData}
              setUpdateData={setUpdateData}
              companyDetails={companyDetails}
              addCompanyScreen={addCompanyScreen}
              setGetCompanyDetails={setGetCompanyDetails}
            />
          ) : null}
        </Mui.TableContainer>
      </Mui.Grid>
      {companyDetails?.length > 10 && addCompanyScreen === false ? (
        <Mui.TablePagination
          className="custom-pagination"
          component="div"
          rowsPerPageOptions={[25, 50, 75, 100]}
          count={companyDetails?.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      ) : null}
      <GlobalDialogBox
        open={openDialog}
        handleCloseDialog={() => setOpenDialog(false)}
        title="Confirmation"
      >
        <Mui.Grid>
          <Mui.Typography sx={{ fontSize: 21 }}>{dialogMessage}</Mui.Typography>
        </Mui.Grid>
        <Mui.DialogActions>
          <CustomButton
            actionFuntion={() => setOpenDialog(false)}
            actionName="No"
            typeName="button"
          />
          &nbsp;&nbsp;
          <CustomButton
            actionFuntion={() => handleDialogAction(true)}
            actionName="Yes"
            typeName="button"
            disableAction={openDialog && buttonLoader ? buttonLoader : false}
          />
        </Mui.DialogActions>
      </GlobalDialogBox>
    </React.Fragment>
  );
};
