import React from "react";
import { Grid, Paper, Typography } from "@mui/material";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useLocation } from "react-router-dom";
import { setGlobalSearchValue } from "services/Redux/userToken";
import { useDispatch } from "react-redux";
import { themes } from "services/constants";
import { PermIdentity, Badge, AdUnits } from "@mui/icons-material";
export const SettingsMenu = () => {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  return (
    <React.Fragment>
      <Grid
        conatiner
        spacing={2}
        sx={{
          marginTop: 2,
          height: "92.5vh",
          overflow: "hidden",
          borderRadius: 2,
          backgroundColor: themes.primary,
        }}
        component={Paper}
        elevation={3}
      >
        <Grid item sx={{ padding: 0.5 }}>
          <Paper
            elevation={
              location.pathname === "/admin/master/designation" ? 1 : 0
            }
            sx={{
              cursor: "pointer",
              color: themes.blackColor,
              backgroundColor:
                location.pathname === "/admin/master/designation"
                  ? themes.whiteColor
                  : themes.primary,
            }}
            onClick={() => {
              dispatch(setGlobalSearchValue(""));
              localStorage.setItem("currentSubMenu", "Master");
              localStorage.setItem("currentScreen", "Administration");
              history.push("/admin/master/designation");
            }}
          >
            <Typography sx={{ padding: 1.5, fontSize: 17 }}>
              <PermIdentity sx={{ fontSize: 20 }} /> Designation
            </Typography>
          </Paper>
        </Grid>
        <Grid item sx={{ padding: 0.5 }}>
          <Paper
            elevation={
              location.pathname === "/admin/master/employment/type" ? 1 : 0
            }
            sx={{
              cursor: "pointer",
              color: themes.blackColor,
              backgroundColor:
                location.pathname === "/admin/master/employment/type"
                  ? themes.whiteColor
                  : themes.primary,
            }}
            onClick={() => {
              dispatch(setGlobalSearchValue(""));
              localStorage.setItem("currentSubMenu", "Master");
              localStorage.setItem("currentScreen", "Administration");
              history.push("/admin/master/employment/type");
            }}
          >
            <Typography sx={{ padding: 1.5, fontSize: 17 }}>
              <Badge sx={{ fontSize: 20 }} /> Employment Type
            </Typography>
          </Paper>
        </Grid>
        <Grid item sx={{ padding: 0.5 }}>
          <Paper
            elevation={location.pathname === "/admin/master/unit" ? 1 : 0}
            sx={{
              cursor: "pointer",
              color: themes.blackColor,
              backgroundColor:
                location.pathname === "/admin/master/unit"
                  ? themes.whiteColor
                  : themes.primary,
            }}
            onClick={() => {
              dispatch(setGlobalSearchValue(""));
              localStorage.setItem("currentSubMenu", "Employement Type");
              localStorage.setItem("currentScreen", "Administration");
              history.push("/admin/master/unit");
            }}
          >
            <Typography sx={{ padding: 1.5, fontSize: 17 }}>
              <AdUnits sx={{ fontSize: 20 }} /> Unit
            </Typography>
          </Paper>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};
