import React, { useState } from "react";
import * as Mui from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { themes } from "services/constants";
import { GlobalDialogBox } from "components/GlobalDialogBox";
import { CustomButton } from "components/CustomComponents/CustomButton";
import { CustomTextBox } from "components/CustomComponents/CustomTextBox";
import { useForm } from "react-hook-form";
import { atleastOneCharacterWithSpace } from "services/constants/patterns";
import { minimum3Characters } from "services/constants/ErrorMessages";
import { useSelector } from "react-redux";
import { showToast } from "services/utils/Status";
import sessionHandling from "services/utils/notificationUtils";

const payScheduleKeyPair = [
  {
    key: "name",
    name: "Payrun name",
  },
  {
    key: "start_date",
    name: "Start date",
  },
  {
    key: "end_date",
    name: "End date",
  },
  {
    key: "num_of_employees",
    name: "Number of Employee",
  },
  {
    key: "run_date",
    name: "Pay date",
  },
  {
    key: "group_count",
    name: "Group count",
  },
  {
    key: "action",
    name: "Action",
  },
];

export const PayScheduleTable = ({
  buttonDisabled,
  payrunDetails,
  setEditPaySchedule,
  editPayScheduleDetails,
  setEditPayScheduleDetails,
  openDialog,
  setOpenDialog,
  updateDeletePayrun,
  listViewPage,
  setListViewPage,
  buttonNotify,
  reCallApi,
  reCallApiWithApproval,
  setViewPayRunScreen,
  setUpdatePayrunCycle,
}) => {
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [anchorEl, setAnchorEl] = useState(null);
  const [payScheduleName, setPayScheduleName] = useState("");
  const [openEditNameDialog, setOpenEditNameDialog] = useState(false);
  const { domain, token } = useSelector((state) => state.tokenReducer);
  const [buttonLoader, setButtonLoader] = useState(false);
  const {
    register,
    // reset,

    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();

  const handleChangePage = (event, newPage) => {
    setListViewPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setListViewPage(0);
  };

  const updateUserName = (event) => {
    if (event.target.value.length <= 32) {
      setValue("payrunname", event.target.value);
      setPayScheduleName(event.target.value);
    }
  };

  const onSubmit = async (data) => {
    // let salaryGroup = [];
    // editPayScheduleDetails.salary_group_detail.forEach((element) => {
    //   salaryGroup.push(element?.id);
    // });
    setButtonLoader(true);
    try {
      const response = await fetch(
        `${domain}past-pay-schedules/${editPayScheduleDetails?.id}/`,
        {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
            Authorization: `token ${token}`,
          },
          body: JSON.stringify({
            name: payScheduleName,
          }),
        }
      );
      const res = await response.json();
      if (response.ok) {
        setEditPaySchedule(false);
        showToast("success", "Pay schedule details updated successfully");
        reCallApi(true);
        setButtonLoader(false);
        reCallApiWithApproval(true);
      } else if (response.status === 409) {
        sessionHandling();
      } else {
        throw new Error(res.error);
      }
    } catch (error) {
      showToast("error", error.message);
      setButtonLoader(false);
    }
  };

  return (
    <React.Fragment>
      <Mui.TableContainer
        component={Mui.Paper}
        sx={{
          mt: 2,
          width: "100%",
          paddingRight: 1,
          maxHeight: 500,
          minHeight: 300,
        }}
      >
        <Mui.Table>
          <Mui.TableHead
            sx={{
              backgroundColor: themes.primary,
              position: "sticky",
              top: -1,
              zIndex: 10,
            }}
          >
            <Mui.TableRow
              sx={{
                "& .MuiTableCell-root": {
                  height: "10px",
                  padding: "3px 15px",
                },
              }}
            >
              {payScheduleKeyPair.map((header) =>
                header.name === "Action" ? (
                  <>
                    {buttonNotify === "Current PaySchedule" ? (
                      <Mui.TableCell
                        sx={{ color: themes.blackColor, fontWeight: "bold" }}
                      >
                        Status
                      </Mui.TableCell>
                    ) : null}
                    <Mui.TableCell
                      sx={{ color: themes.blackColor, fontWeight: "bold" }}
                    >
                      {header.name}
                    </Mui.TableCell>
                  </>
                ) : (
                  <Mui.TableCell
                    sx={{ color: themes.blackColor, fontWeight: "bold" }}
                  >
                    {header.name}
                  </Mui.TableCell>
                )
              )}
            </Mui.TableRow>
          </Mui.TableHead>
          <Mui.TableBody>
            {payrunDetails?.length === 0 ? (
              <Mui.TableRow
                sx={{
                  "& .MuiTableCell-root": {
                    height: "10px",
                    padding: "3px 15px",
                  },
                }}
              >
                <Mui.TableCell colSpan={12}>
                  <Mui.Alert
                    severity="info"
                    sx={{ width: "100%", marginTop: 1 }}
                  >
                    No data available
                  </Mui.Alert>
                </Mui.TableCell>
              </Mui.TableRow>
            ) : (
              payrunDetails
                ?.slice(
                  listViewPage * rowsPerPage,
                  listViewPage * rowsPerPage + rowsPerPage
                )
                .map((department) => (
                  <Mui.TableRow
                    sx={{
                      "& .MuiTableCell-root": {
                        height: "10px",
                        padding: "3px 15px",
                      },
                    }}
                  >
                    {payScheduleKeyPair.map((header) =>
                      header.name !== "Action" &&
                      header.name !== "Payrun period" ? (
                        <Mui.TableCell sx={{ color: themes.blackColor }}>
                          {department[header.key] ||
                          department[header.key] === 0
                            ? department[header.key]
                            : "N/A"}
                        </Mui.TableCell>
                      ) : header.name === "Action" ? (
                        <>
                          {buttonNotify === "Current PaySchedule" ? (
                            <Mui.TableCell>{department.status}</Mui.TableCell>
                          ) : null}
                          <Mui.TableCell>
                            <Mui.IconButton
                              onClick={(event) => {
                                setEditPayScheduleDetails(department);
                                setAnchorEl(event.currentTarget);
                              }}
                            >
                              <MoreVertIcon
                                sx={{
                                  color: themes.blackColor,
                                  strokeWidth: 2,
                                }}
                              />
                            </Mui.IconButton>
                            <Mui.Menu
                              anchorEl={anchorEl}
                              open={Boolean(anchorEl)}
                              onClose={() => setAnchorEl(null)}
                              elevation={1}
                              transformOrigin={{
                                horizontal: "right",
                                vertical: "top",
                              }}
                              anchorOrigin={{
                                horizontal: "right",
                                vertical: "bottom",
                              }}
                            >
                              {buttonNotify === "Current PaySchedule" ? (
                                <>
                                  {editPayScheduleDetails.status ===
                                    "Approved" ||
                                  editPayScheduleDetails.status ===
                                    "Submitted" ||
                                  editPayScheduleDetails.status ===
                                    "InProgress" ||
                                  editPayScheduleDetails.status ===
                                    "Rejected" ? (
                                    <Mui.MenuItem
                                      onClick={() => {
                                        setAnchorEl(null);
                                        setOpenEditNameDialog(true);
                                        setPayScheduleName(
                                          editPayScheduleDetails.name
                                        );
                                        setValue(
                                          "payrunname",
                                          editPayScheduleDetails.name
                                        );
                                      }}
                                    >
                                      Edit Name
                                    </Mui.MenuItem>
                                  ) : (
                                    <>
                                      <Mui.MenuItem
                                        onClick={() => {
                                          setAnchorEl(null);
                                          setEditPaySchedule(true);
                                          setViewPayRunScreen(true);
                                          setUpdatePayrunCycle(true);
                                        }}
                                      >
                                        Edit
                                      </Mui.MenuItem>
                                      {editPayScheduleDetails.status ===
                                      "Draft" ? (
                                        <Mui.MenuItem
                                          onClick={() => {
                                            setAnchorEl(null);
                                            setOpenDialog(true);
                                          }}
                                        >
                                          Delete
                                        </Mui.MenuItem>
                                      ) : null}
                                    </>
                                  )}
                                </>
                              ) : (
                                <>
                                  <Mui.MenuItem
                                    onClick={() => {
                                      setAnchorEl(null);
                                      setEditPaySchedule(true);
                                      setViewPayRunScreen(true);
                                    }}
                                  >
                                    Edit
                                  </Mui.MenuItem>
                                  <Mui.MenuItem
                                    onClick={() => {
                                      setAnchorEl(null);
                                      setOpenDialog(true);
                                    }}
                                  >
                                    Delete
                                  </Mui.MenuItem>
                                </>
                              )}
                            </Mui.Menu>
                          </Mui.TableCell>
                        </>
                      ) : (
                        <Mui.TableCell>
                          {department.start_date} to {department.end_date}
                        </Mui.TableCell>
                      )
                    )}
                  </Mui.TableRow>
                ))
            )}
          </Mui.TableBody>
        </Mui.Table>
        {payrunDetails.length > 10 && (
          <Mui.TablePagination
            className="custom-pagination"
            component="div"
            rowsPerPageOptions={[10, 20, 50, 100]}
            count={payrunDetails.length}
            rowsPerPage={rowsPerPage}
            page={listViewPage}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        )}
      </Mui.TableContainer>

      <GlobalDialogBox
        handleCloseDialog={() => setOpenDialog(false)}
        open={openDialog}
        title="Delete Pay Schedule"
      >
        <Mui.Typography sx={{ paddingBottom: 2, fontSize: 22 }}>
          Are you sure ,you want to delete the{" "}
          <span style={{ color: themes.redColor }}>
            {editPayScheduleDetails?.name}
          </span>{" "}
          ?
        </Mui.Typography>
        <Mui.Grid container>
          <Mui.Grid
            xs={12}
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              alignItems: "flex-end",
              paddingRight: 1,
            }}
          >
            <CustomButton
              actionFuntion={() => setOpenDialog(false)}
              actionName="No"
              typeName="button"
            />
            &nbsp;&nbsp;
            <CustomButton
              actionFuntion={() => updateDeletePayrun()}
              actionName="Yes"
              typeName="button"
              disableAction={
                openDialog && buttonDisabled ? buttonDisabled : false
              }
            />
          </Mui.Grid>
        </Mui.Grid>
      </GlobalDialogBox>
      <GlobalDialogBox
        handleCloseDialog={() => setOpenEditNameDialog(false)}
        open={openEditNameDialog}
        title="Edit Payrun Schedule Name"
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <Mui.Grid xs={12}>
            <Mui.Typography
              sx={{
                fontSize: 15,
                color: themes.colorShadow,
                paddingBottom: 1,
                fontWeight: "bold",
              }}
            >
              Payrun Name
            </Mui.Typography>
            <CustomTextBox
              selectedValue={payScheduleName}
              rules={{
                ...register("payrunname", {
                  required: "payrunname is required",
                  maxLength: {
                    value: 32,
                    message: "Maximum 32 characters are required",
                  },
                  validate: {
                    atleastOneCharacterWithSpaceReg: (value) => {
                      if (
                        atleastOneCharacterWithSpace.test(value) ||
                        value === ""
                      ) {
                        return true;
                      } else {
                        return "Atleast one alphabet required";
                      }
                    },
                    minLengthNoSpaces: (value) => {
                      if (value?.trim().length < 3 && value !== "") {
                        return minimum3Characters;
                      } else {
                        return true;
                      }
                    },
                  },
                }),
              }}
              updateDataFunction={updateUserName}
              placeHolder="Enter the payrun name"
              errorMessage={
                errors?.payrunname?.message ? errors?.payrunname?.message : ""
              }
              textBoxError={errors.payrunname ? true : false}
            />
          </Mui.Grid>
          <Mui.Grid container sx={{ paddingTop: 2 }}>
            <Mui.Grid
              xs={12}
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
                alignItems: "flex-end",
                paddingRight: 1,
              }}
            >
              <CustomButton
                actionFuntion={() => setOpenEditNameDialog(false)}
                actionName="No"
                typeName="button"
              />
              &nbsp;&nbsp;
              <CustomButton
                actionName="Yes"
                typeName="submit"
                disableAction={buttonLoader}
              />
            </Mui.Grid>
          </Mui.Grid>
        </form>
      </GlobalDialogBox>
    </React.Fragment>
  );
};
