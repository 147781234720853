import React, { useState } from "react";
import * as Mui from "@mui/material";
import { themes } from "services/constants";
import { EmployeeBankDetailsHeader } from "pages/PayRoll/PayRollHeaders";
import { tableHeaderSx } from "services/constants";
import { tableRowSx } from "services/constants";

export const EmployeeBankTable = ({
  salaryDetails,
  listViewPage,
  setListViewPage,
}) => {
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const handleChangePage = (event, newPage) => {
    setListViewPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setListViewPage(0);
  };

  return (
    <React.Fragment>
      <Mui.TableContainer
        sx={{
          mt: 2,
          width: "100%",
          maxHeight: 850,
          paddingLeft: "5%",
          paddingRight: "5%",
        }}
      >
        <Mui.Table>
          <Mui.TableHead sx={tableHeaderSx}>
            <Mui.TableRow sx={tableRowSx}>
              {EmployeeBankDetailsHeader.map((header) => (
                <Mui.TableCell
                  sx={{ color: themes.blackColor, fontWeight: "bold" }}
                >
                  {header.name}
                </Mui.TableCell>
              ))}
            </Mui.TableRow>
          </Mui.TableHead>
          <Mui.TableBody>
            {salaryDetails?.length === 0 ? (
              <Mui.TableRow sx={tableRowSx}>
                <Mui.TableCell colSpan={12}>
                  <Mui.Alert
                    severity="info"
                    sx={{ width: "100%", marginTop: 1 }}
                  >
                    No data available
                  </Mui.Alert>
                </Mui.TableCell>
              </Mui.TableRow>
            ) : (
              salaryDetails
                ?.slice(
                  listViewPage * rowsPerPage,
                  listViewPage * rowsPerPage + rowsPerPage
                )
                .map((salary) => (
                  <Mui.TableRow sx={tableRowSx}>
                    {EmployeeBankDetailsHeader.map((header) => (
                      <Mui.TableCell sx={{ color: themes.blackColor }}>
                        {salary[header.key] || salary[header.key] === 0
                          ? salary[header.key]
                          : "N/A"}
                      </Mui.TableCell>
                    ))}
                  </Mui.TableRow>
                ))
            )}
          </Mui.TableBody>
        </Mui.Table>
      </Mui.TableContainer>

      {salaryDetails?.length > 25 && (
        <Mui.TablePagination
          className="custom-pagination"
          component="div"
          rowsPerPageOptions={[25, 50, 75, 100]}
          count={salaryDetails?.length}
          rowsPerPage={rowsPerPage}
          page={listViewPage}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      )}
    </React.Fragment>
  );
};
