import React, { useState, useEffect } from "react";
import * as Mui from "@mui/material";
import { themes } from "services/constants";
import { useSelector } from "react-redux";
import { showToast } from "services/utils/Status";
import moment from "moment";
import { Loader } from "components/Loader";
import sessionHandling from "services/utils/notificationUtils";
import { BackArrow } from "components/CustomComponents/BackArrow";
import { DownloadForOffline } from "@mui/icons-material";
import { useFetch } from "services/hooks/useFetch";
import { tableRowSx } from "services/constants";
import { setGlobalSearchValue } from "services/Redux/userToken";
import { useDispatch } from "react-redux";
import { DateRangePicker } from "pages/MyZone/Status/DateRangePicker";

const OvertimeReport = ({ setShowReport }) => {
  const { domain, token, userDetails, globalSearchValue } = useSelector(
    (state) => state.tokenReducer
  );
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [otRepDetails, setOtRepDetails] = useState([]);
  const [masterDataForSearch, setMasterDataForSearch] = useState([]);
  const [startDate, setStartDate] = useState(
    moment(new Date()).format("DD/MM/YYYY")
  );
  const [endDate, setEndDate] = useState(
    moment(new Date()).format("DD/MM/YYYY")
  );
  const [selectedDateRange, setSelectedDateRange] = useState(
    `${moment(new Date()).format("DD/MM/YYYY")} - ${moment(new Date()).format(
      "DD/MM/YYYY"
    )}`
  );
  const { result: departmentData } = useFetch(
    `${domain}get-department/?user_id=${userDetails.id}`
  );
  const [departmentName, setDepartmentName] = useState("All");
  const [departmentId, setDepartmentId] = useState("99999");

  useEffect(() => {
    const fetchUsertaskDetails = async () => {
      try {
        setIsLoading(true);
        let searchStartDate = startDate.split("/").reverse().join("-");
        let searchEndDate = endDate.split("/").reverse().join("-");

        const response = await fetch(
          `${domain}user/overtime-report/?department_id=${departmentId}&start_date=${searchStartDate}&end_date=${searchEndDate}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `token ${token}`,
            },
          }
        );
        const res = await response.json();
        if (response.ok) {
          setOtRepDetails(res);
          setMasterDataForSearch(res);
        } else if (response.status === 409) {
          setOtRepDetails([]);
          setMasterDataForSearch([]);
          sessionHandling();
        } else {
          setOtRepDetails([]);
          setMasterDataForSearch([]);
          throw new Error(res.error);
        }
      } catch (error) {
        setOtRepDetails([]);
        setMasterDataForSearch([]);
        showToast("error", error.message);
      } finally {
        setIsLoading(false);
      }
    };
    fetchUsertaskDetails();
  }, [domain, token, startDate, endDate, departmentId]);

  useEffect(() => {
    const filteredResults = masterDataForSearch?.filter((item) =>
      [
        "date",
        "shift_name",
        "department_name",
        "user_name",
        "employee_id",
        "overtime_start",
        "overtime_end",
        "duration",
      ].some((key) =>
        item[key]
          ?.toString()
          .toLowerCase()
          .includes(globalSearchValue.toString().toLowerCase())
      )
    );
    setOtRepDetails(filteredResults);
    setPage(0);
  }, [globalSearchValue, masterDataForSearch]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleStartDate = async (startDate, endDate) => {
    setStartDate(startDate);
    setEndDate(endDate);
    setSelectedDateRange(`${startDate} - ${endDate}`);
  };

  const getOvertimeReport = () => {
    let dataPreparation = [];
    otRepDetails?.forEach((element) => {
      let overAllData = {
        date: element?.date,
        shiftname: element?.shift_name ? element?.shift_name : "Unassigned",
        departmentname: element?.department_name
          ? element?.department_name
          : "N/A",
        employeename: element?.user_name,
        employeeid: element?.employee_id,
        overtime_start: element?.overtime_start
          ? element?.overtime_start
          : "N/A",
        overtime_end: element?.overtime_end ? element?.overtime_end : "N/A",
        duration: element?.duration ? element?.duration : "00:00",
      };
      dataPreparation = [...dataPreparation, overAllData];
    });
    const OvertimeReport = ["Overtime Report"];
    const Space = [""];
    const Dates = ["Date"];
    const Department = ["Department Name"];
    const GeneratedOn = ["Report Generated On"];
    OvertimeReport.push("");
    Dates.push(`${startDate} to ${endDate}`);
    Department.push(departmentName);
    GeneratedOn.push(moment(new Date()).format("DD/MM/YYYY, hh:mm a"));
    Space.push("");
    const items = dataPreparation;
    const TableHead = ["Date"];
    TableHead.push("Shift Name");
    TableHead.push("Department Name");
    TableHead.push("Employee Name");
    TableHead.push("Employee Id");
    TableHead.push("Overtime Start");
    TableHead.push("Overtime End");
    TableHead.push("Overtime Duration");
    const replacer = (key, value) => (value === null ? "" : value);
    const header = Object.keys(items[0]);
    let csv = items.map((row) =>
      header
        .map((fieldName) => JSON.stringify(row[fieldName], replacer))
        .join(",")
    );
    csv.unshift(TableHead.join(","));
    csv.unshift(Space.join(","));
    csv.unshift(GeneratedOn.join(","));
    csv.unshift(Department.join(","));
    csv.unshift(Dates.join(","));
    csv.unshift(OvertimeReport.join(","));
    csv = csv.join("\r\n");
    const ConvertData = csv;
    const element = document.createElement("a");
    const file = new Blob([ConvertData], {
      type: "text/plain",
    });
    element.href = URL.createObjectURL(file);
    element.download = "OvertimeReport.csv";
    document.body.appendChild(element);
    element.click();
  };

  return (
    <React.Fragment>
      <Mui.Grid container spacing={1} sx={{ mt: 0 }}>
        <Mui.Grid item xs={12} sm={12} md={12} lg={12}>
          <Mui.Stack direction="row">
            <BackArrow
              actionFunction={() => {
                dispatch(setGlobalSearchValue(""));
                setShowReport(false);
              }}
            />
            <Mui.Typography
              sx={{
                fontSize: 20,
                fontWeight: "bold",
                mt: 1.5,
              }}
            >
              Overtime Report
            </Mui.Typography>
          </Mui.Stack>
        </Mui.Grid>
      </Mui.Grid>

      <Mui.Grid container spacing={1} sx={{ mt: 1, mb: 1 }}>
        <Mui.Grid item xs={12} sm={6} md={3} lg={3}>
          <DateRangePicker
            handleStartDate={handleStartDate}
            selectedDateRange={selectedDateRange}
          />
        </Mui.Grid>
        <Mui.Grid item xs={12} sm={6} md={3} lg={2.5}>
          <Mui.Autocomplete
            disablePortal
            id="combo-box-demo"
            size="small"
            sx={{ minWidth: "100%" }}
            value={
              [{ id: 99999, department_name: "All" }, ...departmentData]?.find(
                (option) => option?.department_name === departmentName
              ) || {
                department_name: departmentName,
              }
            }
            options={[{ id: 99999, department_name: "All" }, ...departmentData]}
            ListboxProps={{
              style: {
                maxHeight: "150px",
              },
            }}
            filterOptions={(options, state) => {
              return options?.filter((option) =>
                option?.department_name
                  ?.toLowerCase()
                  .includes(state.inputValue.toLowerCase())
              );
            }}
            getOptionLabel={(option) =>
              option?.department_name ? option?.department_name : ""
            }
            isOptionEqualToValue={(option, value) =>
              option.department_name === value.department_name
            }
            renderOption={(props, option) => {
              return <li {...props}>{option?.department_name}</li>;
            }}
            onChange={(event, value) => {
              if (value && value?.department_name) {
                setDepartmentName(value.department_name);
                setDepartmentId(value.id);
              }
            }}
            renderInput={(params) => (
              <Mui.TextField
                {...params}
                size="small"
                label="Department"
                sx={{ background: "white" }}
                name="department name"
                placeholder="Select Department"
              />
            )}
          />
        </Mui.Grid>
        <Mui.Grid item lg={5}></Mui.Grid>
        <Mui.Grid item xs={2} sm={2} md={2} lg={1.5}>
          <Mui.IconButton
            onClick={() => {
              getOvertimeReport();
            }}
            disabled={otRepDetails?.length === 0 ? true : false}
          >
            <DownloadForOffline
              size="large"
              sx={{ color: "#25B7D3", fontSize: 40, mt: -1 }}
            />
          </Mui.IconButton>
        </Mui.Grid>
      </Mui.Grid>

      {isLoading ? (
        <Loader info="Loading..." />
      ) : (
        <>
          {otRepDetails?.length !== 0 ? (
            <Mui.Grid
              container
              sx={{
                paddingRight: 1,
              }}
            >
              <Mui.TableContainer
                component={Mui.Paper}
                style={{ paddingTop: 4, paddingBottom: 2 }}
                sx={{
                  mt: 2,
                  borderBottom: "1.5px solid transparent",
                }}
              >
                <Mui.Table>
                  <Mui.TableHead sx={{ backgroundColor: themes.primary }}>
                    <Mui.TableRow sx={tableRowSx}>
                      <Mui.TableCell
                        sx={{
                          color: themes.blackColor,
                          fontWeight: "bold",
                          overflow: "hidden",
                          whiteSpace: "nowrap",
                          maxWidth: 100,
                          width: "10%",
                          textOverflow: "ellipsis",
                        }}
                      >
                        Date
                      </Mui.TableCell>
                      <Mui.TableCell
                        sx={{
                          color: themes.blackColor,
                          fontWeight: "bold",
                          overflow: "hidden",
                          whiteSpace: "nowrap",
                          maxWidth: 100,
                          width: "10%",
                          textOverflow: "ellipsis",
                        }}
                      >
                        Shift Name
                      </Mui.TableCell>
                      <Mui.TableCell
                        sx={{
                          color: themes.blackColor,
                          fontWeight: "bold",
                          overflow: "hidden",
                          whiteSpace: "nowrap",
                          maxWidth: 100,
                          width: "10%",
                          textOverflow: "ellipsis",
                        }}
                      >
                        Department
                      </Mui.TableCell>
                      <Mui.TableCell
                        sx={{
                          color: themes.blackColor,
                          fontWeight: "bold",
                          overflow: "hidden",
                          whiteSpace: "nowrap",
                          maxWidth: 100,
                          width: "10%",
                          textOverflow: "ellipsis",
                        }}
                      >
                        Employee Name
                      </Mui.TableCell>
                      <Mui.TableCell
                        sx={{
                          color: themes.blackColor,
                          fontWeight: "bold",
                          overflow: "hidden",
                          whiteSpace: "nowrap",
                          maxWidth: 100,
                          width: "10%",
                          textOverflow: "ellipsis",
                        }}
                      >
                        Employee Id
                      </Mui.TableCell>
                      <Mui.TableCell
                        sx={{
                          color: themes.blackColor,
                          fontWeight: "bold",
                          overflow: "hidden",
                          whiteSpace: "nowrap",
                          maxWidth: 100,
                          width: "10%",
                          textOverflow: "ellipsis",
                        }}
                      >
                        OT Start
                      </Mui.TableCell>
                      <Mui.TableCell
                        sx={{
                          color: themes.blackColor,
                          fontWeight: "bold",
                          overflow: "hidden",
                          whiteSpace: "nowrap",
                          maxWidth: 100,
                          width: "10%",
                          textOverflow: "ellipsis",
                        }}
                      >
                        OT End
                      </Mui.TableCell>
                      <Mui.TableCell
                        sx={{
                          color: themes.blackColor,
                          fontWeight: "bold",
                          overflow: "hidden",
                          whiteSpace: "nowrap",
                          maxWidth: 100,
                          width: "10%",
                          textOverflow: "ellipsis",
                        }}
                      >
                        Duration
                      </Mui.TableCell>
                    </Mui.TableRow>
                  </Mui.TableHead>
                  <Mui.TableBody>
                    {otRepDetails
                      ?.slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((item, index) => (
                        <>
                          <Mui.TableRow sx={tableRowSx}>
                            <Mui.TableCell
                              sx={{
                                overflow: "hidden",
                                whiteSpace: "nowrap",
                                maxWidth: 100,
                              }}
                            >
                              <Mui.Typography
                                sx={{
                                  overflow: "hidden",
                                  whiteSpace: "nowrap",
                                  textOverflow: "ellipsis",
                                }}
                              >
                                {item?.date}
                              </Mui.Typography>
                            </Mui.TableCell>
                            <Mui.TableCell
                              sx={{
                                overflow: "hidden",
                                whiteSpace: "nowrap",
                                maxWidth: 100,
                              }}
                            >
                              <Mui.Tooltip
                                title={item?.shift_name}
                                disableHoverListener={!item?.shift_name}
                              >
                                <Mui.Typography
                                  sx={{
                                    overflow: "hidden",
                                    whiteSpace: "nowrap",
                                    textOverflow: "ellipsis",
                                  }}
                                >
                                  {item?.shift_name
                                    ? item?.shift_name
                                    : "Unassigned"}
                                </Mui.Typography>
                              </Mui.Tooltip>
                            </Mui.TableCell>
                            <Mui.TableCell
                              sx={{
                                overflow: "hidden",
                                whiteSpace: "nowrap",
                                maxWidth: 100,
                              }}
                            >
                              <Mui.Tooltip
                                title={item?.department_name}
                                disableHoverListener={!item?.department_name}
                              >
                                <Mui.Typography
                                  sx={{
                                    overflow: "hidden",
                                    whiteSpace: "nowrap",
                                    textOverflow: "ellipsis",
                                  }}
                                >
                                  {item?.department_name}
                                </Mui.Typography>
                              </Mui.Tooltip>
                            </Mui.TableCell>
                            <Mui.TableCell
                              sx={{
                                overflow: "hidden",
                                whiteSpace: "nowrap",
                                maxWidth: 100,
                              }}
                            >
                              <Mui.Tooltip
                                title={item?.user_name}
                                disableHoverListener={!item?.user_name}
                              >
                                <Mui.Typography
                                  sx={{
                                    overflow: "hidden",
                                    whiteSpace: "nowrap",
                                    textOverflow: "ellipsis",
                                  }}
                                >
                                  {item?.user_name}
                                </Mui.Typography>
                              </Mui.Tooltip>
                            </Mui.TableCell>
                            <Mui.TableCell
                              sx={{
                                overflow: "hidden",
                                whiteSpace: "nowrap",
                                maxWidth: 100,
                              }}
                            >
                              <Mui.Typography
                                sx={{
                                  overflow: "hidden",
                                  whiteSpace: "nowrap",
                                  textOverflow: "ellipsis",
                                }}
                              >
                                {item?.employee_id}
                              </Mui.Typography>
                            </Mui.TableCell>
                            <Mui.TableCell
                              sx={{
                                overflow: "hidden",
                                whiteSpace: "nowrap",
                                maxWidth: 100,
                              }}
                            >
                              <Mui.Typography
                                sx={{
                                  overflow: "hidden",
                                  whiteSpace: "nowrap",
                                  textOverflow: "ellipsis",
                                }}
                              >
                                {item?.overtime_start
                                  ? item?.overtime_start
                                  : "N/A"}
                              </Mui.Typography>
                            </Mui.TableCell>
                            <Mui.TableCell
                              sx={{
                                overflow: "hidden",
                                whiteSpace: "nowrap",
                                maxWidth: 100,
                              }}
                            >
                              <Mui.Typography
                                sx={{
                                  overflow: "hidden",
                                  whiteSpace: "nowrap",
                                  textOverflow: "ellipsis",
                                }}
                              >
                                {item?.overtime_end
                                  ? item?.overtime_end
                                  : "N/A"}
                              </Mui.Typography>
                            </Mui.TableCell>
                            <Mui.TableCell
                              sx={{
                                overflow: "hidden",
                                whiteSpace: "nowrap",
                                maxWidth: 100,
                              }}
                            >
                              <Mui.Typography
                                sx={{
                                  overflow: "hidden",
                                  whiteSpace: "nowrap",
                                  textOverflow: "ellipsis",
                                }}
                              >
                                {item?.duration ? item?.duration : "00:00"}
                              </Mui.Typography>
                            </Mui.TableCell>
                          </Mui.TableRow>
                        </>
                      ))}
                  </Mui.TableBody>
                </Mui.Table>
                {otRepDetails?.length > 25 ? (
                  <Mui.TablePagination
                    className="custom-pagination"
                    component="div"
                    rowsPerPageOptions={[25, 50, 75, 100]}
                    count={otRepDetails?.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                ) : null}
              </Mui.TableContainer>
            </Mui.Grid>
          ) : (
            <Mui.Stack sx={{ padding: 0.5 }} spacing={2} fullWidth>
              <Mui.Alert severity="info">
                There is a no available data
              </Mui.Alert>
            </Mui.Stack>
          )}
        </>
      )}
    </React.Fragment>
  );
};

export default OvertimeReport;
