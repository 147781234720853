import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { setGlobalSearchValue } from "services/Redux/userToken";
import { CustomAutoComplete } from "components/CustomComponents/CustomAutoComplete";
import { DateRangePicker } from "./DateRangePicker";
import * as Mui from "@mui/material";
import moment from "moment";
import { useSelector } from "react-redux";
import { useFetch } from "services/hooks/useFetch";
import { themes } from "services/constants";
import { AttendanceStatus } from "./AttendanceStatus";
import { AttendanceTimeLine } from "./AttendanceTimeLine";
import { Reports } from "./Reports";
const keys = ["user"];
export const Attendance = () => {
  const { domain, globalSearchValue, userDetails } = useSelector(
    (state) => state.tokenReducer
  );
  const today = new Date();
  const startOfWeek = new Date(today);
  startOfWeek.setDate(today.getDate() - today.getDay() + 1); // Adjust for Sunday being day 0
  const endOfWeek = new Date(startOfWeek);
  endOfWeek.setDate(startOfWeek.getDate() + 6);
  const formattedStart = startOfWeek.toISOString().split("T")[0];
  const formattedEnd = endOfWeek.toISOString().split("T")[0];
  const [departmentName, setDepartmentName] = useState("All Department");
  const [departmentId, setDepartmentId] = useState("99999");
  const [selectionLabel, setSelectionLabel] = useState("This Week");
  const [startDate, setStartDate] = useState(
    moment(formattedStart).format("DD/MM/YYYY")
  );
  const [endDate, setEndDate] = useState(
    moment(formattedEnd).format("DD/MM/YYYY")
  );
  const [selectedDateRange, setSelectedDateRange] = useState(
    `${moment(formattedStart).format("DD/MM/YYYY")}-${moment(
      formattedEnd
    ).format("DD/MM/YYYY")}`
  );
  const [leavePage, setLeavePage] = useState(0);
  const [attendanceDataDetails, setAttendanceDataDetails] = useState([]);
  const [advanceSalary, setAdvanceSalary] = useState("Status");
  const [selectedComponent, setSelectedComponent] = useState("Status");
  const { result: allDepartmentDetails } = useFetch(
    `${domain}get-department/?user_id=${userDetails?.id}`
  );
  const departmentDetails = [
    {
      id: 99999,
      department_name: "All Department",
    },
    ...allDepartmentDetails,
  ];
  const {
    result: attendanceDetails,
    setLoad: reCallPayScheduleApi,
    loading,
  } = useFetch(
    `${domain}attendance-timeline/?department_id=${departmentId}&user_id=${
      userDetails?.id
    }&start_date=${startDate?.split("/").reverse().join("-")}&end_date=${endDate
      ?.split("/")
      .reverse()
      .join("-")}`
  );

  useEffect(() => {
    if (
      attendanceDetails?.attendance_data?.length !== 0 ||
      attendanceDetails?.attendance_data?.length === 0
    ) {
      const filteredResults = attendanceDetails?.attendance_data?.filter(
        (item) =>
          keys.some((key) =>
            item[key]
              ?.toString()
              .toLowerCase()
              .includes(globalSearchValue.toString().toLowerCase())
          )
      );
      setAttendanceDataDetails(filteredResults);
      setLeavePage(0);
    }
  }, [globalSearchValue, attendanceDetails]);

  const handleDepartmentName = (event, data) => {
    if (data) {
      setDepartmentId(data?.id);
      setDepartmentName(data?.label);
      setTimeout(function () {
        reCallPayScheduleApi(true);
      }, 150);
    } else {
      setDepartmentId("");
      setDepartmentName("");
    }
  };

  const dispatch = useDispatch();

  const handleChange = (event, newAlignment) => {
    setAdvanceSalary(newAlignment);
    setSelectedComponent(newAlignment);
  };

  const renderComponent = () => {
    switch (selectedComponent) {
      case "Status":
        return <AttendanceStatus />;
      case "Time Line":
        return (
          <AttendanceTimeLine
            leavePage={leavePage}
            setLeavePage={setLeavePage}
            attendanceDetails={attendanceDetails}
            attendanceDataDetails={attendanceDataDetails}
            loading={loading}
            reCallPayScheduleApi={reCallPayScheduleApi}
          />
        );
      case "Reports":
        return(
          <Reports />
        )
      default:
        break;
    }
  };

  const handleStartDate = async (startDate, endDate) => {
    setStartDate(startDate);
    setEndDate(endDate);
    setSelectedDateRange(`${startDate} - ${endDate}`);
    setTimeout(function () {
      reCallPayScheduleApi(true);
    }, 150);
  };

  return (
    <React.Fragment>
      <Mui.Grid
        container
        sx={{
          paddingTop: 3,
        }}
      >
        <Mui.Grid xs={selectionLabel === "Custom" ? 7.7 : 7.7}>
          <Mui.Box
            sx={{
              display: "flex",
              alignItems: "center",
              width: 400,
              height: 55,
              position: "relative",
              overflow: "hidden",
            }}
          >
            <Mui.ToggleButtonGroup
              value={advanceSalary}
              exclusive
              onChange={handleChange}
              sx={{
                display: "flex",
                width: "100%",
                height: "100%",
                position: "absolute",
                borderRadius: "30px",
                backgroundColor: themes.lightBrown,
              }}
            >
              <Mui.ToggleButton
                value="Status"
                sx={{
                  flex: 1,
                  textTransform: "capitalize",
                  fontSize: 18,
                  borderRadius: "30px",
                  fontWeight: 600,
                  border: "none",
                  color:
                    advanceSalary === "Status"
                      ? themes.whiteColor
                      : themes.blackColor,
                  backgroundColor:
                    advanceSalary === "Status"
                      ? themes.blackColor
                      : themes.lightBrown,
                  "&.Mui-selected": {
                    backgroundColor: themes.blackColor,
                    color: themes.whiteColor,
                    "&:hover": {
                      backgroundColor: themes.blackColor,
                      color: themes.whiteColor,
                    },
                    borderRadius: "30px",
                    "&.Mui-selected": {
                      borderRadius: "30px",
                    },
                  },
                  "&:hover": {
                    backgroundColor:
                      advanceSalary === "Status"
                        ? themes.blackColor
                        : themes.lightBrown,
                    color:
                      advanceSalary === "Status"
                        ? themes.whiteColor
                        : themes.blackColor,
                  },
                  "&:focus": {
                    backgroundColor:
                      advanceSalary === "Status"
                        ? themes.blackColor
                        : themes.lightBrown,
                    color:
                      advanceSalary === "Status"
                        ? themes.whiteColor
                        : themes.blackColor,
                  },
                }}
                onClick={() => {
                  dispatch(setGlobalSearchValue(""));
                  setSelectedComponent("Status");
                }}
              >
                Status
              </Mui.ToggleButton>
              <Mui.ToggleButton
                onClick={() => {
                  dispatch(setGlobalSearchValue(""));
                  setSelectedComponent("Time Line");
                }}
                value="Time Line"
                sx={{
                  flex: 1,
                  textTransform: "capitalize",
                  fontSize: 18,
                  fontWeight: 600,
                  borderRadius: "30px",
                  border: "none",
                  color:
                    advanceSalary === "Time Line"
                      ? themes.whiteColor
                      : themes.blackColor,
                  backgroundColor:
                    advanceSalary === "Time Line"
                      ? themes.blackColor
                      : themes.lightBrown,
                  "&.Mui-selected": {
                    backgroundColor: themes.blackColor,
                    color: themes.whiteColor,
                    "&:hover": {
                      backgroundColor: themes.blackColor,
                      color: themes.whiteColor,
                    },
                    borderRadius: "30px",
                    "&.Mui-selected": {
                      borderRadius: "30px",
                    },
                  },
                  "&:hover": {
                    backgroundColor:
                      advanceSalary === "Time Line"
                        ? themes.blackColor
                        : themes.lightBrown,
                    color:
                      advanceSalary === "Time Line"
                        ? themes.whiteColor
                        : themes.blackColor,
                  },
                }}
              >
                Time Line
              </Mui.ToggleButton>
              <Mui.ToggleButton
                onClick={() => {
                  dispatch(setGlobalSearchValue(""));
                  setSelectedComponent("Reports");
                }}
                value="Reports"
                sx={{
                  flex: 1,
                  textTransform: "capitalize",
                  fontSize: 18,
                  fontWeight: 600,
                  borderRadius: "30px",
                  border: "none",
                  color:
                    advanceSalary === "Reports"
                      ? themes.whiteColor
                      : themes.blackColor,
                  backgroundColor:
                    advanceSalary === "Reports"
                      ? themes.blackColor
                      : themes.lightBrown,
                  "&.Mui-selected": {
                    backgroundColor: themes.blackColor,
                    color: themes.whiteColor,
                    "&:hover": {
                      backgroundColor: themes.blackColor,
                      color: themes.whiteColor,
                    },
                    borderRadius: "30px",
                    "&.Mui-selected": {
                      borderRadius: "30px",
                    },
                  },
                  "&:hover": {
                    backgroundColor:
                      advanceSalary === "Reports"
                        ? themes.blackColor
                        : themes.lightBrown,
                    color:
                      advanceSalary === "Reports"
                        ? themes.whiteColor
                        : themes.blackColor,
                  },
                }}
              >
                Reports
              </Mui.ToggleButton>
            </Mui.ToggleButtonGroup>
          </Mui.Box>
        </Mui.Grid>

        <Mui.Grid xs={2} sx={{ padding: 0.5 }}>
          {selectedComponent === "Time Line" ? (
            <CustomAutoComplete
              dataList={departmentDetails.map((obj) => ({
                ...obj,
                label: obj.department_name,
              }))}
              selectedValue={departmentName}
              updateDataFunction={handleDepartmentName}
              placeHolder="Please select the department name"
            />
          ) : null}
        </Mui.Grid>

        <Mui.Grid
          xs={selectionLabel === "Custom" ? 2.3 : 2}
          sx={{ padding: 0.5 }}
        >
          {selectedComponent === "Time Line" ? (
            <DateRangePicker
              handleStartDate={handleStartDate}
              selectedDateRange={selectedDateRange}
              setSelectionLabel={setSelectionLabel}
            />
          ) : null}
        </Mui.Grid>
      </Mui.Grid>
      {renderComponent()}
    </React.Fragment>
  );
};
