import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { themes } from "services/constants";
import { CustomButton } from "components/CustomComponents/CustomButton";
import { GlobalDialogBox } from "components/GlobalDialogBox";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import { showToast } from "services/utils/Status";
import { essApplyLeave } from "services/constants";
import { PermissionsDetails } from "./permissionDetails";
import { leaveSuccess } from "services/constants/SuccessMessages";
import { leaveManagamentPlaceholder } from "services/constants/PlaceHolder";
import * as Mui from "@mui/material";
import { useFetch } from "services/hooks/useFetch";
import { tableRowSx } from "services/constants";
import { customAPI } from "services/utils/CustomAPI";
import { customGetAPI } from "services/utils/CustomAPI";
import { Loader } from "components/Loader";

export const PastLeaveDetails = ({ selectedUser }) => {
  const { domain, userDetails, globalSearchValue } = useSelector(
    (state) => state.tokenReducer
  );
  const [taxYearStartDateUser, setTaxYearStartDate] = useState();
  const { result: leaveYears } = useFetch(`${domain}leave-years/`);
  const [expandedRow, setExpandedRow] = useState(null);
  const [taxYearEndDateUser, setTaxYearEndDate] = useState();
  const [taxYearUser, setTaxYearUser] = useState();
  const [leavePage, setLeavePage] = useState(0);
  const [leaverowsPerPage, setLeaveRowsPerPage] = useState(25);
  const [totalPermission, setTotalPermission] = useState(0);
  const [loadPermissionApi, setLoadPersmissionApi] = useState(false);
  const [tagIndicator, setTagIndicator] = useState("Leaves");
  const [cancelConfirmation, setCancelConfirmation] = useState(null);
  const [deleteConfirmation, setDeleteConfirmation] = useState(null);
  const [leaveYear, setLeaveYear] = useState(null);
  const [cancelLeaveData, setCancelLeaveData] = useState({});
  const [buttonLoader, setButtonLoader] = useState(false);
  const [loading, setLoading] = useState(true);

  const [pastLeaveDataSearchResults, setPastLeaveDataSearchResults] =
    useState();

  const [reCallApi, setReCallApi] = useState(false);

  const [employeeLeaveData, setEmployeeLeaveData] = useState(
    pastLeaveDataSearchResults
  );

  useEffect(() => {
    const fectchLeaveDetails = async () => {
      setLoading(true);
      const res = await customGetAPI(
        `${domain}leavesummary/?user_id=${selectedUser?.user_id}&start_date=${taxYearStartDateUser}&end_date=${taxYearEndDateUser}`
      );
      if (res.ok) {
        setPastLeaveDataSearchResults(res.responseDetails);
        setLoading(false);
      } else {
        setLoading(false);
      }
    };
    if (
      taxYearStartDateUser &&
      taxYearEndDateUser &&
      reCallApi &&
      selectedUser?.user_id
    ) {
      fectchLeaveDetails();
      setReCallApi(false);
    }
  }, [
    domain,
    reCallApi,
    selectedUser?.user_id,
    taxYearEndDateUser,
    taxYearStartDateUser,
  ]);

  useEffect(() => {
    if (leaveYears?.length !== 0) {
      setLeaveYear(leaveYears[0]?.name);
      setTaxYearStartDate(
        leaveYears[0]?.start_date.split("-").reverse().join("-")
      );
      setTaxYearEndDate(leaveYears[0]?.end_date.split("-").reverse().join("-"));
      setLeaveYear(
        leaveYears[0]?.start_date + " To " + leaveYears[0]?.end_date
      );
      setReCallApi(true);
      setLoadPersmissionApi(true);
    }
  }, [leaveYears]);

  const handleChangeLeavePage = (event, newPage) => {
    setLeavePage(newPage);
  };

  const handleChangeLeaveRowsPerPage = (event) => {
    setLeaveRowsPerPage(parseInt(event.target.value, 10));
    setLeavePage(0);
  };

  const getStatusColor = (status) => {
    switch (status) {
      case "Completed":
        return "primary";
      case "Approved":
        return "success";
      case "Pending":
        return "warning";
      case "Rejected":
        return "error";
      case "cancel":
        return "error";
      default:
        return "default";
    }
  };

  const handleDeleteConfirmation = (leaveId) => {
    setDeleteConfirmation(leaveId);
  };

  const handleCancelConfirmation = (leaveId) => {
    setCancelConfirmation(leaveId);
  };

  const handleDeleteCancel = (event) => {
    setDeleteConfirmation(null);
  };

  const handleCancelCancel = (event) => {
    setCancelConfirmation(null);
  };

  useEffect(() => {
    const filteredResults =
      pastLeaveDataSearchResults?.leave_count?.leave_requests?.filter((item) =>
        essApplyLeave.some((key) =>
          item[key]
            ?.toString()
            .toLowerCase()
            .includes(globalSearchValue.toString().toLowerCase())
        )
      );
    setEmployeeLeaveData(filteredResults);
    setLeavePage(globalSearchValue ? 0 : leavePage);
  }, [globalSearchValue, leavePage, pastLeaveDataSearchResults]);

  const handleDeleteLeave = async (leaveId) => {
    setButtonLoader(true);
    const response = await customAPI(`${domain}leave_request_delete/`, "PUT", {
      isdeleted: true,
      id: leaveId,
    });
    if (response.ok) {
      setReCallApi(true);
      showToast("success", leaveSuccess.deletedLeaveRequest);
      setButtonLoader(false);
    }
    setButtonLoader(false);
    setDeleteConfirmation(null);
  };

  const handleCancelLeave = async (leaveId) => {
    setButtonLoader(true);
    const response = await customAPI(
      `${domain}create_cancel_leave_request/`,
      "POST",
      {
        original_leave_request_id: leaveId,
        user_id: selectedUser?.user_id,
      }
    );
    if (response.ok) {
      handleCancelCancel();
      setReCallApi(true);
      showToast(
        "success",
        `Your leave request for ${cancelLeaveData.from_date} to ${cancelLeaveData.to_date} has been cancelled successfully`
      );
      setButtonLoader(false);
    }
    setButtonLoader(false);
  };

  const handleRowClick = (rowId) => {
    if (expandedRow === rowId) {
      setExpandedRow(null);
    } else {
      setExpandedRow(rowId);
    }
  };

  return loading ? (
    <Loader info="Loading..." />
  ) : (
    <React.Fragment>
      <Mui.Box
        sx={{
          marginTop: 2,
          boxShadow: 3,
          bgcolor: (theme) =>
            theme.palette.mode === "dark" ? "#101010" : "#fff",
          color: (theme) =>
            theme.palette.mode === "dark" ? "grey.300" : "grey.800",
          border: "1px solid",
          borderColor: (theme) =>
            theme.palette.mode === "dark" ? "grey.800" : "grey.300",
          borderRadius: 2,
          fontSize: "0.875rem",
          fontWeight: "700",
        }}
      >
        <Mui.Grid container sx={{ paddingTop: 4, paddingBottom: 2 }}>
          <Mui.Grid xs={1}>
            <Mui.Avatar
              sx={{
                marginTop: -2,
                width: 80,
                height: 80,
                marginLeft: 5,
                backgroundColor: "#ff4d88",
              }}
              alt={selectedUser?.user_name.charAt(0).toUpperCase()}
              src={selectedUser?.image_url}
            />
          </Mui.Grid>
          <Mui.Grid xs={2}>
            <Mui.Tooltip title={selectedUser?.user_name}>
              <Mui.Typography
                sx={{
                  fontWeight: "bold",
                  marginLeft: 5,
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                }}
              >
                {selectedUser?.user_name}
              </Mui.Typography>
            </Mui.Tooltip>
            <Mui.Tooltip title={selectedUser?.role}>
              <Mui.Typography
                sx={{
                  fontWeight: "bold",
                  color: "#B2B2B5",
                  marginLeft: 5,
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                }}
              >
                {selectedUser?.role}
              </Mui.Typography>
            </Mui.Tooltip>
          </Mui.Grid>
          <Mui.Grid xs={6}></Mui.Grid>
          <Mui.Grid xs={2.5}>
            <Mui.Autocomplete
              disablePortal
              id="combo-Mui.Box-demo"
              size="small"
              value={leaveYear}
              options={leaveYears.map((obj) => ({
                ...obj,
                label: obj.name,
                yearDate: obj.start_date + " To " + obj.end_date,
              }))}
              sx={{ width: "100%" }}
              filterOptions={(options, state) => {
                return options.filter((option) =>
                  option.label
                    .toLowerCase()
                    .includes(state.inputValue.toLowerCase())
                );
              }}
              renderOption={(props, option) => {
                return <li {...props}>{option.label}</li>;
              }}
              onChange={(event, value) => {
                if (value) {
                  setTaxYearUser(value.label);
                  setTaxYearStartDate(
                    value.start_date.split("-").reverse().join("-")
                  );
                  setTaxYearEndDate(
                    value.end_date.split("-").reverse().join("-")
                  );
                  setReCallApi(true);
                  setLoadPersmissionApi(true);
                  setLeaveYear(value.yearDate);
                }
              }}
              ListBoxProps={{
                style: {
                  maxHeight: 150,
                },
              }}
              renderInput={(params) => (
                <Mui.TextField
                  {...params}
                  label={taxYearUser}
                  sx={{ background: themes.whiteColor }}
                  placeholder={leaveManagamentPlaceholder.taxyearLeave}
                  size="small"
                  InputProps={{
                    ...params.InputProps,
                    startAdornment: (
                      <>
                        <CalendarMonthIcon
                          style={{ margin: "0 8px", color: "#a6a6a6" }}
                        />
                        {params.InputProps.startAdornment}
                      </>
                    ),
                  }}
                />
              )}
            />
          </Mui.Grid>
        </Mui.Grid>
        <br />
        <Mui.Grid container sx={{ paddingBottom: 2 }}>
          <Mui.Grid
            xs={6}
            sx={{ textAlign: "center", borderRight: "5px solid black" }}
            onClick={() => setTagIndicator("Leaves")}
          >
            <Mui.Button
              variant="text"
              size="small"
              sx={{ textTransform: "capitalize" }}
            >
              <span
                style={{
                  color: tagIndicator === "Leaves" ? "#3AAFF1" : "#B2B2B5",
                  fontWeight: "bold",
                  fontSize: 20,
                }}
              >
                Leaves
              </span>
            </Mui.Button>
          </Mui.Grid>
          <Mui.Grid
            xs={6}
            sx={{ textAlign: "center" }}
            onClick={() => {
              setTagIndicator("Permissions");
              setLoadPersmissionApi(true);
            }}
          >
            <Mui.Button
              variant="text"
              size="small"
              sx={{ textTransform: "capitalize" }}
            >
              <span
                style={{
                  color: tagIndicator === "Leaves" ? "#B2B2B5" : "#3AAFF1",
                  fontWeight: "bold",
                  fontSize: 20,
                }}
              >
                Permissions{" "}
              </span>
            </Mui.Button>
          </Mui.Grid>
        </Mui.Grid>
      </Mui.Box>
      <Mui.Box
        sx={{
          marginTop: 2,
          boxShadow: 3,
          display: "flex",
          ml: 20,
          mr: 20,
          p: 2,
          bgcolor: (theme) =>
            theme.palette.mode === "dark" ? "#101010" : "#fff",
          color: (theme) =>
            theme.palette.mode === "dark" ? "grey.300" : "grey.800",
          border: "1px solid",
          borderColor: (theme) =>
            theme.palette.mode === "dark" ? "grey.800" : "grey.300",
          borderRadius: 2,
          fontSize: "0.875rem",
          fontWeight: "700",
        }}
      >
        <Mui.Grid container>
          {tagIndicator === "Leaves" ? (
            <>
              <Mui.Grid
                xs={3}
                sx={{ textAlign: "center", borderRight: "5px solid black" }}
              >
                <Mui.Typography>
                  <span
                    style={{
                      color: "#3AAFF1",
                      fontWeight: "bold",
                      fontSize: 20,
                    }}
                  >
                    Total
                  </span>
                  <span
                    style={{
                      color: "#0CB708",
                      fontWeight: "bold",
                      fontSize: 18,
                    }}
                  >
                    &nbsp;&nbsp;&nbsp;&nbsp;{selectedUser?.total_leave || 0}{" "}
                    days
                  </span>
                </Mui.Typography>
              </Mui.Grid>
              <Mui.Grid
                xs={3}
                sx={{ textAlign: "center", borderRight: "5px solid black" }}
              >
                <Mui.Typography>
                  <span
                    style={{
                      color: "#3AAFF1",
                      fontWeight: "bold",
                      fontSize: 20,
                    }}
                  >
                    Planned{" "}
                  </span>{" "}
                  <span
                    style={{
                      color: "#AB1010",
                      fontWeight: "bold",
                      fontSize: 18,
                    }}
                  >
                    &nbsp;&nbsp;&nbsp;&nbsp;{selectedUser?.planned_leave || 0}{" "}
                    days{" "}
                  </span>
                </Mui.Typography>
              </Mui.Grid>
              <Mui.Grid
                xs={3}
                sx={{ textAlign: "center", borderRight: "5px solid black" }}
              >
                <Mui.Typography>
                  <span
                    style={{
                      color: "#3AAFF1",
                      fontWeight: "bold",
                      fontSize: 20,
                    }}
                  >
                    Availed{" "}
                  </span>{" "}
                  <span
                    style={{
                      color: "#AB1010",
                      fontWeight: "bold",
                      fontSize: 18,
                    }}
                  >
                    &nbsp;&nbsp;&nbsp;&nbsp;{selectedUser?.completed_leave || 0}{" "}
                    days{" "}
                  </span>
                </Mui.Typography>
              </Mui.Grid>
              <Mui.Grid xs={3} sx={{ textAlign: "center" }}>
                <Mui.Typography>
                  <span
                    style={{
                      color: "#3AAFF1",
                      fontWeight: "bold",
                      fontSize: 20,
                    }}
                  >
                    Balance{" "}
                  </span>{" "}
                  <span
                    style={{
                      color: "#B79B08",
                      fontWeight: "bold",
                      fontSize: 18,
                    }}
                  >
                    &nbsp;&nbsp;&nbsp;&nbsp;{selectedUser?.available_leave || 0}{" "}
                    days
                  </span>
                </Mui.Typography>
              </Mui.Grid>
            </>
          ) : (
            <>
              <Mui.Grid xs={12} sx={{ textAlign: "center" }}>
                <Mui.Typography>
                  <span
                    style={{
                      color: "#3AAFF1",
                      fontWeight: "bold",
                      fontSize: 20,
                    }}
                  >
                    <span
                      style={{
                        color: "#0CB708",
                        fontWeight: "bold",
                        fontSize: 18,
                      }}
                    >
                      {totalPermission} &nbsp;&nbsp;&nbsp;&nbsp;
                    </span>
                    Permissions Taken
                  </span>
                </Mui.Typography>
              </Mui.Grid>
            </>
          )}
        </Mui.Grid>
      </Mui.Box>

      {tagIndicator === "Leaves" ? (
        <Mui.Grid container sx={{ paddingRight: 1 }}>
          <Mui.TableContainer
            sx={{
              height: "100%",
              overflowY: "auto",
              marginTop: 2,
              marginLeft: "auto",
              marginRight: "auto",
              minWidth: "90%",
              maxWidth: "100%",
            }}
            className="w-96 border  rounded "
          >
            <Mui.Table className="border" sx={{ borderRadius: "20px" }}>
              <Mui.TableHead
                sx={{
                  backgroundColor: themes.primary,
                }}
              >
                <Mui.TableRow sx={tableRowSx}>
                  <Mui.TableCell className="column-cell"></Mui.TableCell>
                  <Mui.TableCell className="column-cell">
                    <Mui.Typography fontWeight={"bold"}>
                      Leave Type
                    </Mui.Typography>
                  </Mui.TableCell>
                  <Mui.TableCell className="column-cell">
                    <Mui.Typography fontWeight={"bold"}>
                      {" "}
                      Start Date
                    </Mui.Typography>
                  </Mui.TableCell>
                  <Mui.TableCell className="column-cell">
                    <Mui.Typography fontWeight={"bold"}>
                      {" "}
                      End Date
                    </Mui.Typography>
                  </Mui.TableCell>
                  <Mui.TableCell className="column-cell">
                    <Mui.Typography fontWeight={"bold"}>
                      {" "}
                      Business days{" "}
                    </Mui.Typography>
                  </Mui.TableCell>
                  <Mui.TableCell className="column-cell">
                    <Mui.Typography fontWeight={"bold"}>Status</Mui.Typography>
                  </Mui.TableCell>
                  {userDetails.id === parseInt(selectedUser?.user_id) ? (
                    <Mui.TableCell className="column-cell">
                      <Mui.Typography fontWeight="bold">Action</Mui.Typography>
                    </Mui.TableCell>
                  ) : null}
                </Mui.TableRow>
              </Mui.TableHead>
              <Mui.TableBody>
                {" "}
                {employeeLeaveData
                  ?.slice(
                    leavePage * leaverowsPerPage,
                    leavePage * leaverowsPerPage + leaverowsPerPage
                  )
                  .map((leave) => (
                    <>
                      <Mui.TableRow key={leave.id} sx={tableRowSx}>
                        <Mui.TableCell className="column-cell">
                          {expandedRow === leave.id ? (
                            <Mui.IconButton
                              onClick={() => {
                                setExpandedRow(null);
                              }}
                            >
                              <KeyboardArrowDownIcon
                                style={{
                                  color: themes.blackColor,
                                  fontWeight: "bold",
                                }}
                              />
                            </Mui.IconButton>
                          ) : (
                            <Mui.IconButton
                              onClick={() => handleRowClick(leave.id)}
                            >
                              <KeyboardArrowRightIcon
                                style={{
                                  color: themes.blackColor,
                                  fontWeight: "bold",
                                }}
                              />
                            </Mui.IconButton>
                          )}
                        </Mui.TableCell>
                        <Mui.TableCell className="column-cell">
                          {leave.leave_type}
                        </Mui.TableCell>
                        <Mui.TableCell className="column-cell">
                          {leave.from_date}
                        </Mui.TableCell>
                        <Mui.TableCell className="column-cell">
                          {leave.to_date}
                        </Mui.TableCell>
                        <Mui.TableCell className="column-cell">
                          <Mui.Typography variant="body1" gutterBottom>
                            {leave.business_days}
                          </Mui.Typography>
                        </Mui.TableCell>
                        <Mui.TableCell className="column-cell">
                          {leave.status}
                        </Mui.TableCell>
                        {userDetails.id === parseInt(selectedUser?.user_id) ? (
                          <Mui.TableCell className="column-cell">
                            <Mui.Button
                              sx={{ borderRadius: 3 }}
                              variant="contained"
                              color={getStatusColor("cancel")}
                              className="cancel-button"
                              onClick={() => {
                                if (leave.action === "cancel") {
                                  setCancelLeaveData(leave);
                                  handleCancelConfirmation(leave.id);
                                } else {
                                  handleDeleteConfirmation(leave.id);
                                }
                              }}
                              disabled={leave.action === null ? true : false}
                            >
                              {leave.action === null ? "Cancel" : leave.action}
                            </Mui.Button>
                          </Mui.TableCell>
                        ) : null}
                      </Mui.TableRow>
                      {expandedRow === leave.id && (
                        <Mui.TableRow sx={tableRowSx}>
                          <Mui.TableCell colSpan={12}>
                            <Mui.TableContainer
                              sx={{
                                marginTop: 0,
                                marginLeft: "auto",
                                marginRight: "auto",
                                width: "100%px",
                              }}
                              className="w-96 border rounded"
                            >
                              <Mui.Table className="border">
                                <Mui.Typography
                                  variant="body1"
                                  gutterBottom
                                  sx={{ marginLeft: 1, marginTop: 1 }}
                                >
                                  <strong>Description :</strong>{" "}
                                  <Mui.Typography
                                    fontSize={15}
                                    color="textSecondary"
                                    sx={{ marginLeft: 13 }}
                                  >
                                    {leave.description}
                                  </Mui.Typography>
                                </Mui.Typography>
                              </Mui.Table>
                            </Mui.TableContainer>
                            {leave.status === "Rejected" ? (
                              <Mui.TableContainer
                                sx={{
                                  marginTop: 0.5,
                                  marginLeft: "auto",
                                  marginRight: "auto",
                                  width: "100%px",
                                }}
                                className="w-96 border rounded"
                              >
                                <Mui.Table className="border">
                                  <Mui.Typography
                                    variant="body1"
                                    gutterBottom
                                    sx={{ marginLeft: 1, marginTop: 1 }}
                                  >
                                    <strong>Reason for Rejection :</strong>{" "}
                                    <Mui.Typography
                                      fontSize={15}
                                      color="textSecondary"
                                      sx={{ marginLeft: 13 }}
                                    >
                                      {leave.reason}
                                    </Mui.Typography>
                                  </Mui.Typography>
                                </Mui.Table>
                              </Mui.TableContainer>
                            ) : null}
                          </Mui.TableCell>
                        </Mui.TableRow>
                      )}
                    </>
                  ))}
              </Mui.TableBody>
            </Mui.Table>
            {employeeLeaveData?.length > 0 ? null : (
              <div style={{ paddingTop: 8 }}>
                <Mui.Alert severity="info">
                  There is no data available
                </Mui.Alert>
              </div>
            )}
            {employeeLeaveData?.length > 25 ? (
              tagIndicator === "Leaves" ? (
                <Mui.TablePagination
                  sx={{ marginRight: 7 }}
                  className="custom-pagination"
                  rowsPerPageOptions={[25, 50, 75, 100]}
                  component="div"
                  count={employeeLeaveData?.length}
                  rowsPerPage={leaverowsPerPage}
                  page={leavePage}
                  onPageChange={handleChangeLeavePage}
                  onRowsPerPageChange={handleChangeLeaveRowsPerPage}
                />
              ) : null
            ) : null}
          </Mui.TableContainer>
        </Mui.Grid>
      ) : (
        <PermissionsDetails
          selectedUser={selectedUser}
          loadPermissionApi={loadPermissionApi}
          setLoadPersmissionApi={setLoadPersmissionApi}
          setTotalPermission={setTotalPermission}
          taxYearStartDateUser={taxYearStartDateUser}
          taxYearEndDateUser={taxYearEndDateUser}
        />
      )}
      <GlobalDialogBox
        open={Boolean(deleteConfirmation)}
        handleCloseDialog={handleDeleteCancel}
        title=" Delete Confirmation"
      >
        <Mui.Grid>
          <Mui.Typography sx={{ fontSize: 21 }}>
            {" "}
            Are you sure you want to delete this leave?
          </Mui.Typography>
        </Mui.Grid>
        <Mui.DialogActions>
          <CustomButton
            actionFuntion={handleDeleteCancel}
            actionName="No"
            typeName="button"
          />
          &nbsp;&nbsp;
          <CustomButton
            actionFuntion={() => {
              handleDeleteLeave(deleteConfirmation);
            }}
            disableAction={
              deleteConfirmation && buttonLoader ? buttonLoader : false
            }
            actionName="Yes"
            typeName="button"
          />
        </Mui.DialogActions>
      </GlobalDialogBox>
      <GlobalDialogBox
        open={Boolean(cancelConfirmation)}
        handleCloseDialog={handleCancelCancel}
        title="Cancel confirmation"
      >
        <Mui.Grid>
          <Mui.Typography sx={{ fontSize: 21 }}>
            {" "}
            Are you sure you want to cancel this leave?
          </Mui.Typography>
        </Mui.Grid>
        <Mui.DialogActions>
          <CustomButton
            actionFuntion={handleCancelCancel}
            actionName="No"
            typeName="button"
          />
          &nbsp;&nbsp;
          <CustomButton
            actionFuntion={() => handleCancelLeave(cancelConfirmation)}
            disableAction={
              cancelConfirmation && buttonLoader ? buttonLoader : false
            }
            actionName="Yes"
            typeName="button"
          />
        </Mui.DialogActions>
      </GlobalDialogBox>
    </React.Fragment>
  );
};
