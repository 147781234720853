import React, { useState, useEffect } from "react";
import * as Mui from "@mui/material";
import { themes } from "services/constants";
import { EditAndSidePeek } from "./EditAndSidePeek";
import { runPayrollGroupHeaders } from "pages/PayRoll/PayRollHeaders";
import { MoreVert, Info } from "@mui/icons-material";
import { useSelector } from "react-redux";
import { useFetch } from "services/hooks/useFetch";
import { KeyboardArrowRight, KeyboardArrowDown } from "@mui/icons-material";
import { GroupEmployee } from "pages/PayRoll/PayRollHeaders";
import { CustomButton } from "components/CustomComponents/CustomButton";
import { CustomTextBox } from "components/CustomComponents/CustomTextBox";
import { useForm } from "react-hook-form";
import { GlobalDialogBox } from "components/GlobalDialogBox";
import { showToast } from "services/utils/Status";
import sessionHandling from "services/utils/notificationUtils";
import { CustomAutoComplete } from "components/CustomComponents/CustomAutoComplete";
import { PaymentMode } from "pages/PayRoll/PayRollHeaders";
import { tableRowSx } from "services/constants";
const groupDataFilterKeys = [
  "group_name",
  "number_of_employees",
  "payment_bank",
  "payment_mode",
  "group_payroll_cost",
  "group_total_deductions",
  "group_gross_pay",
];
export const GroupDetails = ({
  viewPayrunDetails,
  companyId,
  payrunData,
  status,
}) => {
  const { domain, token, globalSearchValue } = useSelector(
    (state) => state.tokenReducer
  );
  const [editUserDetails, setEditUserDetails] = useState([]);
  const [reason, setReason] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorEll, setAnchorEll] = useState(null);
  const [listViewPage, setListViewPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [expandedRow, setExpandedRow] = useState(0);
  const [openDialog, setOpenDialog] = useState(false);
  const [changeGroupDialog, setChangeGroupDialog] = useState(false);
  const [payrollGrpName, setPayrollGrpName] = useState("");
  const [payrollGroupId, setPayrollGroupId] = useState(0);
  const [changeGroupError, setChangeGroupError] = useState(false);
  const [groupEmployee, setGroupEmployee] = useState([]);
  const [openSidePeek, setOpenSidePeek] = useState(false);
  const [bankName, setBankName] = useState("");
  const [paymentModeName, setPaymentModeName] = useState("");
  const [paymentBankId, setPaymentBankId] = useState(0);
  const [updateTransferError, setUpdateTransferError] = useState(false);
  const [openTransferMediumDialogBox, setOpenTransferMediumDialogBox] =
    useState(false);
  const { result: payrollGroupData } = useFetch(
    companyId !== 0 ? `${domain}payroll-group/?company=${companyId}` : null
  );
  const { result: bankDetailsData } = useFetch(
    companyId !== 0 ? `${domain}source-bank/?company=${companyId}` : null
  );
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const { result: payRunGroupDetails, setLoad } = useFetch(
    `${domain}payrun/group/retrieve/?pay_schedule_id=${viewPayrunDetails.id}`
  );
  const [groupData, setGroupData] = useState(payRunGroupDetails);

  const handleMenuClose = () => {
    setAnchorEl(null);
  };
  const handleChangePage = (event, newPage) => {
    setListViewPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setListViewPage(0);
  };

  const handleRowClick = (details) => {
    setExpandedRow(details?.group_id);
    setGroupEmployee(details?.user_details);
  };

  const handleMenuCloseEmployee = () => {
    setAnchorEll(null);
  };

  const updateReasonForSkipSalary = async (event) => {
    setReason(event.target.value);
  };

  const onSubmit = async () => {
    try {
      const response = await fetch(`${domain}payrun/skip-salary/`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `token ${token}`,
        },
        body: JSON.stringify({
          payroll_run_id: viewPayrunDetails.id,
        }),
      });
      const res = await response.json();
      if (response.ok) {
        setLoad(true);
        showToast("error", res.message);
        setOpenDialog(false);
        setReason("");
      } else if (response.status === 409) {
        sessionHandling();
      } else {
        throw new Error(res.error);
      }
    } catch (error) {
      showToast("error", error.message);
    }
  };

  const handlePayrollGrpName = (event, data) => {
    if (data) {
      setPayrollGrpName(data?.label);
      setPayrollGroupId(data?.id);
    } else {
      setPayrollGrpName("");
      setPayrollGroupId(0);
    }
  };

  const changeGroupForUser = () => {
    if (payrollGrpName !== "") {
      console.log(payrollGroupId);
    } else {
      setChangeGroupError(true);
    }
  };

  const handleBankName = (event, data) => {
    if (data) {
      setBankName(data?.label);
      setPaymentBankId(data?.id);
    } else {
      setBankName("");
      setPaymentBankId(0);
    }
  };

  const handlePaymentMode = (event, data) => {
    if (data) {
      setPaymentModeName(data.label);
    } else {
      setPaymentModeName("");
    }
  };

  const changeTranferMedium = async () => {
    if (paymentModeName !== "" && bankName !== "") {
      console.log(paymentBankId);
      setUpdateTransferError(false);
      setOpenTransferMediumDialogBox(false);
    } else {
      setUpdateTransferError(true);
    }
  };

  useEffect(() => {
    const filteredResults = payRunGroupDetails?.group_details?.filter((item) =>
      groupDataFilterKeys?.some((key) =>
        item[key]
          ?.toString()
          .toLowerCase()
          .includes(globalSearchValue.toString().toLowerCase())
      )
    );
    setGroupData(filteredResults);
  }, [globalSearchValue, payRunGroupDetails]);

  return (
    <React.Fragment>
      <Mui.TableContainer
        component={Mui.Paper}
        sx={{ mt: 2, width: "100%", maxHeight: 850 }}
      >
        <Mui.Table>
          <Mui.TableHead
            sx={{
              backgroundColor: themes.primary,
              position: "sticky",
              top: -1,
              zIndex: 10,
            }}
          >
            <Mui.TableRow sx={tableRowSx}>
              <Mui.TableCell></Mui.TableCell>
              {runPayrollGroupHeaders.map((header) => (
                <Mui.TableCell
                  sx={{ color: themes.blackColor, fontWeight: "bold" }}
                >
                  {header.name}
                </Mui.TableCell>
              ))}
              <Mui.TableCell
                sx={{ color: themes.blackColor, fontWeight: "bold" }}
              >
                Action
              </Mui.TableCell>
            </Mui.TableRow>
          </Mui.TableHead>
          <Mui.TableBody>
            {groupData?.length === 0 ? (
              <Mui.TableRow sx={tableRowSx}>
                <Mui.TableCell colSpan={12} align="center">
                  <Mui.Alert severity="info">No data available</Mui.Alert>
                </Mui.TableCell>
              </Mui.TableRow>
            ) : (
              groupData
                ?.slice(
                  listViewPage * rowsPerPage,
                  listViewPage * rowsPerPage + rowsPerPage
                )
                .map((group_details) => (
                  <>
                    <Mui.TableRow sx={tableRowSx}>
                      <Mui.TableCell>
                        <Mui.IconButton
                          onClick={() => {
                            if (expandedRow === group_details.group_id) {
                              setExpandedRow(0);
                            } else {
                              handleRowClick(group_details);
                            }
                          }}
                        >
                          {group_details?.group_id === expandedRow ? (
                            <KeyboardArrowDown />
                          ) : (
                            <KeyboardArrowRight />
                          )}
                        </Mui.IconButton>
                      </Mui.TableCell>
                      {runPayrollGroupHeaders.map((header) => (
                        <Mui.TableCell sx={{ color: themes.blackColor }}>
                          <Mui.Tooltip title={group_details[header.key]}>
                            {group_details[header.key] ||
                            group_details[header.key] === 0
                              ? header.key === "group_name"
                                ? group_details[header.key]?.substr(0, 32) +
                                  "..."
                                : group_details[header.key]
                              : "N/A"}
                          </Mui.Tooltip>
                        </Mui.TableCell>
                      ))}
                      <Mui.TableCell>
                        <Mui.IconButton
                          onClick={(e) => {
                            setAnchorEl(e?.currentTarget);
                            setBankName(group_details?.payment_bank);
                            setPaymentModeName(group_details?.payment_mode);
                          }}
                        >
                          <MoreVert />
                        </Mui.IconButton>
                        <Mui.Menu
                          anchorEl={anchorEl}
                          open={Boolean(anchorEl)}
                          onClose={handleMenuClose}
                          elevation={1}
                          transformOrigin={{
                            horizontal: "right",
                            vertical: "top",
                          }}
                          anchorOrigin={{
                            horizontal: "right",
                            vertical: "bottom",
                          }}
                        >
                          <Mui.MenuItem
                            sx={{ width: "100%" }}
                            onClick={() => {
                              setAnchorEl(null);
                              setOpenTransferMediumDialogBox(true);
                            }}
                          >
                            Update Transfer Medium
                          </Mui.MenuItem>
                        </Mui.Menu>
                      </Mui.TableCell>
                    </Mui.TableRow>
                    {group_details?.group_id === expandedRow && (
                      <Mui.TableRow sx={tableRowSx}>
                        <Mui.TableCell colSpan={12}>
                          <Mui.TableContainer
                            sx={{
                              marginTop: 0,
                              marginLeft: "auto",
                              marginRight: "auto",
                              width: "100%px",
                            }}
                            className="w-96 border rounded"
                          >
                            <Mui.Table className="border">
                              <Mui.TableHead
                                sx={{ backgroundColor: themes.primary }}
                              >
                                {GroupEmployee.map((header) => (
                                  <Mui.TableCell
                                    sx={{
                                      color: themes.blackColor,
                                      fontWeight: "bold",
                                    }}
                                  >
                                    {header.name}
                                  </Mui.TableCell>
                                ))}
                                <Mui.TableCell
                                  sx={{
                                    color: themes.blackColor,
                                    fontWeight: "bold",
                                  }}
                                >
                                  Actions
                                </Mui.TableCell>
                              </Mui.TableHead>
                              <Mui.TableBody>
                                {groupEmployee?.length !== 0 ? (
                                  // .slice(
                                  //     listViewPage * rowsPerPage,
                                  //     listViewPage * rowsPerPage + rowsPerPage
                                  //   )
                                  groupEmployee?.map(
                                    (group_employee_details) => (
                                      <Mui.TableRow sx={tableRowSx}>
                                        {GroupEmployee.map((header) => (
                                          <Mui.TableCell
                                            sx={{ color: themes.blackColor }}
                                          >
                                            {group_employee_details[
                                              header.key
                                            ] ||
                                            group_employee_details[
                                              header.key
                                            ] === 0
                                              ? group_employee_details[
                                                  header.key
                                                ]
                                              : "N/A"}
                                          </Mui.TableCell>
                                        ))}
                                        <Mui.TableCell>
                                          <Mui.IconButton
                                            onClick={(e) => {
                                              setAnchorEll(e.currentTarget);
                                              setEditUserDetails(
                                                group_employee_details
                                              );
                                            }}
                                          >
                                            <MoreVert />
                                          </Mui.IconButton>
                                          <Mui.Menu
                                            anchorEl={anchorEll}
                                            open={Boolean(anchorEll)}
                                            onClose={handleMenuCloseEmployee}
                                            elevation={1}
                                            transformOrigin={{
                                              horizontal: "right",
                                              vertical: "top",
                                            }}
                                            anchorOrigin={{
                                              horizontal: "right",
                                              vertical: "bottom",
                                            }}
                                          >
                                            {/* <Mui.MenuItem
                                              sx={{ width: "100%" }}
                                              onClick={() => {
                                                setAnchorEll(null);
                                                setOpenDialog(true);
                                              }}
                                            >
                                              Skip from this payroll
                                            </Mui.MenuItem> */}
                                            <Mui.MenuItem
                                              sx={{ width: "100%" }}
                                              onClick={() => {
                                                setAnchorEll(null);
                                                setOpenSidePeek(true);
                                              }}
                                            >
                                              View payment details
                                            </Mui.MenuItem>
                                            {/* <Mui.MenuItem
                                              sx={{ width: "100%" }}
                                              onClick={() => {
                                                setAnchorEll(null);
                                                setChangeGroupDialog(true);
                                              }}
                                            >
                                              Change Group
                                            </Mui.MenuItem> */}
                                          </Mui.Menu>
                                        </Mui.TableCell>
                                      </Mui.TableRow>
                                    )
                                  )
                                ) : (
                                  <Mui.TableRow sx={tableRowSx}>
                                    <Mui.TableCell
                                      colSpan={12}
                                      sx={{ padding: 0.5 }}
                                    >
                                      <Mui.Alert severity="info">
                                        No data available
                                      </Mui.Alert>
                                    </Mui.TableCell>
                                  </Mui.TableRow>
                                )}
                              </Mui.TableBody>
                            </Mui.Table>
                          </Mui.TableContainer>
                        </Mui.TableCell>
                      </Mui.TableRow>
                    )}
                  </>
                ))
            )}
          </Mui.TableBody>
        </Mui.Table>
        {groupData?.length > 25 ? (
          <Mui.TablePagination
            className="custom-pagination"
            component="div"
            rowsPerPageOptions={[25, 50, 75, 100]}
            count={groupData?.length}
            rowsPerPage={rowsPerPage}
            page={listViewPage}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        ) : null}
      </Mui.TableContainer>
      <GlobalDialogBox
        handleCloseDialog={() => {
          setOpenDialog(false);
          setReason("");
        }}
        open={openDialog}
        title="Skip Employee"
      >
        <form
          onSubmit={handleSubmit(onSubmit)}
          style={{ paddingLeft: 10, paddingRight: 10 }}
        >
          <Mui.Grid container>
            <Mui.Grid
              container
              sx={{ padding: 2, backgroundColor: themes.formBackground }}
            >
              <Mui.Grid xs={2} sx={{ paddingTop: 1 }}>
                <Info sx={{ color: themes.redColor, fontSize: 40 }} />
              </Mui.Grid>
              <Mui.Grid xs={10}>
                <Mui.Typography>
                  Once you remove this employee from this payroll cycle, you
                  won’t be able to add them back or issue payment for this
                  period. Do you wish to proceed?
                </Mui.Typography>
              </Mui.Grid>
            </Mui.Grid>
            <Mui.Grid container>
              <Mui.Grid xs={5}>
                <Mui.Typography sx={{ paddingBottom: 0.5 }}>
                  Employee
                </Mui.Typography>
                <Mui.Grid container>
                  <Mui.Grid xs={3}>
                    <Mui.Avatar
                      sx={{ width: 40, height: 40 }}
                      alt="Remy Sharp"
                      src={editUserDetails.image_url}
                    />
                  </Mui.Grid>
                  <Mui.Grid xs={9}>
                    <Mui.Typography sx={{ color: themes.headLine }}>
                      {editUserDetails.user_name}
                    </Mui.Typography>
                    <Mui.Typography sx={{ color: themes.headLine }}>
                      {editUserDetails.employee_id}
                    </Mui.Typography>
                  </Mui.Grid>
                </Mui.Grid>
              </Mui.Grid>
              <Mui.Grid xs={2}></Mui.Grid>
              <Mui.Grid xs={5}>
                <Mui.Typography sx={{ paddingBottom: 0.5 }}>
                  Payroll Period
                </Mui.Typography>
                <Mui.Typography>
                  {viewPayrunDetails.start_date +
                    "-" +
                    viewPayrunDetails.end_date}
                </Mui.Typography>
              </Mui.Grid>
            </Mui.Grid>
            <Mui.Grid container sx={{ paddingTop: 2 }}>
              <Mui.Typography>Reason for skip</Mui.Typography>
              <CustomTextBox
                type="multiline"
                selectedValue={reason}
                rules={{
                  ...register("skip_reason", {
                    required: false,
                  }),
                }}
                updateDataFunction={updateReasonForSkipSalary}
                errorMessage={
                  errors?.skip_reason?.message
                    ? errors?.skip_reason?.message
                    : ""
                }
                textBoxError={errors.skip_reason ? true : false}
              />
            </Mui.Grid>
            <Mui.Grid
              xs={12}
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
                alignItems: "flex-end",
                paddingTop: 2,
              }}
            >
              <CustomButton
                actionFuntion={() => {
                  setOpenDialog(false);
                  setReason("");
                }}
                actionName="No"
                typeName="button"
              />
              &nbsp;&nbsp;
              <CustomButton actionName="Yes" typeName="submit" />
            </Mui.Grid>
          </Mui.Grid>
        </form>
      </GlobalDialogBox>
      {changeGroupDialog ? (
        <GlobalDialogBox
          handleCloseDialog={() => {
            setChangeGroupDialog(false);
            setChangeGroupError(false);
            setPayrollGrpName("");
            setPayrollGroupId(0);
          }}
          open={changeGroupDialog}
          title="Change Group"
        >
          <Mui.Grid container>
            <Mui.Grid container sx={{ padding: 2 }}>
              <Mui.Grid xs={4} sx={{ paddingTop: 1 }}>
                <Mui.Typography sx={{ fontSize: 18, fontWeight: "bold" }}>
                  Current Group
                </Mui.Typography>
              </Mui.Grid>
              <Mui.Grid xs={8} sx={{ paddingTop: 1 }}>
                <Mui.Typography>{editUserDetails?.group_name}</Mui.Typography>
              </Mui.Grid>
            </Mui.Grid>
            <Mui.Grid container sx={{ padding: 1, paddingBottom: 5 }}>
              <Mui.Grid xs={4} sx={{ paddingTop: 1 }}>
                <Mui.Typography sx={{ fontSize: 18, fontWeight: "bold" }}>
                  New Group
                </Mui.Typography>
              </Mui.Grid>
              <Mui.Grid xs={8} sx={{ paddingTop: 1 }}>
                <CustomAutoComplete
                  dataList={payrollGroupData.map((obj) => ({
                    ...obj,
                    label: obj.name,
                  }))}
                  selectedValue={payrollGrpName}
                  updateDataFunction={handlePayrollGrpName}
                  placeHolder="Please select the payroll group"
                  errorMessage={
                    changeGroupError && payrollGrpName === ""
                      ? "Please select payroll group"
                      : ""
                  }
                  textBoxError={
                    changeGroupError && payrollGrpName === "" ? true : false
                  }
                />
              </Mui.Grid>
            </Mui.Grid>
            <Mui.Grid
              xs={12}
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
                alignItems: "flex-end",
                paddingTop: 2,
              }}
            >
              <CustomButton
                actionFuntion={() => {
                  setChangeGroupDialog(false);
                  setPayrollGrpName("");
                  setPayrollGroupId(0);
                }}
                actionName="Cancel"
                typeName="button"
              />
              &nbsp;&nbsp;
              <CustomButton
                actionFuntion={() => {
                  changeGroupForUser();
                }}
                actionName="Save"
                typeName="button"
              />
            </Mui.Grid>
          </Mui.Grid>
        </GlobalDialogBox>
      ) : null}

      {openTransferMediumDialogBox ? (
        <GlobalDialogBox
          handleCloseDialog={() => {
            setOpenTransferMediumDialogBox(false);
          }}
          open={openTransferMediumDialogBox}
          title="Update Transfer Medium"
        >
          <Mui.Grid container>
            <Mui.Grid container sx={{ padding: 1 }}>
              <Mui.Grid xs={4} sx={{ paddingTop: 1 }}>
                <Mui.Typography sx={{ fontSize: 18, fontWeight: "bold" }}>
                  Payment Bank
                </Mui.Typography>
              </Mui.Grid>
              <Mui.Grid xs={8} sx={{ paddingTop: 1 }}>
                <CustomAutoComplete
                  dataList={bankDetailsData.map((obj) => ({
                    ...obj,
                    label: obj.bank_name,
                  }))}
                  selectedValue={bankName}
                  rules={{
                    ...register("banck_name", {
                      required: "Source bank is required",
                    }),
                  }}
                  updateDataFunction={handleBankName}
                  placeHolder="Select payment bank"
                  errorMessage={
                    errors?.banck_name?.message
                      ? errors?.banck_name?.message
                      : ""
                  }
                  textBoxError={errors?.banck_name ? true : false}
                />
                {bankName === "" && updateTransferError ? (
                  <h5 style={{ color: themes.helperTextColor }}>
                    Payment bank is required
                  </h5>
                ) : null}
              </Mui.Grid>
            </Mui.Grid>
            <Mui.Grid container sx={{ padding: 1, paddingBottom: 7 }}>
              <Mui.Grid xs={4} sx={{ paddingTop: 1 }}>
                <Mui.Typography sx={{ fontSize: 18, fontWeight: "bold" }}>
                  Payment Mode
                </Mui.Typography>
              </Mui.Grid>
              <Mui.Grid xs={8} sx={{ paddingTop: 1 }}>
                <CustomAutoComplete
                  dataList={PaymentMode}
                  rules={{
                    ...register("mode_of_payment", {
                      required: "Payment mode is required",
                    }),
                  }}
                  selectedValue={paymentModeName}
                  updateDataFunction={handlePaymentMode}
                  placeHolder="Select pay cycle"
                  errorMessage={
                    errors?.mode_of_payment?.message
                      ? errors?.mode_of_payment?.message
                      : ""
                  }
                  textBoxError={errors?.mode_of_payment ? true : false}
                />
                {paymentModeName === "" && updateTransferError ? (
                  <h5 style={{ color: themes.helperTextColor }}>
                    Payment mode is required
                  </h5>
                ) : null}
              </Mui.Grid>
            </Mui.Grid>
            <Mui.Grid
              xs={12}
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
                alignItems: "flex-end",
                paddingTop: 3,
              }}
            >
              <CustomButton
                actionFuntion={() => {
                  setOpenTransferMediumDialogBox(false);
                }}
                actionName="Cancel"
                typeName="button"
              />
              &nbsp;&nbsp;
              <CustomButton
                actionFuntion={() => {
                  changeTranferMedium();
                }}
                actionName="Update"
                typeName="button"
              />
            </Mui.Grid>
          </Mui.Grid>
        </GlobalDialogBox>
      ) : null}

      {openSidePeek ? (
        <EditAndSidePeek
          openSidePeek={openSidePeek}
          setOpenSidePeek={setOpenSidePeek}
          editUserDetails={editUserDetails}
          companyId={companyId}
          reloadApi={setLoad}
          viewPayrunDetails={viewPayrunDetails}
          payrunData={payrunData}
          status={status}
        />
      ) : null}
    </React.Fragment>
  );
};
