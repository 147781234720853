import React, { useEffect, useState, useCallback } from "react";
import * as Mui from "@mui/material";
import { themes } from "services/constants";
import { CustomTextBox } from "components/CustomComponents/CustomTextBox";
import { useForm } from "react-hook-form";
import { onlyNumberRegex } from "services/constants/patterns";
import { DeductionDetails } from "./Deduction";
import { EarningDetails } from "./Earning";
import { atleastOneCharacterWithSpace } from "services/constants/patterns";
import { CustomAutoComplete } from "components/CustomComponents/CustomAutoComplete";
import { useSelector } from "react-redux";
import { useFetch } from "services/hooks/useFetch";
import { CustomButton } from "components/CustomComponents/CustomButton";
import sessionHandling from "services/utils/notificationUtils";
import { showToast } from "services/utils/Status";
export const AddNewSalaryGroup = ({
  companyId,
  setAddNewSalaryTemplate,
  setCompanyFilter,
  companyFilter,
  reCallApi,
}) => {
  const {
    register,
    reset,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();
  const { domain, token } = useSelector((state) => state.tokenReducer);
  const [salaryTypeName, setSalaryTypeName] = useState("");
  const [templateName, setTemplateName] = useState("");
  const [earningDetailsData, setEarningDetailsData] = useState([]);
  const [userDeductionDetails, setUserDeductionDetails] = useState([]);
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [emptyEarning, setEmptyEarning] = useState(false);
  const [emptyDeduction, setEmptyDeduction] = useState(false);
  const [amount, setAmount] = useState("");
  const [monthlySalary, setMonthlySalary] = useState(0);
  const [updateDeductionDetails, setUpdateDeductionDetails] = useState(true);
  const [updateEarning, setupdateEarning] = useState(true);
  const [specialAllowance, setSpecialAllowance] = useState(0);
  const [earningDetailsUpdate, setEarningDetailsUpdate] = useState(false);
  const { result: salaryType, setLoad: reCallTypeApi } = useFetch(
    companyId !== 0 ? `${domain}salarytype/` : null
  );

  useEffect(() => {
    if (companyFilter) {
      reCallTypeApi(true);
      setCompanyFilter(false);
    }
  }, [companyFilter, reCallTypeApi, setCompanyFilter]);

  const createSalaryTemplate = async (data) => {
    const zeroAmoutEarnings = earningDetailsData.filter((data) => {
      return (
        data?.amount === 0 ||
        data?.amount === "0" ||
        data.amount === "" ||
        data.amount < 0
      );
    });
    const zeroAmoutDeductions = userDeductionDetails.filter((data) => {
      return (
        data.deduction_type !== "F" &&
        data.employeeContributionAmount === 0 &&
        data.companyContributionAmount === 0
      );
    });
    setButtonDisabled(true);
    if (zeroAmoutEarnings.length === 0 && zeroAmoutDeductions.length === 0) {
      try {
        const response = await fetch(`${domain}salary-templates/`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `token ${token}`,
          },
          body: JSON.stringify({
            name: data.salary_template_name,
            salary_type: data.salary_type_id,
            salary_type_name: data.salary_type,
            amount: data.amount,
            earnings: earningDetailsData,
            deductions: userDeductionDetails?.filter((data) => {
              return data.name !== "";
            }),
            company: companyId,
          }),
        });
        const res = await response.json();
        if (response.ok) {
          setAddNewSalaryTemplate(false);
          reCallApi(true);
          reset();
          showToast("success", "Salary template added successfully");
        } else if (response.status === 409) {
          sessionHandling();
        } else {
          throw new Error(res.error);
        }
      } catch (error) {
        showToast("error", error.message);
      } finally {
        setButtonDisabled(false);
      }
    } else {
      setEmptyEarning(true);
      setEmptyDeduction(true);
      setButtonDisabled(false);
    }
  };

  const updateSalaryGroupName = (event) => {
    if (event.target.value.length < 32) {
      setValue("salary_template_name", event.target.value);
      setTemplateName(event.target.value);
    }
  };

  const handleSalaryType = (event, data) => {
    if (data) {
      setSalaryTypeName(data.label);
      setValue("salary_type_id", data.id);
      setValue("salary_type", data.label);
      setAmount(0);
      setValue("amount", 0);
      if (earningDetailsData.length !== 0) {
        let updateData = earningDetailsData?.map((element) => {
          return {
            ...element,
            amount: 0,
          };
        });
        setEarningDetailsData(updateData);
        setUserDeductionDetails([]);
      }
    }
  };

  const checkSalaryTemplateName = useCallback(
    async (existsName) => {
      try {
        const response = await fetch(
          `${domain}check_salary_template_name/?salary_template_name=${existsName}`,
          {
            method: "GET",
            headers: {
              "content-type": "application/json",
              Authorization: `token ${token}`,
            },
          }
        );
        const res = await response.json();
        if (response.ok) {
          if (res?.exists) {
            return "That salary template name is already taken.Please choose a unique name.";
          } else if (
            atleastOneCharacterWithSpace.test(existsName) ||
            existsName === ""
          ) {
            return true;
          } else {
            return "Atleast one alphabet required";
          }
        } else if (response.status === 409) {
          sessionHandling();
        } else {
          throw new Error(res.error);
        }
      } catch (error) {
        showToast("error", error.message);
      }
    },
    [domain, token]
  );

  const handleAmount = (event) => {
    if (onlyNumberRegex.test(event.target.value)) {
      let salary = parseInt(event.target.value);
      setAmount(event.target.value);
      setValue("amount", event.target.value);
      setMonthlySalary(salary);
      // if (salaryTypeName === "Daily") {
      //   salary = parseInt(event.target.value) * 30;
      //   setMonthlySalary(salary);
      // } else if (salaryTypeName === "Weekly") {
      //   salary = parseInt(event.target.value) * 4;
      //   setMonthlySalary(parseInt(event.target.value) * 4);
      // } else if (salaryTypeName === "Hourly") {
      //   salary = parseInt(event.target.value) * 30 * 8;
      //   setMonthlySalary(salary);
      // } else {
      //   salary = parseInt(event.target.value);
      //   setMonthlySalary(parseInt(event.target.value));
      // }
      if (earningDetailsData.length !== 0) {
        let updateData = earningDetailsData?.map((element) => {
          return {
            ...element,
            amount:
              element.name === "Special Allowance" ? (salary ? salary : 0) : 0,
          };
        });
        setEarningDetailsData(updateData);
        setUserDeductionDetails([]);
      }
    }
  };

  return (
    <form onSubmit={handleSubmit(createSalaryTemplate)}>
      <React.Fragment>
        <Mui.Grid container sx={{ paddingLeft: 5, paddingRight: 5 }}>
          <Mui.Grid
            container
            component={Mui.Paper}
            elevation={2}
            sx={{
              borderTopRightRadius: 10,
              borderTopLeftRadius: 10,
              border: `2px solid ${themes.sidebarDivider}`,
            }}
          >
            <Mui.Grid
              xs={12}
              sx={{
                backgroundColor: themes.primary,
                padding: 1,
                borderTopRightRadius: 10,
                borderTopLeftRadius: 10,
                 borderBottom: `2px solid ${themes.sidebarDivider}`,
              }}
            >
              <Mui.Typography
                sx={{ fontSize: 19, fontWeight: "bold", paddingLeft: 3.5 }}
              >
                Create Salary Template
              </Mui.Typography>
            </Mui.Grid>

            <Mui.Grid container sx={{ padding: 2, paddingLeft: 5 }}>
              <Mui.Grid item xs={2}>
                <Mui.Typography
                  sx={{
                    fontSize: 18,
                    fontWeight: 700,
                  }}
                >
                  Salary Template Name
                </Mui.Typography>
              </Mui.Grid>
              <Mui.Grid xs={0.5}></Mui.Grid>
              <Mui.Grid xs={6}>
                <CustomTextBox
                  selectedValue={templateName}
                  rules={{
                    ...register("salary_template_name", {
                      required: "Salary template name is required",
                      validate: {
                        existsName: async (fieldValue) => {
                          if (fieldValue !== "") {
                            return await checkSalaryTemplateName(
                              fieldValue.trim()
                            );
                          } else {
                            return true;
                          }
                        },
                      },
                    }),
                  }}
                  updateDataFunction={updateSalaryGroupName}
                  placeHolder="Enter salary template name"
                  errorMessage={
                    errors?.salary_template_name?.message
                      ? errors?.salary_template_name?.message
                      : ""
                  }
                  textBoxError={errors.salary_template_name ? true : false}
                />
              </Mui.Grid>
            </Mui.Grid>
            <Mui.Grid container sx={{ padding: 2, paddingLeft: 5 }}>
              <Mui.Grid item xs={2}>
                <Mui.Typography
                  sx={{
                    fontSize: 18,
                    fontWeight: 700,
                  }}
                >
                  Salary Type
                </Mui.Typography>
              </Mui.Grid>
              <Mui.Grid xs={0.5}></Mui.Grid>
              <Mui.Grid xs={6}>
                <CustomAutoComplete
                  dataList={salaryType.map((obj) => ({
                    ...obj,
                    label: obj.salary_type,
                  }))}
                  selectedValue={salaryTypeName}
                  rules={{
                    ...register("salary_type", {
                      required: "Salary type name is required",
                    }),
                  }}
                  updateDataFunction={handleSalaryType}
                  placeHolder="Select the salary type"
                  errorMessage={
                    errors?.salary_type?.message
                      ? errors?.salary_type?.message
                      : ""
                  }
                  textBoxError={errors?.salary_type ? true : false}
                />
              </Mui.Grid>
            </Mui.Grid>
            <Mui.Grid container sx={{ padding: 2, paddingLeft: 5 }}>
              <Mui.Grid item xs={2}>
                <Mui.Typography
                  sx={{
                    fontSize: 18,
                    fontWeight: 700,
                  }}
                >
                  Amount
                </Mui.Typography>
              </Mui.Grid>
              <Mui.Grid xs={0.5}></Mui.Grid>
              <Mui.Grid xs={6}>
                <CustomTextBox
                  selectedValue={amount}
                  rules={{
                    ...register("amount", {
                      required: "Amount is required",
                    }),
                  }}
                  updateDataFunction={handleAmount}
                  placeHolder="Enter amount"
                  errorMessage={
                    errors?.amount?.message ? errors?.amount?.message : ""
                  }
                  textBoxError={errors.amount ? true : false}
                />
              </Mui.Grid>
            </Mui.Grid>
          </Mui.Grid>
        </Mui.Grid>
        <Mui.Grid
          container
          sx={{
            paddingLeft: 5,
            paddingRight: 5,
            paddingTop: 3,
          }}
        >
          <Mui.Grid
            xs={12}
            sx={{
              backgroundColor: themes.primary,
              padding: 1,
              borderTopRightRadius: 10,
              borderTopLeftRadius: 10,
              border: `2px solid ${themes.sidebarDivider}`,
            }}
          >
            <Mui.Stack direction="row">
              <Mui.Typography
                sx={{ fontSize: 18, fontWeight: "bold", paddingLeft: 4 }}
              >
                Salary Components
              </Mui.Typography>{" "}
            </Mui.Stack>
          </Mui.Grid>
        </Mui.Grid>
        <Mui.Grid container sx={{ paddingLeft: 5, paddingRight: 5 }}>
          <Mui.Grid
            container
            sx={{
              paddingRight: 5,
              paddingLeft: 5,
              border: `2px solid ${themes.sidebarDivider}`,
            }}
            component={Mui.Paper}
          >
            <EarningDetails
              companyId={companyId}
              earningDetailsData={earningDetailsData}
              setEarningDetailsData={setEarningDetailsData}
              emptyEarning={emptyEarning}
              setUserDeductionDetails={setUserDeductionDetails}
              setUpdateDeductionDetails={setUpdateDeductionDetails}
              setEmptyEarning={setEmptyEarning}
              monthlySalary={monthlySalary}
              setSpecialAllowance={setSpecialAllowance}
              specialAllowance={specialAllowance}
              updateEarning={updateEarning}
              setupdateEarning={setupdateEarning}
              setEarningDetailsUpdate={setEarningDetailsUpdate}
              userDeductionDetails={userDeductionDetails}
            />
            <DeductionDetails
              companyId={companyId}
              userDeductionDetails={userDeductionDetails}
              setUserDeductionDetails={setUserDeductionDetails}
              updateDeductionDetails={updateDeductionDetails}
              setUpdateDeductionDetails={setUpdateDeductionDetails}
              earningDetailsData={earningDetailsData}
              emptyDeduction={emptyDeduction}
              earningDetailsUpdate={earningDetailsUpdate}
              setEarningDetailsUpdate={setEarningDetailsUpdate}
            />
          </Mui.Grid>
        </Mui.Grid>
        <Mui.Grid
          container
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
            alignItems: "flex-end",
            paddingRight: 3,
            paddingBottom: 2,
            paddingTop: 2,
          }}
        >
          <CustomButton
            actionFuntion={() => setAddNewSalaryTemplate(false)}
            actionName="Cancel"
            typeName="button"
          />
          &nbsp; &nbsp;
          <CustomButton
            actionName="Add"
            typeName="submit"
            disableAction={buttonDisabled}
          />
        </Mui.Grid>
      </React.Fragment>
    </form>
  );
};
