import React, { useState, useEffect } from "react";
import * as Mui from "@mui/material";
import { themes } from "services/constants";
import { useSelector } from "react-redux";
import { showToast } from "services/utils/Status";
import { Loader } from "components/Loader";
import sessionHandling from "services/utils/notificationUtils";
import { RemoveRedEye } from "@mui/icons-material";
import PayslipData from "./PayslipData";
import { PDFViewer } from "@react-pdf/renderer";

const PayslipList = () => {
  const { domain, token, userDetails, globalSearchValue } = useSelector(
    (state) => state.tokenReducer
  );
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [payslipsList, setPayslipsList] = useState([]);
  const [pslipOpenDialog, setPslipOpenDialog] = useState(false);
  const [indPayslipData, setIndPayslipData] = useState("");
  const [masterDataForSearch, setMasterDataForSearch] = useState([]);

  useEffect(() => {
    const fetchUserPayslipDetails = async () => {
      try {
        setIsLoading(true);

        const response = await fetch(
          `${domain}payrun/payslip/employees/?user_id=${userDetails?.id}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `token ${token}`,
            },
          }
        );
        const res = await response.json();
        if (response.ok) {
          setPayslipsList(res);
          setMasterDataForSearch(res);
        } else if (response.status === 409) {
          setPayslipsList([]);
          setMasterDataForSearch([]);
          sessionHandling();
        } else {
          setPayslipsList([]);
          setMasterDataForSearch([]);
          throw new Error(res.error);
        }
      } catch (error) {
        setPayslipsList([]);
        setMasterDataForSearch([]);
        showToast("error", error.message);
      } finally {
        setIsLoading(false);
      }
    };
    fetchUserPayslipDetails();
  }, [domain, token, userDetails]);

  useEffect(() => {
    const filteredResults = masterDataForSearch?.filter((item) =>
      ["pay_schedule", "start_date", "end_date", "run_date", "net_pay"].some(
        (key) =>
          item[key]
            ?.toString()
            .toLowerCase()
            .includes(globalSearchValue.toString().toLowerCase())
      )
    );
    setPayslipsList(filteredResults);
    setPage(0);
  }, [globalSearchValue, masterDataForSearch]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const getPayslipData = async (payslipId) => {
    try {
      const response = await fetch(
        `${domain}payrun/payslip/?payroll_run_id=${payslipId}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `token ${token}`,
          },
        }
      );
      const jsonData = await response.json();
      if (response.ok) {
        setIndPayslipData(jsonData);
        setPslipOpenDialog(true);
      } else if (response.status === 409) {
        sessionHandling();
      } else {
        throw new Error(response.error);
      }
    } catch (error) {
      showToast("error", error.message);
      setIndPayslipData("");
      setPslipOpenDialog(false);
    }
  };

  const handlePress = async (payrollRunId) => {
    try {
      setIsLoading(true);
      await getPayslipData(payrollRunId);
    } catch (error) {
      showToast("error", error.message);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <React.Fragment>
      <Mui.Grid container spacing={1} sx={{ mt: 0 }}>
        <Mui.Grid item xs={12} sm={12} md={12} lg={12}>
          <Mui.Stack direction="row">
            <Mui.Typography
              sx={{
                fontSize: 20,
                fontWeight: "bold",
                mt: 1.5,
              }}
            >
              My Payslip
            </Mui.Typography>
          </Mui.Stack>
        </Mui.Grid>
      </Mui.Grid>
      <Mui.Grid container spacing={1} sx={{ mt: 1 }}></Mui.Grid>

      {isLoading ? (
        <Loader info="Loading..." />
      ) : (
        <>
          {payslipsList?.length !== 0 ? (
            <Mui.Grid
              container
              sx={{
                paddingRight: 1,
              }}
            >
              <Mui.TableContainer
                component={Mui.Paper}
                style={{ paddingTop: 4, paddingBottom: 2 }}
                sx={{
                  mt: 2,
                  borderBottom: "1.5px solid transparent",
                }}
              >
                <Mui.Table>
                  <Mui.TableHead>
                    <Mui.TableRow
                      maxWidth="xl"
                      align="left"
                      sx={{ backgroundColor: themes.primary }}
                    >
                      <Mui.TableCell
                        sx={{
                          color: themes.blackColor,
                          fontWeight: "bold",
                          overflow: "hidden",
                          whiteSpace: "nowrap",
                          maxWidth: 100,
                        }}
                      >
                        Pay Period
                      </Mui.TableCell>
                      <Mui.TableCell
                        sx={{
                          color: themes.blackColor,
                          fontWeight: "bold",
                          overflow: "hidden",
                          whiteSpace: "nowrap",
                          maxWidth: 100,
                        }}
                      >
                        Payslip Name
                      </Mui.TableCell>
                      <Mui.TableCell
                        sx={{
                          color: themes.blackColor,
                          fontWeight: "bold",
                          overflow: "hidden",
                          whiteSpace: "nowrap",
                          maxWidth: 100,
                        }}
                      >
                        Pay Date
                      </Mui.TableCell>
                      <Mui.TableCell
                        sx={{
                          color: themes.blackColor,
                          fontWeight: "bold",
                          overflow: "hidden",
                          whiteSpace: "nowrap",
                          maxWidth: 100,
                        }}
                      >
                        Net Pay
                      </Mui.TableCell>
                      <Mui.TableCell
                        sx={{
                          color: themes.blackColor,
                          fontWeight: "bold",
                          overflow: "hidden",
                          whiteSpace: "nowrap",
                          maxWidth: 100,
                        }}
                      >
                        Action
                      </Mui.TableCell>
                    </Mui.TableRow>
                  </Mui.TableHead>
                  <Mui.TableBody>
                    {payslipsList
                      ?.slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((item, index) => (
                        <>
                          <Mui.TableRow
                            maxWidth="xl"
                            align="left"
                            sx={{
                              "& .MuiTableCell-root": {
                                height: "15px",
                                padding: "2px 15px",
                              },
                            }}
                            hover={true}
                          >
                            <Mui.TableCell
                              sx={{
                                overflow: "hidden",
                                whiteSpace: "nowrap",
                                maxWidth: 100,
                              }}
                            >
                              <Mui.Typography
                                sx={{
                                  overflow: "hidden",
                                  whiteSpace: "nowrap",
                                  textOverflow: "ellipsis",
                                }}
                              >
                                {item?.start_date}
                                {" to "}
                                {item?.end_date}
                              </Mui.Typography>
                            </Mui.TableCell>
                            <Mui.TableCell
                              sx={{
                                overflow: "hidden",
                                whiteSpace: "nowrap",
                                maxWidth: 100,
                              }}
                            >
                              <Mui.Typography
                                sx={{
                                  overflow: "hidden",
                                  whiteSpace: "nowrap",
                                  textOverflow: "ellipsis",
                                }}
                              >
                                {item?.pay_schedule}
                              </Mui.Typography>
                            </Mui.TableCell>
                            <Mui.TableCell
                              sx={{
                                overflow: "hidden",
                                whiteSpace: "nowrap",
                                maxWidth: 100,
                              }}
                            >
                              <Mui.Typography
                                sx={{
                                  overflow: "hidden",
                                  whiteSpace: "nowrap",
                                  textOverflow: "ellipsis",
                                }}
                              >
                                {item?.run_date}
                              </Mui.Typography>
                            </Mui.TableCell>
                            <Mui.TableCell
                              sx={{
                                overflow: "hidden",
                                whiteSpace: "nowrap",
                                maxWidth: 100,
                              }}
                            >
                              <Mui.Typography
                                sx={{
                                  overflow: "hidden",
                                  whiteSpace: "nowrap",
                                  textOverflow: "ellipsis",
                                }}
                              >
                                {item?.net_pay}
                              </Mui.Typography>
                            </Mui.TableCell>
                            <Mui.TableCell
                              sx={{
                                overflow: "hidden",
                                whiteSpace: "nowrap",
                                maxWidth: 100,
                              }}
                            >
                              <Mui.IconButton
                                onClick={() => {
                                  handlePress(item?.payroll_run_id);
                                }}
                              >
                                <RemoveRedEye />
                              </Mui.IconButton>
                            </Mui.TableCell>
                          </Mui.TableRow>
                        </>
                      ))}
                  </Mui.TableBody>
                </Mui.Table>
                {payslipsList.length > 10 ? (
                  <Mui.TablePagination
                    className="custom-pagination"
                    component="div"
                    rowsPerPageOptions={[10, 20, 50, 100]}
                    count={payslipsList.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                ) : null}
              </Mui.TableContainer>
            </Mui.Grid>
          ) : (
            <Mui.Stack sx={{ padding: 0.5 }} spacing={2} fullWidth>
              <Mui.Alert severity="info">
                There is a no available payslip
              </Mui.Alert>
            </Mui.Stack>
          )}
        </>
      )}
      {pslipOpenDialog ? (
        <>
          <Mui.Dialog
            fullWidth
            maxWidth={"lg"}
            open={pslipOpenDialog}
            style={{ height: "100%" }}
          >
            <Mui.Toolbar>
              <Mui.Typography
                sx={{ ml: 2, flex: 1 }}
                variant="h6"
                component="div"
              >
                {indPayslipData?.pay_period}
              </Mui.Typography>
              <Mui.Button
                autoFocus
                color="inherit"
                onClick={() => {
                  setPslipOpenDialog(false);
                  setIndPayslipData("");
                }}
              >
                Close
              </Mui.Button>
            </Mui.Toolbar>

            <PDFViewer width="100%" height="600" className="app">
              <PayslipData payslipData={indPayslipData} />
            </PDFViewer>
          </Mui.Dialog>
        </>
      ) : null}
    </React.Fragment>
  );
};

export default PayslipList;
