import React, { useState, useEffect } from "react";
import { useFetch } from "services/hooks/useFetch";
import * as Mui from "@mui/material";
import { setGlobalSearchValue } from "services/Redux/userToken";
import { themes, defaultAllSelection } from "services/constants";
import { CustomAutoComplete } from "components/CustomComponents/CustomAutoComplete";
import { customGetAPI } from "services/utils/CustomAPI";
import { useSelector, useDispatch } from "react-redux";
import { Approve } from "./Approve";
import { History } from "./History";
import { ViewLoan } from "./ViewLoan";
import {
  setDepartmentId,
  setDepartmentName,
} from "services/Redux/userManagament";
import { ActiveLoans } from "./ActiveLoans";
import { DisburseLoan } from "./DisburseLoan";
export const PayrollLoan = () => {
  const { domain, token, userDetails } = useSelector(
    (state) => state.tokenReducer
  );
  const [loanScreen, setLoanScreen] = useState("Approve");
  const [selectComponent, setSelectComponent] = useState("Approve");
  const [userName, setUserName] = useState("All");
  const [viewLoanDetails, setViewLoanDetails] = useState({});
  const [departmentChange, setDepartmentChange] = useState(true);
  const [userChange, setUserChange] = useState(true);
  const [userId, setUserId] = useState(defaultAllSelection);
  const [departmentData, setDepartmentData] = useState([]);
  const [viewLoan, setViewLoan] = useState(false);
  const dispatch = useDispatch();

  const { departmentId, departmentName } = useSelector(
    (state) => state.userReducer
  );
  const handleChange = (event, newAlignment) => {
    setLoanScreen(newAlignment);
    dispatch(setGlobalSearchValue(""));
  };
  const { result: membersData, setLoad: reCallMemberApi } = useFetch(
    `${domain}get-department-based-user/?department_id=${departmentId}&user_id=${userDetails.id}`
  );

  const renderComponent = () => {
    switch (selectComponent) {
      case "Approve":
        return (
          <Approve
            departmentId={departmentId}
            departmentChange={departmentChange}
            setDepartmentChange={setDepartmentChange}
            loanScreen={loanScreen}
            setViewLoanDetails={setViewLoanDetails}
            setViewLoan={setViewLoan}
            userId={userId}
            userChange={userChange}
            setUserChange={setUserChange}
          />
        );
      case "History":
        return (
          <History
            loanScreen={loanScreen}
            departmentId={departmentId}
            departmentChange={departmentChange}
            setDepartmentChange={setDepartmentChange}
            userChange={userChange}
            setUserChange={setUserChange}
            userId={userId}
            setViewLoanDetails={setViewLoanDetails}
            setViewLoan={setViewLoan}
          />
        );
      case "ActiveLoans":
        return (
          <ActiveLoans
            loanScreen={loanScreen}
            setViewLoan={setViewLoan}
            viewLoan={viewLoan}
            setViewLoanDetails={setViewLoanDetails}
            userId={userId}
            departmentId={departmentId}
            departmentChange={departmentChange}
            setDepartmentChange={setDepartmentChange}
            userChange={userChange}
            setUserChange={setUserChange}
          />
        );
      case "Disburse":
        return (
          <DisburseLoan
            departmentId={departmentId}
            departmentChange={departmentChange}
            setDepartmentChange={setDepartmentChange}
            loanScreen={loanScreen}
            setViewLoanDetails={setViewLoanDetails}
            setViewLoan={setViewLoan}
            userId={userId}
            userChange={userChange}
            setUserChange={setUserChange}
          />
        );

      default:
        break;
    }
  };

  useEffect(() => {
    dispatch(setDepartmentName("All"));
    dispatch(setDepartmentId(99999));
  }, [dispatch]);

  useEffect(() => {
    const fetchDepartmentData = async () => {
      const res = await customGetAPI(
        `${domain}get-department/?user_id=${userDetails.id}`
      );
      if (res.ok) {
        const allDepartment = {
          id: defaultAllSelection,
          department_name: "All",
        };
        setDepartmentData([allDepartment, ...res?.responseDetails]);
      }
    };
    fetchDepartmentData();
  }, [domain, token, userDetails.id]);

  const updateDepartmentName = (event, data) => {
    if (data) {
      dispatch(setDepartmentName(data.label));
      dispatch(setDepartmentId(data.id));
      localStorage.setItem("approveLeaveDepartmentId", data.id);
      localStorage.setItem("approveLeaveDepartmentName", data.label);
      setUserName("All");
      setUserId(defaultAllSelection);
      setDepartmentChange(true);
      reCallMemberApi(true);
    }
  };

  const updateUserName = (event, data) => {
    if (data) {
      setUserName(data.label);
      setUserId(data.user_id);
      setUserChange(true);
      reCallMemberApi(true);
    }
  };

  return (
    <React.Fragment>
      {viewLoan ? null : (
        <Mui.Grid container sx={{ paddingTop: 5, paddingLeft: 1 }}>
          <Mui.Grid item xs={5}>
            <Mui.Box
              sx={{
                display: "flex",
                alignItems: "center",
                width: 600,
                height: 55,
                position: "relative",
                overflow: "hidden",
                borderRadius: "30px",
              }}
            >
              <Mui.ToggleButtonGroup
                value={loanScreen}
                exclusive
                onChange={handleChange}
                sx={{
                  display: "flex",
                  width: "100%",
                  height: "100%",
                  position: "absolute",
                  borderRadius: "30px",
                  backgroundColor: themes.lightBrown,
                  // "&.Mui-selected": {
                  //   borderRadius: "30px",
                  // },
                }}
              >
                <Mui.ToggleButton
                  value="Approve"
                  sx={{
                    flex: 1,
                    textTransform: "capitalize",
                    fontSize: 18,
                    borderRadius: "30px",
                    fontWeight: 600,
                    border: "none",
                    color:
                      loanScreen === "Approve"
                        ? themes.whiteColor
                        : themes.blackColor,
                    backgroundColor:
                      loanScreen === "Approve"
                        ? themes.blackColor
                        : themes.lightBrown,
                    "&.Mui-selected": {
                      backgroundColor: themes.blackColor,
                      color: themes.whiteColor,
                      "&:hover": {
                        backgroundColor: themes.blackColor,
                        color: themes.whiteColor,
                      },
                      borderRadius: "30px",
                      "&.Mui-selected": {
                        borderRadius: "30px",
                      },
                    },
                    "&:hover": {
                      backgroundColor:
                        loanScreen === "Approve"
                          ? themes.blackColor
                          : themes.lightBrown,
                      color:
                        loanScreen === "Approve"
                          ? themes.whiteColor
                          : themes.blackColor,
                    },
                  }}
                  onClick={() => setSelectComponent("Approve")}
                >
                  Approve
                </Mui.ToggleButton>
                <Mui.ToggleButton
                  value="ActiveLoans"
                  sx={{
                    flex: 1,
                    textTransform: "capitalize",
                    fontSize: 18,
                    borderRadius: "30px",
                    fontWeight: 600,
                    border: "none",
                    color:
                      loanScreen === "ActiveLoans"
                        ? themes.whiteColor
                        : themes.blackColor,
                    backgroundColor:
                      loanScreen === "ActiveLoans"
                        ? themes.blackColor
                        : themes.lightBrown,
                    "&.Mui-selected": {
                      backgroundColor: themes.blackColor,
                      color: themes.whiteColor,
                      "&:hover": {
                        backgroundColor: themes.blackColor,
                        color: themes.whiteColor,
                      },
                      borderRadius: "30px",
                      "&.Mui-selected": {
                        borderRadius: "30px",
                      },
                    },
                    "&:hover": {
                      backgroundColor:
                        loanScreen === "ActiveLoans"
                          ? themes.blackColor
                          : themes.lightBrown,
                      color:
                        loanScreen === "ActiveLoans"
                          ? themes.whiteColor
                          : themes.blackColor,
                    },
                    "&:focus": {
                      backgroundColor:
                        loanScreen === "ActiveLoans"
                          ? themes.blackColor
                          : themes.lightBrown,
                      color:
                        loanScreen === "ActiveLoans"
                          ? themes.whiteColor
                          : themes.blackColor,
                    },
                  }}
                  onClick={() => setSelectComponent("ActiveLoans")}
                >
                  Active Loans
                </Mui.ToggleButton>
                <Mui.ToggleButton
                  value="History"
                  sx={{
                    flex: 1,
                    textTransform: "capitalize",
                    fontSize: 18,
                    fontWeight: 600,
                    border: "none",
                    color:
                      loanScreen === "History"
                        ? themes.whiteColor
                        : themes.blackColor,
                    backgroundColor:
                      loanScreen === "History"
                        ? themes.blackColor
                        : themes.lightBrown,
                    "&.Mui-selected": {
                      backgroundColor: themes.blackColor,
                      color: themes.whiteColor,
                      "&:hover": {
                        backgroundColor: themes.blackColor,
                        color: themes.whiteColor,
                      },
                      borderRadius: "30px",
                      "&.Mui-selected": {
                        borderRadius: "30px",
                      },
                    },
                    "&:hover": {
                      backgroundColor:
                        loanScreen === "History"
                          ? themes.blackColor
                          : themes.lightBrown,
                      color:
                        loanScreen === "History"
                          ? themes.whiteColor
                          : themes.blackColor,
                    },
                  }}
                  onClick={() => setSelectComponent("History")}
                >
                  History
                </Mui.ToggleButton>
                <Mui.ToggleButton
                  value="Disburse"
                  sx={{
                    flex: 1,
                    textTransform: "capitalize",
                    fontSize: 18,
                    fontWeight: 600,
                    border: "none",
                    color:
                      loanScreen === "Disburse"
                        ? themes.whiteColor
                        : themes.blackColor,
                    backgroundColor:
                      loanScreen === "Disburse"
                        ? themes.blackColor
                        : themes.lightBrown,
                    "&.Mui-selected": {
                      backgroundColor: themes.blackColor,
                      color: themes.whiteColor,
                      "&:hover": {
                        backgroundColor: themes.blackColor,
                        color: themes.whiteColor,
                      },
                      borderRadius: "30px",
                      "&.Mui-selected": {
                        borderRadius: "30px",
                      },
                    },
                    "&:hover": {
                      backgroundColor:
                        loanScreen === "Disburse"
                          ? themes.blackColor
                          : themes.lightBrown,
                      color:
                        loanScreen === "Disburse"
                          ? themes.whiteColor
                          : themes.blackColor,
                    },
                  }}
                  onClick={() => setSelectComponent("Disburse")}
                >
                  Disburse
                </Mui.ToggleButton>
              </Mui.ToggleButtonGroup>
            </Mui.Box>
          </Mui.Grid>

          <Mui.Grid item xs={2.3}></Mui.Grid>
          <Mui.Grid
            item
            xs={2.4}
            sx={{
              padding: 1,
              display: "flex",
              flexDirection: "row",
              justifyContent: "end",
              alignItems: "flex-end",
            }}
          >
            <CustomAutoComplete
              dataList={departmentData?.map((obj) => ({
                ...obj,
                label: obj.department_name,
              }))}
              selectedValue={departmentName}
              updateDataFunction={updateDepartmentName}
              placeHolder="Select department name"
              label="Select Department Name"
            />
          </Mui.Grid>
          <Mui.Grid item xs={2.3} sx={{ padding: 1 }}>
            <CustomAutoComplete
              dataList={membersData?.map((obj) => ({
                ...obj,
                label: obj?.user_name,
              }))}
              selectedValue={userName}
              updateDataFunction={updateUserName}
              placeHolder="Select employee name"
              label="Select User Name"
            />
          </Mui.Grid>
        </Mui.Grid>
      )}
      {viewLoan ? (
        <ViewLoan
          viewLoanDetails={viewLoanDetails}
          setViewLoan={setViewLoan}
          selectComponent={selectComponent}
          setDepartmentChange={setDepartmentChange}
        />
      ) : (
        renderComponent()
      )}
    </React.Fragment>
  );
};
