import React, { useState, useEffect } from "react";
import * as Mui from "@mui/material";
import { Box } from "@material-ui/core";
import { themes } from "services/constants";
import { Loader } from "components/Loader";
import moment from "moment";
import { Close } from "@mui/icons-material";
import { CustomButton } from "components/CustomComponents/CustomButton";
import { GlobalDialogBox } from "components/GlobalDialogBox";
import { useFetch } from "services/hooks/useFetch";
import { useSelector } from "react-redux";
import { customAPI } from "services/utils/CustomAPI";
import { CustomAutoComplete } from "components/CustomComponents/CustomAutoComplete";
const BootstrapDialog = Mui.styled(Mui.Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
  "& .MuiDialog-paper": {
    borderRadius: "20px",
  },
}));

function FuncDialogTitle(props) {
  const { children, onClose, title, ...other } = props;

  return (
    <Mui.DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <Mui.IconButton
          aria-label="close"
          onClick={() => onClose()}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <Close sx={{ color: themes.blackColor }} />
        </Mui.IconButton>
      ) : null}
    </Mui.DialogTitle>
  );
}

export const Weekly = ({
  loading,
  weeklyShiftList,
  listOfDate,
  filterChanges,
  setFilterChanges,
  shiftData,
  updateWeeklyShift,
  setFetchWeekly,
}) => {
  const [page, setLeavePage] = useState(0);
  const { departmentId } = useSelector((state) => state.userReducer);
  const [rowsPerPage, setLeaveRowsPerPage] = useState(25);
  const handleChangePage = (event, newPage) => {
    setLeavePage(newPage);
  };
  const { domain, userDetails } = useSelector(
    (state) => state.tokenReducer
  );
  const [openDialog, setOpenDialog] = useState(false);
  const [userShiftsDet, setUserShiftsDet] = useState([]);
  const [curShiftDetail, setCurShiftDetail] = useState([]);
  const [shiftName, setShiftName] = useState("");
  const [shiftId, setShiftId] = useState("");
  const [editWorkUnitDialogBox, setOpenEditWorkUnitDialogBox] = useState(false);
  const [newlyAddedWorkUnits, setNewlyAddedWorkUnits] = useState([]);
  const { result: workUnitDetails } = useFetch(
    `${domain}work_units/?department=${departmentId}`
  );
  const [workUnitDetailsValue, setWorkUnitDetails] = useState([]);
  const [overAllData, setOverAllData] = useState([]);
  const [updateLabel, setUpdateLabel] = useState(true);

  useEffect(() => {
    if (updateLabel && workUnitDetails.length !== 0) {
      let updatedWorkUnitDetails = workUnitDetails.map((data) => {
        return {
          ...data,
          label: data.work_unit_name,
        };
      });
      setWorkUnitDetails(updatedWorkUnitDetails);
      setOverAllData(updatedWorkUnitDetails);
      setUpdateLabel(false);
    }
  }, [updateLabel, workUnitDetails]);

  const handleChangeRowsPerPage = (event) => {
    setLeaveRowsPerPage(parseInt(event.target.value, 10));
    setLeavePage(0);
  };
  useEffect(() => {
    if (filterChanges) {
      setFilterChanges(false);
      setLeavePage(0);
    }
  }, [filterChanges, setFilterChanges]);

  const checkDateWithMoment = (inputDate) => {
    const givenDate = moment(inputDate, "DD-MM-YYYY");
    const currentDate = moment();
    if (
      givenDate.isSameOrAfter(currentDate, "day") ||
      userDetails.role === "Admin"
    ) {
      return true;
    } else {
      return false;
    }
  };

  const updateIndividualShift = async () => {
    const shift_assignments = [
      {
        shift_id: shiftId ? shiftId : curShiftDetail?.shift_id,
        date: moment(curShiftDetail?.date, "DD-MM-YYYY").format("YYYY-MM-DD"),
        is_week_off: shiftName === "Weekoff" ? true : false,
      },
    ];
    updateWeeklyShift(userShiftsDet?.user_id, shift_assignments);
    setOpenDialog(false);
  };

  const addNewRow = () => {
    let row = {
      id: newlyAddedWorkUnits.length + 1,
      name: "",
      work_unit: 0,
      number_of_units: 0,
      // label: "Select Work Unit",
    };
    setNewlyAddedWorkUnits([...newlyAddedWorkUnits, row]);
  };

  const handleWorkUnit = (details) => (event, data) => {
    if (data) {
      let updatedData = newlyAddedWorkUnits.map((element) => {
        if (element.id === details.id) {
          return {
            ...element,
            name: data.label,
            label: data.label,
            work_unit: data.id,
          };
        } else {
          return element;
        }
      });
      setNewlyAddedWorkUnits(updatedData);
      removeSelectedData(updatedData);
    }
  };

  const addAdtionalWorkUnits = async (currentModifyDetails) => {
    let updateData = newlyAddedWorkUnits.filter((data) => {
      return (
        data.name !== "" &&
        data.number_of_units !== 0 &&
        data.number_of_units !== ""
      );
    });
    let payload = {
      user: userShiftsDet?.user_id,
      date: currentModifyDetails?.date?.split("-").reverse().join("-"),
      additional_units: updateData.map(({ name, label, ...rest }) => rest),
    };
    const response = await customAPI(
      `${domain}unit/additional-units/`,
      "POST",
      payload
    );
    if (response.ok) {
      setOpenEditWorkUnitDialogBox(false);
      setNewlyAddedWorkUnits([]);
      setFetchWeekly(true);
      setUpdateLabel(true);
    }
  };

  const removeRow = (rowDetails) => {
    let updatedData = newlyAddedWorkUnits.filter((details) => {
      return details.id !== rowDetails.id;
    });
    setNewlyAddedWorkUnits(updatedData);
    removeSelectedData(updatedData);
  };

  const removeSelectedData = (updatedData) => {
    let overAllDetails = overAllData;
    updatedData.forEach((data) => {
      overAllDetails = overAllDetails.filter((details) => {
        return details.label !== data.label;
      });
    });
    setWorkUnitDetails(overAllDetails);
  };

  return (
    <React.Fragment>
      {loading ? (
        <Box>
          <Loader info="Loading..." />
        </Box>
      ) : (
        <>
          <Mui.TableContainer
            component={Mui.Paper}
            sx={{
              mt: 2,
              width: "100%",
              border: 1,
              borderRadius: 5,
              borderColor: themes.primary,
            }}
          >
            <Mui.Table>
              <Mui.TableHead>
                <Mui.TableRow>
                  <Mui.TableCell
                    sx={{
                      color: themes.blackColor,
                      fontWeight: "bold",
                      border: 1,
                      borderColor: themes.primary,
                    }}
                  >
                    Employee Name
                  </Mui.TableCell>

                  {listOfDate?.map((value) => (
                    <Mui.TableCell
                      sx={{ border: 1, borderColor: themes.primary }}
                    >
                      <Mui.Stack
                        direction="column"
                        sx={{
                          alignItems: "center",
                        }}
                      >
                        <Mui.Typography
                          sx={{
                            color: themes.blackColor,
                            fontWeight: "bold",
                            fontSize: 24,
                          }}
                        >
                          {moment(value, "YYYY-MM-DD").format("DD")}
                        </Mui.Typography>
                        <Mui.Typography
                          sx={{
                            color: themes.blackColor,
                            fontWeight: "bold",
                            fontSize: 12,
                          }}
                        >
                          {moment(value, "YYYY-MM-DD").format("ddd, MMM")}
                        </Mui.Typography>
                      </Mui.Stack>
                    </Mui.TableCell>
                  ))}
                </Mui.TableRow>
              </Mui.TableHead>
              <Mui.TableBody>
                {weeklyShiftList?.length === 0 ? (
                  <Mui.Alert severity="info" sx={{ width: "100%", margin: 2 }}>
                    No data found
                  </Mui.Alert>
                ) : (
                  weeklyShiftList
                    ?.slice(
                      page * rowsPerPage,
                      page * rowsPerPage + rowsPerPage
                    )
                    ?.map((shiftList, index) => (
                      <React.Fragment>
                        <Mui.TableRow key={index}>
                          <Mui.TableCell
                            sx={{ border: 1, borderColor: themes.primary }}
                          >
                            {shiftList?.user_name}
                          </Mui.TableCell>
                          {shiftList?.shifts.map((shift, i) => (
                            <Mui.TableCell
                              sx={{
                                fontSize: 13,
                                border: 1,
                                borderColor: themes.primary,
                                backgroundColor: checkDateWithMoment(
                                  shift?.date
                                )
                                  ? themes.whiteColor
                                  : "#ededed",
                              }}
                              style={{
                                cursor: checkDateWithMoment(shift?.date)
                                  ? "pointer"
                                  : "not-allowed",
                                color: checkDateWithMoment(shift?.date)
                                  ? themes.blackColor
                                  : "gray",
                              }}
                            >
                              <Mui.Grid container>
                                <Mui.Grid
                                  xs={
                                    shift?.default_units?.total_count ||
                                    shift?.additional_units?.total_count
                                      ? 8
                                      : 12
                                  }
                                  sx={{
                                    borderRight:
                                      shift?.default_units?.total_count ||
                                      shift?.additional_units?.total_count
                                        ? `3px solid ${themes.sidebarDivider}`
                                        : "none",
                                  }}
                                  onClick={() => {
                                    if (checkDateWithMoment(shift?.date)) {
                                      setUserShiftsDet(shiftList);
                                      setCurShiftDetail(shift);
                                      setShiftName(shift?.shift_name);
                                      setShiftId(shift?.id);
                                      setOpenDialog(true);
                                    }
                                  }}
                                >
                                  <Mui.Stack
                                    direction="column"
                                    width={"100%"}
                                    sx={{
                                      alignItems: "center",
                                    }}
                                  >
                                    <Mui.Typography
                                      sx={{
                                        fontSize: 12,
                                        fontWeight: "bold",
                                      }}
                                    >
                                      {shift?.shift_name
                                        ? shift?.shift_name
                                        : "Not Joined"}
                                    </Mui.Typography>
                                    {shift?.shift_name === null ||
                                    shift?.shift_name === "Weekoff" ? (
                                      ""
                                    ) : shift?.shift_start_time &&
                                      shift?.shift_end_time ? (
                                      <>
                                        <Mui.Typography sx={{ fontSize: 12 }}>
                                          {moment(
                                            shift?.shift_start_time,
                                            "HH:mm:SS"
                                          ).format("h:mm a")}
                                          {" to "}{" "}
                                          {moment(
                                            shift?.shift_end_time,
                                            "HH:mm:SS"
                                          ).format("h:mm a")}
                                        </Mui.Typography>
                                      </>
                                    ) : null}
                                  </Mui.Stack>
                                </Mui.Grid>
                                {shift?.default_units?.total_count ||
                                shift?.additional_units?.total_count ? (
                                  <Mui.Grid
                                    xs={
                                      shift?.default_units?.total_count ? 4 : 0
                                    }
                                    sx={{ alignItems: "center" }}
                                    onClick={() => {
                                      setOpenEditWorkUnitDialogBox(true);
                                      setUserShiftsDet(shiftList);
                                      setCurShiftDetail(shift);
                                      setShiftName(shift?.shift_name);
                                      setShiftId(shift?.id);
                                    }}
                                  >
                                    <Mui.Stack>
                                      <Mui.Typography
                                        sx={{
                                          fontWeight: "bold",
                                          textAlign: "center",
                                        }}
                                      >
                                        Unit
                                      </Mui.Typography>
                                      <Mui.Typography
                                        sx={{
                                          textAlign: "center",
                                          fontSize: {
                                            xs: 12,
                                            sm: 12,
                                            md: 15,
                                            lg: 15,
                                          },
                                        }}
                                      >
                                        {/* {shift?.default_units ? ( */}
                                        <Mui.Tooltip
                                          title={
                                            <>
                                              <Mui.Typography>
                                                Default Work Unit
                                              </Mui.Typography>
                                              {shift?.default_units?.details?.map(
                                                (data) => (
                                                  <Mui.Grid
                                                    container
                                                    sx={{
                                                      borderTop: `2px solid ${themes.sidebarDivider}`,
                                                      borderBottom: `2px solid ${themes.sidebarDivider}`,
                                                    }}
                                                  >
                                                    <Mui.Grid xs={6}>
                                                      <Mui.Typography>
                                                        {data?.work_unit_name}
                                                      </Mui.Typography>
                                                    </Mui.Grid>
                                                    <Mui.Grid xs={6}>
                                                      <Mui.Typography>
                                                        {data?.number_of_units}
                                                      </Mui.Typography>
                                                    </Mui.Grid>
                                                  </Mui.Grid>
                                                )
                                              )}
                                            </>
                                          }
                                        >
                                          {shift?.default_units?.total_count
                                            ? shift?.default_units?.total_count
                                            : 0}
                                          {" + "}
                                        </Mui.Tooltip>
                                        {/* ) : null} */}
                                        {/* {shift?.additional_units
                                          ?.total_count ? ( */}
                                        <Mui.Tooltip
                                          title={
                                            <>
                                              <Mui.Typography>
                                                Additional Work Unit
                                              </Mui.Typography>
                                              {shift?.additional_units?.details?.map(
                                                (data) => (
                                                  <Mui.Grid
                                                    container
                                                    sx={{
                                                      borderTop: `2px solid ${themes.sidebarDivider}`,
                                                      borderBottom: `2px solid ${themes.sidebarDivider}`,
                                                    }}
                                                  >
                                                    <Mui.Grid xs={6}>
                                                      <Mui.Typography>
                                                        {data?.work_unit_name}
                                                      </Mui.Typography>
                                                    </Mui.Grid>
                                                    <Mui.Grid xs={6}>
                                                      <Mui.Typography>
                                                        {data?.number_of_units}
                                                      </Mui.Typography>
                                                    </Mui.Grid>
                                                  </Mui.Grid>
                                                )
                                              )}
                                            </>
                                          }
                                        >
                                          {shift?.additional_units?.total_count
                                            ? shift?.additional_units
                                                ?.total_count
                                            : 0}
                                        </Mui.Tooltip>
                                        {/* ) : null} */}
                                      </Mui.Typography>
                                    </Mui.Stack>
                                  </Mui.Grid>
                                ) : null}
                              </Mui.Grid>
                            </Mui.TableCell>
                          ))}
                        </Mui.TableRow>
                      </React.Fragment>
                    ))
                )}
              </Mui.TableBody>
            </Mui.Table>
          </Mui.TableContainer>
          {weeklyShiftList.length > 25 && (
            <Mui.TablePagination
              className="custom-pagination"
              component="div"
              rowsPerPageOptions={[25, 50, 75, 100]}
              count={weeklyShiftList.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          )}
          {openDialog ? (
            <div>
              <BootstrapDialog
                aria-labelledby="customized-dialog-title"
                open={openDialog}
              >
                <FuncDialogTitle
                  id="customized-dialog-title"
                  align="center"
                  sx={{
                    minWidth: {
                      xs: "90%",
                      sm: "80%",
                      md: "580px",
                    },
                    color: themes.headLine,
                    fontWeight: 1000,
                    fontSize: {
                      xs: "18px",
                      sm: "20px",
                      md: "23px",
                    },
                    background: themes.primary,
                  }}
                  onClose={() => {
                    setOpenDialog(false);
                  }}
                  title={"Edit Shift Details"}
                >
                  {"Edit Shift Details"}
                </FuncDialogTitle>
                <Mui.DialogContent dividers>
                  <Mui.Grid xs={12} sx={{ paddingBottom: 1 }}>
                    <Mui.Typography>Employee Name</Mui.Typography>
                    <Mui.TextField
                      type="text"
                      size="small"
                      style={{
                        paddingTop: "0px",
                        paddingBottom: "0px",
                        fontSize: 14,
                        width: "100%",
                      }}
                      InputProps={{
                        inputProps: { maxLength: 512 },
                      }}
                      value={userShiftsDet?.user_name}
                      disabled={true}
                      fullWidth
                    />
                  </Mui.Grid>
                  <Mui.Grid xs={12} sx={{ paddingBottom: 1 }}>
                    <Mui.Typography>Date</Mui.Typography>
                    <Mui.TextField
                      type="date"
                      size="small"
                      style={{
                        paddingTop: "0px",
                        paddingBottom: "0px",
                        fontSize: 14,
                        width: "100%",
                      }}
                      InputProps={{
                        inputProps: { maxLength: 512 },
                      }}
                      value={moment(curShiftDetail?.date, "DD-MM-YYYY").format(
                        "YYYY-MM-DD"
                      )}
                      disabled={true}
                      fullWidth
                    />
                  </Mui.Grid>
                  <Mui.Grid xs={12} sx={{ paddingBottom: 1 }}>
                    <Mui.Typography>Shift Name</Mui.Typography>
                    <Mui.Autocomplete
                      disablePortal
                      id="combo-box-demo"
                      size="small"
                      value={shiftName}
                      options={shiftData}
                      filterOptions={(options, state) => {
                        return options?.filter((option) =>
                          option?.label
                            .toLowerCase()
                            .includes(state.inputValue.toLowerCase())
                        );
                      }}
                      renderOption={(props, option) => {
                        return <li {...props}>{option?.label}</li>;
                      }}
                      ListboxProps={{
                        style: {
                          maxHeight: "150px",
                          overflow: "auto",
                        },
                      }}
                      sx={{ minWidth: "100%" }}
                      onChange={(event, value) => {
                        if (value) {
                          setShiftId(value.id);
                          setShiftName(value.label);
                        } else {
                          setShiftId("");
                          setShiftName("");
                        }
                      }}
                      renderInput={(params) => (
                        <Mui.TextField
                          {...params}
                          value={shiftName}
                          sx={{
                            background: themes.whiteColor,
                          }}
                          placeholder="Select shift"
                          size="small"
                        />
                      )}
                    />
                  </Mui.Grid>

                  <Mui.Grid container>
                    <Mui.Grid xs={8}></Mui.Grid>
                    <Mui.Grid
                      xs={4}
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "flex-end",
                        alignItems: "flex-end",
                      }}
                    >
                      <CustomButton
                        actionFuntion={() => {
                          setOpenDialog(false);
                        }}
                        actionName="Cancel"
                        typeName="button"
                      />
                      &nbsp;
                      <CustomButton
                        actionName="Update"
                        typeName="submit"
                        actionFuntion={updateIndividualShift}
                      />
                    </Mui.Grid>
                  </Mui.Grid>
                </Mui.DialogContent>
              </BootstrapDialog>
            </div>
          ) : null}
        </>
      )}
      {editWorkUnitDialogBox ? (
        <GlobalDialogBox
          handleCloseDialog={() => {
            setOpenEditWorkUnitDialogBox(false);
            setNewlyAddedWorkUnits([]);
            setUpdateLabel(true);
          }}
          open={editWorkUnitDialogBox}
          title="Assign work unit"
          minWidth={650}
        >
          <Mui.Grid container sx={{ paddingBottom: 2 }}>
            <Mui.Grid xs={4}>
              <Mui.Typography sx={{ fontWeight: "bold" }}>Name</Mui.Typography>
              <Mui.Box
                sx={{
                  width: 150,
                  height: 15,
                  // backgroundColor: themes.primaryIndicator,
                  border: `2px solid ${themes.sidebarDivider}`,
                  borderRadius: "6px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  color: themes.headLine,
                  p: 2,
                  fontSize: 13,
                }}
              >
                {userShiftsDet.user_name}
              </Mui.Box>
            </Mui.Grid>
            <Mui.Grid xs={5}>
              <Mui.Typography sx={{ fontWeight: "bold" }}>
                Shift Name
              </Mui.Typography>
              <Mui.Box
                sx={{
                  width: 200,
                  height: 15,
                  // backgroundColor: themes.primaryIndicator,
                  border: `2px solid ${themes.sidebarDivider}`,
                  borderRadius: "6px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  color: themes.headLine,
                  p: 2,
                  fontSize: 13,
                }}
              >
                {shiftName}
              </Mui.Box>
            </Mui.Grid>
            <Mui.Grid xs={3}>
              <Mui.Typography sx={{ fontWeight: "bold" }}>Date</Mui.Typography>
              <Mui.Box
                sx={{
                  width: 120,
                  height: 15,
                  border: `2px solid ${themes.sidebarDivider}`,
                  // backgroundColor: themes.primaryIndicator,
                  borderRadius: "6px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  color: themes.headLine,
                  p: 2,
                  fontSize: 13,
                }}
              >
                {curShiftDetail.date}
              </Mui.Box>
            </Mui.Grid>
          </Mui.Grid>
          {/* Defalut work Unit */}

          <Mui.Typography sx={{ fontWeight: "bold" }}>
            Default Unit
          </Mui.Typography>
          <Mui.Grid container sx={{ paddingTop: 1 }}>
            <Mui.Grid container>
              <Mui.Grid xs={6}>
                <Mui.Typography sx={{ fontWeight: "bold" }}>
                  Unit Name
                </Mui.Typography>
              </Mui.Grid>
              <Mui.Grid xs={6}>
                <Mui.Typography sx={{ fontWeight: "bold" }}>
                  Unit Number
                </Mui.Typography>
              </Mui.Grid>
            </Mui.Grid>
            {curShiftDetail?.default_units?.details?.map((data) => (
              <Mui.Grid
                container
                sx={{
                  borderTop: `2px solid ${themes.sidebarDivider}`,
                  borderBottom: `2px solid ${themes.sidebarDivider}`,
                }}
              >
                <Mui.Grid xs={6}>
                  <Mui.Typography>{data?.work_unit_name}</Mui.Typography>
                </Mui.Grid>
                <Mui.Grid xs={6}>
                  <Mui.Typography>{data?.number_of_units}</Mui.Typography>
                </Mui.Grid>
              </Mui.Grid>
            ))}

            {/* {curShiftDetail.units.map((data) =>
              data?.is_additional === false ? (
                <Mui.Grid
                  container
                  sx={{
                    borderTop: `2px solid ${themes.sidebarDivider}`,
                    borderBottom: `2px solid ${themes.sidebarDivider}`,
                  }}
                >
                  <Mui.Grid xs={6}>
                    <Mui.Typography>{data?.work_unit_name}</Mui.Typography>
                  </Mui.Grid>
                  <Mui.Grid xs={6}>
                    <Mui.Typography>{data?.number_of_units}</Mui.Typography>
                  </Mui.Grid>
                </Mui.Grid>
              ) : null
            )} */}
          </Mui.Grid>
          <Mui.Typography sx={{ fontWeight: "bold" }}>
            Additional Unit
          </Mui.Typography>
          <Mui.Grid container sx={{ paddingTop: 1 }}>
            <Mui.Grid container>
              <Mui.Grid xs={6}>
                <Mui.Typography sx={{ fontWeight: "bold" }}>
                  Unit Name
                </Mui.Typography>
              </Mui.Grid>
              <Mui.Grid xs={6}>
                <Mui.Typography sx={{ fontWeight: "bold" }}>
                  Unit Number
                </Mui.Typography>
              </Mui.Grid>
            </Mui.Grid>
            {curShiftDetail?.additional_units?.details?.map((data) => (
              <Mui.Grid
                container
                sx={{
                  borderTop: `2px solid ${themes.sidebarDivider}`,
                  borderBottom: `2px solid ${themes.sidebarDivider}`,
                }}
              >
                <Mui.Grid xs={6}>
                  <Mui.Typography>{data?.work_unit_name}</Mui.Typography>
                </Mui.Grid>
                <Mui.Grid xs={6}>
                  <Mui.Typography>{data?.number_of_units}</Mui.Typography>
                </Mui.Grid>
              </Mui.Grid>
            ))}
            {newlyAddedWorkUnits?.map((data) => (
              <Mui.Grid
                container
                sx={{
                  borderTop: `2px solid ${themes.sidebarDivider}`,
                  borderBottom: `2px solid ${themes.sidebarDivider}`,
                }}
              >
                <Mui.Grid xs={5} sx={{ padding: 1 }}>
                  <CustomAutoComplete
                    dataList={workUnitDetailsValue}
                    selectedValue={data?.name}
                    updateDataFunction={handleWorkUnit(data)}
                    placeHolder="Select work unit name"
                    fullWith
                  />
                  {/* <Mui.Autocomplete
                    id="checkboxes-tags-demo"
                    size="small"
                    options={workUnitDetailsValue}
                    getOptionLabel={(option) => option.label
                    }
                    value={data.name}
                    onChange={(event, value) => {
                      updateWorkUnitnameDetails(value, data);
                    }}
                    renderOption={(props, option) => {
                      return <li {...props}>{option.label}</li>;
                    }}
                    renderInput={(params) => (
                      <Mui.TextField
                        {...params}
                        // value={data.name || "Select Work Unit"}
                        // multiline
                        // rows={3}
                        // maxRows={5}
                        placeholder="Select work unit name"
                        // error={userError}
                        // helperText={userError ? "Select at least one user" : ""}
                      />
                    )}
                  /> */}
                </Mui.Grid>
                <Mui.Grid xs={5} sx={{ padding: 1 }}>
                  <Mui.TextField
                    size="small"
                    value={data?.number_of_units}
                    onChange={(event) => {
                      if (event.target.value) {
                        let updatedData = newlyAddedWorkUnits.map((element) => {
                          if (element.id === data.id) {
                            return {
                              ...element,
                              number_of_units: event.target.value,
                            };
                          } else {
                            return element;
                          }
                        });
                        setNewlyAddedWorkUnits(updatedData);
                      }
                    }}
                    fullWidth
                  />
                  {/* <Mui.Typography>{data?.number_of_units}</Mui.Typography> */}
                </Mui.Grid>
                <Mui.Grid xs={2} sx={{ paddingTop: 1 }}>
                  <Mui.IconButton
                    onClick={() => {
                      removeRow(data);
                    }}
                  >
                    <Close />
                  </Mui.IconButton>
                </Mui.Grid>
              </Mui.Grid>
            ))}
            <Mui.Grid
              container
              sx={{
                display: "flex",
                flexDirection: "row",
                alignSelf: "flex-end",
                justifyContent: "flex-end",
                paddingTop: 5,
              }}
            >
              <Mui.Grid xs={9}></Mui.Grid>
              <Mui.Grid xs={3}>
                <Mui.Button
                  sx={{
                    background: themes.primaryButton,
                    textTransform: "capitalize",
                    "&:hover": {
                      backgroundColor: themes.primaryButton,
                    },
                    color: themes.headLine,
                    fontWeight: "bold",
                  }}
                  fullWidth
                  onClick={() => {
                    addNewRow();
                  }}
                >
                  Add
                </Mui.Button>
              </Mui.Grid>
            </Mui.Grid>
            {/* {curShiftDetail?.default_units?.details?.map((data) => (
              <Mui.Grid
                container
                sx={{
                  borderTop: `2px solid ${themes.sidebarDivider}`,
                  borderBottom: `2px solid ${themes.sidebarDivider}`,
                }}
              >
                <Mui.Grid xs={6}>
                  <Mui.Typography>{data?.work_unit_name}</Mui.Typography>
                </Mui.Grid>
                <Mui.Grid xs={6}>
                  <Mui.Typography>{data?.number_of_units}</Mui.Typography>
                </Mui.Grid>
              </Mui.Grid>
            ))} */}
          </Mui.Grid>
          <Mui.Grid container sx={{ paddingTop: 2 }}>
            <Mui.Button
              sx={{
                background: themes.primaryButton,
                textTransform: "capitalize",
                "&:hover": {
                  backgroundColor: themes.primaryButton,
                },
                color: themes.headLine,
                fontWeight: "bold",
              }}
              fullWidth
              onClick={() => {
                addAdtionalWorkUnits(curShiftDetail);
              }}
            >
              Update
            </Mui.Button>
          </Mui.Grid>
        </GlobalDialogBox>
      ) : null}
    </React.Fragment>
  );
};
