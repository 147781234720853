import React, { useState, useContext, useEffect } from "react";
import * as Mui from "@mui/material";
import { useSelector } from "react-redux";
import { themes, filterProject } from "services/constants";
import LinearProgress from "@material-ui/core/LinearProgress";
import "react-toastify/dist/ReactToastify.css";
import { makeStyles } from "@mui/styles";
import {
  setEditProject,
  setProjectStatus,
} from "services/Redux/leaveManagement";
import { setGlobalSearchValue } from "services/Redux/userToken";
import { MyProjectContext } from "./MyProjectContext";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useDispatch } from "react-redux";
import { Loader } from "components/Loader";
import { MoreVert, Public } from "@mui/icons-material";

export const CardView = ({
  handleMoveToEditScreen,
  updateMenuDetails,
  dropDown,
}) => {
  const {
    loading,
    projects,
    setGetClientDetails,
    masterDataForSearch,
    setProjects,
  } = useContext(MyProjectContext);
  const { userDetails, globalSearchValue } = useSelector(
    (state) => state.tokenReducer
  );
  const [editProject, setEditProjectDetails] = useState(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const role = userDetails.role === "User" ? true : false;
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    const filteredResults = masterDataForSearch.filter((item) =>
      filterProject.some((key) =>
        item[key]
          ?.toString()
          .toLowerCase()
          .includes(globalSearchValue.toString().toLowerCase())
      )
    );
    setProjects(filteredResults);
  }, [globalSearchValue, masterDataForSearch, setProjects]);

  const getStatusColor = (status) => {
    switch (status) {
      case "Active":
        return themes.activeProject;
      case "Inactive":
        return themes.inactiveProject;
      case "Onhold":
        return themes.holdProject;
      case "Completed":
        return themes.completedProject;
      default:
        return themes.blackColor;
    }
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const updateProjectDetails = (item) => {
    let status = [];
    item?.workflow?.statuses.forEach((element) => {
      status.push(element.name);
    });
    dispatch(setProjectStatus(status));
    localStorage.setItem("currentEditProject", JSON.stringify(item));
    dispatch(setEditProject(item));
    dispatch(setGlobalSearchValue(""));
    history.push("/admin/task");
  };

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const updateProjectOption = (option) => {
    handleMenuClose();
    handleMoveToEditScreen(option, editProject);
    if (option !== "View" && option !== "Clone") {
      setGetClientDetails(true);
    }
  };

  return (
    <React.Fragment>
      {projects.length !== 0 ? (
        <>
          <Mui.Grid container spacing={0}>
            {projects
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((item, index) => (
                <Mui.Grid item key={item.id}>
                  <Mui.Card
                    className=" max-w-fit mt-3 w-96 flex space-x-1 shadow-lg"
                    variant="outlined"
                    sx={{
                      borderRadius: 2,
                      marginLeft: 3,
                      marginTop: 5,
                      width: 300,
                      cursor: "pointer",
                    }}
                    onDoubleClick={() => {
                      updateProjectDetails(item);
                    }}
                  >
                    <Mui.CardHeader
                      action={
                        <React.Fragment>
                          <Mui.IconButton
                            aria-label="settings"
                            onClick={(event) => {
                              handleMenuOpen(event);
                              updateMenuDetails(item);
                              setEditProjectDetails(item);
                            }}
                          >
                            <MoreVert style={{ color: themes.activeTap }} />
                          </Mui.IconButton>
                          <Mui.Menu
                            anchorEl={anchorEl}
                            open={Boolean(anchorEl)}
                            onClose={handleMenuClose}
                            elevation={1}
                            transformOrigin={{
                              horizontal: "right",
                              vertical: "top",
                            }}
                            anchorOrigin={{
                              horizontal: "right",
                              vertical: "bottom",
                            }}
                          >
                            {role ? (
                              <>
                                <Mui.Stack>
                                  <Mui.MenuItem
                                    key={"Dashboard"}
                                    onClick={() =>
                                      updateProjectOption("Dashboard")
                                    }
                                  >
                                    {"Dashboard"}
                                  </Mui.MenuItem>
                                </Mui.Stack>
                              </>
                            ) : (
                              <>
                                {dropDown.map((option) => (
                                  <Mui.Stack>
                                    <Mui.MenuItem
                                      key={option}
                                      onClick={() =>
                                        updateProjectOption(option)
                                      }
                                    >
                                      {option}
                                    </Mui.MenuItem>
                                  </Mui.Stack>
                                ))}
                              </>
                            )}
                          </Mui.Menu>
                        </React.Fragment>
                      }
                      title={
                        <Mui.Stack direction="row">
                          <Mui.Grid xs={1}>
                            <span
                              className={classes.dot}
                              style={{
                                backgroundColor: getStatusColor(
                                  item.status.status
                                ),
                              }}
                            ></span>
                          </Mui.Grid>
                          <Mui.Grid xs={11} sx={{ paddingTop: 1 }}>
                            <Mui.Typography
                              style={{
                                color: getStatusColor(item.status.status),
                                fontSize: 14,
                              }}
                            >
                              {item.status.status}
                            </Mui.Typography>
                          </Mui.Grid>
                        </Mui.Stack>
                      }
                    />
                    <Mui.CardContent>
                      <Mui.Stack sx={{ marginTop: -3 }}>
                        <Mui.Grid container>
                          <Mui.Grid item xs={12}>
                            <Mui.Tooltip title={item.projectname}>
                              <Mui.Typography
                                sx={{
                                  fontWeight: "bold",
                                  color: themes.cardProjectName,
                                  fontSize: 18,
                                  overflow: "hidden",
                                  whiteSpace: "nowrap",
                                  textOverflow: "ellipsis",
                                }}
                              >
                                {item.projectname}
                              </Mui.Typography>
                            </Mui.Tooltip>
                          </Mui.Grid>
                        </Mui.Grid>
                        <Mui.Grid container sx={{ paddingTop: 0.5 }}>
                          <Mui.Grid item xs={4}>
                            <Mui.Typography
                              sx={{ fontSize: 12, fontWeight: 700 }}
                            >
                              Client
                            </Mui.Typography>
                          </Mui.Grid>
                          <Mui.Grid item xs={4}>
                            <Mui.Typography
                              sx={{ fontSize: 12, fontWeight: 700 }}
                            >
                              Project Lead
                            </Mui.Typography>
                          </Mui.Grid>
                          <Mui.Grid item xs={4}>
                            <Mui.Typography
                              sx={{ fontSize: 12, fontWeight: 700 }}
                            >
                              Started
                            </Mui.Typography>
                          </Mui.Grid>
                        </Mui.Grid>
                        <Mui.Grid container>
                          <Mui.Grid item xs={4}>
                            <Mui.Tooltip title={item.client?.clientname}>
                              <Mui.Typography
                                sx={{
                                  fontSize: 14,
                                  overflow: "hidden",
                                  whiteSpace: "nowrap",
                                  maxWidth: 100,
                                  textOverflow: "ellipsis",
                                }}
                              >
                                {item.client?.clientname
                                  ? item.client?.clientname.length > 10
                                    ? item.client?.clientname.substr(0, 10) +
                                      "..."
                                    : item.client?.clientname
                                  : "N/A"}
                              </Mui.Typography>
                            </Mui.Tooltip>
                          </Mui.Grid>
                          <Mui.Grid item xs={4}>
                            <Mui.Tooltip title={item.project_lead_name}>
                              <Mui.Typography
                                sx={{
                                  fontSize: 14,
                                  overflow: "hidden",
                                  whiteSpace: "nowrap",
                                  maxWidth: 100,
                                  textOverflow: "ellipsis",
                                }}
                              >
                                {item.project_lead_name
                                  ? item.project_lead_name.length > 9
                                    ? item.project_lead_name.substr(0, 9) +
                                      "..."
                                    : item.project_lead_name
                                  : "N/A"}
                              </Mui.Typography>
                            </Mui.Tooltip>
                          </Mui.Grid>
                          <Mui.Grid item xs={4}>
                            <Mui.Typography
                              sx={{
                                fontSize: 14,
                                overflow: "hidden",
                                whiteSpace: "nowrap",
                                maxWidth: 100,
                                textOverflow: "ellipsis",
                              }}
                              direction="row"
                            >
                              {item.date ? item.date : "N/A"}
                            </Mui.Typography>
                          </Mui.Grid>
                        </Mui.Grid>
                        <Mui.Grid container sx={{ paddingTop: 0.5 }}>
                          <Mui.Grid
                            item
                            xs={5}
                            sx={{
                              paddingBottom:
                                item.projectType === "Private" ? 0 : 1,
                            }}
                          >
                            {item.projectType === "Private" ? (
                              <>
                                <Mui.Typography
                                  sx={{ fontWeight: 700, fontSize: 12 }}
                                >
                                  Members
                                </Mui.Typography>
                                <Mui.AvatarGroup
                                  max={3}
                                  sx={{
                                    "& .MuiAvatar-root": {
                                      width: 25,
                                      height: 25,
                                      fontSize: 15,
                                    },
                                    justifyContent: "left",
                                  }}
                                >
                                  {item.members.map((member) => (
                                    <Mui.Avatar
                                      key={member.id}
                                      sx={{ width: 25, height: 25 }}
                                      alt={member.username}
                                      src={member.image_url}
                                    >
                                      {member.image_url ? (
                                        <img
                                          src={member.image_url}
                                          alt={member?.username
                                            ?.charAt(0)
                                            .toUpperCase()}
                                          style={{
                                            width: "100%",
                                            height: "100%",
                                            objectFit: "cover",
                                          }}
                                        />
                                      ) : (
                                        member.username?.charAt(0).toUpperCase()
                                      )}
                                    </Mui.Avatar>
                                  ))}
                                </Mui.AvatarGroup>{" "}
                              </>
                            ) : (
                              <Mui.Stack
                                direction="row"
                                sx={{ padding: 1, fontSize: 14 }}
                              >
                                <Public />
                                &nbsp;
                                <Mui.Typography
                                  sx={{ fontSize: 14, mt: "2px" }}
                                >
                                  {item.projectType}
                                </Mui.Typography>
                              </Mui.Stack>
                            )}
                          </Mui.Grid>
                          <Mui.Grid xs={7}>
                            <Mui.Grid container>
                              <Mui.Grid
                                xs={6}
                                sx={{
                                  backgroundColor: themes.backgroundShadow,
                                }}
                              >
                                <Mui.Typography
                                  sx={{ fontSize: 18, textAlign: "center" }}
                                >
                                  {item.total_tasks}
                                </Mui.Typography>
                              </Mui.Grid>
                              <Mui.Grid
                                xs={6}
                                sx={{
                                  backgroundColor: themes.backgroundShadow,
                                }}
                              >
                                <Mui.Typography
                                  sx={{ fontSize: 18, textAlign: "center" }}
                                >
                                  {item.completed_tasks}
                                </Mui.Typography>
                              </Mui.Grid>
                            </Mui.Grid>
                            <Mui.Grid
                              container
                              sx={{ justifyContent: "center" }}
                            >
                              <Mui.Grid
                                xs={6}
                                sx={{
                                  backgroundColor: themes.backgroundShadow,
                                }}
                              >
                                <Mui.Typography
                                  sx={{ fontSize: 11, textAlign: "center" }}
                                >
                                  tasks
                                </Mui.Typography>
                              </Mui.Grid>
                              <Mui.Grid
                                xs={6}
                                sx={{
                                  backgroundColor: themes.backgroundShadow,
                                }}
                              >
                                <Mui.Typography
                                  sx={{ fontSize: 11, textAlign: "center" }}
                                >
                                  Completed
                                </Mui.Typography>
                              </Mui.Grid>
                            </Mui.Grid>
                          </Mui.Grid>
                        </Mui.Grid>
                        <Mui.Grid
                          container
                          sx={{ paddingBottom: 2, paddingTop: 0.5 }}
                        >
                          <Mui.Typography>
                            Progress{" "}
                            {item.completed_tasks / item.total_tasks
                              ? (
                                  (item.completed_tasks / item.total_tasks) *
                                  100
                                ).toFixed(0)
                              : 0}
                            %
                          </Mui.Typography>
                          <LinearProgress
                            variant="determinate"
                            color="success"
                            value={
                              item.total_tasks === 0
                                ? 0
                                : (item.completed_tasks / item.total_tasks) *
                                  100
                            }
                            sx={{ width: "100%" }}
                          />
                        </Mui.Grid>
                      </Mui.Stack>
                    </Mui.CardContent>
                  </Mui.Card>
                </Mui.Grid>
              ))}
          </Mui.Grid>
          {projects.length > 25 && (
            <Mui.TablePagination
              className="custom-pagination"
              component="div"
              rowsPerPageOptions={[25, 50, 75, 100]}
              count={projects.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          )}
        </>
      ) : loading ? (
        <Mui.Box>
          <Loader info="Loading..." />
        </Mui.Box>
      ) : (
        <div style={{ paddingTop: 8 }}>
          <Mui.Alert severity="info">No data available</Mui.Alert>
        </div>
      )}
    </React.Fragment>
  );
};

const useStyles = makeStyles((theme) => ({
  dot: {
    display: "inline-block",
    borderRadius: "60%",
    borderStyle: "solid",
    borderWidth: "0.5px",
    borderColor: themes.whiteColor,
    height: "10px",
    width: "10px",
  },
}));
