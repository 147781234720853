import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Loader } from "components/Loader";
import dayjs from "dayjs";
import { renderTimeViewClock } from "@mui/x-date-pickers/timeViewRenderers";
import { GlobalDialogBox } from "components/GlobalDialogBox";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { getColorWorkingHours } from "services/utils/Status";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { leaveManagementError } from "services/constants/ErrorMessages";
import { leaveSuccess } from "services/constants/SuccessMessages";
import { OtReports } from "./OtReports";
import { DatePicker } from "@mui/x-date-pickers";
import { customAPI } from "services/utils/CustomAPI";
import { firstLevelWorkingHours, workingHoursHeader } from "../ReportHeaders";
import { tableRowSx } from "services/constants";
import {
  AccountBalance,
  KeyboardArrowDown,
  ChevronRight,
  DriveFileRenameOutline,
  Cancel,
  Done,
  Loupe,
  DownloadForOffline,
  DeleteSharp,
  Search,
} from "@mui/icons-material";
import moment from "moment";
import styled from "styled-components";
import * as Mui from "@mui/material";
import {
  themes,
  reports,
  defaultAllSelection,
  punchInDetails,
} from "services/constants";
import { showToast } from "services/utils/Status";
import { DateRangePicker } from "./DateRangePicker";
import { PunchDetails } from "./PunchDetails";
import {
  setDepartmentId,
  setDepartmentName,
} from "services/Redux/userManagament";
import sessionHandling from "services/utils/notificationUtils";
import { BackArrow } from "components/CustomComponents/BackArrow";
import { CustomButton } from "components/CustomComponents/CustomButton";

const dateFormat = "YYYY-MM-DD";

export const WorkingHours = ({ setShowReport, reportName }) => {
  const dispatch = useDispatch();
  const {
    token,
    domain,
    globalSearchValue,
    userDetails: currentUserDetails,
  } = useSelector((state) => state.tokenReducer);
  const { departmentId, departmentName } = useSelector(
    (state) => state.userReducer
  );

  const [editCheckInCheckOut, setEditCheckInCheckOut] = useState(false);
  const [breakDayDetails, setBreakDayDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const [selectedDateRange, setSelectedDateRange] = useState("");
  const [departmentData, setDepartmentData] = useState([]);
  const [expandedSubMenuAnchorEl, setExpandedSubMenuAnchorEl] = useState(null);
  const [shiftData, setShiftData] = useState([]);
  const [handleCheckInError, setHandleCheckInError] = useState("");
  const [editBreakDetails, setEditBreakDetails] = useState(null);
  const [editCheckinCheckOutTime, setEditCheckInCheckOutDetails] = useState({});
  const [breakInTime, setBreakInTime] = useState(null);
  const [breakOutTime, setBreakOutTime] = useState(null);
  const [editBreakInTime, setEditBreakInTime] = useState(null);
  const [editBreakOutTime, setEditBreakOutTime] = useState(null);
  const [ediBreakErrors, setEditBreakErrors] = useState("");
  const [breakInError, setBreakInError] = useState(null);
  const [breakOutError, setBreakOutError] = useState(null);
  const [breakPopup, setBreakPopup] = useState(false);
  const [removeExpand, setRemoveExpand] = useState(false);
  const [departmentLeadId, setDepartmentLeadId] = useState(0);
  const [punchExports, setPunchExports] = useState(false);
  const currentDate = new Date();
  const currentDay = currentDate.getDay();
  const diffToMonday = currentDay === 0 ? -6 : 1 - currentDay;
  const mostRecentMonday = new Date(
    currentDate.setDate(currentDate.getDate() + diffToMonday)
  );
  const currentDateDetails = new Date();
  const diffToSunday = currentDay === 0 ? 0 : 7 - currentDay;
  const currentWeekSunday = new Date(
    currentDateDetails.setDate(currentDateDetails.getDate() + diffToSunday)
  );
  const [exportOTime, exportOverTimeReport] = useState(false);
  const [startDate, setStartDate] = useState(
    moment(new Date(mostRecentMonday.toISOString()?.split("T")[0])).format(
      dateFormat
    )
  );
  const [endDate, setEndDate] = useState(
    moment(new Date(currentWeekSunday.toISOString()?.split("T")[0])).format(
      dateFormat
    )
  );
  const [shiftCatagoryName, setShiftCatagoryName] = useState("");
  const [shiftName, setShiftName] = useState("");
  const [shiftId, setshiftId] = useState("");
  const [personExpandableId, setPersonExpandableId] = useState(0);
  const [breakExpandableDate, setBreakExpandableDate] = useState(0);
  const [getReportedData, setGetReportedData] = useState(false);
  const [updatedDetailReportData, setUpdatedDetailsReportData] = useState([]);
  const [reportedType, setReportedType] = useState(reportName);
  const [workingHoursDetails, setWorkingHoursDetails] = useState([]);
  const [userDetails, setUserDetails] = useState([]);
  const [page, setLeavePage] = useState(0);
  const [rowsPerPage, setLeaveRowsPerPage] = useState(25);
  const [punchDetailsData, setPunchDetailsData] = useState([]);
  const [masterPunchDetails, setmasterPunchDetails] = useState([]);
  const [userLoader, setUserLoader] = useState(false);
  //Edit checkin checkout time
  const [shiftStartTimeFormated, setShiftStartTimeFormated] = useState(null);
  const [shiftEndTimeFormated, setShiftEndTimeFormated] = useState(null);
  const [shiftStartDate, setShiftStartDate] = useState(new Date());
  const [shiftEndDate, setShiftEndDate] = useState(new Date());

  const handleChangePage = (event, newPage) => {
    setLeavePage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setLeaveRowsPerPage(parseInt(event.target.value, 10));
    setLeavePage(0);
  };
  const [masterDataForSearch, setMasterDataForSearch] = useState([]);
  const [fetchSummarizedReport, setFetchSummarizedReport] = useState(true);
  const [fetchPunchDetails, setFetchPunchDetails] = useState(true);

  const [searchQuery, setSearchQuery] = useState("");
  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };
  const filteredData = departmentData.filter((data) =>
    data.label.toLowerCase().includes(searchQuery.toLowerCase())
  );

  useEffect(() => {
    const fetchDepartmentData = async () => {
      try {
        const response = await fetch(
          `${domain}get-department/?user_id=${currentUserDetails.id}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `token ${token}`,
            },
          }
        );
        const res = await response.json();
        if (response.ok) {
          setDepartmentData([]);
          const departmentDataWithLabel = res.map((obj) => ({
            ...obj,
            label: obj.department_name,
          }));
          const allDepartment = {
            id: defaultAllSelection,
            label: "All",
            departmentname: "All",
          };
          setDepartmentData([allDepartment, ...departmentDataWithLabel]);
        } else if (response.status === 409) {
          sessionHandling();
        } else {
          throw new Error(res.error);
        }
      } catch (error) {
        showToast("error", error.message);
      }
    };
    fetchDepartmentData();
  }, [currentUserDetails.id, domain, token]);

  useEffect(() => {
    const fetchDepartmentData = async () => {
      setLoading(true);
      try {
        const response = await fetch(
          `${domain}punchin-details/?shift_id=${shiftId}&department_id=${departmentId}&start_date=${startDate}&end_date=${endDate}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `token ${token}`,
            },
          }
        );
        const res = await response.json();
        if (response.ok) {
          setPunchDetailsData(res);
          setmasterPunchDetails(res);
          setLoading(false);
        } else if (response.status === 409) {
          sessionHandling();
        } else {
          throw new Error(res.error);
        }
      } catch (error) {
        showToast("error", error.message);
      }
    };
    if (fetchPunchDetails && reportedType === "Punch details") {
      fetchDepartmentData();
      setFetchPunchDetails(false);
    }
  }, [
    departmentId,
    domain,
    endDate,
    fetchPunchDetails,
    reportedType,
    setFetchPunchDetails,
    shiftId,
    startDate,
    token,
  ]);

  useEffect(() => {
    setBreakExpandableDate(0);
    setPersonExpandableId(0);
    const filteredResults = masterPunchDetails.filter((item) =>
      punchInDetails.some((key) =>
        item[key]
          ?.toString()
          .toLowerCase()
          .includes(globalSearchValue.toString().toLowerCase())
      )
    );
    setPunchDetailsData(filteredResults);
    setLeavePage(0);
  }, [globalSearchValue, masterPunchDetails]);

  useEffect(() => {
    const fetchShiftCatagory = async () => {
      try {
        const response = await fetch(
          `${domain}shift_categories/?department_id=${departmentId}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `token ${token}`,
            },
          }
        );
        const res = await response.json();
        const allShifts = {
          id: defaultAllSelection,
          label: "All",
          name: "All",
        };
        setShiftName(allShifts?.label);
        setshiftId(allShifts?.id);
        if (response.ok) {
          setShiftCatagoryName("");
          setShiftCatagoryName(res[0]?.name);
          setShiftData([]);
          const fetchShifts = res?.map((value) => value?.shifts);
          let shiftDataWithLabel = [];
          if (departmentName !== "All") {
            shiftDataWithLabel = fetchShifts[0]?.map((obj) => ({
              ...obj,
              label: obj.name,
            }));
            setShiftData([allShifts, ...shiftDataWithLabel]);
          }
        } else if (response.status === 409) {
          sessionHandling();
        } else {
          throw new Error(res.error);
        }
      } catch (error) {
        showToast("error", error.message);
      }
    };
    fetchShiftCatagory();
  }, [departmentId, departmentName, dispatch, domain, token]);

  useEffect(() => {
    const getWorkingHoursDetails = async () => {
      setLoading(true);
      try {
        const response = await fetch(
          `${domain}summarized-detailed-workinghours/?start_date=${startDate}&end_date=${endDate}&department_id=${departmentId}&shift_id=${shiftId}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `token ${token}`,
            },
          }
        );
        const res = await response.json();
        if (response.ok) {
          setWorkingHoursDetails(res);
          setMasterDataForSearch(res);
          setLoading(false);
          if (reportedType !== "Summarized working hours") {
            setGetReportedData(true);
          }
        } else if (response.status === 409) {
          sessionHandling();
        } else {
          throw new Error(res.error);
        }
      } catch (error) {
        showToast("error", error.message);
      }
    };
    if (fetchSummarizedReport) {
      getWorkingHoursDetails();
      setFetchSummarizedReport(false);
    }
  }, [
    departmentId,
    domain,
    endDate,
    fetchSummarizedReport,
    reportedType,
    shiftId,
    startDate,
    token,
  ]);

  useEffect(() => {
    const getReportDetails = () => {
      let Urls = [],
        overAllData = [];
      workingHoursDetails.forEach((element) => {
        let urls = [
          ...Urls,
          `${domain}individual_details_working_hours/?user_id=${element.user_id}&start_date=${startDate}&end_date=${endDate}`,
        ];
        Urls = urls;
      });
      Promise.all(
        Urls.map((url) =>
          fetch(url, {
            headers: {
              "Content-Type": "application/json",
              Authorization: `token ${token}`,
            },
          }).then((r) => r.json())
        )
      )
        .then(function (responses) {
          // Get a JSON object from each of the responses
          return Promise.all(
            responses.map(function (response) {
              if (
                response?.error !== "No data found for the given date range"
              ) {
                overAllData = [...overAllData, ...response.checkin_details];
                setUpdatedDetailsReportData(overAllData);
                return overAllData;
              } else {
                return overAllData;
              }
            })
          );
        })
        .catch((error) => console.log(error));
    };
    if (getReportedData) {
      getReportDetails();
      setGetReportedData(false);
    }
  }, [domain, endDate, getReportedData, startDate, token, workingHoursDetails]);

  const handleStartDate = async (startDate, endDate) => {
    setPersonExpandableId(0);
    setStartDate(moment(new Date(startDate)).format("YYYY-MM-DD"));
    setEndDate(moment(new Date(endDate)).format("YYYY-MM-DD"));
    setSelectedDateRange(
      `${moment(new Date(startDate)).format("DD-MM-YYYY")} - ${moment(
        new Date(endDate)
      ).format("DD-MM-YYYY")}`
    );
    if (reportedType === "Punch details") {
      setFetchPunchDetails(true);
    } else {
      setFetchSummarizedReport(true);
    }
  };

  const summarizeExport = () => {
    if (workingHoursDetails.length === 0) {
      showToast("error", "No data available");
    } else {
      let dataPreparation = [];
      workingHoursDetails.forEach((element) => {
        let overAllData = {
          username: element.username,
          no_shift: element.attendant_shift_count,
          workinghours: element.weekly_working_hours,
          permissionshours: element.permissions_taken,
          break: element.break_count,
          leaves: element.leave_count,
          absent: element.absent_count,
          late: element.late_count,
          latecomminghours: element?.late_coming_hours,
          earlyleavinghours: element?.early_leaving_hours,
          othours: element?.ot_hours,
          total_break_hours: element?.total_break_hours,
        };
        dataPreparation = [...dataPreparation, overAllData];
      });
      const department = ["Department Name"];
      const shiftCategory = ["ShiftCategory Name"];
      const shift = ["Shift Name"];
      const date = ["Date"];
      department.push(departmentName);
      shiftCategory.push(shiftCatagoryName);
      shift.push(shiftName);
      date.push(`${startDate} to ${endDate}`);
      const items = dataPreparation;
      const TableHead = ["Username"];
      TableHead.push("No Of Shifts");
      TableHead.push("Total Hours");
      TableHead.push("Permission Counts");
      TableHead.push("Break Counts");
      TableHead.push("Leave Counts");
      TableHead.push("Absent Counts");
      TableHead.push("Late Counts");
      TableHead.push("Late Coming Hours");
      TableHead.push("Early Leaving Hours");
      TableHead.push("OT Hours");
      TableHead.push("Total Break Hours");

      const replacer = (key, value) => (value === null ? "" : value);
      const header = Object.keys(items[0]);
      let csv = items.map((row) =>
        header
          .map((fieldName) => JSON.stringify(row[fieldName], replacer))
          .join(",")
      );
      csv.unshift(TableHead.join(","));
      csv.unshift(",");
      csv.unshift(date.join(","));
      csv.unshift(shift.join(","));
      csv.unshift(shiftCategory.join(","));
      csv.unshift(department.join(","));
      csv = csv.join("\r\n");
      const ConvertData = csv;
      const element = document.createElement("a");
      const file = new Blob([ConvertData], {
        type: "text/plain",
      });
      element.href = URL.createObjectURL(file);
      element.download = "SummarizedReported.csv";
      document.body.appendChild(element);
      element.click();
    }
  };

  const getCheckInCheckOutDetails = async (userid) => {
    setUserLoader(true);
    try {
      const response = await fetch(
        `${domain}individual_details_working_hours/?user_id=${userid}&start_date=${startDate}&end_date=${endDate}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `token ${token}`,
          },
        }
      );
      const res = await response.json();
      if (response.ok) {
        setUserDetails(res);
      } else if (response.status === 409) {
        sessionHandling();
      } else {
        throw new Error(res.error);
      }
    } catch (error) {
      setUserDetails([]);
      showToast("error", error.message);
    } finally {
      setUserLoader(false);
    }
  };

  const exportDetailedReport = () => {
    if (updatedDetailReportData.length !== 0) {
      let dataPreparation = [];
      updatedDetailReportData.forEach((element) => {
        let overAllData = {
          user_name: element?.user_name,
          date: element?.date,
          shift: element?.shift ? element.shift : "",
          status: element?.type,
          checkInTime: element?.check_in_time_formatted,
          BreakTime: element?.break_hours,
          Permission: element?.permission_details?.individual_permission_time
            ? element?.permission_details?.individual_permission_time
            : "",
          CheckOutTime: element?.check_out_time_formatted,
          working_hours: element?.working_hours,
        };
        dataPreparation = [...dataPreparation, overAllData];
      });
      if (dataPreparation.length !== 0) {
        const department = ["Department Name"];
        const shiftCategory = ["ShiftCategory Name"];
        const shift = ["Shift Name"];
        const date = ["Date"];
        department.push(departmentName);
        shiftCategory.push(shiftCatagoryName);
        shift.push(shiftName);
        date.push(`${startDate} to ${endDate}`);
        const items = dataPreparation;
        const TableHead = ["User Name"];
        TableHead.push("Date");
        TableHead.push("Shift");
        TableHead.push("Status");
        TableHead.push("CheckIn Time");
        TableHead.push("Break Hours");
        TableHead.push("Permission Hours");
        TableHead.push("CheckOut Time");
        TableHead.push("Working Hours");
        const replacer = (key, value) => (value === null ? "" : value);
        const header = Object.keys(items[0]);
        let csv = items.map((row) =>
          header
            .map((fieldName) => JSON.stringify(row[fieldName], replacer))
            .join(",")
        );
        csv.unshift(TableHead.join(","));
        csv.unshift(",");
        csv.unshift(date.join(","));
        csv.unshift(shift.join(","));
        csv.unshift(shiftCategory.join(","));
        csv.unshift(department.join(","));
        csv = csv.join("\r\n");
        const ConvertData = csv;
        const element = document.createElement("a");
        const file = new Blob([ConvertData], {
          type: "text/plain",
        });
        element.href = URL.createObjectURL(file);
        element.download = "DetailedReports.csv";
        document.body.appendChild(element);
        element.click();
      } else {
        showToast("error", "No data available");
      }
    }
  };
  useEffect(() => {
    const FilterDockets = () => {
      const cols =
        masterDataForSearch[0] && Object.keys(masterDataForSearch[0]);
      return masterDataForSearch.filter((r) =>
        cols.some((c) =>
          r[c]
            ? r[c]
                .toString()
                .toLowerCase()
                .indexOf(globalSearchValue.toLowerCase()) > -1
            : null
        )
      );
    };
    setWorkingHoursDetails(FilterDockets());
    setLeavePage(0);
  }, [globalSearchValue, masterDataForSearch]);

  const getTwentyFourHourTime = (time) => {
    if (time) {
      let hours = Number(time.match(/^(\d+)/)[1]);
      let minutes = Number(time.match(/:(\d+)/)[1]);
      let AMPM = time.match(/\s(.*)$/)[1];
      if (AMPM === "PM" && hours < 12) hours = hours + 12;
      if (AMPM === "AM" && hours === 12) hours = hours - 12;
      let sHours = hours.toString();
      let sMinutes = minutes.toString();
      if (hours < 10) sHours = "0" + sHours;
      if (minutes < 10) sMinutes = "0" + sMinutes;
      return sHours + ":" + sMinutes + ":00";
    }
  };

  const handleBreakDetails = (breakDetail) => {
    const parseTime = (timeString) => {
      if (!timeString)
        return dayjs().set("hour", 0).set("minute", 0).set("second", 0);

      const [hour = 0, minute = 0, second = 0] = timeString
        .split(":")
        .map(Number);
      return dayjs()
        .set("hour", hour)
        .set("minute", minute)
        .set("second", second);
    };

    setEditBreakInTime(parseTime(breakDetail.break_in));
    setEditBreakOutTime(parseTime(breakDetail.break_out));
    setEditBreakDetails(breakDetail);
  };

  const validate = () => {
    if (breakInTime === null) {
      setBreakInError(leaveManagementError.breakInError);
    } else {
      setBreakInError("");
    }
    if (breakOutTime === null) {
      setBreakOutError(leaveManagementError.breakOutError);
    } else {
      setBreakOutError("");
    }
  };

  const createNewBreaks = async () => {
    const breakInData = getTwentyFourHourTime(breakInTime);
    const breakOutData = getTwentyFourHourTime(breakOutTime);
    const checkBreakOutTime =
      parseInt(breakOutData?.split(":")[0]) * 60 +
      parseInt(breakOutData?.split(":")[1]);
    const checkBreakInTime =
      parseInt(breakInData?.split(":")[0]) * 60 +
      parseInt(breakInData?.split(":")[1]);
    if (breakInTime && breakOutTime) {
      if (checkBreakInTime > checkBreakOutTime) {
        const payload = {
          break_in: getTwentyFourHourTime(breakInTime),
          break_out: getTwentyFourHourTime(breakOutTime),
          user: personExpandableId,
          date: breakDayDetails?.date?.split("-").reverse().join("-"),
        };
        const response = await customAPI(
          `${domain}break-user/`,
          "POST",
          payload
        );
        if (response.ok) {
          handleCloseDialog();
          showToast("success", leaveSuccess.breakInOutTime);
          setEditBreakErrors("");
          getCheckInCheckOutDetails(personExpandableId);
        }
      } else {
        setEditBreakErrors(leaveManagementError.breakError);
      }
    } else {
      if (breakInTime === null) {
        setBreakInError(leaveManagementError.breakInError);
      } else {
        setBreakOutError(leaveManagementError.breakOutError);
      }
    }
  };

  const updateBreakDetailsDetails = async () => {
    const breakInData = getTwentyFourHourTime(
      moment(editBreakInTime["$d"]).format("hh:mm A")
    );
    const breakOutData = getTwentyFourHourTime(
      moment(editBreakOutTime["$d"]).format("hh:mm A")
    );
    const checkTotalBreakOutTime =
      parseInt(breakOutData?.split(":")[0]) * 60 +
      parseInt(breakOutData?.split(":")[1]);
    const checkTotalBreakInTime =
      parseInt(breakInData?.split(":")[0]) * 60 +
      parseInt(breakInData?.split(":")[1]);
    if (checkTotalBreakOutTime < checkTotalBreakInTime) {
      const payload = {
        user: personExpandableId,
        break_in: getTwentyFourHourTime(
          moment(editBreakInTime["$d"]).format("hh:mm A")
        ),
        break_out: getTwentyFourHourTime(
          moment(editBreakOutTime["$d"]).format("hh:mm A")
        ),
        date: editBreakDetails?.date?.split("-").reverse().join("-"),
      };
      const response = await customAPI(
        `${domain}break-user/${editBreakDetails?.id}/`,
        "PUT",
        payload
      );
      if (response.ok) {
        setEditBreakDetails(null);
        setEditBreakErrors("");
        getCheckInCheckOutDetails(personExpandableId);
        showToast("success", leaveSuccess.breakInOutTime);
      }
    } else {
      setEditBreakErrors(leaveManagementError.editBreakError);
    }
  };

  const handleCloseDialog = () => {
    setBreakDayDetails(null);
    setBreakPopup(false);
    setBreakOutError(null);
    setBreakInError(null);
    setBreakInTime(null);
    setBreakOutTime(null);
  };

  const handleBreakEnd = (newValue) => {
    if (
      newValue &&
      newValue["$d"] &&
      newValue["$d"].toString() !== "Invalid Date"
    ) {
      setEditBreakInTime(dayjs(newValue["$d"]));
      setEditBreakErrors(null);
    } else {
      setEditBreakErrors("Invalid Break Start format");
    }
  };

  const handleBreakStart = (newValue) => {
    if (
      newValue &&
      newValue["$d"] &&
      newValue["$d"].toString() !== "Invalid Date"
    ) {
      setEditBreakOutTime(dayjs(newValue["$d"]));
      setEditBreakErrors(null);
    } else {
      setEditBreakErrors("Invalid Break Start format");
    }
  };

  const deleteBreakDetails = async (id) => {
    try {
      const response = await fetch(`${domain}break-user/${id}/`, {
        method: "delete",
        headers: {
          "Content-Type": "application/json",
          Authorization: `token ${token}`,
        },
      });
      if (response.ok) {
        showToast("error", "Break deleted successfully");
        getCheckInCheckOutDetails(personExpandableId);
      } else if (response.status === 409) {
        sessionHandling();
      } else {
        const res = await response.json();
        throw new Error(res.error);
      }
    } catch (error) {
      showToast("error", error.message);
    }
  };

  const updateEditDetails = async () => {
    let validation = false;
    if (
      shiftStartTimeFormated &&
      shiftEndTimeFormated &&
      (dayjs(shiftEndDate.format("YYYY-MM-DD")).isSame(
        dayjs(editCheckinCheckOutTime?.date?.split("-").reverse().join("-")),
        "day"
      ) ||
        dayjs(shiftEndDate.format("YYYY-MM-DD")).isSame(
          dayjs(
            editCheckinCheckOutTime?.date?.split("-").reverse().join("-")
          ).add(1, "day"),
          "day"
        ))
    ) {
      if (
        shiftStartDate.format("YYYY-MM-DD") ===
        shiftEndDate.format("YYYY-MM-DD")
      ) {
        const checkInData = getTwentyFourHourTime(
          moment(shiftStartTimeFormated["$d"]).format("hh:mm A")
        );
        const checkOutData = getTwentyFourHourTime(
          moment(shiftEndTimeFormated["$d"]).format("hh:mm A")
        );
        const checkTotalCheckOutTime =
          parseInt(checkOutData?.split(":")[0]) * 60 +
          parseInt(checkOutData?.split(":")[1]);
        const checkTotalCheckInTime =
          parseInt(checkInData?.split(":")[0]) * 60 +
          parseInt(checkInData?.split(":")[1]);
        if (checkTotalCheckInTime < checkTotalCheckOutTime) {
          validation = true;
        } else {
          validation = false;
          setHandleCheckInError(leaveManagementError.checkInError);
        }
      } else {
        validation = true;
      }
    } else {
      if (shiftEndTimeFormated) {
        if (shiftStartTimeFormated) {
          if (
            dayjs(shiftEndDate.format("YYYY-MM-DD")).isSame(
              dayjs(
                editCheckinCheckOutTime?.date?.split("-").reverse().join("-")
              ),
              "day"
            ) ||
            dayjs(shiftEndDate.format("YYYY-MM-DD")).isSame(
              dayjs(
                editCheckinCheckOutTime?.date?.split("-").reverse().join("-")
              ).add(1, "day"),
              "day"
            )
          ) {
            validation = true;
          } else {
            validation = false;
            setHandleCheckInError(
              `The check-out date can only be set to the selected date or the following day.`
            );
          }
        } else {
          validation = false;
          setHandleCheckInError("Invalid check-in format");
        }
      } else if (
        handleCheckInError === "Invalid check-out format" ||
        handleCheckInError === "Invalid check-out format"
      ) {
        validation = false;
        setHandleCheckInError("Invalid check-out format");
      } else {
        if (shiftEndTimeFormated?._d) {
          validation = true;
        } else {
          validation = false;
          setHandleCheckInError("Invalid check-out format");
        }
      }
    }
    if (validation) {
      if (
        editCheckinCheckOutTime.status === "A" ||
        editCheckinCheckOutTime.status === "H" ||
        editCheckinCheckOutTime.status === "W"
      ) {
        setHandleCheckInError("");
        const payload = {
          check_in_time: shiftStartTimeFormated["$d"]
            ? getTwentyFourHourTime(
                moment(shiftStartTimeFormated["$d"]).format("hh:mm A")
              )
            : null,
          check_out_time: shiftEndTimeFormated["$d"]
            ? getTwentyFourHourTime(
                moment(shiftEndTimeFormated["$d"]).format("hh:mm A")
              )
            : null,
          date: editCheckinCheckOutTime.date?.split("-").reverse().join("-"),
          start_date: shiftStartDate.format("YYYY-MM-DD"),
          end_date: shiftEndDate.format("YYYY-MM-DD"),
          user: personExpandableId,
        };
        const response = await customAPI(
          `${domain}check-in-entries/`,
          "POST",
          payload
        );
        if (response.ok) {
          showToast("success", leaveSuccess.checkInOutTime);
          setHandleCheckInError("");
          getCheckInCheckOutDetails(personExpandableId);
          setEditCheckInCheckOut(false);
          setEditCheckInCheckOutDetails(null);
        }
      } else {
        setHandleCheckInError("");
        const payload = {
          id: editCheckinCheckOutTime.id,
          check_out_time: shiftEndTimeFormated["$d"]
            ? getTwentyFourHourTime(
                moment(shiftEndTimeFormated["$d"]).format("hh:mm A")
              )
            : null,
          check_in_time: shiftStartTimeFormated["$d"]
            ? getTwentyFourHourTime(
                moment(shiftStartTimeFormated["$d"]).format("hh:mm A")
              )
            : null,
          start_date: shiftStartDate.format("YYYY-MM-DD"),
          end_date: shiftEndDate.format("YYYY-MM-DD"),
        };
        const response = await customAPI(
          `${domain}update_checkout_time/`,
          "PUT",
          payload
        );
        if (response.ok) {
          showToast("success", leaveSuccess.checkInOutTime);
          setHandleCheckInError("");
          getCheckInCheckOutDetails(personExpandableId);
          setEditCheckInCheckOut(false);
          setEditCheckInCheckOutDetails(null);
        }
      }
    }
  };

  const updateDateAndTimeDetails = (shiftDetails) => {
    const dateObjectCheckInTime = shiftDetails?.check_in_datetime
      ? new Date(shiftDetails?.check_in_datetime)
      : null;
    const dateObjectCheckOutTime = shiftDetails?.check_out_datetime
      ? new Date(shiftDetails?.check_out_datetime)
      : null;
    const formattedDate = dateObjectCheckInTime
      ? dayjs(dateObjectCheckInTime.toISOString().split("T")[0])
      : dayjs(shiftDetails?.date?.split("-").reverse().join("-"));
    const formattedDateCheckout = dateObjectCheckOutTime
      ? dayjs(dateObjectCheckOutTime.toISOString().split("T")[0])
      : dayjs(shiftDetails?.date?.split("-").reverse().join("-"));
    setShiftStartDate(formattedDate);
    setShiftEndDate(formattedDateCheckout);
    const defaultTime = shiftDetails?.check_in_time_formatted
      ? getTwentyFourHourTime(shiftDetails?.check_in_time_formatted)
      : null;
    const defaultCheckOutTime = shiftDetails?.check_out_time_formatted
      ? getTwentyFourHourTime(shiftDetails?.check_out_time_formatted)
      : null;
    const checkIntimeValue = defaultTime ? dayjs(defaultTime, "HH:mm") : null;
    const checkOuttimeValue = defaultCheckOutTime
      ? dayjs(defaultCheckOutTime, "HH:mm")
      : null;
    setShiftStartTimeFormated(checkIntimeValue);
    setShiftEndTimeFormated(checkOuttimeValue);
    setEditCheckInCheckOut(true);
  };

  const handleDateOnChange = (value) => {
    if (value) {
      setShiftStartDate(value);
    } else {
      setHandleCheckInError("Invalid check-in format");
    }
  };

  const handleCheckOutDateOnChange = (value) => {
    if (value) {
      setShiftEndDate(value);
    } else {
      setHandleCheckInError("Invalid check-out format");
    }
  };

  return (
    <React.Fragment>
      <Mui.Grid sx={{ paddingTop: 1 }}>
        <BackArrow actionFunction={() => setShowReport(false)} />
      </Mui.Grid>
      <Mui.Grid container xs={12}>
        <Mui.Grid item xs={3}>
          <Mui.Box
            sx={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
              width: 270,
              height: 120,
              backgroundColor: "#FFF8E5",
            }}
          >
            <Mui.Grid container sx={{ paddingLeft: 2 }}>
              <Mui.Grid
                xs={2}
                container
                justifyContent="center"
                alignItems="center"
                sx={{
                  width: 40,
                  height: 40,
                  backgroundColor: "#FFD83A",
                  borderRadius: "50%",
                }}
              >
                <AccountBalance
                  style={{ color: "white", alignItems: "center" }}
                  fontSize="medium"
                />
              </Mui.Grid>
              &nbsp; &nbsp;
              <Mui.Grid xs={7}>
                <Mui.Typography
                  style={{
                    fontSize: "18px",
                    fontWeight: "800",
                    textTransform: "capitalize",
                  }}
                >
                  {departmentName?.length > 10 ? (
                    <Mui.Tooltip title={departmentName}>
                      <span>{`${departmentName.substring(0, 10)}..`}</span>
                    </Mui.Tooltip>
                  ) : (
                    departmentName
                  )}
                </Mui.Typography>
                <Mui.Typography
                  sx={{
                    fontSize: 15,
                    fontWeight: 600,
                  }}
                >
                  {shiftCatagoryName?.length > 10 ? (
                    <Mui.Tooltip title={shiftCatagoryName}>
                      <span>{`${shiftCatagoryName.substring(0, 10)}..`}</span>
                    </Mui.Tooltip>
                  ) : (
                    shiftCatagoryName
                  )}
                </Mui.Typography>
              </Mui.Grid>
              <Mui.Grid xs={2} sx={{ paddingRight: 2 }}>
                <Mui.IconButton
                  onClick={(e) => setExpandedSubMenuAnchorEl(e.currentTarget)}
                >
                  <KeyboardArrowDown />
                </Mui.IconButton>
              </Mui.Grid>
            </Mui.Grid>
          </Mui.Box>

          <Mui.Popover
            anchorEl={expandedSubMenuAnchorEl}
            open={Boolean(expandedSubMenuAnchorEl)}
            onClose={() => {
              setExpandedSubMenuAnchorEl(null);
              setSearchQuery("");
            }}
            anchorReference="anchorPosition"
            anchorPosition={{ top: 326, left: 520 }}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "center",
              horizontal: "right",
            }}
            PaperProps={{
              style: {
                maxHeight: "200px",
                width: "270px",
                overflowY: "auto",
              },
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                height: "200px",
              }}
            >
              <Mui.TextField
                variant="outlined"
                size="small"
                margin="dense"
                placeholder="Search..."
                value={searchQuery}
                onChange={handleSearchChange}
                fullWidth
                InputProps={{
                  endAdornment: (
                    <Mui.InputAdornment position="end">
                      <Search />
                    </Mui.InputAdornment>
                  ),
                }}
              />
              <div style={{ flex: 1, overflowY: "auto", height: "50px" }}>
                {filteredData.length > 0 ? (
                  filteredData.map((data) => (
                    <Mui.MenuItem
                      onClick={() => {
                        if (data && data.label) {
                          setDepartmentId(data.id);
                          setDepartmentName(data.department_name);
                          setShiftCatagoryName(data.shift_category_name);
                          localStorage.setItem(
                            "approveLeaveDepartmentId",
                            data.id
                          );
                          localStorage.setItem(
                            "approveLeaveDepartmentName",
                            data.label
                          );
                          dispatch(setDepartmentName(data.label));
                          dispatch(setDepartmentId(data.id));
                          setPersonExpandableId(0);
                          setRemoveExpand(true);
                          if (reportedType !== "Punch details") {
                            setFetchSummarizedReport(true);
                          } else {
                            setFetchPunchDetails(true);
                          }
                        }
                        setExpandedSubMenuAnchorEl(null);
                      }}
                    >
                      {data.label}
                    </Mui.MenuItem>
                  ))
                ) : (
                  <Mui.MenuItem disabled>No data available</Mui.MenuItem>
                )}
              </div>
            </div>
          </Mui.Popover>
        </Mui.Grid>
        <ContentGrid
          item
          xs={12}
          sm={12}
          md={8}
          lg={9}
          sx={{ paddingRight: 2 }}
        >
          <Mui.Grid
            container
            style={{
              display: "flex",
              flexDirection: "row",
              alignSelf: "flex-end",
              justifyContent: "flex-end",
            }}
          >
            <Mui.Autocomplete
              disablePortal
              id="combo-box-demo"
              size="small"
              style={{
                width: 300,
                marginLeft: 5,
              }}
              value={
                shiftData?.find((option) => option?.name === shiftName) || "All"
              }
              options={shiftData}
              getOptionLabel={(option) =>
                option?.name ? option?.name : option
              }
              filterOptions={(options, state) => {
                return options.filter((option) =>
                  option.name
                    .toLowerCase()
                    .includes(state.inputValue.toLowerCase())
                );
              }}
              renderOption={(props, option) => {
                return <li {...props}>{option.name}</li>;
              }}
              ListboxProps={{
                style: {
                  maxHeight: "150px",
                },
              }}
              onChange={async (event, value) => {
                setshiftId("");
                setShiftName("");
                if (value) {
                  setShiftName(value.name);
                  setshiftId(value.id);
                  setPersonExpandableId(0);
                  setRemoveExpand(true);
                  if (reportedType === "Punch details") {
                    setFetchPunchDetails(true);
                  } else {
                    setFetchSummarizedReport(true);
                  }
                }
              }}
              renderInput={(params) => (
                <Mui.TextField
                  {...params}
                  sx={{ background: themes.whiteColor }}
                  placeholder="Select shift"
                  label="Select Shift"
                />
              )}
            />
            &nbsp;&nbsp;
            <Mui.Stack
              style={{
                width: 320,
                marginLeft: 5,
              }}
            >
              <DateRangePicker
                handleStartDate={handleStartDate}
                selectedDateRange={selectedDateRange}
              />
            </Mui.Stack>
            &nbsp;&nbsp;
            <Mui.Autocomplete
              disablePortal
              id="combo-box-demo"
              size="small"
              style={{
                width: 290,
                marginLeft: 5,
                marginRight: 5,
              }}
              value={reportedType}
              options={reports}
              ListboxProps={{ style: { maxHeight: "150px" } }}
              filterOptions={(options, state) => {
                return options.filter((option) =>
                  option.label
                    .toLowerCase()
                    .includes(state.inputValue.toLowerCase())
                );
              }}
              renderOption={(props, option) => {
                return <li {...props}>{option.label}</li>;
              }}
              onChange={(event, value) => {
                if (value) {
                  setReportedType(value.label);
                  setPersonExpandableId(0);
                  setRemoveExpand(true);
                  if (value.label === "Punch details") {
                    setFetchPunchDetails(true);
                  } else {
                    setFetchSummarizedReport(true);
                  }
                }
              }}
              renderInput={(params) => (
                <Mui.TextField
                  {...params}
                  value={reportedType}
                  label="Report Type"
                  placeholder="Select the report type"
                  size="small"
                />
              )}
            />
            <Mui.Grid
              sx={{
                display: "flex",
                flexDirection: "row",
                paddingBottom: 0.2,
              }}
            >
              <Mui.IconButton
                onClick={() => {
                  reportedType === "Summarized working hours"
                    ? summarizeExport()
                    : reportedType === "Punch details"
                    ? setPunchExports(true)
                    : reportedType === "Detailed working hours"
                    ? exportDetailedReport()
                    : exportOverTimeReport(true);
                }}
              >
                <DownloadForOffline
                  size="large"
                  sx={{ color: "#25B7D3", fontSize: 50 }}
                />
              </Mui.IconButton>
            </Mui.Grid>
          </Mui.Grid>
        </ContentGrid>
      </Mui.Grid>
      <Mui.Grid
        container
        spacing={2}
        sx={{ paddingTop: { xs: 1, md: 1, sm: 1, lg: 1 } }}
      ></Mui.Grid>
      {(reportedType === "Summarized working hours" ||
        reportedType === "Detailed working hours") &&
      !loading ? (
        <Mui.Grid container sx={{ paddingTop: 6 }}>
          <Mui.TableContainer sx={{ mt: 2, width: "100%", paddingRight: 2 }}>
            <Mui.Table>
              <Mui.TableHead
                sx={{
                  backgroundColor: themes.primary,
                }}
              >
                <Mui.TableRow sx={tableRowSx}>
                  {reportedType === "Detailed working hours" ? (
                    <Mui.TableCell></Mui.TableCell>
                  ) : null}
                  {firstLevelWorkingHours.map((header) => (
                    <Mui.TableCell
                      sx={{
                        color: themes.blackColor,
                        fontWeight: "bold",
                      }}
                    >
                      {header.header}
                    </Mui.TableCell>
                  ))}
                </Mui.TableRow>
              </Mui.TableHead>
              <Mui.TableBody>
                {workingHoursDetails
                  ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((element) => (
                    <>
                      <Mui.TableRow sx={tableRowSx}>
                        {reportedType === "Detailed working hours" ? (
                          <Mui.TableCell>
                            {element?.user_id === personExpandableId ? (
                              <Mui.IconButton
                                onClick={() => {
                                  setBreakExpandableDate(0);
                                  setPersonExpandableId(0);
                                  setEditBreakDetails(null);
                                  setEditBreakErrors();
                                  // setEditCheckInCheckOutDetails(null);
                                }}
                              >
                                <KeyboardArrowDown />
                              </Mui.IconButton>
                            ) : (
                              <Mui.IconButton
                                onClick={() => {
                                  setBreakExpandableDate(0);
                                  setUserDetails([]);
                                  setDepartmentLeadId(
                                    element?.department_lead_id
                                  );
                                  setPersonExpandableId(element?.user_id);
                                  getCheckInCheckOutDetails(element?.user_id);
                                }}
                                style={{
                                  padding: "0px",
                                }}
                              >
                                <ChevronRight />
                              </Mui.IconButton>
                            )}
                          </Mui.TableCell>
                        ) : null}
                        {firstLevelWorkingHours.map((header) => (
                          <Mui.TableCell sx={{ color: themes.blackColor }}>
                            {element[header.key] || element[header.key] === 0
                              ? element[header.key]
                              : "N/A"}
                          </Mui.TableCell>
                        ))}
                      </Mui.TableRow>

                      {personExpandableId === element?.user_id && userLoader ? (
                        <Mui.TableRow sx={tableRowSx}>
                          <Mui.TableCell
                            colSpan={10}
                            style={{ textAlign: "center" }}
                          >
                            <Mui.Grid container justifyContent="center">
                              <Loader info="loading" />
                            </Mui.Grid>
                          </Mui.TableCell>
                        </Mui.TableRow>
                      ) : personExpandableId === element?.user_id ? (
                        <Mui.TableRow sx={tableRowSx}>
                          <Mui.TableCell colSpan={12}>
                            <Mui.TableContainer
                              sx={{
                                marginTop: 0,
                                marginLeft: "auto",
                                marginRight: "auto",
                                width: "100%",
                                maxHeight: "650px",
                              }}
                              className="w-96 border rounded"
                            >
                              {element?.check_ins?.length !== 0 ? (
                                userDetails && (
                                  <Mui.Table className="border">
                                    <Mui.TableHead
                                      sx={{
                                        backgroundColor: themes.primary,
                                        position: "sticky",
                                        top: -1,
                                        zIndex: 10,
                                      }}
                                    >
                                      <Mui.TableRow sx={tableRowSx}>
                                        <Mui.TableCell></Mui.TableCell>
                                        {workingHoursHeader.map((header) => (
                                          <Mui.TableCell
                                            sx={{
                                              color: themes.blackColor,
                                              fontWeight: "bold",
                                            }}
                                          >
                                            {header.header}
                                          </Mui.TableCell>
                                        ))}
                                        {(personExpandableId !==
                                          currentUserDetails.id &&
                                          departmentLeadId ===
                                            currentUserDetails.id) ||
                                        currentUserDetails.role === "Admin" ? (
                                          <Mui.TableCell
                                            sx={{
                                              color: themes.blackColor,
                                              fontWeight: "bold",
                                            }}
                                          >
                                            Action
                                          </Mui.TableCell>
                                        ) : null}
                                        <Mui.TableCell></Mui.TableCell>
                                      </Mui.TableRow>
                                    </Mui.TableHead>
                                    <Mui.TableBody>
                                      {userDetails?.checkin_details?.map(
                                        (shiftDetails, index) =>
                                          shiftDetails?.status === "HL" ||
                                          shiftDetails?.status === "L" ? (
                                            <>
                                              <Mui.TableRow
                                                key={index}
                                                sx={tableRowSx}
                                              >
                                                <Mui.TableCell> </Mui.TableCell>
                                                <Mui.TableCell>
                                                  {shiftDetails?.date}
                                                </Mui.TableCell>
                                                <Mui.TableCell> </Mui.TableCell>
                                                <Mui.TableCell> </Mui.TableCell>
                                                <Mui.TableCell> </Mui.TableCell>
                                                <Mui.TableCell> </Mui.TableCell>
                                                {shiftDetails?.status ===
                                                "L" ? (
                                                  <Mui.TableCell></Mui.TableCell>
                                                ) : (
                                                  <Mui.TableCell>
                                                    {shiftDetails?.break_hours}
                                                  </Mui.TableCell>
                                                )}
                                                {shiftDetails?.status ===
                                                "L" ? (
                                                  <Mui.TableCell></Mui.TableCell>
                                                ) : (
                                                  <Mui.TableCell>
                                                    {shiftDetails?.over_time}
                                                  </Mui.TableCell>
                                                )}
                                                {shiftDetails?.status ===
                                                "L" ? (
                                                  <Mui.TableCell></Mui.TableCell>
                                                ) : (
                                                  <Mui.TableCell>
                                                    {
                                                      shiftDetails?.working_hours
                                                    }
                                                  </Mui.TableCell>
                                                )}
                                                <Mui.TableCell>
                                                  {shiftDetails.status ===
                                                    "L" ||
                                                  shiftDetails.status ===
                                                    "H" ? (
                                                    <Mui.Typography>
                                                      {
                                                        shiftDetails.Leave_credit_hours
                                                      }
                                                    </Mui.Typography>
                                                  ) : shiftDetails.status ===
                                                    "HL" ? (
                                                    <Mui.Typography>
                                                      {
                                                        shiftDetails.Leave_credit_hours
                                                      }
                                                    </Mui.Typography>
                                                  ) : null}
                                                </Mui.TableCell>
                                                <Mui.TableCell>
                                                  <Mui.Typography
                                                    sx={{
                                                      display: "inline-block",
                                                      width: "28px",
                                                      color:
                                                        shiftDetails.status ===
                                                        "W"
                                                          ? themes.blackColor
                                                          : themes.whiteColor,

                                                      height: "28px",
                                                      textAlign: "center",
                                                      lineHeight: "29px",
                                                      backgroundColor:
                                                        getColorWorkingHours(
                                                          shiftDetails.status
                                                        ),
                                                    }}
                                                  >
                                                    {shiftDetails.status ===
                                                    "HL" ? (
                                                      <Mui.Grid container>
                                                        <Mui.Typography
                                                          sx={{
                                                            width: "14px",
                                                            height: "28px",
                                                            textAlign: "center",
                                                            lineHeight: "29px",
                                                            backgroundColor:
                                                              shiftDetails.half_day_leave_period ===
                                                              "2nd"
                                                                ? themes.greenColor
                                                                : themes.yellowColor,
                                                          }}
                                                        >
                                                          H
                                                        </Mui.Typography>
                                                        <Mui.Typography
                                                          sx={{
                                                            width: "14px",
                                                            height: "28px",
                                                            textAlign: "center",
                                                            lineHeight: "29px",
                                                            backgroundColor:
                                                              shiftDetails.half_day_leave_period ===
                                                              "2nd"
                                                                ? themes.yellowColor
                                                                : themes.greenColor,
                                                          }}
                                                        >
                                                          L
                                                        </Mui.Typography>
                                                      </Mui.Grid>
                                                    ) : (
                                                      shiftDetails.status
                                                    )}
                                                  </Mui.Typography>
                                                </Mui.TableCell>
                                                <Mui.TableCell></Mui.TableCell>

                                                {(personExpandableId !==
                                                  currentUserDetails.id &&
                                                  departmentLeadId ===
                                                    currentUserDetails.id) ||
                                                currentUserDetails.role ===
                                                  "Admin" ? (
                                                  <Mui.TableCell>
                                                    {" "}
                                                  </Mui.TableCell>
                                                ) : null}
                                              </Mui.TableRow>
                                            </>
                                          ) : (
                                            <>
                                              <Mui.TableRow
                                                key={index}
                                                sx={tableRowSx}
                                              >
                                                <Mui.TableCell>
                                                  {shiftDetails?.date ===
                                                  breakExpandableDate ? (
                                                    <Mui.IconButton
                                                      onClick={() => {
                                                        setBreakExpandableDate(
                                                          0
                                                        );
                                                      }}
                                                    >
                                                      <KeyboardArrowDown />
                                                    </Mui.IconButton>
                                                  ) : (
                                                    <Mui.IconButton
                                                      onClick={() =>
                                                        setBreakExpandableDate(
                                                          shiftDetails?.date
                                                        )
                                                      }
                                                      style={{
                                                        padding: "0px",
                                                      }}
                                                    >
                                                      <ChevronRight />
                                                    </Mui.IconButton>
                                                  )}
                                                </Mui.TableCell>
                                                <Mui.TableCell>
                                                  {shiftDetails?.date}
                                                </Mui.TableCell>
                                                <Mui.TableCell>
                                                  {shiftDetails?.shift}
                                                </Mui.TableCell>

                                                <Mui.TableCell>
                                                  {
                                                    shiftDetails?.check_in_time_formatted
                                                  }
                                                </Mui.TableCell>

                                                <Mui.TableCell>
                                                  {
                                                    shiftDetails?.check_out_time_formatted
                                                  }
                                                </Mui.TableCell>
                                                {shiftDetails.status === "A" ||
                                                shiftDetails.status === "W" ? (
                                                  <Mui.TableCell></Mui.TableCell>
                                                ) : (
                                                  <Mui.TableCell>
                                                    {shiftDetails?.break_hours}
                                                  </Mui.TableCell>
                                                )}

                                                {shiftDetails.status === "A" ||
                                                shiftDetails.status === "W" ? (
                                                  <Mui.TableCell></Mui.TableCell>
                                                ) : (
                                                  <Mui.TableCell>
                                                    {shiftDetails?.shift_hours
                                                      ? shiftDetails?.shift_hours
                                                      : "N/A"}
                                                  </Mui.TableCell>
                                                )}
                                                {shiftDetails.status === "A" ||
                                                shiftDetails.status === "W" ? (
                                                  <Mui.TableCell></Mui.TableCell>
                                                ) : (
                                                  <Mui.TableCell>
                                                    {shiftDetails?.over_time}
                                                  </Mui.TableCell>
                                                )}

                                                {shiftDetails.status === "A" ||
                                                shiftDetails.status === "W" ? (
                                                  <Mui.TableCell></Mui.TableCell>
                                                ) : (
                                                  <Mui.TableCell>
                                                    {
                                                      shiftDetails?.working_hours
                                                    }
                                                  </Mui.TableCell>
                                                )}
                                                {shiftDetails.status === "A" ||
                                                shiftDetails.status === "W" ||
                                                shiftDetails.status === "P" ||
                                                shiftDetails.status === "PR" ? (
                                                  <Mui.TableCell></Mui.TableCell>
                                                ) : (
                                                  <Mui.TableCell>
                                                    {
                                                      shiftDetails?.Leave_credit_hours
                                                    }
                                                  </Mui.TableCell>
                                                )}
                                                <Mui.TableCell>
                                                  <Mui.Typography
                                                    sx={{
                                                      display: "inline-block",
                                                      width: "28px",
                                                      color:
                                                        shiftDetails.status ===
                                                        "W"
                                                          ? themes.blackColor
                                                          : themes.whiteColor,
                                                      border:
                                                        shiftDetails.status ===
                                                        "W"
                                                          ? "1px solid black"
                                                          : "none",
                                                      height: "28px",
                                                      textAlign: "center",
                                                      lineHeight: "29px",
                                                      backgroundColor:
                                                        getColorWorkingHours(
                                                          shiftDetails.status
                                                        ),
                                                    }}
                                                  >
                                                    {shiftDetails.status ===
                                                      "PL" ||
                                                    shiftDetails.status ===
                                                      "LP" ? (
                                                      <Mui.Grid container>
                                                        {shiftDetails.status ===
                                                          "PL" && (
                                                          <>
                                                            <Mui.Typography
                                                              sx={{
                                                                width: "14px",
                                                                height: "28px",
                                                                textAlign:
                                                                  "center",
                                                                lineHeight:
                                                                  "29px",
                                                                backgroundColor:
                                                                  themes.greenColor,
                                                              }}
                                                            >
                                                              P
                                                            </Mui.Typography>
                                                            <Mui.Typography
                                                              sx={{
                                                                width: "14px",
                                                                height: "28px",
                                                                textAlign:
                                                                  "center",
                                                                lineHeight:
                                                                  "29px",
                                                                backgroundColor:
                                                                  themes.yellowColor,
                                                              }}
                                                            >
                                                              L
                                                            </Mui.Typography>
                                                          </>
                                                        )}
                                                        {shiftDetails.status ===
                                                          "LP" && (
                                                          <>
                                                            <Mui.Typography
                                                              sx={{
                                                                width: "14px",
                                                                height: "28px",
                                                                textAlign:
                                                                  "center",
                                                                lineHeight:
                                                                  "29px",
                                                                backgroundColor:
                                                                  themes.yellowColor,
                                                              }}
                                                            >
                                                              L
                                                            </Mui.Typography>
                                                            <Mui.Typography
                                                              sx={{
                                                                width: "14px",
                                                                height: "28px",
                                                                textAlign:
                                                                  "center",
                                                                lineHeight:
                                                                  "29px",
                                                                backgroundColor:
                                                                  themes.greenColor,
                                                              }}
                                                            >
                                                              P
                                                            </Mui.Typography>
                                                          </>
                                                        )}
                                                      </Mui.Grid>
                                                    ) : (
                                                      shiftDetails.status
                                                    )}
                                                  </Mui.Typography>
                                                </Mui.TableCell>

                                                {(personExpandableId !==
                                                  currentUserDetails.id &&
                                                  departmentLeadId ===
                                                    currentUserDetails.id) ||
                                                currentUserDetails.role ===
                                                  "Admin" ? (
                                                  <Mui.TableCell>
                                                    <>
                                                      <Mui.Grid
                                                        container
                                                        xs={12}
                                                      >
                                                        <Mui.Grid item xs={5}>
                                                          <Mui.IconButton
                                                            onClick={() => {
                                                              setEditCheckInCheckOutDetails(
                                                                shiftDetails
                                                              );
                                                              updateDateAndTimeDetails(
                                                                shiftDetails
                                                              );
                                                            }}
                                                          >
                                                            <DriveFileRenameOutline />
                                                          </Mui.IconButton>
                                                        </Mui.Grid>

                                                        <Mui.Grid item xs={5}>
                                                          <Mui.IconButton
                                                            onClick={() => {
                                                              setBreakDayDetails(
                                                                shiftDetails
                                                              );
                                                              setBreakPopup(
                                                                true
                                                              );
                                                            }}
                                                          >
                                                            <Loupe />
                                                          </Mui.IconButton>
                                                        </Mui.Grid>
                                                      </Mui.Grid>
                                                    </>
                                                  </Mui.TableCell>
                                                ) : null}
                                                <Mui.TableCell></Mui.TableCell>
                                              </Mui.TableRow>

                                              {breakExpandableDate ===
                                                shiftDetails?.date && (
                                                <Mui.TableRow sx={tableRowSx}>
                                                  <Mui.TableCell colSpan={12}>
                                                    <Mui.TableContainer
                                                      sx={{
                                                        marginTop: 0,
                                                        marginLeft: "auto",
                                                        marginRight: "auto",
                                                        width: "100%",
                                                      }}
                                                      className="w-96 border rounded"
                                                    >
                                                      {shiftDetails
                                                        ?.break_details
                                                        ?.length !== 0 ? (
                                                        <Mui.Table className="border">
                                                          <Mui.TableHead
                                                            sx={{
                                                              backgroundColor:
                                                                themes.primary,
                                                            }}
                                                          >
                                                            <Mui.TableRow
                                                              sx={tableRowSx}
                                                            >
                                                              <Mui.TableCell
                                                                sx={{
                                                                  color:
                                                                    themes.blackColor,
                                                                  fontWeight:
                                                                    "bold",
                                                                }}
                                                              >
                                                                Break Start
                                                              </Mui.TableCell>
                                                              <Mui.TableCell
                                                                sx={{
                                                                  color:
                                                                    themes.blackColor,
                                                                  fontWeight:
                                                                    "bold",
                                                                }}
                                                              >
                                                                Break End
                                                              </Mui.TableCell>
                                                              <Mui.TableCell
                                                                sx={{
                                                                  color:
                                                                    themes.blackColor,
                                                                  fontWeight:
                                                                    "bold",
                                                                }}
                                                              >
                                                                Break-Time
                                                              </Mui.TableCell>

                                                              {(personExpandableId !==
                                                                currentUserDetails.id &&
                                                                departmentLeadId ===
                                                                  currentUserDetails.id) ||
                                                              currentUserDetails.role ===
                                                                "Admin" ? (
                                                                <Mui.TableCell
                                                                  sx={{
                                                                    color:
                                                                      themes.blackColor,
                                                                    fontWeight:
                                                                      "bold",
                                                                  }}
                                                                >
                                                                  Action
                                                                </Mui.TableCell>
                                                              ) : null}
                                                            </Mui.TableRow>
                                                          </Mui.TableHead>
                                                          <Mui.TableBody>
                                                            {shiftDetails?.break_details?.map(
                                                              (
                                                                shift,
                                                                index
                                                              ) => (
                                                                <Mui.TableRow
                                                                  key={index}
                                                                  sx={
                                                                    tableRowSx
                                                                  }
                                                                >
                                                                  {editBreakDetails?.id ===
                                                                  shift?.id ? (
                                                                    <Mui.TableCell>
                                                                      <LocalizationProvider
                                                                        dateAdapter={
                                                                          AdapterDayjs
                                                                        }
                                                                      >
                                                                        <DemoContainer
                                                                          components={[
                                                                            "TimePicker",
                                                                          ]}
                                                                        >
                                                                          <TimePicker
                                                                            label="Break Start"
                                                                            size={
                                                                              5
                                                                            }
                                                                            value={
                                                                              editBreakOutTime
                                                                            }
                                                                            onChange={(
                                                                              newValue
                                                                            ) =>
                                                                              handleBreakStart(
                                                                                newValue
                                                                              )
                                                                            }
                                                                          />
                                                                        </DemoContainer>
                                                                        <Mui.Typography
                                                                          sx={{
                                                                            color:
                                                                              "red",
                                                                            fontSize: 12,
                                                                          }}
                                                                        >
                                                                          {ediBreakErrors ===
                                                                          "Invalid break time format"
                                                                            ? "Invalid break start/end time format"
                                                                            : ediBreakErrors ===
                                                                              "Invalid Break Start format"
                                                                            ? ediBreakErrors
                                                                            : null}
                                                                          {ediBreakErrors ===
                                                                          "Breakout time to be earlier than break start time"
                                                                            ? ediBreakErrors
                                                                            : null}
                                                                        </Mui.Typography>
                                                                      </LocalizationProvider>
                                                                    </Mui.TableCell>
                                                                  ) : (
                                                                    <Mui.TableCell>
                                                                      {
                                                                        shift?.break_out_formatted
                                                                      }
                                                                    </Mui.TableCell>
                                                                  )}
                                                                  {editBreakDetails?.id ===
                                                                  shift?.id ? (
                                                                    <Mui.TableCell>
                                                                      <LocalizationProvider
                                                                        dateAdapter={
                                                                          AdapterDayjs
                                                                        }
                                                                      >
                                                                        <DemoContainer
                                                                          components={[
                                                                            "TimePicker",
                                                                          ]}
                                                                        >
                                                                          <TimePicker
                                                                            label="Break End"
                                                                            size={
                                                                              5
                                                                            }
                                                                            value={
                                                                              editBreakInTime
                                                                            }
                                                                            onChange={(
                                                                              newValue
                                                                            ) =>
                                                                              handleBreakEnd(
                                                                                newValue
                                                                              )
                                                                            }
                                                                          />
                                                                        </DemoContainer>
                                                                        <Mui.Typography
                                                                          sx={{
                                                                            color:
                                                                              "red",
                                                                            fontSize: 12,
                                                                          }}
                                                                        >
                                                                          {ediBreakErrors ===
                                                                          "Invalid break time format"
                                                                            ? "Invalid break start/end time format"
                                                                            : ediBreakErrors ===
                                                                              "Invalid Break Start format"
                                                                            ? ediBreakErrors
                                                                            : null}
                                                                          {ediBreakErrors ===
                                                                          "Breakout time to be earlier than break start time"
                                                                            ? ediBreakErrors
                                                                            : null}
                                                                        </Mui.Typography>
                                                                      </LocalizationProvider>
                                                                    </Mui.TableCell>
                                                                  ) : (
                                                                    <Mui.TableCell>
                                                                      {
                                                                        shift?.break_in_formatted
                                                                      }
                                                                    </Mui.TableCell>
                                                                  )}
                                                                  <Mui.TableCell>
                                                                    {
                                                                      shift?.individual_break_time
                                                                    }
                                                                  </Mui.TableCell>
                                                                  {(personExpandableId !==
                                                                    currentUserDetails.id &&
                                                                    departmentLeadId ===
                                                                      currentUserDetails.id) ||
                                                                  currentUserDetails.role ===
                                                                    "Admin" ? (
                                                                    <Mui.TableCell>
                                                                      {editBreakDetails?.id ===
                                                                      shift?.id ? (
                                                                        <>
                                                                          <Mui.IconButton
                                                                            onClick={() =>
                                                                              updateBreakDetailsDetails()
                                                                            }
                                                                          >
                                                                            <Done
                                                                              sx={{
                                                                                color:
                                                                                  themes.greenColor,
                                                                                strokeWidth: 2,
                                                                              }}
                                                                            />
                                                                          </Mui.IconButton>
                                                                          <Mui.IconButton
                                                                            onClick={() => {
                                                                              setEditBreakDetails(
                                                                                null
                                                                              );
                                                                              setEditBreakErrors();
                                                                            }}
                                                                          >
                                                                            <Cancel
                                                                              sx={{
                                                                                color:
                                                                                  themes.redColor,
                                                                                strokeWidth: 2,
                                                                              }}
                                                                            />
                                                                          </Mui.IconButton>
                                                                        </>
                                                                      ) : (
                                                                        <>
                                                                          <Mui.IconButton
                                                                            onClick={() => {
                                                                              handleBreakDetails(
                                                                                shift
                                                                              );
                                                                            }}
                                                                          >
                                                                            <DriveFileRenameOutline size="small" />
                                                                          </Mui.IconButton>

                                                                          <Mui.IconButton
                                                                            onClick={() => {
                                                                              deleteBreakDetails(
                                                                                shift.id
                                                                              );
                                                                            }}
                                                                          >
                                                                            <DeleteSharp
                                                                              size="large"
                                                                              sx={{
                                                                                color:
                                                                                  themes.redColor,
                                                                                strokeWidth: 2,
                                                                              }}
                                                                            />
                                                                          </Mui.IconButton>
                                                                        </>
                                                                      )}
                                                                    </Mui.TableCell>
                                                                  ) : null}
                                                                </Mui.TableRow>
                                                              )
                                                            )}
                                                          </Mui.TableBody>
                                                        </Mui.Table>
                                                      ) : (
                                                        <Mui.Stack
                                                          sx={{
                                                            padding: 0.5,
                                                          }}
                                                          spacing={2}
                                                          fullWidthy
                                                        >
                                                          <Mui.Alert severity="info">
                                                            There is a no
                                                            available breaks
                                                          </Mui.Alert>
                                                        </Mui.Stack>
                                                      )}
                                                    </Mui.TableContainer>
                                                  </Mui.TableCell>
                                                </Mui.TableRow>
                                              )}
                                            </>
                                          )
                                      )}
                                    </Mui.TableBody>
                                    <Mui.TableBody>
                                      <Mui.TableRow sx={tableRowSx}>
                                        <Mui.TableCell></Mui.TableCell>
                                        <Mui.TableCell></Mui.TableCell>
                                        <Mui.TableCell></Mui.TableCell>
                                        <Mui.TableCell></Mui.TableCell>
                                        <Mui.TableCell></Mui.TableCell>
                                        <Mui.TableCell></Mui.TableCell>
                                        <Mui.TableCell>
                                          <Mui.Typography>Total</Mui.Typography>
                                        </Mui.TableCell>
                                        <Mui.TableCell>
                                          {" "}
                                          {userDetails?.total_over_time}
                                        </Mui.TableCell>
                                        <Mui.TableCell>
                                          {userDetails?.total_working_hours}
                                        </Mui.TableCell>
                                        <Mui.TableCell>
                                          {" "}
                                          {
                                            userDetails?.total_leave_credit_hours
                                          }
                                        </Mui.TableCell>
                                        <Mui.TableCell></Mui.TableCell>
                                      </Mui.TableRow>
                                    </Mui.TableBody>
                                  </Mui.Table>
                                )
                              ) : (
                                <Mui.Stack
                                  sx={{ padding: 0.5 }}
                                  spacing={2}
                                  fullWidthy
                                >
                                  <Mui.Alert severity="info">
                                    There is a no available shifts
                                  </Mui.Alert>
                                </Mui.Stack>
                              )}
                            </Mui.TableContainer>
                          </Mui.TableCell>
                        </Mui.TableRow>
                      ) : null}
                    </>
                  ))}
              </Mui.TableBody>
            </Mui.Table>
            {workingHoursDetails.length === 0 && loading === false ? (
              <Mui.Alert sx={{ marginTop: 2, marginBottom: 2 }} severity="info">
                No data available
              </Mui.Alert>
            ) : null}
            {workingHoursDetails?.length > 25 && (
              <Mui.TablePagination
                className="custom-pagination"
                component="div"
                rowsPerPageOptions={[25, 50, 75, 100]}
                count={workingHoursDetails.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                loading={loading}
              />
            )}
          </Mui.TableContainer>
        </Mui.Grid>
      ) : loading ? (
        <Loader info="Loading..." />
      ) : reportedType === "Punch details" ? (
        <PunchDetails
          masterPunchDetails={masterPunchDetails}
          departmentId={departmentId}
          shiftId={shiftId}
          startDate={startDate}
          endDate={endDate}
          departmentName={departmentName}
          shiftCategoryName={shiftCatagoryName}
          shiftName={shiftName}
          selectedDateRange={selectedDateRange}
          removeExpand={removeExpand}
          setRemoveExpand={setRemoveExpand}
          fetchPunchDetails={fetchPunchDetails}
          setFetchPunchDetails={setFetchPunchDetails}
          setPunchExports={setPunchExports}
          page={page}
          setLeavePage={setLeavePage}
          rowsPerPage={rowsPerPage}
          setLeaveRowsPerPage={setLeaveRowsPerPage}
          punchExports={punchExports}
          punchDetailsData={punchDetailsData}
        />
      ) : (
        <OtReports
          workingHoursDetails={workingHoursDetails}
          exportOTime={exportOTime}
          exportOverTimeReport={exportOverTimeReport}
          departmentName={departmentName}
          shiftCatagoryName={shiftCatagoryName}
          shiftName={shiftName}
          startDate={startDate}
          endDate={endDate}
        />
      )}
      <GlobalDialogBox
        handleCloseDialog={handleCloseDialog}
        open={breakPopup}
        title="Add Breaks"
      >
        <Mui.Grid container sx={{ paddingBottom: 1 }}>
          <Mui.Grid item xs={5.5}>
            <Mui.Typography>Date</Mui.Typography>
          </Mui.Grid>
          <Mui.Grid item xs={1} sx={{ paddingLeft: 2 }}>
            <Mui.Typography>:</Mui.Typography>
          </Mui.Grid>
          <Mui.Grid item xs={5.5}>
            <Mui.Typography>
              {breakDayDetails ? breakDayDetails?.date : null}
            </Mui.Typography>
          </Mui.Grid>
        </Mui.Grid>
        <Mui.Grid container>
          <Mui.Grid item xs={5.5}>
            <Mui.Typography>Break Start Time</Mui.Typography>
          </Mui.Grid>
          <Mui.Grid item xs={1}></Mui.Grid>
          <Mui.Grid item xs={5.5}>
            <Mui.Typography>Break End Time</Mui.Typography>
          </Mui.Grid>
        </Mui.Grid>
        <Mui.Grid container>
          <Mui.Grid item xs={5.5}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoContainer components={["TimePicker"]}>
                <TimePicker
                  label="Break Start"
                  onChange={(newValue) => {
                    setBreakOutTime(moment(newValue["$d"]).format("hh:mm A"));
                    validate();
                  }}
                />
              </DemoContainer>
            </LocalizationProvider>
            {breakOutError === "Please enter breakout time" ? (
              <Mui.Typography sx={{ color: themes.redColor, fontSize: 12 }}>
                {breakOutError}
              </Mui.Typography>
            ) : null}
          </Mui.Grid>
          <Mui.Grid item xs={1} sx={{ paddingTop: 3, paddingLeft: 2 }}>
            <Mui.Typography> To</Mui.Typography>
          </Mui.Grid>
          <Mui.Grid item xs={5.5}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoContainer components={["TimePicker"]}>
                <TimePicker
                  label="Break End"
                  onChange={(newValue) => {
                    setBreakInTime(moment(newValue["$d"]).format("hh:mm A"));
                    validate();
                  }}
                />
              </DemoContainer>
            </LocalizationProvider>
            {breakInError === "Please enter breakin time" ? (
              <Mui.Typography sx={{ color: "red" }}>
                {breakInError}
              </Mui.Typography>
            ) : null}
          </Mui.Grid>
          <Mui.Typography sx={{ color: themes.redColor, fontSize: 12 }}>
            {ediBreakErrors === "Breakout time is lesser then breakin time"
              ? ediBreakErrors
              : null}{" "}
          </Mui.Typography>
        </Mui.Grid>
        <Mui.Grid container sx={{ paddingTop: 2 }}>
          <Mui.Grid item xs={10}></Mui.Grid>
          <Mui.Grid item xs={2}>
            <Mui.Button
              sx={{
                backgroundColor: themes.button,
                color: themes.whiteColor,
                "&:hover": {
                  backgroundColor: themes.button,
                },
              }}
              onClick={() => createNewBreaks()}
            >
              Create
            </Mui.Button>
          </Mui.Grid>
        </Mui.Grid>
      </GlobalDialogBox>
      <GlobalDialogBox
        handleCloseDialog={() => {
          setEditCheckInCheckOut(false);
        }}
        open={editCheckInCheckOut}
        title="Edit Check In/Out Details"
        minWidth={750}
      >
        <Mui.Grid container>
          <Mui.Grid xs={4}>
            <Mui.Typography sx={{ fontSize: 20, fontWeight: "bold" }}>
              Employee Name
            </Mui.Typography>
          </Mui.Grid>
          <Mui.Grid xs={8}>
            <Mui.Typography sx={{ fontSize: 20, paddingLeft: 1 }}>
              {editCheckinCheckOutTime?.user_name}
            </Mui.Typography>
          </Mui.Grid>
        </Mui.Grid>
        <Mui.Grid container sx={{ paddingTop: 1 }}>
          <Mui.Grid xs={4} sx={{ paddingTop: 2 }}>
            <Mui.Typography sx={{ fontSize: 20, fontWeight: "bold" }}>
              Check In
            </Mui.Typography>
          </Mui.Grid>
          <Mui.Grid xs={8}>
            <Mui.Grid container>
              <Mui.Grid xs={6} sx={{ padding: 1 }}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DemoContainer components={["DatePicker"]}>
                    <DatePicker
                      size="small"
                      slotProps={{ textField: { size: "small" } }}
                      sx={{ minWidth: "60%" }}
                      value={shiftStartDate}
                      format="DD/MM/YYYY"
                      minDate={dayjs(
                        editCheckinCheckOutTime?.date
                          ?.split("-")
                          .reverse()
                          .join("-")
                      )}
                      maxDate={dayjs(
                        editCheckinCheckOutTime?.date
                          ?.split("-")
                          .reverse()
                          .join("-")
                      )}
                      onChange={handleDateOnChange}
                      renderInput={(params) => (
                        <Mui.TextField {...params} size="small" />
                      )}
                      disabled={true}
                    />
                  </DemoContainer>
                </LocalizationProvider>
              </Mui.Grid>
              <Mui.Grid xs={6} sx={{ padding: 1 }}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DemoContainer components={["TimePicker"]}>
                    <div sx={{ width: 4 }}>
                      <TimePicker
                        ampm={false}
                        viewRenderers={{
                          hours: renderTimeViewClock,
                          minutes: renderTimeViewClock,
                          seconds: renderTimeViewClock,
                        }}
                        value={shiftStartTimeFormated || null}
                        slotProps={{
                          textField: {
                            size: "small",
                            width: 15,
                          },
                        }}
                        onChange={(newValue) => {
                          const timeWithDate = newValue
                            ? newValue.toDate()
                            : new Date();
                          let minTime = moment.utc(timeWithDate);
                          setShiftStartTimeFormated(
                            dayjs()
                              .set("hour", minTime._d.getHours())
                              .set("minute", minTime._d.getMinutes())
                              .set("second", minTime._d.getSeconds())
                          );
                        }}
                      />
                    </div>
                  </DemoContainer>
                </LocalizationProvider>
                <Mui.Typography
                  sx={{
                    color: themes.redColor,
                    fontSize: 12,
                  }}
                >
                  {handleCheckInError ===
                  "Checkin time is greater then check out time"
                    ? "Checkin time is greater then check out time"
                    : null}
                  {handleCheckInError === "Invalid check-in format"
                    ? "Invalid check-in format"
                    : null}
                </Mui.Typography>
              </Mui.Grid>
            </Mui.Grid>
          </Mui.Grid>
        </Mui.Grid>
        <Mui.Grid container sx={{ paddingTop: 1 }}>
          <Mui.Grid xs={4} sx={{ paddingTop: 2 }}>
            <Mui.Typography sx={{ fontSize: 20, fontWeight: "bold" }}>
              Check Out
            </Mui.Typography>
          </Mui.Grid>
          <Mui.Grid xs={8}>
            <Mui.Grid container>
              <Mui.Grid xs={6} sx={{ padding: 1 }}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DemoContainer components={["DatePicker"]}>
                    <DatePicker
                      size="small"
                      slotProps={{ textField: { size: "small" } }}
                      sx={{ minWidth: "80%" }}
                      value={shiftEndDate}
                      minDate={dayjs(
                        editCheckinCheckOutTime?.date
                          ?.split("-")
                          .reverse()
                          .join("-")
                      )}
                      maxDate={dayjs(
                        editCheckinCheckOutTime?.date
                          ?.split("-")
                          .reverse()
                          .join("-")
                      ).add(1, "day")}
                      format="DD/MM/YYYY"
                      onChange={handleCheckOutDateOnChange}
                      renderInput={(params) => (
                        <Mui.TextField {...params} size="small" />
                      )}
                    />
                  </DemoContainer>
                </LocalizationProvider>
                <Mui.Typography
                  sx={{
                    paddingTop: 1,
                    color: themes.redColor,
                    fontSize: 12,
                  }}
                >
                  {handleCheckInError ===
                  "The check-out date can only be set to the selected date or the following day."
                    ? "The check-out date can only be set to the selected date or the following day."
                    : null}
                </Mui.Typography>
              </Mui.Grid>
              <Mui.Grid xs={6} sx={{ padding: 1 }}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DemoContainer components={["TimePicker"]}>
                    <div sx={{ width: 4 }}>
                      <TimePicker
                        ampm={false}
                        viewRenderers={{
                          hours: renderTimeViewClock,
                          minutes: renderTimeViewClock,
                          seconds: renderTimeViewClock,
                        }}
                        value={shiftEndTimeFormated || null}
                        slotProps={{
                          textField: {
                            size: "small",
                            width: 15,
                          },
                        }}
                        onChange={(newValue) => {
                          const timeWithDate = newValue
                            ? newValue.toDate()
                            : new Date();
                          let minTime = moment.utc(timeWithDate);
                          setShiftEndTimeFormated(
                            dayjs()
                              .set("hour", minTime._d.getHours())
                              .set("minute", minTime._d.getMinutes())
                              .set("second", minTime._d.getSeconds())
                          );
                        }}
                      />
                    </div>
                  </DemoContainer>
                </LocalizationProvider>
                <Mui.Typography
                  sx={{
                    color: themes.redColor,
                    fontSize: 12,
                  }}
                >
                  {handleCheckInError === "Invalid check-out format"
                    ? "Invalid check-out format"
                    : null}
                  {handleCheckInError ===
                  "Check-in time to be earlier than check-out time."
                    ? "Check-in time to be earlier than check-out time."
                    : null}
                </Mui.Typography>
              </Mui.Grid>
            </Mui.Grid>
          </Mui.Grid>
        </Mui.Grid>
        <Mui.Grid
          container
          sx={{
            paddingTop: 3,
          }}
        >
          <Mui.Grid xs={9}></Mui.Grid>
          <Mui.Grid
            xs={1.5}
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              alignItems: "flex-end",
            }}
          >
            <CustomButton
              actionFuntion={() => {
                setEditCheckInCheckOut(false);
              }}
              actionName="Cancel"
              typeName="button"
            />
          </Mui.Grid>
          <Mui.Grid
            xs={1.5}
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              alignItems: "flex-end",
            }}
          >
            <CustomButton
              actionFuntion={() => {
                updateEditDetails();
              }}
              actionName="Update"
              typeName="button"
            />
          </Mui.Grid>
        </Mui.Grid>
      </GlobalDialogBox>
    </React.Fragment>
  );
};

const ContentGrid = styled(Mui.Grid)`
  width: 80%;
`;
