import React, { useState, useEffect } from "react";
import * as Mui from "@mui/material";
import { useDispatch } from "react-redux";
import { setGlobalSearchValue } from "services/Redux/userToken";
import { CustomAutoComplete } from "components/CustomComponents/CustomAutoComplete";
import { useFetch } from "services/hooks/useFetch";
import { useSelector } from "react-redux";
import { DownloadForOffline } from "@mui/icons-material";
import { BackArrow } from "components/CustomComponents/BackArrow";
import { customGetAPI } from "services/utils/CustomAPI";
import { showToast } from "services/utils/Status";
import { EmployeeBankTable } from "./EmployeeBankTable";
import { Loader } from "components/Loader";
const filterKey = [
  "user_name",
  "employee_id",
  "account_holder_name",
  "account_no",
  "bank_name",
  "branch_name",
  "ifsc",
  "net_pay",
];

export const EmployeeBankDetails = ({ setShowReport }) => {
  const { domain, token, globalSearchValue } = useSelector(
    (state) => state.tokenReducer
  );
  const dispatch = useDispatch();
  const [companyName, setCompanyName] = useState("");
  const [companyId, setCompanyId] = useState(0);
  const [paySheduleDetails, setPayScheduleDetails] = useState(true);
  const [getPayScheduleDetails, setGetPayScheduleDetails] = useState([]);
  const [payScheduleDetailsApi, setPayScheduleDetailsApi] = useState(false);
  const [updateCompanyDetails, setUpdateCompanyDetails] = useState(true);
  const [payScheduleName, setPayScheduleName] = useState("");
  const { result: companyList } = useFetch(`${domain}get-active-company/`);
  const [listViewPage, setListViewPage] = useState(0);
  const {
    result: payShedule,
    setLoad: reCallApi,
    loading,
  } = useFetch(
    companyId !== 0 && companyId
      ? `${domain}payschedule-report/?company=${companyId}`
      : null
  );
  const [salaryDetails, setSalaryDetails] = useState([]);
  const [loader, setLoader] = useState(false);
  const [salarySatementState, setSalarySatementState] = useState([]);

  useEffect(() => {
    if (companyList.length !== 0 && updateCompanyDetails) {
      const data = companyList.filter((company) => {
        return company.isprimary === true;
      });
      setCompanyId(data[0].id);
      setCompanyName(data[0].companyname);
      setUpdateCompanyDetails(false);
    }
  }, [companyList, updateCompanyDetails]);

  const handleCompanyName = (event, data) => {
    if (data) {
      setCompanyName(data.companyname);
      setCompanyId(data.id);
      reCallApi(true);
      setTimeout(function () {
        setPayScheduleDetails(true);
      }, 100);
    }
  };

  const handlePaySchedule = (event, data) => {
    if (data) {
      setGetPayScheduleDetails(data);
      setPayScheduleName(data.name);
      setPayScheduleDetailsApi(true);
    }
  };

  useEffect(() => {
    if (payShedule.length !== 0 && paySheduleDetails && !loading) {
      setGetPayScheduleDetails(payShedule[0] ? payShedule[0] : []);
      setPayScheduleName(payShedule[0] ? payShedule[0]?.name : "");
      setPayScheduleDetails(false);
      if (payShedule[0]) {
        setPayScheduleDetailsApi(true);
      }
    } else if (payShedule.length === 0) {
      setGetPayScheduleDetails([]);
      setPayScheduleName("");
      setSalaryDetails([]);
    }
  }, [loading, payShedule, paySheduleDetails]);

  useEffect(() => {
    const getSalaryStatementReport = async () => {
      setLoader(true);
      const res = await customGetAPI(
        `${domain}user/bank/payrun-report/?pay_schedule_id=${getPayScheduleDetails?.id}`
      );
      if (res.ok) {
        setSalaryDetails(res?.responseDetails);
        setSalarySatementState(res?.responseDetails);
        setLoader(false);
      } else {
        setLoader(false);
      }
    };
    if (payScheduleDetailsApi) {
      getSalaryStatementReport();
      setPayScheduleDetailsApi(false);
    }
  }, [domain, getPayScheduleDetails, payScheduleDetailsApi, token]);

  const exportPayScheduleDetails = () => {
    if (salaryDetails.length !== 0) {
      let dataPreparation = [];
      let id = 0;
      salaryDetails?.forEach((element) => {
        id = id + 1;
        let overAllData = {
          user_name: element.user_name,
          employee_id: element.employee_id,
          account_holder_name: element?.account_holder_name
            ? element?.account_holder_name
            : "N/A",
          account_no: element?.account_no ? element?.account_no : "N/A",
          bank_name: element?.bank_name ? element?.bank_name : "N/A",
          branch_name: element?.branch_name ? element?.branch_name : "N/A",
          ifsc: element?.ifsc ? element?.ifsc : "N/A",
          net_pay: element?.net_pay ? element?.net_pay : "0",
        };
        dataPreparation = [...dataPreparation, overAllData];
      });
      const company_Name = ["Company Name"];
      const pay_ScheduleName = ["PaySchedule Name"];
      const paySchedulePeriod = ["PaySchedule Period"];
      const TableHead = ["Employee Name"];
      TableHead.push("Employee Id");
      TableHead.push("Account Holder Name");
      TableHead.push("Account Number");
      TableHead.push("Bank Name");
      TableHead.push("Branch Name");
      TableHead.push("IFSC");
      TableHead.push("Amount");
      company_Name.push(companyName);
      pay_ScheduleName.push(payScheduleName);
      paySchedulePeriod.push(
        getPayScheduleDetails.start_date +
          "  to  " +
          getPayScheduleDetails.end_date
      );
      const items = dataPreparation;
      const replacer = (key, value) => (value === null ? "" : value);
      const header = Object.keys(items[0]).filter(
        (field) => field !== "break_details"
      );
      let csv = items.map((row) =>
        header
          .map((fieldName) => JSON.stringify(row[fieldName], replacer))
          .join(",")
      );
      csv.unshift(TableHead.join(","));
      csv.unshift(paySchedulePeriod.join(","));
      csv.unshift(pay_ScheduleName.join(","));
      csv.unshift(company_Name.join(","));
      csv = csv.join("\r\n");
      const ConvertData = csv;
      const element = document.createElement("a");
      const file = new Blob([ConvertData], {
        type: "text/plain",
      });
      element.href = URL.createObjectURL(file);
      element.download = `${payScheduleName}.csv`;
      document.body.appendChild(element);
      element.click();
    } else {
      showToast("error", "No data available");
    }
  };
  useEffect(() => {
    const filteredResults = salarySatementState?.filter((item) =>
      filterKey?.some((key) =>
        item[key]
          ?.toString()
          .toLowerCase()
          .includes(globalSearchValue?.toString().toLowerCase())
      )
    );
    setSalaryDetails(filteredResults);
    setListViewPage(0);
  }, [globalSearchValue, salarySatementState]);

  return (
    <React.Fragment>
      <Mui.Grid container sx={{ paddingTop: 2, paddingBottom: 1 }}>
        <Mui.Grid xs={0.5} sx={{ paddingTop: 2 }}>
          <BackArrow
            actionFunction={() => {
              setShowReport(false);
              dispatch(setGlobalSearchValue(""));
            }}
          />
        </Mui.Grid>
        <Mui.Grid xs={2.5}>
          <Mui.Typography>Company Name</Mui.Typography>
          <CustomAutoComplete
            dataList={companyList.map((obj) => ({
              ...obj,
              label: obj.companyname,
            }))}
            selectedValue={companyName}
            updateDataFunction={handleCompanyName}
            placeHolder="Please select the company name"
          />
        </Mui.Grid>
        <Mui.Grid xs={5.9}></Mui.Grid>
        <Mui.Grid xs={2.5}>
          <Mui.Typography>Pay Schedule</Mui.Typography>
          <CustomAutoComplete
            dataList={payShedule.map((obj) => ({
              ...obj,
              label: obj.name,
            }))}
            selectedValue={payScheduleName}
            updateDataFunction={handlePaySchedule}
            placeHolder="Please select the Payshedule"
          />
        </Mui.Grid>
        <Mui.Grid xs={0.5} sx={{ paddingTop: 1 }}>
          <Mui.IconButton onClick={() => exportPayScheduleDetails()}>
            <DownloadForOffline
              size="large"
              sx={{ color: "#25B7D3", fontSize: 50 }}
            />
          </Mui.IconButton>
        </Mui.Grid>
      </Mui.Grid>
      {salaryDetails?.length === 0 ? null : (
        <Mui.Grid container xs={12}>
          <Mui.Grid xs={8.9}></Mui.Grid>
          <Mui.Grid xs={3.1} sx={{ paddingLeft: 0.5 }}>
            <Mui.Typography
              sx={{
                fontSize: 16,
                fontWeight: "bold",
              }}
            >
              Salary Statement Report
            </Mui.Typography>
            <Mui.Typography
              sx={{
                fontSize: 16,
                fontWeight: "bold",
              }}
            >
              Period {getPayScheduleDetails.start_date} to{" "}
              {getPayScheduleDetails.end_date}
            </Mui.Typography>
          </Mui.Grid>
        </Mui.Grid>
      )}
      {loader ? (
        <Loader />
      ) : (
        <EmployeeBankTable
          salaryDetails={salaryDetails}
          listViewPage={listViewPage}
          setListViewPage={setListViewPage}
        />
      )}
    </React.Fragment>
  );
};
