import React, { useCallback, useEffect, useState } from "react";
import { themes } from "services/constants";
import * as Mui from "@mui/material";
import { showToast } from "services/utils/Status";
import { useSelector } from "react-redux";
import { GlobalDialogBox } from "components/GlobalDialogBox";
import { MoreVert, Info } from "@mui/icons-material";
import { useForm, Controller } from "react-hook-form";
import { leaveGroupHrms } from "services/constants";
import { CustomButton } from "components/CustomComponents/CustomButton";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import sessionHandling from "services/utils/notificationUtils";
import { setGlobalSearchValue } from "services/Redux/userToken";
import { useDispatch } from "react-redux";
import { tableRowSx, tableHeaderSx } from "services/constants";
import { tableContainerSx } from "services/constants";
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export const LeaveGroupDetails = ({
  setView,
  leaveGroupData,
  leaveTypeData,
  globalSearchValue,
  masterLeaveGroup,
  setLeaveGroupData,
  usersData,
  setFetchLeaveGroupData,
  edit,
  setEdit,
  openDialog,
  setOpenDialog,
  setFetchIndividualLeaveGroupData,
  setFetchLeaveTypeData,
  setFetchUsersData,
}) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [etype, setEtype] = useState("");
  const dispatch = useDispatch();
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    if (etype !== "") {
      setOpenDialog(true);
    }
  }, [etype, setOpenDialog]);
  useEffect(() => {
    const filteredResults = masterLeaveGroup.filter((item) =>
      leaveGroupHrms.some((key) =>
        item[key]
          ?.toString()
          .toLowerCase()
          .includes(globalSearchValue.toString().toLowerCase())
      )
    );
    setLeaveGroupData(filteredResults);
    setPage(globalSearchValue ? 0 : page);
  }, [globalSearchValue, masterLeaveGroup, page, setLeaveGroupData]);
  return (
    <React.Fragment>
      <EtypeDialog
        type={etype}
        setEtype={setEtype}
        openDialog={openDialog}
        setOpenDialog={setOpenDialog}
        leaveTypeData={leaveTypeData}
        usersData={usersData}
        edit={edit}
        setFetchLeaveGroupData={setFetchLeaveGroupData}
        setFetchUsersData={setFetchUsersData}
      />
      <Mui.Grid
        container
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-end",
          alignItems: "flex-end",
          padding: 2,
        }}
      >
        <CustomButton
          actionFuntion={() => {
            setEtype("Add");
          }}
          actionName="Add Leave Group"
          typeName="button"
        />
      </Mui.Grid>
      <Mui.TableContainer sx={tableContainerSx}>
        <Mui.Table>
          <Mui.TableHead sx={tableHeaderSx}>
            <Mui.TableRow sx={tableRowSx}>
              <Mui.TableCell
                sx={{ color: themes.blackColor, fontWeight: "bold" }}
              >
                Leave Group Name
              </Mui.TableCell>
              <Mui.TableCell
                sx={{ color: themes.blackColor, fontWeight: "bold" }}
              >
                No of Users
              </Mui.TableCell>
              <Mui.TableCell
                sx={{ color: themes.blackColor, fontWeight: "bold" }}
              >
                Actions
              </Mui.TableCell>
            </Mui.TableRow>
          </Mui.TableHead>
          <Mui.TableBody>
            {leaveGroupData?.length === 0 ? (
              <Mui.TableRow>
                <Mui.TableCell colSpan={12}>
                  <Mui.Alert severity="info" sx={{ width: "100%", margin: 2 }}>
                    No data available
                  </Mui.Alert>
                </Mui.TableCell>
              </Mui.TableRow>
            ) : (
              leaveGroupData
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((item, index) => (
                  <Mui.TableRow
                    key={item?.id}
                    sx={tableRowSx}
                    onDoubleClick={() => {
                      setView("ViewLeaveGroup");
                      setFetchIndividualLeaveGroupData(true);
                      setFetchLeaveTypeData(true);
                      setEdit(item);
                      dispatch(setGlobalSearchValue(""));
                    }}
                    maxWidth="xl"
                    align="left"
                  >
                    <Mui.TableCell
                      sx={{ color: themes.blackColor, fontWeight: "bold" }}
                    >
                      <Mui.Typography>
                        {item?.name ? item?.name : "N/A"}
                      </Mui.Typography>
                    </Mui.TableCell>
                    <Mui.TableCell
                      sx={{ color: themes.blackColor, fontWeight: "bold" }}
                    >
                      <Mui.Typography>
                        {item?.number_of_users ? item?.number_of_users : "N/A"}
                      </Mui.Typography>
                    </Mui.TableCell>
                    <Mui.TableCell
                      sx={{ color: themes.blackColor, fontWeight: "bold" }}
                    >
                      <Mui.IconButton
                        onClick={(event) => {
                          setAnchorEl(event.currentTarget);
                          setEdit(item);
                        }}
                      >
                        <MoreVert />
                      </Mui.IconButton>
                      <Mui.Menu
                        anchorEl={anchorEl}
                        open={Boolean(anchorEl)}
                        onClose={handleMenuClose}
                        elevation={1}
                        transformOrigin={{
                          horizontal: "right",
                          vertical: "top",
                        }}
                        anchorOrigin={{
                          horizontal: "right",
                          vertical: "bottom",
                        }}
                      >
                        <Mui.MenuItem
                          sx={{ width: "100%" }}
                          onClick={() => {
                            setAnchorEl(null);
                            setEtype("Edit");
                          }}
                        >
                          Edit
                        </Mui.MenuItem>
                        <Mui.MenuItem
                          sx={{ width: "100%" }}
                          onClick={() => {
                            setAnchorEl(null);
                            setEtype("Delete");
                          }}
                        >
                          Delete
                        </Mui.MenuItem>
                      </Mui.Menu>
                    </Mui.TableCell>
                  </Mui.TableRow>
                ))
            )}
          </Mui.TableBody>
        </Mui.Table>
      </Mui.TableContainer>
      <Mui.Grid
        container
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-end",
          alignItems: "flex-end",
        }}
      >
        {leaveGroupData?.length > 25 ? (
          <Mui.TablePagination
            className="custom-pagination"
            component="div"
            rowsPerPageOptions={[25, 50, 75, 100]}
            count={leaveGroupData?.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        ) : null}
      </Mui.Grid>
    </React.Fragment>
  );
};

export function EtypeDialog({
  type,
  setEtype,
  openDialog,
  setOpenDialog,
  leaveTypeData,
  usersData,
  edit,
  setFetchLeaveGroupData,
}) {
  const {
    control,
    register,
    reset,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors },
  } = useForm();
  const { domain, token } = useSelector((state) => state.tokenReducer);
  const [selectedLeaveType, setSelectedLeaveType] = useState([]);
  const [selectedLeaveTypeId, setSelectedLeaveTypeId] = useState([]);
  const [selectedUser, setSelectedUser] = useState([]);
  const [selectedUserId, setSelectedUserId] = useState([]);
  const [buttonLoader, setButtonLoader] = useState(false);
  const handleClose = useCallback(() => {
    setEtype("");
    setValue("name", "");
    reset();
    setSelectedLeaveType([]);
    setSelectedLeaveTypeId([]);
    setSelectedUser([]);
    setSelectedUserId([]);
    setOpenDialog(false);
  }, [reset, setEtype, setOpenDialog, setValue]);

  useEffect(() => {
    if (type === "Add") {
      reset();
    } else if (type === "Edit") {
      setValue("name", edit?.name);
      const leaveType = edit?.leave_types?.map((value) => value);
      const user = edit?.users?.map((value) => value);
      const typeId = edit?.leave_types?.map((value) => value?.id);
      const userId = edit?.users?.map((value) => value?.id);
      setSelectedLeaveType(leaveType);
      setSelectedUser(user);
      setSelectedLeaveTypeId(typeId);
      setSelectedUserId(userId);
    } else if (type === "Delete") {
      setValue("name", "");
      reset();
    }
  }, [edit, reset, setValue, type]);

  const onSubmit = (data) => {
    if (data) {
      if (type === "Add") {
        addLeaveGroup(data?.name, selectedLeaveTypeId, selectedUserId);
      } else if (type === "Edit") {
        updateGroupName(data?.name, selectedLeaveTypeId, selectedUserId);
      } else if (type === "Delete") {
        removeLeaveGroup();
      }
    }
  };

  const checkSpecialChar = (e) => {
    if (!/[0-9a-zA-Z ]/.test(e.key)) {
      e.preventDefault();
    }
  };

  const addLeaveGroup = useCallback(
    async (name, leaveTypeId, userId) => {
      setButtonLoader(true);
      try {
        const response = await fetch(`${domain}leave-groups/`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `token ${token}`,
          },
          body: JSON.stringify({
            name: name,
            leave_type_ids: leaveTypeId,
            user_ids: userId,
          }),
        });
        const res = await response.json();
        if (response.ok) {
          handleClose();
          showToast("success", `${name} added successfully`);
          setValue("uniquename_check", "");
        } else if (response.status === 409) {
          sessionHandling();
        } else {
          throw new Error(res.user_ids.error);
        }
      } catch (error) {
        showToast("error", error.message);
      } finally {
        setOpenDialog(false);
        setFetchLeaveGroupData(true);
        setButtonLoader(false);
      }
    },
    [
      domain,
      handleClose,
      setFetchLeaveGroupData,
      setOpenDialog,
      setValue,
      token,
    ]
  );

  const updateGroupName = useCallback(
    async (name, leaveTypeId, userId) => {
      setButtonLoader(true);
      try {
        const response = await fetch(`${domain}leave-groups/${edit?.id}/`, {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `token ${token}`,
          },
          body: JSON.stringify({
            name: name,
            leave_type_ids: leaveTypeId,
            user_ids: userId,
          }),
        });
        const res = await response.json();
        if (response.ok) {
          handleClose();
          showToast("success", `${name} updated successfully`);
        } else if (response.status === 409) {
          sessionHandling();
        } else {
          throw new Error(res.error);
        }
      } catch (error) {
        showToast("error", error.message);
      } finally {
        setOpenDialog(false);
        setFetchLeaveGroupData(true);
        setButtonLoader(false);
      }
    },
    [
      domain,
      edit?.id,
      handleClose,
      setFetchLeaveGroupData,
      setOpenDialog,
      token,
    ]
  );
  const checkExistingLeaveGroup = useCallback(
    async (name) => {
      try {
        const response = await fetch(
          `${domain}leave-group-namecheck?leave_group=${name}`,
          {
            method: "GET",
            headers: {
              "content-type": "application/json",
              Authorization: `token ${token}`,
            },
          }
        );
        const res = await response.json();
        if (response.ok) {
          if (res?.exists) {
            return "Group name is already exists";
          } else {
            return true;
          }
        } else if (response.status === 409) {
          sessionHandling();
        } else {
          throw new Error(res.error);
        }
      } catch (error) {
        showToast("error", error.message);
      }
    },
    [domain, token]
  );

  const removeLeaveGroup = useCallback(async () => {
    setButtonLoader(true);
    try {
      const response = await fetch(`${domain}leave-groups/${edit?.id}/`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `token ${token}`,
        },
        body: JSON.stringify({
          isdeleted: "true",
        }),
      });
      const res = await response.json();
      if (response.ok) {
        handleClose();
        showToast("success", `${edit.name} deleted successfully`);
      } else if (response.status === 409) {
        sessionHandling();
      } else {
        throw new Error(res.error);
      }
    } catch (error) {
      showToast("error", error.message);
    } finally {
      setFetchLeaveGroupData(true);
      setOpenDialog(false);
      setButtonLoader(false);
    }
  }, [
    domain,
    edit?.id,
    edit?.name,
    handleClose,
    setFetchLeaveGroupData,
    setOpenDialog,
    token,
  ]);

  return (
    <form onSubmit={handleSubmit(onSubmit)} noValidate>
      <GlobalDialogBox
        key={type}
        handleCloseDialog={() => handleClose()}
        open={openDialog}
        title={
          type === "Delete"
            ? "Delete Leave Group"
            : type === "Add"
            ? "Add Leave Group"
            : "Edit Leave Group"
        }
        minWidth={700}
      >
        {type === "" ? null : type === "Delete" ? (
          <>
            <Mui.Grid container>
              <Mui.Grid xs={12}>
                <Mui.Typography sx={{ fontSize: 22, paddingBottom: 2 }}>
                  Are you sure you want to delete{" "}
                  <b style={{ color: themes.redColor }}>{edit?.name}?</b>
                </Mui.Typography>
              </Mui.Grid>
            </Mui.Grid>
            <Mui.Grid
              container
              sx={{
                paddingTop: 2,
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "flex-center",
              }}
            >
              <CustomButton
                actionFuntion={handleClose}
                actionName="No"
                typeName="submit"
              />
              &nbsp;&nbsp;
              <CustomButton
                actionFuntion={handleSubmit(onSubmit)}
                actionName="Yes"
                disableAction={
                  type === "Delete" && buttonLoader ? buttonLoader : false
                }
                typeName="submit"
              />
            </Mui.Grid>
          </>
        ) : (
          <>
            <Mui.Grid container sx={{ paddingBottom: 1 }}>
              <Mui.Grid xs={4} sx={{ justifyContent: "flex-end" }}>
                <Mui.Stack
                  direction="Row"
                  sx={{
                    paddingTop: 1,
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "end",
                    alignItems: "flex-end",
                    paddingRight: 1,
                  }}
                >
                  <Mui.Typography style={{ alignSelf: "center" }}>
                    Leave Group Name
                  </Mui.Typography>{" "}
                  &nbsp;&nbsp;
                  <Mui.Tooltip
                    style={{ alignSelf: "center" }}
                    title="This leave group allows grouping specific leave types and users among existing users and leave types"
                  >
                    <Info />
                  </Mui.Tooltip>
                </Mui.Stack>
              </Mui.Grid>
              <Mui.Grid xs={8}>
                <Mui.TextField
                  id="name"
                  placeholder="Enter the leave group name"
                  {...register("name", {
                    required: "Leave group name is required",
                    minLength: {
                      value: 3,
                      message: "Minimum 3 characters are required",
                    },
                    validate: {
                      leaveGroup: async (fieldValue) => {
                        if (getValues("uniquename_check") !== fieldValue) {
                          if (fieldValue.trim().length < 3) {
                            return "Minimum 3 characters are required";
                          } else {
                            setValue("uniquename_check", fieldValue);
                            if (type === "Add") {
                              return checkExistingLeaveGroup(fieldValue.trim());
                            } else {
                              if (edit?.name !== fieldValue) {
                                return checkExistingLeaveGroup(
                                  fieldValue.trim()
                                );
                              }
                            }
                          }
                        } else if (errors.name) {
                          return errors?.name?.message;
                        }
                      },
                    },
                  })}
                  onKeyPress={checkSpecialChar}
                  size="small"
                  InputProps={{
                    inputProps: { maxLength: 32 },
                  }}
                  error={Boolean(errors?.name)}
                  helperText={errors.name?.message || ""}
                  fullWidth
                />
              </Mui.Grid>
            </Mui.Grid>
            <Mui.Grid container sx={{ paddingBottom: 1 }}>
              <Mui.Grid xs={2.5} sx={{ justifyContent: "flex-end" }}>
                <Mui.Stack
                  direction="Row"
                  sx={{
                    paddingTop: 1,
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "end",
                    alignItems: "flex-end",
                    paddingRight: 1,
                    marginTop: 1,
                  }}
                >
                  <Mui.Typography>Leave Type</Mui.Typography> &nbsp;&nbsp;
                </Mui.Stack>
              </Mui.Grid>
              <Mui.Grid xs={1.5}></Mui.Grid>
              <Mui.Grid xs={8} sx={{ marginTop: 1 }}>
                <Controller
                  name="leave_type"
                  control={control}
                  defaultValue={selectedLeaveType}
                  rules={{
                    required: "Please select at least one leave type",
                  }}
                  render={({ field }) => (
                    <Mui.Autocomplete
                      {...field}
                      size="small"
                      fullWidth
                      multiline
                      multiple
                      limitTags={1}
                      disableCloseOnSelect
                      options={leaveTypeData}
                      getOptionLabel={(option) =>
                        option?.leave_type ? option?.leave_type : ""
                      }
                      isOptionEqualToValue={(option, value) =>
                        option.id === value.id
                      }
                      onChange={(event, value) => {
                        if (value) {
                          const id = value?.map((e) => e?.id);
                          setSelectedLeaveType(value);
                          setSelectedLeaveTypeId(id);
                          field.onChange(value);
                        } else {
                          setSelectedLeaveType([]);
                          setSelectedLeaveTypeId([]);
                          field.onChange([]);
                        }
                      }}
                      renderTags={(value, getTagProps) =>
                        value.map((option, index) => (
                          <Mui.Chip
                            variant="outlined"
                            label={option?.leave_type}
                            {...getTagProps({ index })}
                            key={option.id}
                          />
                        ))
                      }
                      renderOption={(props, option, { selected }) => {
                        return (
                          <li {...props}>
                            <Mui.Checkbox
                              icon={icon}
                              checkedIcon={checkedIcon}
                              style={{ marginRight: 8 }}
                              checked={selected}
                            />
                            &nbsp;{option.leave_type}
                          </li>
                        );
                      }}
                      componentsProps={{
                        popper: { style: { width: "fit-content" } },
                      }}
                      renderInput={(params) => (
                        <Mui.TextField
                          {...params}
                          variant="outlined"
                          placeholder="Select leave type"
                          error={errors.leave_type}
                          helperText={
                            errors.leave_type ? errors.leave_type.message : ""
                          }
                        />
                      )}
                    />
                  )}
                ></Controller>
              </Mui.Grid>
            </Mui.Grid>
            <Mui.Grid container sx={{ paddingBottom: 1 }}>
              <Mui.Grid xs={1.76} sx={{ justifyContent: "flex-end" }}>
                <Mui.Stack
                  direction="Row"
                  sx={{
                    paddingTop: 1,
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "end",
                    alignItems: "flex-end",
                    paddingRight: 1,
                    marginTop: 1,
                  }}
                >
                  <Mui.Typography> Users</Mui.Typography> &nbsp;&nbsp;
                </Mui.Stack>
              </Mui.Grid>
              <Mui.Grid xs={2.22}></Mui.Grid>
              <Mui.Grid xs={8} sx={{ marginTop: 1, marginBottom: 1 }}>
                <Controller
                  name="users"
                  control={control}
                  defaultValue={selectedUser}
                  rules={{
                    required: "Please select at least one users",
                  }}
                  render={({ field }) => (
                    <Mui.Autocomplete
                      {...field}
                      size="small"
                      fullWidth
                      multiline
                      multiple
                      limitTags={1}
                      disableCloseOnSelect
                      options={usersData}
                      getOptionLabel={(option) =>
                        option?.user_name ? option?.user_name : ""
                      }
                      isOptionEqualToValue={(option, value) =>
                        option.id === value.id
                      }
                      onChange={(event, value) => {
                        if (value) {
                          const id = value?.map((e) => e?.id);
                          setSelectedUser(value);
                          setSelectedUserId(id);
                          field.onChange(value);
                        } else {
                          setSelectedUser([]);
                          setSelectedUserId([]);
                          field.onChange([]);
                        }
                      }}
                      renderOption={(props, option, { selected }) => {
                        return (
                          <li {...props}>
                            <Mui.Checkbox
                              icon={icon}
                              checkedIcon={checkedIcon}
                              style={{ marginRight: 8 }}
                              checked={selected}
                            />
                            &nbsp;{option.user_name}
                          </li>
                        );
                      }}
                      componentsProps={{
                        popper: { style: { width: "fit-content" } },
                      }}
                      renderTags={(value, getTagProps) =>
                        value.map((option, index) => (
                          <Mui.Chip
                            variant="outlined"
                            label={option?.user_name}
                            {...getTagProps({ index })}
                            key={option.id}
                          />
                        ))
                      }
                      renderInput={(params) => (
                        <Mui.TextField
                          {...params}
                          variant="outlined"
                          placeholder="Select users"
                          error={errors.users}
                          helperText={errors.users ? errors.users.message : ""}
                        />
                      )}
                    />
                  )}
                ></Controller>
              </Mui.Grid>
              <Mui.Grid xs={1}></Mui.Grid>
            </Mui.Grid>
            <Mui.Stack
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
                alignItems: "flex-end",
              }}
            >
              <CustomButton
                actionFuntion={() => {
                  handleClose();
                }}
                actionName="Cancel"
                typeName="submit"
              />
              &nbsp;&nbsp;
              <CustomButton
                actionFuntion={handleSubmit(onSubmit)}
                actionName={type === "Add" ? "Add" : "Update"}
                typeName="submit"
                disableAction={
                  type === "Add" || type === "Update"
                    ? buttonLoader
                    : buttonLoader
                }
              />
            </Mui.Stack>
          </>
        )}
      </GlobalDialogBox>
    </form>
  );
}
