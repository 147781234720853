import React, { useState, useEffect } from "react";
import * as Mui from "@mui/material";
import { useFetch } from "services/hooks/useFetch";
import { useSelector } from "react-redux";
import { themes, tableContainerSxWithCompany } from "services/constants";
import { Edit, RemoveRedEye } from "@mui/icons-material";
import { CustomButton } from "components/CustomComponents/CustomButton";
import { GlobalDialogBox } from "components/GlobalDialogBox";
import { showToast } from "services/utils/Status";
import sessionHandling from "services/utils/notificationUtils";
import { Loader } from "components/Loader";
import { AddNewSalaryGroup } from "./AddNewSalaryTemplate";
import { BackArrow } from "components/CustomComponents/BackArrow";
import { setGlobalSearchValue } from "services/Redux/userToken";
import { useDispatch } from "react-redux";
import { EditSalaryGroup } from "./EditSalaryTemplate";
import { View } from "./View";
const keys = ["name"];
export const SalaryTemplate = ({
  companyId,
  currentScreen,
  companyChange,
  setCompanyChange,
  salaryTemplate,
  setAddNewSalaryTemplate,
  editTemplate,
  setEditTemplate,
  earningDetails,
  reCallEarningApi,
  viewOption,
  setViewOption,
}) => {
  const { domain, token, globalSearchValue } = useSelector(
    (state) => state.tokenReducer
  );
  const dispatch = useDispatch();
  const [openDialog, setOpenDialog] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [editSalaryeTemplateDetails, setEditSalaryGroupDetails] = useState({});
  const [companyFilter, setCompanyFilter] = useState(false);
  const [bankDetailsData, setBankDetailsData] = useState([]);
  const [updateData, setUpdateData] = useState(true);
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [updateDeductionDetails, setUpdateDeductionDetails] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);

  const {
    result: salaryTemplateData,
    setLoad: reCallApi,
    loading,
  } = useFetch(
    companyId !== 0 ? `${domain}salary-templates/?company=${companyId}` : null
  );

  useEffect(() => {
    if (companyChange && currentScreen === "Salary Template") {
      reCallApi(true);
      setCompanyChange(false);
    }
    setAddNewSalaryTemplate(false);
    setEditTemplate(false);
  }, [
    companyChange,
    currentScreen,
    reCallApi,
    setAddNewSalaryTemplate,
    setCompanyChange,
    setEditTemplate,
  ]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const deleteSalaryTemplate = async () => {
    setButtonDisabled(true);
    try {
      const response = await fetch(
        `${domain}salary-templates/${editSalaryeTemplateDetails.id}`,
        {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
            Authorization: `token ${token}`,
          },
          body: JSON.stringify({ isdeleted: true }),
        }
      );
      const res = await response.json();
      if (response.ok) {
        showToast("error", "Salary template deleted successfully");
        reCallApi(true);
        setOpenDialog(false);
      } else if (response.status === 409) {
        sessionHandling();
      } else {
        throw new Error(res.error);
      }
    } catch (error) {
      showToast("error", error.message);
    } finally {
      setButtonDisabled(false);
    }
  };

  const handleCloseDialogBox = () => {
    setOpenDialog(false);
  };

  useEffect(() => {
    if (updateData && salaryTemplateData?.length !== 0 && salaryTemplateData) {
      setBankDetailsData(salaryTemplateData);
      setUpdateData(false);
    }
  }, [salaryTemplateData, updateData]);

  useEffect(() => {
    if (salaryTemplateData?.length !== 0 || salaryTemplateData?.length === 0) {
      const filteredResults = salaryTemplateData?.filter((item) =>
        keys.some((key) =>
          item[key]
            ?.toString()
            .toLowerCase()
            .includes(globalSearchValue.toString().toLowerCase())
        )
      );
      setBankDetailsData(filteredResults);
      setPage(0);
    }
  }, [globalSearchValue, salaryTemplateData]);

  return (
    <React.Fragment>
      <Mui.Grid container>
        <Mui.Grid xs={9}>
          {salaryTemplate || editTemplate ? (
            <Mui.Stack direction="row">
              <BackArrow
                actionFunction={() => {
                  reCallApi(true);
                  setAddNewSalaryTemplate(false);
                  setEditTemplate(false);
                  dispatch(setGlobalSearchValue(""));
                }}
              />

              <Mui.Typography
                sx={{
                  fontWeight: "bold",
                  fontSize: 25,
                  paddingTop: 1,
                }}
              >
                {editTemplate
                  ? "Payroll - Settings - Edit Salary Template"
                  : "Payroll - Settings - Add Salary Template"}
              </Mui.Typography>
            </Mui.Stack>
          ) : null}
        </Mui.Grid>
        <Mui.Grid
          container
          sx={{
            paddingTop: 1,
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
            alignItems: "flex-end",
            paddingRight: 1,
          }}
        >
          {salaryTemplate || editTemplate || viewOption ? null : (
            <CustomButton
              actionFuntion={() => {
                setAddNewSalaryTemplate(true);
                reCallEarningApi(true);
                dispatch(setGlobalSearchValue(""));
              }}
              actionName="Add salary Template"
              typeName="button"
            />
          )}
        </Mui.Grid>
        {loading && !salaryTemplate && !editTemplate && !viewOption ? (
          <Loader />
        ) : (
          <Mui.TableContainer sx={tableContainerSxWithCompany}>
            <Mui.Table sx={{ border: `3px solid ${themes.sidebarDivider}` }}>
              {editTemplate || salaryTemplate || viewOption ? null : (
                <Mui.TableHead
                  sx={{
                    backgroundColor: themes.primary,
                    position: "sticky",
                    top: -1,
                    zIndex: 10,
                  }}
                >
                  <Mui.TableRow
                    sx={{
                      "& .MuiTableCell-root": {
                        height: "10px",
                        padding: "3px 15px",
                      },
                    }}
                  >
                    <Mui.TableCell
                      sx={{ color: themes.blackColor, fontWeight: "bold" }}
                    >
                      Salary Template Name
                    </Mui.TableCell>
                    <Mui.TableCell
                      sx={{ color: themes.blackColor, fontWeight: "bold" }}
                    >
                      Salary Type
                    </Mui.TableCell>
                    <Mui.TableCell
                      sx={{ color: themes.blackColor, fontWeight: "bold" }}
                    >
                      Amount
                    </Mui.TableCell>

                    <Mui.TableCell
                      sx={{ color: themes.blackColor, fontWeight: "bold" }}
                    >
                      Employee
                    </Mui.TableCell>
                    <Mui.TableCell
                      sx={{
                        color: themes.blackColor,
                        fontWeight: "bold",
                      }}
                    >
                      Action
                    </Mui.TableCell>
                  </Mui.TableRow>
                </Mui.TableHead>
              )}
              <Mui.TableBody>
                {viewOption ||
                salaryTemplate ? null : bankDetailsData?.length === 0 &&
                  !editTemplate ? (
                  <Mui.TableRow>
                    <Mui.TableCell colSpan={8} align="center">
                      <Mui.Alert severity="info">No data available</Mui.Alert>
                    </Mui.TableCell>
                  </Mui.TableRow>
                ) : viewOption || editTemplate ? null : (
                  bankDetailsData
                    ?.slice(
                      page * rowsPerPage,
                      page * rowsPerPage + rowsPerPage
                    )
                    ?.map((salary, index) => (
                      <React.Fragment>
                        <Mui.TableRow
                          sx={{
                            "& .MuiTableCell-root": {
                              height: "10px",
                              padding: "3px 15px",
                            },
                          }}
                        >
                          <Mui.TableCell>{salary?.name}</Mui.TableCell>
                          <Mui.TableCell>
                            {salary?.salary_type_name
                              ? salary?.salary_type_name
                              : "N/A"}
                          </Mui.TableCell>
                          <Mui.TableCell>
                            {salary?.amount ? salary?.amount : "N/A"}
                          </Mui.TableCell>

                          <Mui.TableCell>
                            {salary?.employee_count
                              ? salary?.employee_count
                              : "N/A"}
                          </Mui.TableCell>

                          <Mui.TableCell>
                            <Mui.IconButton>
                              <RemoveRedEye
                                onClick={() => {
                                  setEditSalaryGroupDetails(salary);
                                  setViewOption(true);
                                  setIsDisabled(true);
                                }}
                              />
                            </Mui.IconButton>
                            <Mui.IconButton
                              onClick={() => {
                                setEditSalaryGroupDetails(salary);
                                setEditTemplate(true);
                                reCallEarningApi(true);
                                dispatch(setGlobalSearchValue(""));
                                setUpdateDeductionDetails(true);
                              }}
                            >
                              <Edit />
                            </Mui.IconButton>
                          </Mui.TableCell>
                        </Mui.TableRow>
                      </React.Fragment>
                    ))
                )}
              </Mui.TableBody>
            </Mui.Table>
          </Mui.TableContainer>
        )}
        {salaryTemplate || editTemplate || viewOption ? null : (
          <Mui.Grid
            container
            sx={{
              paddingTop: 1,
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              alignItems: "flex-end",
              paddingRight: 1,
            }}
          >
            {bankDetailsData.length > 25 && (
              <Mui.TablePagination
                className="custom-pagination"
                component="div"
                rowsPerPageOptions={[25, 50, 75, 100]}
                count={bankDetailsData.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            )}
          </Mui.Grid>
        )}
      </Mui.Grid>
      {salaryTemplate ? (
        <AddNewSalaryGroup
          companyId={companyId}
          setAddNewSalaryTemplate={setAddNewSalaryTemplate}
          setCompanyFilter={setCompanyFilter}
          companyFilter={companyFilter}
          reCallApi={reCallApi}
          earningDetails={earningDetails}
          reCallEarningApi={reCallEarningApi}
        />
      ) : null}
      {editTemplate ? (
        <EditSalaryGroup
          companyId={companyId}
          setCompanyFilter={setCompanyFilter}
          companyFilter={companyFilter}
          reCallApi={reCallApi}
          editSalaryeTemplateDetails={editSalaryeTemplateDetails}
          setEditTemplate={setEditTemplate}
          setUpdateDeductionDetails={setUpdateDeductionDetails}
          updateDeductionDetails={updateDeductionDetails}
        />
      ) : null}
      {viewOption ? (
        <View
          companyId={companyId}
          setCompanyFilter={setCompanyFilter}
          companyFilter={companyFilter}
          reCallApi={reCallApi}
          setViewOption={setViewOption}
          editSalaryeTemplateDetails={editSalaryeTemplateDetails}
          setEditTemplate={setEditTemplate}
          setUpdateDeductionDetails={setUpdateDeductionDetails}
          updateDeductionDetails={updateDeductionDetails}
          isDisabled={isDisabled}
          setIsDisabled={setIsDisabled}
        />
      ) : null}
      <GlobalDialogBox
        handleCloseDialog={handleCloseDialogBox}
        open={openDialog}
        title="Delete Salary Template"
      >
        <Mui.Typography sx={{ paddingBottom: 2, fontSize: 22 }}>
          Are you sure,you want to delete the{" "}
          <span style={{ color: themes.redColor }}>
            {editSalaryeTemplateDetails?.name}
          </span>{" "}
          salary template?
        </Mui.Typography>
        <Mui.Grid container>
          <Mui.Grid
            xs={12}
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              alignItems: "flex-end",
              paddingRight: 1,
            }}
          >
            <CustomButton
              actionFuntion={() => setOpenDialog(false)}
              actionName="No"
              typeName="button"
            />
            &nbsp;&nbsp;
            <CustomButton
              actionFuntion={() => deleteSalaryTemplate()}
              actionName="Yes"
              typeName="button"
              diableAction={
                openDialog && buttonDisabled ? buttonDisabled : false
              }
            />
          </Mui.Grid>
        </Mui.Grid>
      </GlobalDialogBox>
    </React.Fragment>
  );
};
