import React, { useEffect, useState, useCallback } from "react";
import { Close } from "@mui/icons-material";
import * as Mui from "@mui/material";
import { CustomAutoComplete } from "components/CustomComponents/CustomAutoComplete";
import { CustomTextBox } from "components/CustomComponents/CustomTextBox";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { useFetch } from "services/hooks/useFetch";
import { themes } from "services/constants";
import { showToast } from "services/utils/Status";
import { customGetAPI } from "services/utils/CustomAPI";
import {
  specialCharacterRestrict,
  specialCharacterRest,
  onlyNumberRegex,
} from "services/constants/patterns";
import {
  minimum3Characters,
  minimum10Digits,
} from "services/constants/ErrorMessages";
import sessionHandling from "services/utils/notificationUtils";
import { CustomButton } from "components/CustomComponents/CustomButton";
export const EditUserDetails = ({
  setOpenSidePeek,
  openSidePeek,
  editEmployeeDetails,
  reloadData,
  setReloadData,
  setBulkData,
  importedData,
  setImportData,

  setButtonDisabled,
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    getValues,
    reset,
  } = useForm();
  const { domain, token } = useSelector((state) => state.tokenReducer);
  const { result: department } = useFetch(`${domain}department/`);
  const { result: designation } = useFetch(`${domain}designation/`);
  const { result: userGroup } = useFetch(`${domain}leave-groups-basics/`);
  const { result: companyList } = useFetch(`${domain}get-active-company/`);
  const { result: employmentType } = useFetch(`${domain}employment-type/`);
  const [doAnyAct, setDoAnyAct] = useState(false);
  const [employeeId, setEmployeeId] = useState(editEmployeeDetails.employee_id);
  const [employeeName, setEmployeeName] = useState(
    editEmployeeDetails.user_name
  );
  const [departmentName, setDepartmentName] = useState(
    editEmployeeDetails.department_name
  );
  const [phoneNumber, setPhoneNumber] = useState(
    editEmployeeDetails.phone_number ? editEmployeeDetails.phone_number : ""
  );
  const [dateOfJoined, setDateOfJoined] = useState(
    editEmployeeDetails?.date_joined
  );
  const [companyName, setCompanyName] = useState(
    editEmployeeDetails?.company_name
  );
  const [designationName, setDesignationName] = useState(
    editEmployeeDetails.designation_name
  );
  const [shiftGradeList, setShiftGradeList] = useState([]);
  const [shiftGradeName, setShiftGradeName] = useState(
    editEmployeeDetails.shift_grade_name
  );
  const [shiftCatgList, setShiftCatgList] = useState([]);
  const [shiftCatgName, setShiftCatgName] = useState(
    editEmployeeDetails.shift_category_name
  );

  const [leaveGroupName, setLeaveGroupName] = useState(
    editEmployeeDetails.leave_group_name
  );

  const [employmentTypeName, setEmploymentTypeName] = useState(
    editEmployeeDetails.employment_type_name
  );
  const [errorMessage, setErrorMessage] = useState(
    editEmployeeDetails.error_messages ? editEmployeeDetails.error_messages : ""
  );
  const [checkBoxValue, setCheckBoxValue] = useState(
    editEmployeeDetails.portal_access
  );

  useEffect(() => {
    if (reloadData) {
      setValue("employee", editEmployeeDetails.employee_id);
      setValue("changeUserName", editEmployeeDetails.user_name);
      setValue("mobileNumber", editEmployeeDetails.phone_number);
      setValue("departmentName", editEmployeeDetails.department_name);
      setValue("dateofJoined", editEmployeeDetails.date_joined);
      setValue("designation", editEmployeeDetails.designation_name);
      setValue("leaveGroup", editEmployeeDetails.leave_group_name);
      setValue("company", editEmployeeDetails.company_name);
      setValue("shiftGradeName", editEmployeeDetails.shift_grade_name);
      setValue("shiftCatgName", editEmployeeDetails.shift_category_name);
      setValue("employmentType", editEmployeeDetails.employment_type_name);
      setValue("departmentNameId", editEmployeeDetails?.department_id);
      setValue("leaveGroupId", editEmployeeDetails?.leave_group_id);
      setValue("designationId", editEmployeeDetails?.designation_id);
      setValue("selectedCompanyId", editEmployeeDetails?.company_id);
      setValue("employmentId", editEmployeeDetails?.employment_type_id);
      setValue("email", editEmployeeDetails?.email);
      setValue("verifyApiCall", true);
      setReloadData(false);
    }
  }, [
    editEmployeeDetails?.company_id,
    editEmployeeDetails.company_name,
    editEmployeeDetails.date_joined,
    editEmployeeDetails?.department_id,
    editEmployeeDetails.department_name,
    editEmployeeDetails?.designation_id,
    editEmployeeDetails.designation_name,
    editEmployeeDetails.employee_id,
    editEmployeeDetails.user_name,
    editEmployeeDetails.employment_type,
    editEmployeeDetails?.employment_type_id,
    editEmployeeDetails.employment_type_name,
    editEmployeeDetails.leave_group,
    editEmployeeDetails?.leave_group_id,
    editEmployeeDetails.leave_group_name,
    editEmployeeDetails.phone_number,
    reloadData,
    setReloadData,
    setValue,
    editEmployeeDetails.shift_grade_name,
    editEmployeeDetails.shift_category_name,
    editEmployeeDetails?.email,
  ]);

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Mui.Tab" || event.key === "Shift")
    ) {
      return;
    }
    setOpenSidePeek(open);
    if (open === false) {
      if (doAnyAct) {
        setDoAnyAct(false);
      }
    }
  };

  const updatePhoneNumber = (event) => {
    if (
      onlyNumberRegex.test(event.target.value) &&
      event.target.value.length <= 15
    ) {
      setPhoneNumber(event.target.value);
      setValue("mobileNumber", event.target.value);
    }
  };

  const updateCheckBoxValue = (event) => {
    setCheckBoxValue(event.target.checked);
  };

  const handleEmployeeId = (event) => {
    if (
      specialCharacterRest.test(event.target.value) &&
      event.target.value.length <= 12
    ) {
      setValue("employee", event.target.value);
      setEmployeeId(event.target.value);
    }
  };

  const updateUserNameChange = (event) => {
    if (
      specialCharacterRestrict.test(event.target.value) &&
      event.target.value.length <= 32
    ) {
      setValue("changeUserName", event.target.value);
      setEmployeeName(event.target.value);
    }
  };

  const handleDepartment = async (event, data) => {
    if (data) {
      setValue("departmentName", data?.label);
      setValue("departmentNameId", data?.id);
      setDepartmentName(data?.label);
      setValue("shiftGradeName", "");
      setValue("shift_grade", null);
      setValue("shiftCatgName", "");
      setValue("shift_category", null);
      setShiftGradeName("");
      setShiftCatgList([]);
      setShiftCatgName("");
    } else {
      setValue("departmentName", "");
      setValue("departmentNameId", null);
      setDepartmentName("");
      setValue("shiftGradeName", "");
      setValue("shift_grade", null);
      setValue("shiftCatgName", "");
      setValue("shift_category", null);
      setShiftGradeName("");
      setShiftCatgList([]);
      setShiftCatgName("");
    }
  };

  const handleDateOfJoined = (event) => {
    setValue("dateofJoined", event.target.value);
    setDateOfJoined(event.target.value);
  };

  const fetchShiftGradeData = useCallback(
    async (deptId) => {
      const res = await customGetAPI(
        `${domain}department-shift-category/?department_id=${deptId}`
      );
      if (res.ok) {
        setShiftGradeList(res?.responseDetails);
      }
    },
    [domain]
  );

  useEffect(() => {
    const deptId = getValues("departmentNameId");
    if (deptId) {
      fetchShiftGradeData(deptId);
    }
  }, [fetchShiftGradeData, getValues, departmentName]);

  const fetchShiftCatgoryData = useCallback(
    async (sftgrdId) => {
      const res = await customGetAPI(
        `${domain}shift-grade-shift-category/?shift_grade_id=${sftgrdId}`
      );
      if (res.ok) {
        setShiftCatgList(res?.responseDetails);
      }
    },
    [domain]
  );

  useEffect(() => {
    const sftgrdId = getValues("shift_grade");
    if (sftgrdId) {
      fetchShiftCatgoryData(sftgrdId);
    }
  }, [fetchShiftCatgoryData, getValues, shiftGradeName]);

  const handleShiftGradeName = (event, data) => {
    if (data) {
      setValue("shiftGradeName", data?.label);
      setValue("shift_grade", data?.shift_grade_id);
      setShiftGradeName(data?.label);
      setValue("shiftCatgName", "");
      setValue("shift_category", null);
      setShiftCatgName("");
    } else {
      setValue("shiftGradeName", "");
      setValue("shift_grade", null);
      setShiftGradeName("");
      setValue("shiftCatgName", "");
      setValue("shift_category", null);
      setShiftCatgList([]);
      setShiftCatgName("");
    }
  };

  const handleShiftCatgName = (event, data) => {
    if (data) {
      setValue("shiftCatgName", data?.label);
      setValue("shift_category", data?.id);
      setShiftCatgName(data?.label);
    } else {
      setValue("shiftCatgName", "");
      setValue("shift_category", null);
      setShiftCatgName("");
    }
  };

  const handleDesignation = (event, data) => {
    if (data) {
      setValue("designation", data?.label);
      setValue("designationId", data?.id);
      setDesignationName(data?.label);
    } else {
      setValue("designation", "");
      setValue("designationId", null);
      setDesignationName("");
    }
  };

  const handleUserLeaveGroup = (event, data) => {
    if (data) {
      setValue("leaveGroup", data?.label);
      setValue("leaveGroupId", data?.id);
      setValue("leaveGroupCount", data?.overall_leave_count);
      setLeaveGroupName(data.label);
    } else {
      setValue("leaveGroup", "");
      setValue("leaveGroupId", null);
      setValue("leaveGroupCount", 0);
      setLeaveGroupName("");
    }
  };

  const handleCompanyName = (event, data) => {
    if (data) {
      setValue("company", data?.label);
      setValue("selectedCompanyId", data?.id);
      setCompanyName(data?.label);
    } else {
      setValue("company", "");
      setValue("selectedCompanyId", null);
      setCompanyName("");
    }
  };

  const handleUpdateUserDetails = async (data) => {
    let verifyCall = getValues("verifyApiCall");
    if (verifyCall) {
      setValue("verifyApiCall", false);
      let json = [
        {
          rowid: editEmployeeDetails?.rowid,
          user_name: data.changeUserName,
          employee_id: data.employee,
          phone_number: data.mobileNumber,
          department_name: data.departmentName,
          designation_name: data.designation,
          employment_type_name: data.employmentType,
          leave_group_name: data.leaveGroup,
          shift_grade_name: data.shiftGradeName,
          shift_category_name: data.shiftCatgName,
          company_name: data.company,
          date_joined: data.dateofJoined?.split("-").reverse().join("-"),
          email: data.email,
          portal_access: checkBoxValue,
        },
      ];
      try {
        const response = await fetch(`${domain}bulk-employees/`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `token ${token}`,
          },
          body: JSON.stringify(json),
        });
        const res = await response.json();
        if (response.ok) {
          setErrorMessage(res[0].error_messages);
          let updatedUserDetails = importedData.map((modifyData) => {
            if (modifyData.rowid === editEmployeeDetails.rowid) {
              return {
                ...modifyData,
                company_id: res[0].company_id,
                company_name: res[0].company_name,
                date_joined: res[0].date_joined,
                department_id: res[0].department_id,
                department_name: res[0].department_name,
                designation_id: res[0].designation_id,
                designation_name: res[0].designation_name,
                employee_id: res[0].employee_id,
                user_name: res[0].user_name,
                employment_type_id: res[0].employment_type_id,
                employment_type_name: res[0].employment_type_name,
                shift_grade_name: res[0].shift_grade_name,
                shift_category_name: res[0].shift_category_name,
                leave_group: res[0].leave_group,
                leave_group_id: res[0].leave_group_id,
                leave_group_name: res[0].leave_group_name,
                phone_number: res[0].phone_number,
                status: res[0].status,
                error_messages: res[0].error_messages,
                email: res[0].email,
                portal_access: res[0].portal_access,
              };
            } else {
              return modifyData;
            }
          });
          setImportData(updatedUserDetails);
          setBulkData(updatedUserDetails);

          let resData = updatedUserDetails.filter((resDetails) => {
            return resDetails.error_messages !== "";
          });
          setButtonDisabled(resData?.length > 0 ? true : false);
          if (res[0].error_messages === "") {
            showToast("success", "Employee details verified successfully");
            setValue("verifyApiCall", true);
          } else {
            setValue("verifyApiCall", true);
            showToast("error", res[0].error_messages);
          }
        } else if (response.status === 409) {
          sessionHandling();
        } else {
          throw new Error(res.error);
        }
      } catch (error) {
        showToast("error", error.message);
      }
    }
  };

  const handleEmploymentType = (event, data) => {
    if (data) {
      setValue("employmentType", data?.label);
      setValue("employmentId", data?.id);
      setEmploymentTypeName(data?.label);
    } else {
      setValue("employmentType", "");
      setValue("employmentId", null);
      setEmploymentTypeName("");
    }
  };

  return (
    <React.Fragment>
      <Mui.Drawer
        anchor="right"
        open={openSidePeek}
        onClose={toggleDrawer("right", false)}
        PaperProps={{
          sx: {
            width: {
              xs: 300,
              sm: 360,
              md: 500,
              lg: 700,
            },
          },
          height: "calc(100% - 64px)",
          top: 64,
        }}
      >
        <form
          style={{ paddingTop: 2 }}
          onSubmit={handleSubmit(handleUpdateUserDetails)}
        >
          <Mui.Grid
            container
            sx={{
              padding: 1,
              backgroundColor: themes.primaryIndicator,
              borderRadius: 5,
            }}
          >
            <Mui.Grid xs={6}>
              <Mui.Typography sx={{ fontSize: 22 }}>
                Edit Employee Details
              </Mui.Typography>
            </Mui.Grid>
            <Mui.Grid
              xs={6}
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
                alignItems: "flex-end",
              }}
            >
              <Mui.IconButton
                sx={{
                  backgroundColor: themes.blackColor,
                  "&:hover": {
                    backgroundColor: themes.blackColor,
                  },
                }}
                onClick={() => {
                  reset();
                  setOpenSidePeek(false);
                }}
              >
                <Close sx={{ color: themes.whiteColor }} />
              </Mui.IconButton>
            </Mui.Grid>
          </Mui.Grid>
          <Mui.Grid container sx={{ padding: 1 }}>
            <Mui.Grid item>
              <Mui.Stack direction="row">
                <Mui.Typography sx={{ color: themes.backgroundRed }}>
                  {errorMessage}
                </Mui.Typography>
              </Mui.Stack>
            </Mui.Grid>
          </Mui.Grid>
          <Mui.Grid container sx={{ padding: 1 }}>
            <Mui.Grid xs={6} sx={{ padding: 0.5 }}>
              <Mui.Typography>Employee Id</Mui.Typography>
              <CustomTextBox
                selectedValue={employeeId}
                rules={{
                  ...register("employee", {
                    required: "Employee Id is required",
                    validate: {
                      minLengthNoSpaces: (value) => {
                        if (value?.trim().length < 6 && value !== "") {
                          return "Minimum 6 characters with no spaces";
                        } else {
                          return true;
                        }
                      },
                      employeeIdAvailable: async (fieldValue) => {
                        if (
                          fieldValue !== "" &&
                          editEmployeeDetails.employee_id !== fieldValue &&
                          getValues("uniqueNameCheck") !== fieldValue
                        ) {
                          setValue("uniqueNameCheck", fieldValue);
                          const response = await fetch(
                            `${domain}employee-id/check?employee_id=${fieldValue}`
                          );
                          const resp = await response.json();
                          if (resp.exists) {
                            return "Employee Id Already Exists";
                          }
                        }
                      },
                    },
                  }),
                }}
                updateDataFunction={handleEmployeeId}
                placeHolder="Enter the Employee Id"
                errorMessage={
                  errors?.employee?.message ? errors?.employee?.message : ""
                }
                textBoxError={errors.employee ? true : false}
              />
            </Mui.Grid>
            <Mui.Grid xs={6} sx={{ padding: 0.5 }}>
              <Mui.Typography>Employee Name</Mui.Typography>
              <CustomTextBox
                selectedValue={employeeName}
                rules={{
                  ...register("changeUserName", {
                    required: "userName is required",
                    maxLength: {
                      value: 32,
                      message: "Maximum 32 characters are required",
                    },
                    validate: {
                      minLengthNoSpaces: (value) => {
                        if (value?.trim().length < 3 && value !== "") {
                          return minimum3Characters;
                        } else {
                          return true;
                        }
                      },
                    },
                  }),
                }}
                updateDataFunction={updateUserNameChange}
                placeHolder="Enter the Name"
                errorMessage={
                  errors?.changeUserName?.message
                    ? errors?.changeUserName?.message
                    : ""
                }
                textBoxError={errors.changeUserName ? true : false}
              />
            </Mui.Grid>
          </Mui.Grid>
          <Mui.Grid container sx={{ padding: 1 }}>
            <Mui.Grid xs={6} sx={{ padding: 0.5 }}>
              <Mui.Typography>Phone Number</Mui.Typography>
              <CustomTextBox
                selectedValue={phoneNumber}
                rules={{
                  ...register("mobileNumber", {
                    required: "Phone number is required",
                    minLength: {
                      value: 10,
                      message: minimum10Digits,
                    },
                  }),
                }}
                updateDataFunction={updatePhoneNumber}
                placeHolder="Enter Phone Number"
                errorMessage={
                  errors?.mobileNumber?.message
                    ? errors?.mobileNumber?.message
                    : ""
                }
                textBoxError={errors.mobileNumber ? true : false}
              />
            </Mui.Grid>
            <Mui.Grid xs={6} sx={{ padding: 0.5 }}>
              <Mui.Typography>Designation</Mui.Typography>
              <CustomAutoComplete
                dataList={designation.map((obj) => ({
                  ...obj,
                  label: obj.designation,
                }))}
                selectedValue={designationName}
                rules={{
                  ...register("designation", {
                    required: "Designation is required",
                  }),
                }}
                updateDataFunction={handleDesignation}
                placeHolder="Please select the designation"
                errorMessage={
                  errors?.designation?.message
                    ? errors?.designation?.message
                    : ""
                }
                textBoxError={errors?.designation ? true : false}
              />
            </Mui.Grid>
          </Mui.Grid>
          <Mui.Grid container sx={{ padding: 1 }}>
            <Mui.Grid item xs={6} sx={{ padding: 0.5 }}>
              <Mui.Typography>Date Of Joined</Mui.Typography>
              <CustomTextBox
                type="date"
                selectedValue={dateOfJoined}
                rules={{
                  ...register("dateofJoined", {
                    required: "Date of joined is required",
                  }),
                }}
                updateDataFunction={handleDateOfJoined}
                errorMessage={
                  errors?.dateofJoined?.message
                    ? errors?.dateofJoined?.message
                    : ""
                }
                textBoxError={errors.dateofJoined ? true : false}
              />
            </Mui.Grid>
            <Mui.Grid item xs={6} sx={{ padding: 0.5 }}>
              <Mui.Typography>Department</Mui.Typography>
              <CustomAutoComplete
                dataList={department.map((obj) => ({
                  ...obj,
                  label: obj?.department?.toString(),
                }))}
                selectedValue={departmentName}
                rules={{
                  ...register("departmentName", {
                    required: "Department name is required",
                  }),
                }}
                updateDataFunction={handleDepartment}
                placeHolder="Please select the department name"
                errorMessage={
                  errors?.departmentName?.message
                    ? errors?.departmentName?.message
                    : ""
                }
                textBoxError={errors?.departmentName ? true : false}
              />
            </Mui.Grid>
          </Mui.Grid>
          <Mui.Grid container sx={{ padding: 1 }}>
            <Mui.Grid item xs={6} sx={{ padding: 0.5 }}>
              <Mui.Typography>Shift Grade</Mui.Typography>
              <CustomAutoComplete
                dataList={shiftGradeList.map((obj) => ({
                  ...obj,
                  label: obj.shift_grade_name,
                }))}
                selectedValue={shiftGradeName}
                rules={{
                  ...register("shiftGradeName", {
                    required: "Shift grade is required",
                  }),
                }}
                updateDataFunction={handleShiftGradeName}
                placeHolder="Please select the shift grade"
                errorMessage={
                  errors?.shiftGradeName?.message
                    ? errors?.shiftGradeName?.message
                    : ""
                }
                textBoxError={errors?.shiftGradeName ? true : false}
              />
            </Mui.Grid>
            <Mui.Grid item xs={6} sx={{ padding: 0.5 }}>
              <Mui.Typography>Shift Category</Mui.Typography>
              <CustomAutoComplete
                dataList={shiftCatgList.map((obj) => ({
                  ...obj,
                  label: obj.shift_category_name,
                }))}
                selectedValue={shiftCatgName}
                rules={{
                  ...register("shiftCatgName", {
                    required: "Shift Category is required",
                  }),
                }}
                updateDataFunction={handleShiftCatgName}
                placeHolder="Please select the shift category"
                errorMessage={
                  errors?.shiftCatgName?.message
                    ? errors?.shiftCatgName?.message
                    : ""
                }
                textBoxError={errors?.shiftCatgName ? true : false}
              />
            </Mui.Grid>
          </Mui.Grid>
          <Mui.Grid container sx={{ padding: 1 }}>
            <Mui.Grid item xs={6} sx={{ padding: 0.5 }}>
              <Mui.Typography>Employment Type</Mui.Typography>
              <CustomAutoComplete
                dataList={employmentType.map((obj) => ({
                  ...obj,
                  label: obj.employment_type,
                }))}
                selectedValue={employmentTypeName}
                rules={{
                  ...register("employmentType", {
                    required: "Employment Type is required",
                  }),
                }}
                updateDataFunction={handleEmploymentType}
                placeHolder="Please select the employment type"
                errorMessage={
                  errors?.employmentType?.message
                    ? errors?.employmentType?.message
                    : ""
                }
                textBoxError={errors.employmentType ? true : false}
              />
            </Mui.Grid>
            <Mui.Grid item xs={6} sx={{ padding: 0.5 }}>
              <Mui.Typography>leaveGroup</Mui.Typography>
              <CustomAutoComplete
                dataList={userGroup.map((obj) => ({
                  ...obj,
                  label: obj.name,
                }))}
                selectedValue={leaveGroupName}
                rules={{
                  ...register("leaveGroup", {
                    required: "Leave group is required",
                  }),
                }}
                updateDataFunction={handleUserLeaveGroup}
                placeHolder="Please select the leave group"
                errorMessage={
                  errors?.leaveGroup?.message ? errors?.leaveGroup?.message : ""
                }
                textBoxError={errors?.leaveGroup ? true : false}
              />
            </Mui.Grid>
          </Mui.Grid>
          <Mui.Grid container sx={{ padding: 1 }}>
            <Mui.Grid xs={6} sx={{ padding: 0.5 }}>
              <Mui.Typography>Company Name</Mui.Typography>
              <CustomAutoComplete
                dataList={companyList.map((obj) => ({
                  ...obj,
                  label: obj.companyname,
                }))}
                selectedValue={companyName}
                rules={{
                  ...register("company", {
                    required: "Company name is required",
                  }),
                }}
                updateDataFunction={handleCompanyName}
                placeHolder="Please select the company name"
                errorMessage={
                  errors?.company?.message ? errors?.company?.message : ""
                }
                textBoxError={errors.company ? true : false}
              />
            </Mui.Grid>
            <Mui.Grid xs={6} sx={{ padding: 1 }}>
              <Mui.Typography>Email Id</Mui.Typography>
              <Mui.TextField
                size="small"
                {...register("email", {
                  required: false,
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    message: "Invalid e-mail address",
                  },
                  validate: {
                    emailAvailable: async (fieldValue) => {
                      if (fieldValue !== "") {
                        try {
                          const response = await fetch(
                            `${domain}unique/email/?email=${fieldValue}`,
                            {
                              method: "GET",
                              headers: {
                                "Content-Type": "application/json",
                                Authorization: `token ${token}`,
                              },
                            }
                          );
                          const res = await response.json();
                          if (response.ok) {
                            if (res.exists) {
                              return "Email already exists";
                            } else {
                              return true;
                            }
                          } else if (response.status === 409) {
                            sessionHandling();
                          } else {
                            throw new Error(res.error);
                          }
                        } catch (error) {
                          showToast("error", error.message);
                        }
                      }
                    },
                  },
                })}
                error={Boolean(errors.email)}
                helperText={errors.email ? errors.email.message : null}
                placeholder="Enter the email"
                fullWidth
              />
            </Mui.Grid>
          </Mui.Grid>
          <Mui.Grid container xs={6} sx={{ padding: 1, paddingLeft: 1.5 }}>
            <Mui.Grid xs={4} sx={{ padding: 0.5, paddingTop: 1.5 }}>
              <Mui.Typography>Portal Access</Mui.Typography>
            </Mui.Grid>
            <Mui.Grid xs={2}>
              <Mui.FormControlLabel
                control={
                  <Mui.Checkbox
                    checked={checkBoxValue}
                    color="primary"
                    size="large"
                    onChange={updateCheckBoxValue}
                  />
                }
                label=""
                sx={{ marginRight: 1 }}
              />
            </Mui.Grid>

            <Mui.Grid xs={6}></Mui.Grid>
          </Mui.Grid>
          <Mui.Grid
            container
            sx={{
              padding: 2,
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              alignItems: "flex-end",
            }}
          >
            <CustomButton
              actionFuntion={() => {
                setOpenSidePeek(false);
              }}
              actionName="Cancel"
              typeName="button"
            />
            &nbsp;&nbsp;
            <CustomButton actionName="Re-Verify" typeName="submit" />
          </Mui.Grid>
        </form>
      </Mui.Drawer>
    </React.Fragment>
  );
};
