import React, { useState, useEffect } from "react";
import * as Mui from "@mui/material";
import { themes } from "services/constants";
import { useSelector } from "react-redux";
import { showToast } from "services/utils/Status";
import moment from "moment";
import { Loader } from "components/Loader";
import sessionHandling from "services/utils/notificationUtils";
import { BackArrow } from "components/CustomComponents/BackArrow";
import { DownloadForOffline } from "@mui/icons-material";
import { useFetch } from "services/hooks/useFetch";
import { tableRowSx } from "services/constants";
import { setGlobalSearchValue } from "services/Redux/userToken";
import { useDispatch } from "react-redux";

const DailyReport = ({ setShowReport }) => {
  const { domain, token, userDetails, globalSearchValue } = useSelector(
    (state) => state.tokenReducer
  );
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [dailyRepDetails, setDailyRepDetails] = useState([]);
  const [masterDataForSearch, setMasterDataForSearch] = useState([]);
  const [startDate, setStartDate] = useState(
    moment(new Date()).format("YYYY-MM-DD")
  );
  const { result: departmentData } = useFetch(
    `${domain}get-department/?user_id=${userDetails.id}`
  );
  const [departmentName, setDepartmentName] = useState("All");
  const [departmentId, setDepartmentId] = useState("99999");

  // const [selectedColumns, setSelectedColumns] = React.useState([
  //   "employeename",
  //   "shiftname",
  //   "checkintime",
  //   "checkouttime",
  //   "totalbreaktime",
  //   "totalworktime",
  //   "totalovertime",
  //   "earlyleavingminutes",
  //   "latecomingminutes",
  //   "status",
  // ]);
  // const [popoverAnchor, setPopoverAnchor] = React.useState(null);

  useEffect(() => {
    const fetchUsertaskDetails = async () => {
      try {
        setIsLoading(true);
        let searchStartDate = startDate.split("/").reverse().join("-");

        const response = await fetch(
          `${domain}daily-report/?department_id=${departmentId}&date=${searchStartDate}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `token ${token}`,
            },
          }
        );
        const res = await response.json();
        if (response.ok) {
          const sortedDetails = res?.user_status_details?.sort(function (a, b) {
            if (a.shift_name?.toLowerCase() < b.shift_name?.toLowerCase())
              return -1;
            if (a.shift_name?.toLowerCase() > b.shift_name?.toLowerCase())
              return 1;
            return 0;
          });

          setDailyRepDetails(sortedDetails);
          setMasterDataForSearch(sortedDetails);
        } else if (response.status === 409) {
          setDailyRepDetails([]);
          setMasterDataForSearch([]);
          sessionHandling();
        } else {
          setDailyRepDetails([]);
          setMasterDataForSearch([]);
          throw new Error(res.error);
        }
      } catch (error) {
        setDailyRepDetails([]);
        setMasterDataForSearch([]);
        showToast("error", error.message);
      } finally {
        setIsLoading(false);
      }
    };
    fetchUsertaskDetails();
  }, [domain, token, startDate, departmentId]);

  useEffect(() => {
    const filteredResults = masterDataForSearch?.filter((item) =>
      [
        "user_code",
        "user_name",
        "departmentname",
        "shift_name",
        "check_in_time",
        "check_out_time",
        "total_break_time",
        "total_work_time",
        "total_over_time",
        "early_leaving_minutes",
        "late_coming_minutes",
        "type",
      ].some((key) =>
        item[key]
          ?.toString()
          .toLowerCase()
          .includes(globalSearchValue.toString().toLowerCase())
      )
    );
    setDailyRepDetails(filteredResults);
    setPage(0);
  }, [globalSearchValue, masterDataForSearch]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const getMaxDate = () => {
    const today = new Date();
    today.setDate(today.getDate());
    return today.toISOString().split("T")[0];
  };

  const handleDateChange = (event) => {
    if (event.target.value) {
      const selectedDate = event.target.value;
      const maxDate = getMaxDate();

      if (selectedDate <= maxDate) {
        setStartDate(selectedDate);
      } else {
        setStartDate(maxDate);
      }
    }
  };

  // const handleOpenPopover = (event) => {
  //   setPopoverAnchor(event.currentTarget);
  // };

  // const handleClosePopover = () => {
  //   setPopoverAnchor(null);
  // };

  // const handleColumnSelection = (column) => {
  //   setSelectedColumns((prev) =>
  //     prev.includes(column)
  //       ? prev.filter((col) => col !== column)
  //       : [...prev, column]
  //   );
  // };

  // const getFilteredHeaders = () => {
  //   const allHeaders = {
  //     employeename: "Employee Name",
  //     shiftname: "Shift Name",
  //     checkintime: "Check In",
  //     checkouttime: "Check Out",
  //     totalbreaktime: "Total Break Time",
  //     totalworktime: "Total Work Time",
  //     totalovertime: "Total Over Time",
  //     earlyleavingminutes: "Early Leaving Minutes",
  //     latecomingminutes: "Late Coming Minutes",
  //     status: "Status",
  //   };

  //   return Object.entries(allHeaders).filter(([key]) =>
  //     selectedColumns.includes(key)
  //   );
  // };

  const getDailyReport = () => {
    let dataPreparation = [];
    dailyRepDetails?.forEach((element) => {
      let overAllData = {
        usercode: element?.user_code ? element?.user_code : "N/A",
        employeename: element?.user_name,
        departmentname: element?.department_name
          ? element?.department_name
          : "N/A",
        shiftname: element?.shift_name ? element?.shift_name : "Unassigned",
        checkintime: element?.check_in_time
          ? moment(element?.check_in_time, "HH:mm:ss").format("h:mm a")
          : "N/A",
        checkouttime: element?.check_out_time
          ? moment(element?.check_out_time, "HH:mm:ss").format("h:mm a")
          : "N/A",
        totalbreaktime: element?.total_break_time
          ? element?.total_break_time
          : "00:00",
        totalworktime: element?.total_work_time
          ? element?.total_work_time
          : "00:00",
        totalovertime: element?.total_over_time
          ? element?.total_over_time
          : "00:00",
        latecomingminutes: element?.late_coming_minutes
          ? element?.late_coming_minutes
          : "00:00",
        earlyleavingminutes: element?.early_leaving_minutes
          ? element?.early_leaving_minutes
          : "00:00",
        status: element?.type ? element?.type : "N/A",
      };
      dataPreparation = [...dataPreparation, overAllData];
    });
    const DailyReport = ["Daily Report"];
    const Space = [""];
    const Dates = ["Date"];
    const Department = ["Department Name"];
    const GeneratedOn = ["Report Generated On"];
    DailyReport.push("");
    Dates.push(moment(startDate).format("DD/MM/YYYY"));
    Department.push(departmentName);
    GeneratedOn.push(moment(new Date()).format("DD/MM/YYYY, hh:mm a"));
    Space.push("");
    const items = dataPreparation;
    const TableHead = ["User Code"];
    TableHead.push("Employee Name");
    TableHead.push("Department Name");
    TableHead.push("Shift Name");
    TableHead.push("Check In");
    TableHead.push("Check Out");
    TableHead.push("Total Break Time");
    TableHead.push("Total Work Time");
    TableHead.push("Total Over Time");
    TableHead.push("Late Coming Hour");
    TableHead.push("Early Leaving Hour");
    TableHead.push("Status");
    const replacer = (key, value) => (value === null ? "" : value);
    const header = Object.keys(items[0]);
    let csv = items.map((row) =>
      header
        .map((fieldName) => JSON.stringify(row[fieldName], replacer))
        .join(",")
    );
    csv.unshift(TableHead.join(","));
    csv.unshift(Space.join(","));
    csv.unshift(GeneratedOn.join(","));
    csv.unshift(Department.join(","));
    csv.unshift(Dates.join(","));
    csv.unshift(DailyReport.join(","));
    csv = csv.join("\r\n");
    const ConvertData = csv;
    const element = document.createElement("a");
    const file = new Blob([ConvertData], {
      type: "text/plain",
    });
    element.href = URL.createObjectURL(file);
    element.download = "DailyReport.csv";
    document.body.appendChild(element);
    element.click();
  };

  // const getDailyReport = () => {
  //   let dataPreparation = dailyRepDetails.map((element) => ({
  //     employeename: element?.user_name || "N/A",
  //     shiftname: element?.shift_name || "Unassigned",
  //     checkintime: element?.check_in_time
  //       ? moment(element?.check_in_time, "HH:mm:ss").format("h:mm a")
  //       : "N/A",
  //     checkouttime: element?.check_out_time
  //       ? moment(element?.check_out_time, "HH:mm:ss").format("h:mm a")
  //       : "N/A",
  //     totalbreaktime: element?.total_break_time || "00:00",
  //     totalworktime: element?.total_work_time || "00:00",
  //     totalovertime: element?.total_over_time || "00:00",
  //     earlyleavingminutes: element?.early_leaving_minutes || "00:00",
  //     latecomingminutes: element?.late_coming_minutes || "00:00",
  //     status: element?.type || "N/A",
  //   }));

  //   const filteredHeaders = getFilteredHeaders();
  //   const headerKeys = filteredHeaders.map(([key]) => key);
  //   const headerLabels = filteredHeaders.map(([_, label]) => label);

  //   const csvData = dataPreparation.map((row) =>
  //     headerKeys.map((key) => row[key]).join(",")
  //   );
  //   csvData.unshift(headerLabels.join(","));

  //   const csv = csvData.join("\r\n");
  //   const element = document.createElement("a");
  //   const file = new Blob([csv], { type: "text/csv" });
  //   element.href = URL.createObjectURL(file);
  //   element.download = "DailyReport.csv";
  //   document.body.appendChild(element);
  //   element.click();
  // };

  return (
    <React.Fragment>
      <Mui.Grid container spacing={1} sx={{ mt: 0 }}>
        <Mui.Grid item xs={12} sm={12} md={12} lg={12}>
          <Mui.Stack direction="row">
            <BackArrow
              actionFunction={() => {
                dispatch(setGlobalSearchValue(""));
                setShowReport(false);
              }}
            />
            <Mui.Typography
              sx={{
                fontSize: 20,
                fontWeight: "bold",
                mt: 1.5,
              }}
            >
              Daily Report
            </Mui.Typography>
          </Mui.Stack>
        </Mui.Grid>
      </Mui.Grid>

      {/* <Mui.Button variant="outlined" onClick={handleOpenPopover}>
        Select Columns
      </Mui.Button>
      <Mui.Popover
        open={Boolean(popoverAnchor)}
        anchorEl={popoverAnchor}
        onClose={handleClosePopover}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Mui.Box sx={{ padding: 2, minWidth: 200 }}>
          <Mui.Typography variant="h6">Select Columns</Mui.Typography>
          <Mui.FormGroup>
            {[
              "employeename",
              "shiftname",
              "checkintime",
              "checkouttime",
              "totalbreaktime",
              "totalworktime",
              "totalovertime",
              "earlyleavingminutes",
              "latecomingminutes",
              "status",
            ].map((column) => (
              <Mui.FormControlLabel
                key={column}
                control={
                  <Mui.Checkbox
                    checked={selectedColumns.includes(column)}
                    onChange={() => handleColumnSelection(column)}
                  />
                }
                label={column
                  .replace(/([A-Z])/g, " $1")
                  .replace(/^./, (str) => str.toUpperCase())}
              />
            ))}
          </Mui.FormGroup>
          <Mui.Stack direction="row" spacing={2} justifyContent="flex-end">
            <Mui.Button
              variant="contained"
              size="small"
              onClick={handleClosePopover}
            >
              Submit
            </Mui.Button>
            <Mui.Button
              variant="outlined"
              size="small"
              onClick={handleClosePopover}
            >
              Cancel
            </Mui.Button>
          </Mui.Stack>
        </Mui.Box>
      </Mui.Popover> */}

      <Mui.Grid container spacing={1} sx={{ mt: 1, mb: 1 }}>
        <Mui.Grid item xs={12} sm={6} md={3} lg={2}>
          <Mui.TextField
            id="from-date"
            type="date"
            size="small"
            label="Start Date"
            value={startDate}
            sx={{ background: themes.whiteColor }}
            InputLabelProps={{
              shrink: true,
            }}
            onChange={handleDateChange}
            fullWidth
            disableOpenPicker
            inputProps={{
              max: getMaxDate(),
            }}
          />
        </Mui.Grid>
        <Mui.Grid item xs={12} sm={6} md={3} lg={2}>
          <Mui.Autocomplete
            disablePortal
            id="combo-box-demo"
            size="small"
            sx={{ minWidth: "100%" }}
            value={
              [{ id: 99999, department_name: "All" }, ...departmentData]?.find(
                (option) => option?.department_name === departmentName
              ) || {
                department_name: departmentName,
              }
            }
            options={[{ id: 99999, department_name: "All" }, ...departmentData]}
            ListboxProps={{
              style: {
                maxHeight: "150px",
              },
            }}
            filterOptions={(options, state) => {
              return options?.filter((option) =>
                option?.department_name
                  ?.toLowerCase()
                  .includes(state.inputValue.toLowerCase())
              );
            }}
            getOptionLabel={(option) =>
              option?.department_name ? option?.department_name : ""
            }
            isOptionEqualToValue={(option, value) =>
              option.department_name === value.department_name
            }
            renderOption={(props, option) => {
              return <li {...props}>{option?.department_name}</li>;
            }}
            onChange={(event, value) => {
              if (value && value?.department_name) {
                setDepartmentName(value.department_name);
                setDepartmentId(value.id);
              }
            }}
            renderInput={(params) => (
              <Mui.TextField
                {...params}
                size="small"
                label="Department"
                sx={{ background: "white" }}
                name="department name"
                placeholder="Select Department"
              />
            )}
          />
        </Mui.Grid>
        <Mui.Grid item lg={6.5}></Mui.Grid>
        <Mui.Grid item xs={2} sm={2} md={2} lg={1.5}>
          <Mui.IconButton
            onClick={() => {
              getDailyReport();
            }}
            disabled={dailyRepDetails?.length === 0 ? true : false}
          >
            <DownloadForOffline
              size="large"
              sx={{ color: "#25B7D3", fontSize: 40, mt: -1 }}
            />
          </Mui.IconButton>
        </Mui.Grid>
      </Mui.Grid>

      {isLoading ? (
        <Loader info="Loading..." />
      ) : (
        <>
          {dailyRepDetails?.length !== 0 ? (
            <Mui.Grid
              container
              sx={{
                paddingRight: 1,
              }}
            >
              <Mui.TableContainer
                component={Mui.Paper}
                style={{ paddingTop: 4, paddingBottom: 2 }}
                sx={{
                  mt: 2,
                  borderBottom: "1.5px solid transparent",
                }}
              >
                <Mui.Table>
                  <Mui.TableHead sx={{ backgroundColor: themes.primary }}>
                    <Mui.TableRow sx={tableRowSx}>
                      <Mui.TableCell
                        sx={{
                          color: themes.blackColor,
                          fontWeight: "bold",
                          overflow: "hidden",
                          whiteSpace: "nowrap",
                          maxWidth: 100,
                          width: "6%",
                          textOverflow: "ellipsis",
                        }}
                      >
                        Usercode
                      </Mui.TableCell>
                      <Mui.TableCell
                        sx={{
                          color: themes.blackColor,
                          fontWeight: "bold",
                          overflow: "hidden",
                          whiteSpace: "nowrap",
                          maxWidth: 100,
                          width: "13%",
                          textOverflow: "ellipsis",
                        }}
                      >
                        Employee Name
                      </Mui.TableCell>
                      <Mui.TableCell
                        sx={{
                          color: themes.blackColor,
                          fontWeight: "bold",
                          overflow: "hidden",
                          whiteSpace: "nowrap",
                          maxWidth: 100,
                          width: "13%",
                          textOverflow: "ellipsis",
                        }}
                      >
                        Department Name
                      </Mui.TableCell>
                      <Mui.TableCell
                        sx={{
                          color: themes.blackColor,
                          fontWeight: "bold",
                          overflow: "hidden",
                          whiteSpace: "nowrap",
                          maxWidth: 100,
                          width: "13%",
                          textOverflow: "ellipsis",
                        }}
                      >
                        Shift Name
                      </Mui.TableCell>
                      <Mui.TableCell
                        sx={{
                          color: themes.blackColor,
                          fontWeight: "bold",
                          overflow: "hidden",
                          whiteSpace: "nowrap",
                          maxWidth: 100,
                          width: "5%",
                          textOverflow: "ellipsis",
                        }}
                      >
                        In
                      </Mui.TableCell>
                      <Mui.TableCell
                        sx={{
                          color: themes.blackColor,
                          fontWeight: "bold",
                          overflow: "hidden",
                          whiteSpace: "nowrap",
                          maxWidth: 100,
                          width: "5%",
                          textOverflow: "ellipsis",
                        }}
                      >
                        Out
                      </Mui.TableCell>
                      <Mui.TableCell
                        sx={{
                          color: themes.blackColor,
                          fontWeight: "bold",
                          overflow: "hidden",
                          whiteSpace: "nowrap",
                          maxWidth: 100,
                          width: "5%",
                          textOverflow: "ellipsis",
                        }}
                      >
                        Break Hr
                      </Mui.TableCell>
                      <Mui.TableCell
                        sx={{
                          color: themes.blackColor,
                          fontWeight: "bold",
                          overflow: "hidden",
                          whiteSpace: "nowrap",
                          maxWidth: 100,
                          width: "5%",
                          textOverflow: "ellipsis",
                        }}
                      >
                        Work Hr
                      </Mui.TableCell>
                      <Mui.TableCell
                        sx={{
                          color: themes.blackColor,
                          fontWeight: "bold",
                          overflow: "hidden",
                          whiteSpace: "nowrap",
                          maxWidth: 100,
                          width: "5%",
                          textOverflow: "ellipsis",
                        }}
                      >
                        OT Hr
                      </Mui.TableCell>
                      <Mui.TableCell
                        sx={{
                          color: themes.blackColor,
                          fontWeight: "bold",
                          overflow: "hidden",
                          whiteSpace: "nowrap",
                          maxWidth: 100,
                          width: "10%",
                          textOverflow: "ellipsis",
                        }}
                      >
                        LateComing Hr
                      </Mui.TableCell>
                      <Mui.TableCell
                        sx={{
                          color: themes.blackColor,
                          fontWeight: "bold",
                          overflow: "hidden",
                          whiteSpace: "nowrap",
                          maxWidth: 100,
                          width: "10%",
                          textOverflow: "ellipsis",
                        }}
                      >
                        EarlyLeaving Hr
                      </Mui.TableCell>
                      <Mui.TableCell
                        sx={{
                          color: themes.blackColor,
                          fontWeight: "bold",
                          overflow: "hidden",
                          whiteSpace: "nowrap",
                          maxWidth: 100,
                          width: "10%",
                          textOverflow: "ellipsis",
                        }}
                      >
                        Status
                      </Mui.TableCell>
                    </Mui.TableRow>
                  </Mui.TableHead>
                  <Mui.TableBody>
                    {dailyRepDetails
                      ?.slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((item, index) => (
                        <>
                          <Mui.TableRow sx={tableRowSx}>
                            <Mui.TableCell
                              sx={{
                                overflow: "hidden",
                                whiteSpace: "nowrap",
                                maxWidth: 100,
                              }}
                            >
                              <Mui.Typography
                                sx={{
                                  overflow: "hidden",
                                  whiteSpace: "nowrap",
                                  textOverflow: "ellipsis",
                                }}
                              >
                                {item?.user_code ? item?.user_code : "N/A"}
                              </Mui.Typography>
                            </Mui.TableCell>
                            <Mui.TableCell
                              sx={{
                                overflow: "hidden",
                                whiteSpace: "nowrap",
                                maxWidth: 100,
                              }}
                            >
                              <Mui.Tooltip
                                title={item?.user_name}
                                disableHoverListener={!item?.user_name}
                              >
                                <Mui.Typography
                                  sx={{
                                    overflow: "hidden",
                                    whiteSpace: "nowrap",
                                    textOverflow: "ellipsis",
                                  }}
                                >
                                  {item?.user_name}
                                </Mui.Typography>
                              </Mui.Tooltip>
                            </Mui.TableCell>
                            <Mui.TableCell
                              sx={{
                                overflow: "hidden",
                                whiteSpace: "nowrap",
                                maxWidth: 100,
                              }}
                            >
                              <Mui.Tooltip
                                title={item?.department_name}
                                disableHoverListener={!item?.department_name}
                              >
                                <Mui.Typography
                                  sx={{
                                    overflow: "hidden",
                                    whiteSpace: "nowrap",
                                    textOverflow: "ellipsis",
                                  }}
                                >
                                  {item?.department_name
                                    ? item?.department_name
                                    : "Unassigned"}
                                </Mui.Typography>
                              </Mui.Tooltip>
                            </Mui.TableCell>
                            <Mui.TableCell
                              sx={{
                                overflow: "hidden",
                                whiteSpace: "nowrap",
                                maxWidth: 100,
                              }}
                            >
                              <Mui.Tooltip
                                title={item?.shift_name}
                                disableHoverListener={!item?.shift_name}
                              >
                                <Mui.Typography
                                  sx={{
                                    overflow: "hidden",
                                    whiteSpace: "nowrap",
                                    textOverflow: "ellipsis",
                                  }}
                                >
                                  {item?.shift_name
                                    ? item?.shift_name
                                    : "Unassigned"}
                                </Mui.Typography>
                              </Mui.Tooltip>
                            </Mui.TableCell>
                            <Mui.TableCell
                              sx={{
                                overflow: "hidden",
                                whiteSpace: "nowrap",
                                maxWidth: 100,
                              }}
                            >
                              <Mui.Typography
                                sx={{
                                  overflow: "hidden",
                                  whiteSpace: "nowrap",
                                  textOverflow: "ellipsis",
                                }}
                              >
                                {item?.check_in_time
                                  ? moment(
                                      item?.check_in_time,
                                      "HH:mm:ss"
                                    ).format("h:mm a")
                                  : "N/A"}
                              </Mui.Typography>
                            </Mui.TableCell>
                            <Mui.TableCell
                              sx={{
                                overflow: "hidden",
                                whiteSpace: "nowrap",
                                maxWidth: 100,
                              }}
                            >
                              <Mui.Typography
                                sx={{
                                  overflow: "hidden",
                                  whiteSpace: "nowrap",
                                  textOverflow: "ellipsis",
                                }}
                              >
                                {item?.check_out_time
                                  ? moment(
                                      item?.check_out_time,
                                      "HH:mm:ss"
                                    ).format("h:mm a")
                                  : "N/A"}
                              </Mui.Typography>
                            </Mui.TableCell>
                            <Mui.TableCell
                              sx={{
                                overflow: "hidden",
                                whiteSpace: "nowrap",
                                maxWidth: 100,
                              }}
                            >
                              <Mui.Typography
                                sx={{
                                  overflow: "hidden",
                                  whiteSpace: "nowrap",
                                  textOverflow: "ellipsis",
                                }}
                              >
                                {item?.total_break_time
                                  ? item?.total_break_time
                                  : "00:00"}
                              </Mui.Typography>
                            </Mui.TableCell>
                            <Mui.TableCell
                              sx={{
                                overflow: "hidden",
                                whiteSpace: "nowrap",
                                maxWidth: 100,
                              }}
                            >
                              <Mui.Typography
                                sx={{
                                  overflow: "hidden",
                                  whiteSpace: "nowrap",
                                  textOverflow: "ellipsis",
                                }}
                              >
                                {item?.total_work_time
                                  ? item?.total_work_time
                                  : "00:00"}
                              </Mui.Typography>
                            </Mui.TableCell>
                            <Mui.TableCell
                              sx={{
                                overflow: "hidden",
                                whiteSpace: "nowrap",
                                maxWidth: 100,
                              }}
                            >
                              <Mui.Typography
                                sx={{
                                  overflow: "hidden",
                                  whiteSpace: "nowrap",
                                  textOverflow: "ellipsis",
                                }}
                              >
                                {item?.total_over_time
                                  ? item?.total_over_time
                                  : "00:00"}
                              </Mui.Typography>
                            </Mui.TableCell>
                            <Mui.TableCell
                              sx={{
                                overflow: "hidden",
                                whiteSpace: "nowrap",
                                maxWidth: 100,
                              }}
                            >
                              <Mui.Typography
                                sx={{
                                  overflow: "hidden",
                                  whiteSpace: "nowrap",
                                  textOverflow: "ellipsis",
                                }}
                              >
                                {item?.late_coming_minutes
                                  ? item?.late_coming_minutes
                                  : "00:00"}
                              </Mui.Typography>
                            </Mui.TableCell>
                            <Mui.TableCell
                              sx={{
                                overflow: "hidden",
                                whiteSpace: "nowrap",
                                maxWidth: 100,
                              }}
                            >
                              <Mui.Typography
                                sx={{
                                  overflow: "hidden",
                                  whiteSpace: "nowrap",
                                  textOverflow: "ellipsis",
                                }}
                              >
                                {item?.early_leaving_minutes
                                  ? item?.early_leaving_minutes
                                  : "00:00"}
                              </Mui.Typography>
                            </Mui.TableCell>
                            <Mui.TableCell
                              sx={{
                                overflow: "hidden",
                                whiteSpace: "nowrap",
                                maxWidth: 100,
                              }}
                            >
                              <Mui.Typography
                                sx={{
                                  overflow: "hidden",
                                  whiteSpace: "nowrap",
                                  textOverflow: "ellipsis",
                                }}
                              >
                                {item?.type ? item?.type : "N/A"}
                              </Mui.Typography>
                            </Mui.TableCell>
                          </Mui.TableRow>
                        </>
                      ))}
                  </Mui.TableBody>
                </Mui.Table>
                {dailyRepDetails?.length > 25 ? (
                  <Mui.TablePagination
                    className="custom-pagination"
                    component="div"
                    rowsPerPageOptions={[25, 50, 75, 100]}
                    count={dailyRepDetails?.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                ) : null}
              </Mui.TableContainer>
            </Mui.Grid>
          ) : (
            <Mui.Stack sx={{ padding: 0.5 }} spacing={2} fullWidth>
              <Mui.Alert severity="info">
                There is a no available data
              </Mui.Alert>
            </Mui.Stack>
          )}
        </>
      )}
    </React.Fragment>
  );
};

export default DailyReport;
