import React from "react";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
} from "@react-pdf/renderer";
import ProductImage from "assets/img/brand/paysliplogo.png";
import moment from "moment";

const styles = StyleSheet.create({
  page: {
    size: "A4 landscape",
    padding: 20,
    color: "#333",
    pageBreakAfter: "always",
    boxSizing: "border-box",
  },
  section: {
    display: "flex",
    width: "49.5%",
    border: "1px solid #ddd",
    padding: 15,
    paddingTop: 10,
    borderRadius: 5,
    boxShadow: "0 2px 5px rgba(0,0,0,0.1)",
  },
  item: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    margin: 5,
    marginLeft: 0,
    fontSize: 12,
  },
});

const PayslipData = ({ payslipData }) => {
  const totalEarnings = payslipData.earnings.reduce(
    (acc, item) => acc + item.amount,
    0
  );
  const totalDeductions = payslipData.deductions.reduce(
    (acc, item) => acc + item.amount,
    0
  );
  const totalAddEarnings = payslipData.additional_earnings.reduce(
    (acc, item) => acc + item.amount,
    0
  );
  const totalAddDeductions = payslipData.additional_deductions.reduce(
    (acc, item) => acc + item.amount,
    0
  );

  const renderSection = (title, data, total) => (
    <View style={styles.section}>
      <Text style={{ fontSize: 14, fontWeight: "heavy" }}>{title}</Text>
      {data?.map((item) => (
        <View style={styles.item} key={item.name}>
          <Text style={{ fontSize: 12 }}>{item.name}</Text>
          <Text style={{ fontSize: 12 }}>Rs. {item.amount}</Text>
        </View>
      ))}
      <View style={styles.item}>
        <Text style={{ fontSize: 12, fontWeight: "heavy" }}>Total {title}</Text>
        <Text style={{ fontSize: 12, fontWeight: 900 }}>Rs. {total}</Text>
      </View>
    </View>
  );

  const renderBreakdownSection = (title, data) => (
    <View
      style={{
        display: "flex",
        width: "100%",
        border: "1px solid #ddd",
        padding: 15,
        paddingTop: 10,
        borderRadius: 5,
        boxShadow: "0 2px 5px rgba(0,0,0,0.1)",
        marginTop: 10,
      }}
    >
      <View
        style={{
          display: "flex",
          flexDirection: "row",
          fontStyle: "bold",
          justifyContent: "space-between",
          borderBottom: "1px solid #ddd",
          paddingBottom: 5,
        }}
      >
        <Text style={{ flex: 1, fontSize: 12, fontStyle: "bold" }}>
          {title} Shift
        </Text>
        <Text style={{ flex: 1, fontSize: 12, fontStyle: "bold" }}>Shift</Text>
        <Text style={{ flex: 1, fontSize: 12, fontStyle: "bold" }}>Factor</Text>
        <Text style={{ flex: 1, fontSize: 12, fontStyle: "bold" }}>
          Adj. Shift
        </Text>
        <Text style={{ flex: 1, fontSize: 12, fontStyle: "bold" }}>
          Adj. Hrs
        </Text>
      </View>
      {data?.map((item) => (
        <View
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            borderBottom: "1px solid #f5f5f5",
            padding: 5,
          }}
          key={item.name}
        >
          <Text style={{ flex: 1, fontSize: 10, fontStyle: "bold" }}>
            {item.name}
          </Text>
          <Text style={{ flex: 1, fontSize: 10, fontStyle: "bold" }}>
            {item.shifts}
          </Text>
          <Text style={{ flex: 1, fontSize: 10, fontStyle: "bold" }}>
            {item.factor}
          </Text>
          <Text style={{ flex: 1, fontSize: 10, fontStyle: "bold" }}>
            {item.adjusted_shifts}
          </Text>
          <Text style={{ flex: 1, fontSize: 10, fontStyle: "bold" }}>
            {item.adjusted_hours}
          </Text>
        </View>
      ))}
    </View>
  );

  return (
    <Document>
      <Page size="A4" style={styles.page} orientation="landscape">
        <View
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
          }}
        >
          <Text style={{ fontSize: 14 }}>{payslipData.company_address}</Text>
          <Image
            source={{ uri: ProductImage }}
            style={{ width: 200, height: 60 }}
          />
        </View>

        <View style={{ marginTop: 10, marginBottom: 5 }}>
          <Text style={{ fontSize: 16, fontWeight: "heavy" }}>
            Payslip for the month of{" "}
            {moment(payslipData.pay_date, "DD-MM-YYYY").format("MMMM, YYYY")}
          </Text>
        </View>

        <Text
          style={{
            marginTop: 10,
            marginBottom: 5,
            fontSize: 14,
            fontStyle: "bold",
          }}
        >
          EMPLOYEE PAY SUMMARY
        </Text>

        <View>
          <View
            style={{ display: "flex", flexDirection: "row", borderRadius: 5 }}
          >
            <View
              style={{
                width: "25%",
                backgroundColor: "#f0f0f0",
                padding: 4,
              }}
            >
              <Text style={{ fontSize: 12, padding: 2, textAlign: "left" }}>
                Employee Name: {payslipData.user_name}
              </Text>
            </View>
            <View
              style={{
                width: "25%",
                backgroundColor: "#f0f0f0",
                padding: 4,
              }}
            >
              <Text style={{ fontSize: 12, padding: 2, textAlign: "left" }}>
                Designation: {payslipData.designation}
              </Text>
            </View>
            <View
              style={{
                width: "25%",
                backgroundColor: "#f0f0f0",
                padding: 4,
              }}
            >
              <Text style={{ fontSize: 12, padding: 2, textAlign: "left" }}>
                Pay Period:{" "}
                {moment(payslipData.pay_date, "DD-MM-YYYY").format("MMMM-YYYY")}
              </Text>
            </View>
            <View
              style={{
                width: "25%",
                backgroundColor: "#f0f0f0",
                padding: 4,
              }}
            >
              <Text style={{ fontSize: 12, padding: 2, textAlign: "left" }}>
                Pay Date: {payslipData.pay_date}
              </Text>
            </View>
          </View>
        </View>

        <View>
          <View
            style={{ display: "flex", flexDirection: "row", borderRadius: 5 }}
          >
            <View
              style={{
                width: "25%",
                padding: 4,
              }}
            >
              <Text style={{ fontSize: 12, padding: 2, textAlign: "left" }}>
                Total Shift Payable: {payslipData.total_shift || 0}
              </Text>
            </View>
            <View style={{ width: "25%", padding: 4 }}>
              <Text style={{ fontSize: 12, padding: 2, textAlign: "left" }}>
                Additional Credit/LOP Days:{" "}
                {payslipData.additional_credit_lop_days || 0}
              </Text>
            </View>
            <View style={{ width: "25%", padding: 4 }}>
              <Text style={{ fontSize: 12, padding: 2, textAlign: "left" }}>
                Net Payable Shift: {payslipData.net_payable_shifts || 0}
              </Text>
            </View>
            <View style={{ width: "25%", padding: 4 }}>
              <Text style={{ fontSize: 12, padding: 2, textAlign: "left" }}>
                Total Over Time: {payslipData.total_over_time || "N/A"}
              </Text>
            </View>
          </View>
        </View>

        <View
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            marginTop: 10,
          }}
        >
          {renderSection("Earnings", payslipData.earnings, totalEarnings)}
          {renderSection("Deductions", payslipData.deductions, totalDeductions)}
        </View>

        <View
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            marginTop: 10,
          }}
        >
          {renderSection(
            "Additional Earnings",
            payslipData.additional_earnings,
            totalAddEarnings
          )}
          {renderSection(
            "Additional Deductions",
            payslipData.additional_deductions,
            totalAddDeductions
          )}
        </View>

        <View
          style={{
            marginTop: 20,
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            padding: 10,

            backgroundColor: "#84deb8",
          }}
        >
          <Text style={{ fontSize: 14, fontStyle: "bold" }}>NET PAY</Text>
          <Text style={{ fontSize: 14, fontWeight: "bold" }}>
            Rs. {payslipData.net_pay}
          </Text>
        </View>

        <Text
          style={{
            marginTop: 20,
            fontSize: 12,
          }}
        >
          Total Net Payable: Rs. {payslipData.net_pay} (Rupees{" "}
          {payslipData.net_pay_in_words})
        </Text>

        <Text style={{ textAlign: "center", marginTop: 20, fontSize: 12 }}>
          - This is a system generated payslip -
        </Text>
      </Page>

      <Page size="A4" style={styles.page} orientation="landscape">
        <View
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
          }}
        >
          <Text style={{ fontSize: 14 }}>{payslipData.company_address}</Text>
          <Image
            source={{ uri: ProductImage }}
            style={{ width: 200, height: 60 }}
          />
        </View>

        <View style={{ marginTop: 10, marginBottom: 5 }}>
          <Text style={{ fontSize: 16, fontWeight: "heavy" }}>
            Pay Calculation for the month of{" "}
            {moment(payslipData.pay_date, "DD-MM-YYYY").format("MMMM, YYYY")}
          </Text>
        </View>

        <View>
          <View
            style={{ display: "flex", flexDirection: "row", borderRadius: 5 }}
          >
            <View
              style={{
                width: "25%",
                backgroundColor: "#f0f0f0",
                padding: 4,
              }}
            >
              <Text style={{ fontSize: 12, padding: 2, textAlign: "left" }}>
                Employee Name: {payslipData.user_name}
              </Text>
            </View>
            <View
              style={{
                width: "25%",
                backgroundColor: "#f0f0f0",
                padding: 4,
              }}
            >
              <Text style={{ fontSize: 12, padding: 2, textAlign: "left" }}>
                Designation: {payslipData.designation}
              </Text>
            </View>
            <View
              style={{
                width: "25%",
                backgroundColor: "#f0f0f0",
                padding: 4,
              }}
            >
              <Text style={{ fontSize: 12, padding: 2, textAlign: "left" }}>
                Pay Period:{" "}
                {moment(payslipData.pay_date, "DD-MM-YYYY").format("MMMM-YYYY")}
              </Text>
            </View>
            <View
              style={{
                width: "25%",
                backgroundColor: "#f0f0f0",
                padding: 4,
              }}
            >
              <Text style={{ fontSize: 12, padding: 2, textAlign: "left" }}>
                Pay Date: {payslipData.pay_date}
              </Text>
            </View>
          </View>
        </View>

        <View>
          <View style={{ display: "flex", flexDirection: "row" }}>
            <View style={{ width: "50%", padding: 4 }}>
              <Text style={{ fontSize: 12, padding: 2, textAlign: "left" }}>
                Total days in this period :{" "}
                {payslipData?.no_of_shifts_in_the_period
                  ? payslipData?.no_of_shifts_in_the_period
                  : 0}{" "}
                days
              </Text>
            </View>
            <View style={{ width: "50%", padding: 4 }}>
              <Text style={{ fontSize: 12, padding: 2, textAlign: "left" }}>
                No. of shifts assigned :{" "}
                {payslipData?.no_of_attended_shifts
                  ? payslipData?.no_of_attended_shifts
                  : 0}{" "}
                days
              </Text>
            </View>
          </View>
        </View>

        {renderBreakdownSection(
          "Earning",
          payslipData?.detailed_breakdown?.earning
        )}
        {renderBreakdownSection(
          "Additional Earning",
          payslipData?.detailed_breakdown?.additional_earning
        )}
        {renderBreakdownSection(
          "Deduction",
          payslipData?.detailed_breakdown?.deduction
        )}
        {renderBreakdownSection(
          "Penalty",
          payslipData?.detailed_breakdown?.penalty
        )}
        {renderBreakdownSection(
          "Overtime",
          payslipData?.detailed_breakdown?.overtime
        )}

        <View>
          <View style={{ display: "flex", flexDirection: "row" }}>
            <View style={{ width: "50%", padding: 4 }}>
              <Text style={{ fontSize: 12, padding: 2, textAlign: "left" }}>
                Total Payable Hours :{" "}
                {payslipData?.detailed_breakdown?.total_no_of_payable_hours
                  ? payslipData?.detailed_breakdown?.total_no_of_payable_hours
                  : 0}{" "}
                hours
              </Text>
            </View>
            <View style={{ width: "50%", padding: 4 }}>
              <Text style={{ fontSize: 12, padding: 2, textAlign: "left" }}>
                Total Payable Shifts :{" "}
                {payslipData?.detailed_breakdown?.total_no_of_payable_shifts
                  ? payslipData?.detailed_breakdown?.total_no_of_payable_shifts
                  : 0}{" "}
                days
              </Text>
            </View>
          </View>
        </View>

        <Text style={{ textAlign: "center", marginTop: 20, fontSize: 12 }}>
          - This is a system generated payslip -
        </Text>
      </Page>
    </Document>
  );
};

export default PayslipData;
