import React, { useState, useEffect } from "react";
import * as Mui from "@mui/material";
import { useFetch } from "services/hooks/useFetch";
import { useSelector } from "react-redux";
import { themes } from "services/constants";
// import { useDispatch } from "react-redux";
import { BackArrow } from "components/CustomComponents/BackArrow";
import { CustomButton } from "components/CustomComponents/CustomButton";
// import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { Loader } from "components/Loader";
// import { GlobalDialogBox } from "components/GlobalDialogBox";
// import { showToast } from "services/utils/Status";
// import sessionHandling from "services/utils/notificationUtils";
import { IndividualMemberHeader } from "pages/Administration/AdministrationHeaders";
import { AddEmployee } from "./AddEmployee";
import { MoveEmployee } from "./MoveEmployee";

// const keys = [
//   "amount",
//   "criteria_type",
//   "criteria_value",
//   "cycle",
//   "effective_from",
//   "incentive_mode",
//   "incentive_type",
//   "previous_effective_from",
//   "select_type",
// ];

export const ViewGroupMembers = ({
  viewAssignDetails,
  setRenderComponentName,
  setShowTopBar,
}) => {
  const { domain } = useSelector((state) => state.tokenReducer);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  // const [openDialog, setOpenDialog] = useState(false);
  // const [deleteIncentiveDetails, setDeleteIncentiveDetails] = useState({});
  const [incentiveDetailsStateData, setIncentiveStateData] = useState([]);
  const [updateData, setUpdateData] = useState(true);
  // const [buttonDisabled, setButtonDisabled] = useState(false);
  const [addEmployee, setAddEmployee] = useState(false);
  const [moveEmployee, setMoveEmployee] = useState(false);
  // const history = useHistory();
  // const dispatch = useDispatch();
  const {
    result: IncentiveData,
    setLoad: reCallApi,
    loading,
  } = useFetch(
    `${domain}assignment_details/${viewAssignDetails.id}/employee_details/`
  );

  console.log(IncentiveData);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    if (updateData && IncentiveData?.length !== 0 && IncentiveData) {
      setIncentiveStateData(IncentiveData);
      setUpdateData(false);
    }
  }, [IncentiveData, updateData]);

  // useEffect(() => {
  //   if (IncentiveData?.length !== 0 || IncentiveData?.length === 0) {
  //     const filteredResults = IncentiveData?.filter((item) =>
  //       keys.some((key) =>
  //         item[key]
  //           ?.toString()
  //           .toLowerCase()
  //           .includes(globalSearchValue.toString().toLowerCase())
  //       )
  //     );
  //     setIncentiveStateData(filteredResults);
  //     setPage(0);
  //   }
  // }, [globalSearchValue, IncentiveData]);

  return (
    <React.Fragment>
      {addEmployee ? (
        <AddEmployee
          // companyId={companyId
          viewAssignDetails={viewAssignDetails}
          setAddEmployee={setAddEmployee}
          reCallApi={reCallApi}
          IncentiveData={IncentiveData}
        />
      ) : loading ? (
        <Loader />
      ) : moveEmployee ? (
        <MoveEmployee
          // companyId={companyId}
          setMoveEmployee={setMoveEmployee}
          viewAssignDetails={viewAssignDetails}
          IncentiveData={IncentiveData}
          reCallApi={reCallApi}
        />
      ) : incentiveDetailsStateData?.length === 0 ? (
        <>
          <Mui.Grid container sx={{ paddingTop: 2 }}>
            <Mui.Grid item xs={0.4}>
              <BackArrow
                actionFunction={() => {
                  setRenderComponentName("ListView");
                  setShowTopBar(true);
                }}
              />
            </Mui.Grid>
            <Mui.Grid item xs={3}>
              <Mui.Typography
                sx={{ fontSize: 24, fontWeight: 700, paddingTop: 1 }}
              >
                {viewAssignDetails?.name}
              </Mui.Typography>
            </Mui.Grid>
            <Mui.Grid xs={6.5}></Mui.Grid>
            <Mui.Grid item xs={1} sx={{ paddingTop: 1 }}>
              <CustomButton
                actionFuntion={() => setAddEmployee(true)}
                actionName="Add Employee"
                typeName="button"
              />
            </Mui.Grid>
          </Mui.Grid>
          <Mui.Alert severity="info" sx={{ width: "100%", margin: 2 }}>
            No data found
          </Mui.Alert>
        </>
      ) : (
        <React.Fragment>
          <Mui.Grid container sx={{ paddingTop: 2 }}>
            <Mui.Grid item xs={0.4}>
              <BackArrow
                actionFunction={() => {
                  setRenderComponentName("ListView");
                  setShowTopBar(true);
                }}
              />
            </Mui.Grid>
            <Mui.Grid item xs={3}>
              <Mui.Typography
                sx={{ fontSize: 24, fontWeight: 700, paddingTop: 1 }}
              >
                {viewAssignDetails?.name}
              </Mui.Typography>
            </Mui.Grid>
            <Mui.Grid xs={6.5}></Mui.Grid>
            <Mui.Grid item xs={1} sx={{ paddingTop: 1 }}>
              <CustomButton
                actionFuntion={() => setAddEmployee(true)}
                actionName="Add Employee"
                typeName="button"
              />
            </Mui.Grid>
            <Mui.Grid item xs={1.1} sx={{ paddingTop: 1 }}>
              <CustomButton
                actionFuntion={() => setMoveEmployee(true)}
                actionName="Move Employee"
                typeName="button"
              />
            </Mui.Grid>
          </Mui.Grid>
          <Mui.TableContainer
            sx={{ mt: 2, width: "100%", paddingLeft: 1, maxHeight: 850 }}
          >
            <Mui.Table>
              <Mui.TableHead
                sx={{
                  backgroundColor: themes.primary,
                  position: "sticky",
                  top: -1,
                  zIndex: 10,
                }}
              >
                <Mui.TableRow
                  sx={{
                    "& .MuiTableCell-root": {
                      height: "10px",
                      padding: "6px 30px",
                    },
                  }}
                >
                  {IndividualMemberHeader.map((head) => (
                    <Mui.TableCell
                      sx={{ color: themes.blackColor, fontWeight: "bold" }}
                    >
                      {head.name}
                    </Mui.TableCell>
                  ))}
                </Mui.TableRow>
              </Mui.TableHead>
              <Mui.TableBody>
                {incentiveDetailsStateData
                  ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  ?.map((account, index) => (
                    <React.Fragment>
                      <Mui.TableRow
                        sx={{
                          "& .MuiTableCell-root": {
                            height: "10px",
                            padding: "6px 35px",
                          },
                        }}
                      >
                        {IndividualMemberHeader.map((head) => (
                          <Mui.TableCell>
                            {account[head.key] ? account[head.key] : "N/A"}
                          </Mui.TableCell>
                        ))}
                      </Mui.TableRow>
                    </React.Fragment>
                  ))}
              </Mui.TableBody>
            </Mui.Table>
          </Mui.TableContainer>
          <Mui.Grid
            container
            sx={{
              paddingTop: 1,
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              alignItems: "flex-end",
              paddingRight: 1,
            }}
          >
            {incentiveDetailsStateData?.length > 25 && (
              <Mui.TablePagination
                className="custom-pagination"
                component="div"
                rowsPerPageOptions={[25, 50, 75, 100]}
                count={incentiveDetailsStateData?.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            )}
          </Mui.Grid>
        </React.Fragment>
      )}
    </React.Fragment>
  );
};
