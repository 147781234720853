import React, { useState, useEffect } from "react";
import { CustomButton } from "components/CustomComponents/CustomButton";
import * as Mui from "@mui/material";
import { useFetch } from "services/hooks/useFetch";
import { setGlobalSearchValue } from "services/Redux/userToken";
import { customGetAPI } from "services/utils/CustomAPI";
import { useDispatch, useSelector } from "react-redux";
import { PayingUnit } from "./PayScreen/PayingUnit";
import { GroupUnit } from "./GroupScreen/GroupUnit";
import { AssignUnit } from "./AssignScreen/AssignUnit";
import { CustomAutoComplete } from "components/CustomComponents/CustomAutoComplete";
import { themes, defaultAllSelection } from "services/constants";
import {
  setDepartmentId,
  setDepartmentName,
} from "services/Redux/userManagament";

export const WorkUnit = () => {
  const { departmentId, departmentName } = useSelector(
    (state) => state.userReducer
  );
  const [groupUnit, setGroupUnit] = useState(false);
  const [showTopBar, setShowTopBar] = useState(true);
  const [workUnit, setWorkUnit] = useState("Pay");
  const [selectComponent, setSelectComponent] = useState("Pay");
  const { domain, token } = useSelector((state) => state.tokenReducer);
  const [payUnit, setPayUnit] = useState(false);
  const [editWorkUnitPay, setEditWorkUnitPay] = useState(false);
  const [editAssignUnit, setEditAssignUnit] = useState(false);
  const [departmentData, setDepartmentData] = useState([]);
  const [assignGroupUnit, setAssignGroupUnit] = useState(false);
  const [refreshCall, setRefreshCall] = useState(false);
  const {
    result: workUnitGroup,
    setLoad: reCallApi,
    loading,
  } = useFetch(`${domain}work_unit_groups/?department=${departmentId}`);
  const dispatch = useDispatch();
  const handleChange = (event, newAlignment) => {
    setWorkUnit(newAlignment);
    dispatch(setGlobalSearchValue(""));
  };

  useEffect(() => {
    dispatch(setDepartmentName("All"));
    dispatch(setDepartmentId(99999));
  }, [dispatch]);

  useEffect(() => {
    const fetchDepartmentData = async () => {
      const res = await customGetAPI(`${domain}work-unint-based-department/`);
      if (res.ok) {
        const allDepartment = {
          id: defaultAllSelection,
          department: "All",
        };
        setDepartmentData([allDepartment, ...res?.responseDetails]);
      }
    };
    fetchDepartmentData();
  }, [domain, token]);

  const renderComponent = () => {
    switch (selectComponent) {
      case "Pay":
        return (
          <PayingUnit
            payUnit={payUnit}
            setPayUnit={setPayUnit}
            editWorkUnitPay={editWorkUnitPay}
            setEditWorkUnitPay={setEditWorkUnitPay}
            setShowTopBar={setShowTopBar}
            departmentId={departmentId}
            refreshCall={refreshCall}
            selectComponent={selectComponent}
            setRefreshCall={setRefreshCall}
          />
        );
      case "Group":
        return (
          <GroupUnit
            groupUnit={groupUnit}
            departmentId={departmentId}
            workUnitGroup={workUnitGroup}
            reCallApi={reCallApi}
            loading={loading}
            setShowTopBar={setShowTopBar}
            setGroupUnit={setGroupUnit}
          />
        );
      case "Assign":
        return (
          <AssignUnit
            assignGroupUnit={assignGroupUnit}
            setAssignGroupUnit={setAssignGroupUnit}
            editAssignUnit={editAssignUnit}
            setEditAssignUnit={setEditAssignUnit}
            setShowTopBar={setShowTopBar}
            departmentId={departmentId}
            refreshCall={refreshCall}
            selectComponent={selectComponent}
            setRefreshCall={setRefreshCall}
          />
        );
      default:
        break;
    }
  };

  const updateDepartmentName = (event, data) => {
    if (data) {
      dispatch(setDepartmentName(data.label));
      dispatch(setDepartmentId(data.id));
      localStorage.setItem("approveLeaveDepartmentId", data.id);
      localStorage.setItem("approveLeaveDepartmentName", data.label);
      if (selectComponent === "Pay") {
        setRefreshCall(true);
      } else if (selectComponent === "Group") {
        reCallApi(true);
      } else {
        setRefreshCall(true);
      }
    }
  };

  return (
    <React.Fragment>
      <Mui.Grid container sx={{ paddingTop: 2, paddingLeft: 1 }}>
        {showTopBar ? (
          <>
            <Mui.Grid item xs={5}>
              <Mui.Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  width: 400,
                  borderRadius: 3,
                  height: 55,
                  position: "relative",
                  overflow: "hidden",
                }}
              >
                <Mui.ToggleButtonGroup
                  value={workUnit}
                  exclusive
                  onChange={handleChange}
                  sx={{
                    display: "flex",
                    width: "100%",
                    height: "100%",
                    position: "absolute",
                    borderRadius: 3,
                    backgroundColor: themes.lightBrown,
                  }}
                >
                  <Mui.ToggleButton
                    value="Pay"
                    sx={{
                      flex: 1,
                      textTransform: "capitalize",
                      fontSize: 18,

                      fontWeight: 600,
                      border: "none",
                      color:
                        workUnit === "Pay"
                          ? themes.whiteColor
                          : themes.blackColor,
                      backgroundColor:
                        workUnit === "Pay"
                          ? themes.blackColor
                          : themes.lightBrown,
                      "&.Mui-selected": {
                        backgroundColor: themes.blackColor,
                        color: themes.whiteColor,
                        "&:hover": {
                          backgroundColor: themes.blackColor,
                          color: themes.whiteColor,
                        },
                      },
                      "&:hover": {
                        backgroundColor:
                          workUnit === "Pay"
                            ? themes.blackColor
                            : themes.lightBrown,
                        color:
                          workUnit === "Pay"
                            ? themes.whiteColor
                            : themes.blackColor,
                      },
                    }}
                    onClick={() => setSelectComponent("Pay")}
                  >
                    Pay
                  </Mui.ToggleButton>
                  <Mui.ToggleButton
                    value="Group"
                    sx={{
                      flex: 1,
                      textTransform: "capitalize",
                      fontSize: 18,
                      fontWeight: 600,
                      borderRadius: 3,
                      border: "none",
                      color:
                        workUnit === "Group"
                          ? themes.whiteColor
                          : themes.blackColor,
                      backgroundColor:
                        workUnit === "Group"
                          ? themes.blackColor
                          : themes.lightBrown,
                      "&.Mui-selected": {
                        backgroundColor: themes.blackColor,
                        color: themes.whiteColor,
                        "&:hover": {
                          backgroundColor: themes.blackColor,
                          color: themes.whiteColor,
                        },
                      },
                      "&:hover": {
                        backgroundColor:
                          workUnit === "Group"
                            ? themes.blackColor
                            : themes.lightBrown,
                        color:
                          workUnit === "Group"
                            ? themes.whiteColor
                            : themes.blackColor,
                      },
                    }}
                    onClick={() => setSelectComponent("Group")}
                  >
                    Group
                  </Mui.ToggleButton>
                  <Mui.ToggleButton
                    value="Assign"
                    sx={{
                      flex: 1,
                      textTransform: "capitalize",
                      fontSize: 18,
                      fontWeight: 600,
                      borderRadius: 3,
                      border: "none",
                      color:
                        workUnit === "Assign"
                          ? themes.whiteColor
                          : themes.blackColor,
                      backgroundColor:
                        workUnit === "Assign"
                          ? themes.blackColor
                          : themes.lightBrown,
                      "&.Mui-selected": {
                        backgroundColor: themes.blackColor,
                        color: themes.whiteColor,
                        "&:hover": {
                          backgroundColor: themes.blackColor,
                          color: themes.whiteColor,
                        },
                      },
                      "&:hover": {
                        backgroundColor:
                          workUnit === "Assign"
                            ? themes.blackColor
                            : themes.lightBrown,
                        color:
                          workUnit === "Assign"
                            ? themes.whiteColor
                            : themes.blackColor,
                      },
                      "&:focus": {
                        backgroundColor:
                          workUnit === "Assign"
                            ? themes.blackColor
                            : themes.lightBrown,
                        color:
                          workUnit === "Assign"
                            ? themes.whiteColor
                            : themes.blackColor,
                      },
                    }}
                    onClick={() => setSelectComponent("Assign")}
                  >
                    Assign
                  </Mui.ToggleButton>
                </Mui.ToggleButtonGroup>
              </Mui.Box>
            </Mui.Grid>
            <Mui.Grid xs={7}>
              <Mui.Grid container sx={{ paddingTop: 1 }}>
                <Mui.Grid xs={6}></Mui.Grid>
                <Mui.Grid
                  xs={6}
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-end",
                    alignItems: "flex-end",
                    paddingRight: 0.5,
                  }}
                >
                  <Mui.Grid container>
                    <Mui.Grid xs={6} sx={{ paddingRight: 1 }}>
                      <CustomAutoComplete
                        dataList={departmentData?.map((obj) => ({
                          ...obj,
                          label: obj.department,
                        }))}
                        selectedValue={departmentName}
                        updateDataFunction={updateDepartmentName}
                        placeHolder="Select department name"
                        label="Select Department Name"
                      />
                    </Mui.Grid>
                    <Mui.Grid xs={6}>
                      {selectComponent === "Pay" ? (
                        <CustomButton
                          actionFuntion={() => {
                            setPayUnit(true);
                            setShowTopBar(false);
                          }}
                          actionName="Add WorkUnit Pay"
                          typeName="button"
                        />
                      ) : selectComponent === "Group" ? (
                        <CustomButton
                          actionFuntion={() => {
                            setShowTopBar(false);
                            setGroupUnit(true);
                          }}
                          actionName="Add WorkUnit Group"
                          typeName="button"
                        />
                      ) : (
                        <CustomButton
                          actionFuntion={() => {
                            setShowTopBar(false);
                            setAssignGroupUnit(true);
                          }}
                          actionName="Assign WorkUnit Group"
                          typeName="button"
                        />
                      )}
                    </Mui.Grid>
                  </Mui.Grid>
                </Mui.Grid>
              </Mui.Grid>
            </Mui.Grid>
          </>
        ) : null}
      </Mui.Grid>
      {renderComponent()}
    </React.Fragment>
  );
};
