import React, { useState, useEffect } from "react";
import * as Mui from "@mui/material";
import moment from "moment";
import { DateRangePicker } from "./DateRangePicker";
import { themes } from "services/constants";
import { useSelector } from "react-redux";
import { showToast } from "services/utils/Status";
import sessionHandling from "services/utils/notificationUtils";
import { Loader } from "components/Loader";
import { tableRowSx } from "services/constants";
const overTimeKeyPair = [
  {
    key: "user_name",
    name: "Employee name",
  },
  {
    key: "date",
    name: "Date",
  },
  {
    key: "shift",
    name: "Shift Name",
  },
  {
    key: "check_in_time",
    name: "Check-in",
  },
  {
    key: "check_out_time",
    name: "Check-out",
  },
];

export const Approve = () => {
  const { domain, token, userDetails, globalSearchValue } = useSelector(
    (state) => state.tokenReducer
  );
  const [overTimeDetails, setOverTimeDetails] = useState([1]);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [listViewPage, setListViewPage] = useState(0);
  const [loader, setLoader] = useState(true);
  const [approveOt, setApproveOt] = useState([]);
  const [selectedDateRange, setSelectedDateRange] = useState("");
  const [showDetails, setShowDetails] = useState(true);

  const handleChangePage = (event, newPage) => {
    setListViewPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setListViewPage(0);
  };

  useEffect(() => {
    const getWorkingHours = async () => {
      const currentDate = new Date();
      const currentDay = currentDate.getDay();
      const diffToMonday = currentDay === 0 ? -6 : 1 - currentDay;
      const mostRecentMonday = new Date(
        currentDate.setDate(currentDate.getDate() + diffToMonday)
      );
      const currentDateDetails = new Date();
      const diffToSunday = currentDay === 0 ? 0 : 7 - currentDay;
      const currentWeekSunday = new Date(
        currentDateDetails.setDate(currentDateDetails.getDate() + diffToSunday)
      );
      let searchStartDate = moment(new Date(mostRecentMonday))
        .format("DD/MM/YYYY")
        .split("/")
        .reverse()
        .join("-");
      let searchEndDate = moment(new Date(currentWeekSunday))
        .format("DD/MM/YYYY")
        .split("/")
        .reverse()
        .join("-");
      setLoader(true);
      try {
        const response = await fetch(
          `${domain}week-off-approvals/?selected_user_id=${userDetails.id}&start_date=${searchStartDate}&end_date=${searchEndDate}&status=Pending`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `token ${token}`,
            },
          }
        );
        const res = await response.json();
        if (response.ok) {
          const combinedData = res.map((item) => ({
            ...item,
            overtime_period: `${item.check_in_time || "N/A"} to ${
              item.check_out_time || "N/A"
            }`,
          }));
          setOverTimeDetails(combinedData);
          setApproveOt(combinedData);
        } else if (response.status === 409) {
          sessionHandling();
        } else {
          throw new Error(res.error);
        }
        setLoader(false);
        setShowDetails(false);
      } catch (error) {
        showToast("error", error.message);
      }
    };
    if (showDetails) {
      getWorkingHours();
    }
  }, [domain, setShowDetails, showDetails, token, userDetails.id]);

  useEffect(() => {
    const currentDate = new Date();
    const currentDay = currentDate.getDay();
    const diffToMonday = currentDay === 0 ? -6 : 1 - currentDay;
    const mostRecentMonday = new Date(
      currentDate.setDate(currentDate.getDate() + diffToMonday)
    );
    const currentDateDetails = new Date();
    const diffToSunday = currentDay === 0 ? 0 : 7 - currentDay;
    const currentWeekSunday = new Date(
      currentDateDetails.setDate(currentDateDetails.getDate() + diffToSunday)
    );
    setSelectedDateRange(
      `${moment(new Date(mostRecentMonday.toISOString().split("T")[0])).format(
        "DD/MM/YYYY"
      )} - ${moment(
        new Date(currentWeekSunday.toISOString().split("T")[0])
      ).format("DD/MM/YYYY")}`
    );
  }, []);

  const handleStartDate = async (startDate, endDate) => {
    setSelectedDateRange(`${startDate} - ${endDate}`);
    let searchStartDate = startDate.split("/").reverse().join("-");
    let searchEndDate = endDate.split("/").reverse().join("-");
    setLoader(true);
    try {
      const response = await fetch(
        `${domain}week-off-approvals/?selected_user_id=${userDetails.id}&start_date=${searchStartDate}&end_date=${searchEndDate}&status=Pending`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `token ${token}`,
          },
        }
      );
      const res = await response.json();
      if (response.ok) {
        const combinedData = res.map((item) => ({
          ...item,
          overtime_period: `${item.check_in_time || "N/A"} to ${
            item.check_out_time || "N/A"
          }`,
        }));
        setOverTimeDetails(combinedData);
        setApproveOt(combinedData);
      } else if (response.status === 409) {
        sessionHandling();
      } else {
        throw new Error(res.error);
      }
      setLoader(false);
    } catch (error) {
      showToast("error", error.message);
    }
  };

  useEffect(() => {
    const searchFields = [
      "user_name",
      "date",
      "shift",
      "check_in_time",
      "check_out_time",
      "approvedby",
      "createdat",
    ];
    const filteredResults = approveOt.filter((item) =>
      searchFields.some((key) =>
        item[key]
          ?.toString()
          .toLowerCase()
          .includes(globalSearchValue.toString().toLowerCase())
      )
    );
    setOverTimeDetails(filteredResults);
    setListViewPage(0);
  }, [approveOt, globalSearchValue]);

  return loader === true ? (
    <Loader info="Loading..." />
  ) : (
    <React.Fragment>
      <Mui.Grid xs={9.3}></Mui.Grid>
      <Mui.Grid xs={2.4} sx={{ paddingTop: 1, marginTop: -7 }}>
        <DateRangePicker
          handleStartDate={handleStartDate}
          selectedDateRange={selectedDateRange}
        />
      </Mui.Grid>

      <Mui.Grid container sx={{ paddingRight: 2, paddingLeft: 2 }}>
        <Mui.TableContainer component={Mui.Paper} sx={{ mt: 2, width: "100%" }}>
          <Mui.Table>
            <Mui.TableHead
              sx={{
                backgroundColor: themes.primary,
              }}
            >
              <Mui.TableRow sx={tableRowSx}>
                {overTimeKeyPair.map((header) => (
                  <Mui.TableCell
                    sx={{ color: themes.blackColor, fontWeight: "bold" }}
                  >
                    {header.name}
                  </Mui.TableCell>
                ))}
              </Mui.TableRow>
            </Mui.TableHead>
            <Mui.TableBody>
              {overTimeDetails?.length === 0 ? (
                <Mui.TableRow sx={tableRowSx}>
                  <Mui.TableCell colSpan={12}>
                    <Mui.Alert severity="info" sx={{ margin: 2 }}>
                      No data available
                    </Mui.Alert>
                  </Mui.TableCell>
                </Mui.TableRow>
              ) : (
                overTimeDetails
                  ?.slice(
                    listViewPage * rowsPerPage,
                    listViewPage * rowsPerPage + rowsPerPage
                  )
                  .map((department) => (
                    <Mui.TableRow sx={tableRowSx}>
                      {overTimeKeyPair.map((header) => (
                        <Mui.TableCell sx={{ color: themes.blackColor }}>
                          {department[header.key]
                            ? department[header.key]
                            : "N/A"}
                        </Mui.TableCell>
                      ))}
                    </Mui.TableRow>
                  ))
              )}
            </Mui.TableBody>
          </Mui.Table>
        </Mui.TableContainer>
      </Mui.Grid>
      {overTimeDetails?.length > 25 && (
        <Mui.TablePagination
          className="custom-pagination"
          component="div"
          rowsPerPageOptions={[25, 50, 75, 100]}
          count={overTimeDetails?.length}
          rowsPerPage={rowsPerPage}
          page={listViewPage}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      )}
    </React.Fragment>
  );
};
