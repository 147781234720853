import React, { useState, useEffect } from "react";
import * as Mui from "@mui/material";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { Check, Close } from "@mui/icons-material";
import { CustomButton } from "components/CustomComponents/CustomButton";
import { showToast } from "services/utils/Status";
import { GlobalDialogBox } from "components/GlobalDialogBox";
import { useAutocomplete } from "@mui/base/useAutocomplete";
import { autocompleteClasses } from "@mui/material/Autocomplete";
import sessionHandling from "services/utils/notificationUtils";
import { customGetAPI } from "services/utils/CustomAPI";
import { customAPI } from "services/utils/CustomAPI";
import { useFetch } from "services/hooks/useFetch";
export const EditGroup = ({
  openDialog,
  setOpenDialogBox,
  editUserGroup,
  unAssignedUsers,
  setGetUpdatedData,
}) => {
  const { companyId } = useSelector((state) => state.userReducer);
  const { domain, token } = useSelector((state) => state.tokenReducer);
  const [userGroupName, setUserGroupName] = useState(editUserGroup.name);
  const [helperTextValidation, setHelperTextValidation] = useState("");
  const { result } = useFetch(
    `${domain}user-group-products-details/?group_id=${editUserGroup.id}`
  );
  const [selectedMembers, setSelectedMembers] = useState([]);
  const [buttonLoader, setButtonLoader] = useState(false);

  useEffect(() => {
    if (result && Array.isArray(result.users)) {
      setSelectedMembers(
        result.users.map((user) => ({
          ...user,
          label: user.user_name,
        }))
      );
    }
  }, [result]);

  const autocompleteVisible = true;
  const {
    getRootProps,
    getInputProps,
    getTagProps,
    getListboxProps,
    getOptionProps,
    groupedOptions,
    focused,
    setAnchorEl,
  } = useAutocomplete({
    id: "customized-hook-demo",
    multiple: true,
    options: unAssignedUsers.map((obj) => ({
      ...obj,
      label: obj.user_name,
    })),
    getOptionLabel: (option) => option.user_name,
    value: selectedMembers,
    onChange: (event, newValue) => {
      setSelectedMembers(newValue);
    },
  });

  const handleClosePopup = () => {
    setOpenDialogBox(false);
    setGetUpdatedData(true);
  };

  function debounce(func, wait) {
    let timeout;
    return function (...args) {
      const context = this;
      clearTimeout(timeout);
      timeout = setTimeout(() => func.apply(context, args), wait);
    };
  }
  const validateUserGroupName = async () => {
    if (
      userGroupName.trim().length >= 3 &&
      userGroupName !== editUserGroup.name
    ) {
      const res = await customGetAPI(
        `${domain}check/usergroup?name=${userGroupName.trim()}&company_id=${companyId}`
      );
      if (res?.responseDetails?.exists) {
        setHelperTextValidation("Group name is already exists");
      } else {
        updateUserGroup();
      }
    } else if (userGroupName === editUserGroup.name) {
      updateUserGroup();
    }
  };

  const updateUserGroup = debounce(async () => {
    if (userGroupName.trim() !== "" && helperTextValidation === "") {
      if (userGroupName !== "") {
        setButtonLoader(true);
        const response = await customAPI(
          `${domain}user-group-details/`,
          "PUT",
          {
            group_id: editUserGroup.id,
            company_id: companyId,
            name: userGroupName,
            selected_members: selectedMembers,
          }
        );
        if (response.ok) {
          showToast("success", "User group updated successfully");
          handleClosePopup();
          setAnchorEl(null);
          setButtonLoader(false);
        } else {
          setAnchorEl(null);
          setButtonLoader(false);
        }
      }
    } else if (userGroupName.trim() === "") {
      setHelperTextValidation("User group name is required");
    }
  }, 5);

  const checkSpecialChar = (e) => {
    if (!/^[a-zA-Z0-9 ]*$/.test(e.key)) {
      e.preventDefault();
    }
  };

  return (
    <GlobalDialogBox
      open={openDialog}
      title="Edit User Group"
      handleCloseDialog={handleClosePopup}
    >
      <Mui.Stack>
        <Mui.Typography sx={{ paddingBottom: 0.5 }}>Group Name</Mui.Typography>
        <Mui.TextField
          sx={{ paddingBottom: 2 }}
          placeholder="Enter Group Name"
          value={userGroupName}
          error={
            helperTextValidation === "User group name is required" ||
            helperTextValidation === "Group name is already exists" ||
            helperTextValidation === "Minimum 3 characters required"
              ? true
              : false
          }
          helperText={
            helperTextValidation === "User group name is required" ||
            helperTextValidation === "Group name is already exists" ||
            helperTextValidation === "Minimum 3 characters required"
              ? helperTextValidation
              : null
          }
          InputProps={{
            inputProps: { maxLength: 64 },
          }}
          onChange={(event) => {
            setUserGroupName(event.target.value);
            if (helperTextValidation === "User group name is required") {
              setHelperTextValidation("");
            }
          }}
          onKeyPress={checkSpecialChar}
          onBlur={async () => {
            if (
              userGroupName.replace(/\s/g, "").length >= 3 &&
              editUserGroup.name !== userGroupName.replace(/\s/g, "")
            ) {
              try {
                const response = await fetch(
                  `${domain}check/usergroup?name=${userGroupName.trim()}&company_id=${companyId}`,
                  {
                    method: "GET",
                    headers: {
                      "content-type": "application/json",
                      Authorization: `token ${token}`,
                    },
                  }
                );
                const res = await response.json();
                if (response.ok) {
                  if (res?.exists) {
                    setHelperTextValidation("Group name is already exists");
                  } else {
                    setHelperTextValidation("");
                  }
                } else if (response.status === 409) {
                  sessionHandling();
                } else {
                  throw new Error(res.error);
                }
              } catch (error) {
                showToast("error", error.message);
              }
            } else {
              if (userGroupName.replace(/\s/g, "").length <= 3) {
                setHelperTextValidation("Minimum 3 characters required");
              }
            }
          }}
        />
        <Mui.Typography sx={{ paddingBottom: 0.5 }}>Users</Mui.Typography>
        <div {...getRootProps()} style={{ padding: 2 }}>
          <InputWrapper
            sx={{
              width: "100%",
              minHeight: 50,
              mt: 1,
              mb: 1,
              borderRadius: "15px",
              padding: 3,
            }}
            ref={setAnchorEl}
            className={autocompleteVisible || focused ? "focused" : ""}
            autocompleteVisible={autocompleteVisible}
          >
            {selectedMembers.map((option, index) => (
              <StyledTag label={option.user_name} {...getTagProps({ index })} />
            ))}
            <input {...getInputProps()} />
          </InputWrapper>
        </div>
        {groupedOptions.length > 0 ? (
          <Listbox
            {...getListboxProps()}
            autocompleteVisible={autocompleteVisible}
          >
            {groupedOptions.map((option, index) => (
              <li {...getOptionProps({ option, index })}>
                <span>{option.user_name}</span>
                <Check fontSize="small" />
              </li>
            ))}
          </Listbox>
        ) : null}
        <Mui.Grid container sx={{ padding: 1, justifyContent: "flex-end" }}>
          <Mui.Grid item>
            <CustomButton
              actionFuntion={() => validateUserGroupName()}
              actionName="Save"
              typeName="submit"
              disableAction={openDialog && buttonLoader ? buttonLoader : false}
            />
          </Mui.Grid>
        </Mui.Grid>
      </Mui.Stack>
    </GlobalDialogBox>
  );
};

const InputWrapper = Mui.styled("div")(
  ({ theme, autocompleteVisible }) => `
   width: 100%; 
   border: 1px solid ${theme.palette.mode === "dark" ? "#434343" : "#d9d9d9"};
   background-color: ${theme.palette.mode === "dark" ? "#141414" : "#fff"};
   border-radius: 4px;
   padding: 1px;
   display: flex;
   flex-wrap: wrap;
   
   &:hover {
     border-color: ${theme.palette.mode === "dark" ? "#177ddc" : "#40a9ff"};
   }

   ${
     autocompleteVisible
       ? `
       &.focused {
         border-color: ${theme.palette.mode === "dark" ? "#177ddc" : "#40a9ff"};
         box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
       }
     `
       : ""
   }

   & input {
     background-color: ${theme.palette.mode === "dark" ? "#141414" : "#fff"};
     color: ${
       theme.palette.mode === "dark"
         ? "rgba(255,255,255,0.65)"
         : "rgba(0,0,0,.85)"
     };
    
    
     flex-grow: 1;
     border: 0;
     margin: 0;
     outline: 0;
   }
`
);

const Listbox = Mui.styled("ul")(
  ({ theme, autocompleteVisible }) => `
    width: 100%;
   margin: 2px 0 0;
   padding: 0;
   position: relative;
   list-style: none;
   background-color: ${theme.palette.mode === "dark" ? "#141414" : "#fff"};
   overflow: auto;
   max-height: 100px;
   border-radius: 4px;
   box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
   z-index: 1;
  
   ${
     autocompleteVisible
       ? `
     background-color: ${theme.palette.mode === "dark" ? "#141414" : "#fff"};
   `
       : ""
   }
  
   & li {
     padding: 5px 12px;
     display: flex;
  
     & span {
       flex-grow: 1;
     }
  
     & svg {
       color: transparent;
     }
   }
  
   & li[aria-selected='true'] {
     background-color: ${theme.palette.mode === "dark" ? "#2b2b2b" : "#fafafa"};
     font-weight: 600;
  
     & svg {
       color: #1890ff;
     }
   }
  
   & li.${autocompleteClasses.focused} {
     background-color: ${theme.palette.mode === "dark" ? "#003b57" : "#e6f7ff"};
     cursor: pointer;
  
     & svg {
       color: currentColor;
     }
   }
`
);

function Tag(props) {
  const { label, onDelete, ...other } = props;
  return (
    <div {...other}>
      <span>{label}</span>
      <Close onClick={onDelete} />
    </div>
  );
}

Tag.propTypes = {
  label: PropTypes.string.isRequired,
  onDelete: PropTypes.func.isRequired,
};

const StyledTag = Mui.styled(Tag)(
  ({ theme }) => `
  display: flex;
  align-items: center;
  height: 34px;
  margin: 2px;
  line-height: 22px;
  border-radius: 25px;
  background-color: ${
    theme.palette.mode === "dark" ? "rgba(255,255,255,0.08)" : "#fafafa"
  };
  border: 1px solid ${theme.palette.mode === "dark" ? "#303030" : "#e8e8e8"};
  border-radius: 2px;
  box-sizing: content-box;
  padding: 0 4px 0 10px;
  outline: 0;
  overflow: hidden;
  border-radius: 25px;

  &:focus {
    border-color: ${theme.palette.mode === "dark" ? "#177ddc" : "#40a9ff"};
    background-color: ${theme.palette.mode === "dark" ? "#003b57" : "#e6f7ff"};
  }

  & span {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  & svg {
    font-size: 12px;
    cursor: pointer;
    padding: 4px;
  }
`
);
