import React, { useState, useEffect } from "react";
import * as Mui from "@mui/material";
import { useFetch } from "services/hooks/useFetch";
import { useSelector } from "react-redux";
import { themes, tableContainerSx } from "services/constants";
import { MoreVert } from "@mui/icons-material";
import { AddPayRunCycle } from "./AddPayRunCycle";
import { EditPayRunCycle } from "./EditPayRunCycle";
import { GlobalDialogBox } from "components/GlobalDialogBox";
import { CustomButton } from "components/CustomComponents/CustomButton";
import { customAPI } from "services/utils/CustomAPI";
import { showToast } from "services/utils/Status";
import { payrunCycleTableFilterKeys } from "pages/PayRoll/PayRollHeaders";
export const PayrunCycle = () => {
  const [displayScreen, setDisplayScreen] = useState("ListView");
  const { domain, token, globalSearchValue } = useSelector(
    (state) => state.tokenReducer
  );
  const [anchorElMenu, setAnchorElMenu] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [actionRowData, setActionRowData] = useState(null);
  const [updateData, setUpdateData] = useState(false);
  const { result: payrunCycle, setLoad: reCallApi } = useFetch(
    `${domain}pay-run-cycles/`
  );
  const [payrunCycleDetailsData, setPayrunCycleDetailsData] =
    useState(payrunCycle);
  const [rowsPerPage, setLeaveRowsPerPage] = useState(25);
  const [page, setPage] = useState(0);

  const handleChangeLeavePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeLeaveRowsPerPage = (event) => {
    setLeaveRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleClose = () => {
    setOpenDialog(false);
  };

  const handleDelete = async () => {
    let payload = {
      isdeleted: true,
    };
    const response = await customAPI(
      `${domain}pay-run-cycles/${actionRowData?.id}/`,
      "PATCH",
      payload
    );
    if (response.ok) {
      showToast("error", "Payrun cycle delete successfully,");
      reCallApi(true);
      setOpenDialog(false);
    }
  };

  useEffect(() => {
    const filteredResults = payrunCycle.filter((item) =>
      payrunCycleTableFilterKeys.some((key) =>
        item[key]
          ?.toString()
          .toLowerCase()
          .includes(globalSearchValue.toString().toLowerCase())
      )
    );
    setPayrunCycleDetailsData(filteredResults);
    setPage(0);
  }, [globalSearchValue, payrunCycle]);

  return (
    <React.Fragment>
      {displayScreen === "AddPayRunCycle" ? (
        <AddPayRunCycle
          setDisplayScreen={setDisplayScreen}
          reCallApi={reCallApi}
          domain={domain}
          token={token}
        />
      ) : displayScreen === "EditPayRunCycle" ? (
        <EditPayRunCycle
          setDisplayScreen={setDisplayScreen}
          reCallApi={reCallApi}
          domain={domain}
          token={token}
          actionRowData={actionRowData}
          updateData={updateData}
          setUpdateData={setUpdateData}
        />
      ) : (
        <Mui.Grid container>
          <Mui.Grid
            container
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              alignItems: "flex-end",
              padding: 1,
            }}
          >
            <CustomButton
              actionFuntion={() => {
                setDisplayScreen("AddPayRunCycle");
              }}
              actionName="Add Payrun Cycle"
              typeName="submit"
            />
          </Mui.Grid>
          <Mui.TableContainer sx={tableContainerSx}>
            <Mui.Table sx={{ border: `3px solid ${themes.sidebarDivider}` }}>
              <Mui.TableHead
                sx={{
                  backgroundColor: themes.primary,
                  position: "sticky",
                  // top: -1,
                  zIndex: 10,
                }}
              >
                <Mui.TableRow
                  sx={{
                    "& .MuiTableCell-root": {
                      height: "10px",
                      padding: "6px 5px",
                    },
                  }}
                >
                  <Mui.TableCell
                    sx={{ color: themes.blackColor, fontWeight: "bold" }}
                  >
                    Pay Cycle
                  </Mui.TableCell>
                  <Mui.TableCell
                    sx={{
                      color: themes.blackColor,
                      fontWeight: "bold",
                    }}
                  >
                    Start Day
                  </Mui.TableCell>
                  <Mui.TableCell
                    sx={{
                      color: themes.blackColor,
                      fontWeight: "bold",
                    }}
                  >
                    Payment calculation
                  </Mui.TableCell>
                  <Mui.TableCell
                    sx={{
                      color: themes.blackColor,
                      fontWeight: "bold",
                    }}
                  >
                    Payrun Cycle Name
                  </Mui.TableCell>
                  <Mui.TableCell
                    sx={{
                      color: themes.blackColor,
                      fontWeight: "bold",
                    }}
                  >
                    Action
                  </Mui.TableCell>
                </Mui.TableRow>
              </Mui.TableHead>
              <Mui.TableBody>
                {payrunCycleDetailsData?.length === 0 ? (
                  <Mui.TableRow>
                    <Mui.TableCell colSpan={12} align="center">
                      <Mui.Alert severity="info">No data available</Mui.Alert>
                    </Mui.TableCell>
                  </Mui.TableRow>
                ) : (
                  payrunCycleDetailsData
                    ?.slice(
                      page * rowsPerPage,
                      page * rowsPerPage + rowsPerPage
                    )
                    ?.map((paycycleData, index) => (
                      <React.Fragment>
                        <Mui.TableRow
                          sx={{
                            "& .MuiTableCell-root": {
                              height: "5px",
                              padding: "3px 6px",
                            },
                          }}
                        >
                          <Mui.TableCell>
                            {paycycleData?.pay_run_cycle_type}
                          </Mui.TableCell>
                          <Mui.TableCell>
                            {paycycleData?.pay_run_cycle_type === "Monthly"
                              ? paycycleData?.date_of_month
                              : paycycleData?.day_of_week}
                          </Mui.TableCell>

                          <Mui.TableCell>
                            {paycycleData?.payment_basis}
                          </Mui.TableCell>
                          <Mui.TableCell>{paycycleData?.name}</Mui.TableCell>
                          <Mui.TableCell>
                            <Mui.IconButton
                              onClick={(event) => {
                                setActionRowData(paycycleData);
                                setAnchorElMenu(event.currentTarget);
                                setUpdateData(true);
                              }}
                            >
                              <MoreVert />
                            </Mui.IconButton>
                            <Mui.Menu
                              anchorEl={anchorElMenu}
                              open={Boolean(anchorElMenu)}
                              onClose={() => {
                                setAnchorElMenu(null);
                              }}
                              elevation={1}
                              transformOrigin={{
                                horizontal: "right",
                                vertical: "top",
                              }}
                              anchorOrigin={{
                                horizontal: "right",
                                vertical: "bottom",
                              }}
                            >
                              <Mui.MenuItem
                                sx={{ width: "100%" }}
                                onClick={() => {
                                  setDisplayScreen("EditPayRunCycle");
                                  setAnchorElMenu(null);
                                }}
                              >
                                Edit
                              </Mui.MenuItem>
                              <Mui.MenuItem
                                sx={{ width: "100%" }}
                                onClick={() => {
                                  setOpenDialog(true);
                                  setAnchorElMenu(null);
                                }}
                              >
                                Delete
                              </Mui.MenuItem>
                            </Mui.Menu>
                          </Mui.TableCell>
                        </Mui.TableRow>
                      </React.Fragment>
                    ))
                )}
              </Mui.TableBody>
            </Mui.Table>
          </Mui.TableContainer>
          <Mui.Grid
            container
            sx={{
              paddingTop: 1,
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              alignItems: "flex-end",
              paddingRight: 1,
            }}
          >
            {payrunCycleDetailsData.length >= 25 ? (
              <Mui.TablePagination
                className="custom-pagination"
                component="div"
                rowsPerPageOptions={[25, 50, 75, 100]}
                count={payrunCycleDetailsData.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangeLeavePage}
                onRowsPerPageChange={handleChangeLeaveRowsPerPage}
              />
            ) : null}
          </Mui.Grid>
        </Mui.Grid>
      )}
      <GlobalDialogBox
        handleCloseDialog={handleClose}
        open={openDialog}
        title="Remove Payrun Cycle"
      >
        <Mui.Grid container>
          <Mui.Typography>
            Do you want to remove this payrun cycle?
          </Mui.Typography>
        </Mui.Grid>
        <Mui.Grid container>
          <Mui.Grid item xs={8}></Mui.Grid>
          <Mui.Grid item xs={2}>
            <CustomButton
              actionFuntion={handleClose}
              actionName="No"
              typeName="submit"
            />
          </Mui.Grid>
          <Mui.Grid item xs={2}>
            <CustomButton
              actionFuntion={() => handleDelete()}
              actionName="Yes"
              typeName="submit"
            />
          </Mui.Grid>
        </Mui.Grid>
      </GlobalDialogBox>
    </React.Fragment>
  );
};
