import React, { useState } from "react";
import * as Mui from "@mui/material";
import styled from "styled-components";
import { themes } from "services/constants";
import { Close } from "@mui/icons-material";
import { AttendanceDetails } from "./AttendanceDetails";
import { PaymentDetails } from "./PaymentDetails";
export const EditAndSidePeek = ({
  openSidePeek,
  setOpenSidePeek,
  editUserDetails,
  companyId,
  reloadApi,
  viewPayrunDetails,
  payrunData,
  status,
}) => {
  const [doAnyAct, setDoAnyAct] = useState(false);
  const [netPayAmount, setNetPayAmount] = useState("");
  const [tabIndicator, setTabIndicator] = useState(0);
  const [attendanceScreenData, setAttendanceScreenData] = useState(null);
  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Mui.Tab" || event.key === "Shift")
    ) {
      return;
    }
    setOpenSidePeek(open);
    if (open === false) {
      if (doAnyAct) {
        setDoAnyAct(false);
      }
    }
  };
  const renderTabContent = () => {
    switch (tabIndicator) {
      case 0:
        return (
          <PaymentDetails
            companyId={companyId}
            reloadApi={reloadApi}
            viewPayrunDetails={viewPayrunDetails}
            payrunData={payrunData}
            editUserDetails={editUserDetails}
            setNetPayAmount={setNetPayAmount}
            status={status}
            setAttendanceScreenData={setAttendanceScreenData}
          />
        );
      case 1:
        return (
          <AttendanceDetails
            editUserDetails={editUserDetails}
            viewPayrunDetails={viewPayrunDetails}
            status={status}
            attendanceScreenData={attendanceScreenData}
          />
        );

      default:
        alert("They have no option to view");
    }
  };

  const handleChange = (event, newValue) => {
    setTabIndicator(newValue);
  };

  return (
    <React.Fragment>
      <Mui.Drawer
        anchor="right"
        open={openSidePeek}
        onClose={toggleDrawer("right", false)}
        PaperProps={{
          sx: {
            width: {
              xs: 300,
              sm: 360,
              md: 500,
              lg: 700,
            },
          },
        }}
      >
        <Mui.Grid
          container
          sx={{
            padding: 1,
            backgroundColor: themes.primaryIndicator,
          }}
        >
          <Mui.Grid xs={11}>
            <Mui.Typography
              sx={{ padding: 1, fontSize: 20, fontWeight: "bold" }}
            >
              Employee Details
            </Mui.Typography>
          </Mui.Grid>
          <Mui.Grid xs={1}>
            <Mui.IconButton
              sx={{
                // backgroundColor: themes.blackColor,
                "&:hover": {
                  backgroundColor: themes.whiteColor,
                },
              }}
              onClick={() => setOpenSidePeek(false)}
            >
              <Close sx={{ color: themes.redColor }} />
            </Mui.IconButton>
          </Mui.Grid>
        </Mui.Grid>
        <Mui.Grid container sx={{ paddingTop: 0.5 }}></Mui.Grid>
        <Mui.Grid
          container
          sx={{
            paddingLeft: 1,
            backgroundColor: themes.formBackground,
            paddingBottom: 1,
          }}
        >
          <Mui.Grid xs={1}>
            <Mui.Avatar
              sx={{ width: 60, height: 60 }}
              alt="Remy Sharp"
              src={editUserDetails.image_url}
            />
          </Mui.Grid>
          <Mui.Grid xs={4} sx={{ paddingLeft: 2, paddingTop: 1 }}>
            <Mui.Typography sx={{ color: themes.headLine }}>
              {editUserDetails.user_name}
            </Mui.Typography>
            <Mui.Typography sx={{ color: themes.shadowText }}>
              Emp.ID : {editUserDetails.employee_id}
            </Mui.Typography>
          </Mui.Grid>
          <Mui.Grid xs={2.5} sx={{ paddingTop: 1 }}>
            <Mui.Typography sx={{ color: themes.shadowText }}>
              Per Day
            </Mui.Typography>
            <Mui.Typography sx={{ color: themes.shadowText }}>
              {editUserDetails?.per_day_salary}
            </Mui.Typography>
          </Mui.Grid>
          <Mui.Grid xs={2.5} sx={{ paddingTop: 1 }}>
            <Mui.Typography sx={{ color: themes.shadowText }}>
              Net Pay
            </Mui.Typography>
            <Mui.Typography sx={{ color: themes.shadowText }}>
              {netPayAmount}
            </Mui.Typography>
          </Mui.Grid>
          <Mui.Grid xs={2} sx={{ paddingTop: 1.5 }}></Mui.Grid>
        </Mui.Grid>
        <Mui.Grid container sx={{ paddingTop: 0.5 }}></Mui.Grid>
        <StyledGrid container>
          <ContentGrid item xs={12}>
            <Mui.Tabs
              selectionFollowsFocus
              textColor={themes.primaryIndicator}
              TabIndicatorProps={{
                style: {
                  backgroundColor: themes.primaryIndicator,
                },
              }}
              value={tabIndicator}
              onChange={handleChange}
              centered
              variant="fullWidth"
            >
              <Mui.Tab
                aria-label="phone"
                sx={{
                  "&.Mui-selected": {
                    backgroundColor: themes.shadowBackGround,
                  },
                }}
                label={
                  <Mui.Typography
                    sx={{
                      fontWeight: "bold",
                      textTransform: "capitalize",
                      color:
                        tabIndicator === 0
                          ? themes.tagBlurColor
                          : themes.dividerBorder,
                    }}
                  >
                    Payment
                  </Mui.Typography>
                }
                iconPosition="end"
              />
              <Mui.Tab
                aria-label="phone"
                sx={{
                  "&.Mui-selected": {
                    backgroundColor: themes.shadowBackGround, // Active tab background color
                  },
                }}
                label={
                  <Mui.Typography
                    sx={{
                      fontWeight: "bold",
                      textTransform: "capitalize",
                      color:
                        tabIndicator === 1
                          ? themes.tagBlurColor
                          : themes.dividerBorder,
                    }}
                  >
                    Attendance
                  </Mui.Typography>
                }
                iconPosition="end"
              />
            </Mui.Tabs>
            <Mui.Grid container sx={{ paddingTop: 0.1 }}></Mui.Grid>
            {renderTabContent()}
          </ContentGrid>
        </StyledGrid>
      </Mui.Drawer>
    </React.Fragment>
  );
};

const StyledGrid = styled(Mui.Grid)`
  height: 80vh;
`;

const ContentGrid = styled(Mui.Grid)`
  width: 80%;
`;
